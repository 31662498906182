import React, { useContext } from "react";

import { AnnotatedPlan } from "types/explain";

const PlanContext = React.createContext<ComparablePlanType | undefined>(
  undefined,
);

const ComparePlanContext = React.createContext<ComparablePlanType | undefined>(
  undefined,
);

export type ComparablePlanType = {
  id: string;
  label: string;
  url?: string;
  fingerprint: string;
  seenAt: number;
  plan: AnnotatedPlan;
  runtime: number | undefined;
  totCost: number | undefined;
  ioBytes: number | undefined;
  ioMs: number | undefined;
};

export type ComparablePlanSummaryType = Pick<
  ComparablePlanType,
  "id" | "fingerprint" | "label" | "runtime" | "ioMs"
>;

const WithExplainPlan = ({
  children,
  plan,
  comparePlan,
}: {
  children: React.ReactNode;
  plan: ComparablePlanType;
  comparePlan?: ComparablePlanType;
}) => {
  return (
    <PlanContext.Provider value={plan}>
      <ComparePlanContext.Provider value={comparePlan}>
        {children}
      </ComparePlanContext.Provider>
    </PlanContext.Provider>
  );
};

export function useCurrentPlan() {
  return useContext(PlanContext);
}

export function useCurrentComparePlan() {
  return useContext(ComparePlanContext);
}

export default WithExplainPlan;
