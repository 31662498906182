import "jquery-ujs";
import "bootstrap/js/dist/tab";

// Timezone setting
import moment from "moment-timezone";

export const setTimezone = (timezone: string) => {
  moment.tz.setDefault(timezone);
};

import React from "react";
import { createRoot } from "react-dom/client";

// Core components that can render the page
import App from "../components/App";
import Page from "../components/Page";
import ApiExplorer from "../components/ApiExplorer";

// Top-level React components
import AccountSettings from "../components/AccountSettings";
import GoogleSignIn from "../components/GoogleSignIn";
import Login from "../components/Login";
import Signup from "../components/Signup";

const topLevelComponents = {
  AccountSettings,
  GoogleSignIn,
  Login,
  Signup,
};

export const renderTopLevelComponent = (
  graphqlUri: string,
  id: string,
  name: string,
  props: { [key: string]: any },
) => {
  const container = document.getElementById(id);
  if (container) {
    const root = createRoot(container);
    root.render(
      <App graphqlUri={graphqlUri}>
        {React.createElement(topLevelComponents[name], props)}
      </App>,
    );
  }
};

export const renderPageComponent = (
  graphqlUri: string,
  id: string,
  props: { [key: string]: any },
) => {
  const container = document.getElementById(id);
  if (container) {
    const root = createRoot(container);
    root.render(
      <App graphqlUri={graphqlUri}>
        <Page {...props} />
      </App>,
    );
  }
};

export const renderApiExplorer = (
  graphqlUri: string,
  enterpriseEdition: boolean,
  id: string,
) => {
  const container = document.getElementById(id);
  if (container) {
    const root = createRoot(container);
    root.render(
      <ApiExplorer
        graphqlUri={graphqlUri}
        enterpriseEdition={enterpriseEdition}
      />,
    );
  }
};
