import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  QueryForWorkbookQuery,
  useQueryForWorkbookQuery,
  useQueryForWorkbookLazyQuery,
} from "./gql/Query.generated";
import { useCreateExplainWorkbookMutation } from "./gql/Mutation.generated";

import Loading from "components/Loading";
import Panel from "components/Panel";
import PageContent from "components/PageContent";
import PanelSection from "components/PanelSection";
import Callout from "components/Callout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faExclamationCircle,
  faRotateLeft,
} from "@fortawesome/pro-solid-svg-icons";
import SQL from "components/SQL";
import { useRoutes } from "utils/routes";
import classNames from "classnames";
import { CreateSteps, WorkbookCreationHeader } from "../ChooseParameters";
import CopyToClipboard from "components/CopyToClipboard";
import Tip from "components/Tip";
import Button from "components/Button";
import { cleanupQuery } from "./util";

export type LocationState = {
  queryText: string;
  name: string;
  description: string;
};
type ParamNamesType = {
  [key: string]: {
    name: string;
    initialName: string;
    errorMessage?: string;
  };
};
type CheckedQuery = QueryForWorkbookQuery["getQueryForWorkbook"] & {
  queryText: string;
};

const MERGE_MESSAGE = "Name already taken.";

const ReviewQuery = () => {
  // queryId can be passed from the Query Details page
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryId = searchParams.get("queryId");

  // state is passed when coming back to Review Query step from steps nav
  const state = location.state as LocationState;
  // initial query to be used for ReviewQueryEditQuery component
  const [initialQuery, setInitialQuery] = useState(state?.queryText ?? "");

  // For WorkbookCreationHeader
  const [name, setName] = useState(
    state?.name ?? (queryId ? `Query #${queryId}` : "New workbook"),
  );
  const [description, setDescription] = useState(state?.description ?? "");

  const { databaseId } = useParams();
  const [checkedQuery, setCheckedQuery] = useState<CheckedQuery>();

  const navigate = useNavigate();
  const { databaseWorkbooks, databaseQuery } = useRoutes();

  const { loading, error, data } = useQueryForWorkbookQuery({
    variables: { databaseId, queryId },
    skip: !queryId || !!checkedQuery || !!initialQuery,
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  if (data) {
    if (data.getQueryForWorkbook.validQuery) {
      // set a normalized query as queryText here, since we don't know the original query
      // when created from the Query Details page
      setCheckedQuery({
        ...data.getQueryForWorkbook,
        queryText: data.getQueryForWorkbook.normalizedQuery,
      });
    } else {
      // This shouldn't happen, but if we do for some reason, we should ideally do better than just showing the error screen
      return <Loading error />;
    }
  }

  // Not a delete as nothing has been persisted yet at this point
  const handleDeleteWorkbook = () => {
    queryId
      ? navigate(databaseQuery(databaseId, queryId))
      : navigate(databaseWorkbooks(databaseId));
  };
  const workbookTitle = (
    <WorkbookCreationHeader
      name={name}
      description={description}
      setName={setName}
      setDescription={setDescription}
      handleDeleteWorkbook={handleDeleteWorkbook}
    />
  );

  return checkedQuery ? (
    <ReviewQueryEditParameters
      checkedQuery={checkedQuery}
      setCheckedQuery={setCheckedQuery}
      setInitialQuery={setInitialQuery}
      name={name}
      description={description}
      workbookTitle={workbookTitle}
    />
  ) : (
    <PageContent
      windowTitle={`EXPLAIN Workbook: ${name}`}
      title={workbookTitle}
      pageCategory="query-tuning"
      pageName="workbooks"
    >
      <ReviewQueryEditQuery
        initialQuery={initialQuery}
        setCheckedQuery={setCheckedQuery}
      />
    </PageContent>
  );
};

const ReviewQueryEditQuery = ({
  initialQuery,
  setCheckedQuery,
}: {
  initialQuery: string;
  setCheckedQuery: React.Dispatch<React.SetStateAction<CheckedQuery>>;
}) => {
  const { databaseId } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [query, setQuery] = useState(initialQuery ?? "");

  const [getQuery, { loading, error }] = useQueryForWorkbookLazyQuery();

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const handleCheckQuery = () => {
    if (query.trim() === "") {
      setErrorMessage("Query text is required");
      return;
    }
    getQuery({
      variables: { databaseId, queryText: query },
      onCompleted: (data) => {
        if (data.getQueryForWorkbook.validQuery) {
          setErrorMessage("");
          setCheckedQuery({
            ...data.getQueryForWorkbook,
            queryText: cleanupQuery(query),
          });
        } else {
          setErrorMessage(data.getQueryForWorkbook.errorMessage);
        }
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };
  function handleUpdateQuery(newQuery: string) {
    setQuery(newQuery);
    setErrorMessage("");
  }

  return (
    <Panel title={<CreateSteps step="step1" />} className="mt-1 flex-grow">
      <PanelSection className="flex-grow flex flex-col items-stretch gap-2">
        <div className="flex-grow">
          <textarea
            className="h-full resize-none rounded border border-[#E8E8EE] p-2 text-[#606060] w-full font-query text-[13px]"
            placeholder="Paste query text here..."
            value={query}
            onChange={(e) => handleUpdateQuery(e.target.value)}
          ></textarea>
        </div>
        {errorMessage && (
          <div className="text-[#C22426]">
            <FontAwesomeIcon icon={faExclamationCircle} /> {errorMessage}
          </div>
        )}
        <div>
          <button
            className="btn btn-success"
            onClick={handleCheckQuery}
            disabled={query === ""}
          >
            Verify Query
          </button>
        </div>
      </PanelSection>
    </Panel>
  );
};

const ReviewQueryEditParameters = ({
  checkedQuery,
  setCheckedQuery,
  setInitialQuery,
  name,
  description,
  workbookTitle,
}: {
  checkedQuery: CheckedQuery;
  setCheckedQuery: React.Dispatch<React.SetStateAction<CheckedQuery>>;
  setInitialQuery: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  description: string;
  workbookTitle: React.ReactNode;
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [paramNames, setParamNames] = useState<ParamNamesType>(
    checkedQuery.parameters.reduce((names, val) => {
      names[val.ref.toString()] = { name: val.name, initialName: val.name };
      return names;
    }, {} as ParamNamesType),
  );
  const [normalizedQuery, setNormalizedQuery] = useState(
    checkedQuery.normalizedQuery,
  );
  const [focusedParamName, setFocusedParamName] = useState(undefined);
  const paramInputsRef = useRef<HTMLDivElement | null>(null);
  const { databaseId } = useParams();
  const navigate = useNavigate();
  const { databaseWorkbookVariants } = useRoutes();

  const [createWorkbook] = useCreateExplainWorkbookMutation();

  const queryTextWithParamNames = normalizedQuery.replace(
    /(?<=\$)\d+/g,
    (match) => {
      const matchedParam = paramNames[match]?.name;
      return matchedParam ?? match;
    },
  );

  const handleCreate = () => {
    if (name === "") {
      setErrorMessage("Name is required");
      return;
    }
    if (checkedQuery.queryText.trim() === "") {
      setErrorMessage("Query text is required");
      return;
    }
    const newAliases = Object.fromEntries(
      Object.entries(paramNames).map(([key, value]) => [key, value.name]),
    );
    createWorkbook({
      variables: {
        databaseId: databaseId,
        name: name,
        queryText: checkedQuery.queryText,
        normalizedQuery: normalizedQuery,
        description: description || null,
        newAliases: newAliases,
      },
      onCompleted: (data) => {
        navigate(
          databaseWorkbookVariants(
            databaseId,
            data.createExplainWorkbook.explainWorkbookId,
          ),
        );
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  function updateErrorMessage(newNames: ParamNamesType) {
    if (Object.values(newNames).some((val) => !!val.errorMessage)) {
      setErrorMessage(
        "Invalid parameter names found. Please check and correct parameter names before proceeding to the next step.",
      );
    } else {
      setErrorMessage("");
    }
  }

  function handleParamNameUpdate(ref: string, newName: string) {
    const paramName = paramNames[ref];
    if (paramName.name !== newName) {
      const oldName = paramName.name;
      paramName.name = newName;
      const newNames = { ...paramNames, [ref]: paramName };
      const names = Object.values(newNames).map((val) => val.name);
      const refIdx = names.findIndex((val) => val === newName);
      paramName.errorMessage = validateParamName(names, refIdx);
      setParamNames(newNames);
      updateErrorMessage(newNames);
      if (focusedParamName == oldName) {
        setFocusedParamName(paramName.name);
      }
    }
  }

  function handleMergeName(ref: string) {
    const inComingName = paramNames[ref].name;
    const baseRef = Object.entries(paramNames).find(
      ([key, value]) => value.name === inComingName && key !== ref,
    )[0];
    // Update normalized query's param ref (parameter values from the original query text will be gone here)
    const newQuery = normalizedQuery.replace(/(?<=\$)\d+/g, (match) => {
      return match === ref ? baseRef : match;
    });
    setNormalizedQuery(newQuery);
    // Make new paramNames without the incoming
    const newNames = { ...paramNames };
    delete newNames[ref];
    setParamNames(newNames);
    updateErrorMessage(newNames);
  }

  return (
    <PageContent
      windowTitle={`EXPLAIN Workbook: ${name}`}
      title={workbookTitle}
      pageCategory="query-tuning"
      pageName="workbooks"
      layout="sidebar"
    >
      <Panel
        title={<CreateSteps step="step1" />}
        secondaryTitle={
          <button
            onClick={() => {
              setCheckedQuery(null);
              setInitialQuery(checkedQuery.queryText);
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
        }
        className="mt-1 flex-grow"
      >
        <PanelSection className="flex-grow flex flex-col items-stretch gap-2">
          <Callout>
            In order to process this query, we normalized it and turned
            positional parameters (<code>$1</code>) into named parameters (
            <code>$param</code>). Because we do not know if a parameter contains
            the same content, we suffix the named parameters with consecutive
            numbers—for example, <code>param</code>, <code>param_2</code>, and{" "}
            <code>param_3</code>.
          </Callout>
          <div className="flex-grow">
            <QueryTextArea
              className="h-full"
              queryText={queryTextWithParamNames}
              focusedParamName={focusedParamName}
              paramInputsRef={paramInputsRef}
            />
          </div>
          {errorMessage && (
            <div className="text-[#C22426]">
              <FontAwesomeIcon icon={faExclamationCircle} /> {errorMessage}
            </div>
          )}
          <div>
            <button
              className="btn btn-success"
              onClick={handleCreate}
              disabled={!!errorMessage}
            >
              Choose Parameters
            </button>
          </div>
        </PanelSection>
      </Panel>
      <div className="h-10">
        {/* margin for above panel; due to layout we can't specify margin there directly */}
      </div>
      <div className="w-[320px] mb-20">
        <Callout className="mb-4" thin>
          {checkedQuery.parameters.length} parameters detected
        </Callout>
        <div className="leading-7 font-medium my-2">
          Rename Parameters{" "}
          <Tip content="We try to infer parameter names from the keys. In some cases, this isn't possible, so we use a generic name instead. You can update the names here." />
        </div>
        <div className="grid gap-2 form-group" ref={paramInputsRef}>
          {Object.keys(paramNames).map((ref) => {
            const paramName = paramNames[ref];
            return (
              <div key={ref}>
                <div className="flex">
                  <input
                    className="form-control"
                    value={paramName.name}
                    onChange={(e) => handleParamNameUpdate(ref, e.target.value)}
                    onFocus={() => setFocusedParamName(paramName.name)}
                    onBlur={() => setFocusedParamName(undefined)}
                  />
                </div>
                {paramName.errorMessage && (
                  <div className="flex items-center mt-1">
                    <div className="text-[#C22426]">
                      {paramName.errorMessage}
                    </div>
                    {paramName.errorMessage === MERGE_MESSAGE && (
                      <>
                        <div className="ml-1 flex-grow">
                          <Tip
                            content={
                              <>
                                Parameter names must be unique. If the
                                conflicting parameters always refer to the same
                                value, they can be merged together. Otherwise
                                they must be renamed.
                              </>
                            }
                          />
                        </div>
                        <Button
                          bare
                          onClick={() => handleMergeName(ref)}
                          className="text-[#337AB7]"
                        >
                          Merge
                        </Button>{" "}
                        <span className="mx-1">or</span>
                        <Button
                          bare
                          onClick={() =>
                            handleParamNameUpdate(ref, paramName.initialName)
                          }
                          className="text-[#337AB7]"
                        >
                          Reset
                        </Button>
                      </>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <Button
          bare
          className="text-[#337AB7] mb-2"
          onClick={() => {
            const newValues = { ...paramNames };
            Object.values(newValues).forEach((value) => {
              value.name = value.initialName;
              value.errorMessage = null;
            });
            setParamNames(newValues);
            setErrorMessage("");
          }}
        >
          <FontAwesomeIcon icon={faRotateLeft} /> Reset all names
        </Button>
      </div>
    </PageContent>
  );
};

export const QueryTextArea = ({
  queryText,
  className,
  showCopyToClipboard,
  focusedParamName,
  paramInputsRef,
}: {
  queryText: string;
  className?: string;
  showCopyToClipboard?: boolean;
  focusedParamName?: string;
  paramInputsRef?: React.MutableRefObject<HTMLDivElement>;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    let scrollIntoElement: HTMLElement = undefined;
    let needsScroll = true;
    const highlight = "bg-[#fcf8e3]";
    ref.current.querySelectorAll(".hljs-alias").forEach((elem: HTMLElement) => {
      const currentName = elem.innerText.substring(1); // Parameter name without leading $

      if (currentName == focusedParamName) {
        elem.classList.add(highlight);
        if (
          elem.getBoundingClientRect().top <
            ref.current.getBoundingClientRect().top ||
          elem.getBoundingClientRect().bottom >
            ref.current.getBoundingClientRect().bottom
        ) {
          scrollIntoElement ??= elem;
        } else {
          // some element (param name) is within the current screen, no need to focus
          needsScroll = false;
        }
      } else {
        elem.classList.remove(highlight);
        elem.onclick = () => {
          if (paramInputsRef?.current) {
            (
              paramInputsRef.current.querySelector(
                `input[value=${currentName}]`,
              ) as HTMLElement
            )?.focus();
          }
        };
      }
    });
    if (scrollIntoElement && needsScroll) {
      scrollIntoElement.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }
  }, [queryText, focusedParamName, paramInputsRef]);
  return (
    <div
      className={classNames(
        "relative min-h-[128px] rounded border border-[#E8E8EE] p-2 text-[#606060]",
        className,
      )}
    >
      <div
        className="absolute top-2 bottom-2 left-2 right-2 overflow-y-auto"
        ref={ref}
      >
        {showCopyToClipboard && (
          <CopyToClipboard
            className="float-right text-[12px] text-[#606060] ml-1 relative z-10"
            label="copy"
            content={queryText}
          />
        )}
        <SQL sql={queryText} />
      </div>
    </div>
  );
};

export const validateParamName = (names: string[], index: number): string => {
  const name = names[index];
  const otherNames = [...names.slice(0, index), ...names.slice(index + 1)];
  if (!/^[a-z][a-z0-9_]*$/.test(name)) {
    return "Parameter names must only contain lowercase letters, numbers, and underscores.";
  } else if (otherNames.includes(name)) {
    return MERGE_MESSAGE;
  }
  return "";
};

export default ReviewQuery;
