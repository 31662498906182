// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type DeleteExplainParameterSetsMutationVariables = Types.Exact<{
  workbookId: Types.Scalars["ID"]["input"];
  parameterSetId: Types.Scalars["ID"]["input"];
}>;

export type DeleteExplainParameterSetsMutation = {
  __typename?: "Mutation";
  deleteExplainParameterSets?: {
    __typename?: "ExplainWorkbook";
    id: string;
    parameterRefAliases: Array<string>;
    parameterSets: Array<{
      __typename?: "ParameterSet";
      id: string;
      name: string;
      paramValues: any;
    }>;
    aliasParamMapList: Array<{
      __typename?: "AliasParamMap";
      id: string;
      name: string;
      parameters: any;
    }>;
  } | null;
};

export const DeleteExplainParameterSetsDocument = gql`
  mutation DeleteExplainParameterSets($workbookId: ID!, $parameterSetId: ID!) {
    deleteExplainParameterSets(
      workbookId: $workbookId
      parameterSetId: $parameterSetId
    ) {
      id
      parameterSets {
        id
        name
        paramValues
      }
      parameterRefAliases
      aliasParamMapList {
        id
        name
        parameters
      }
    }
  }
`;
export type DeleteExplainParameterSetsMutationFn = Apollo.MutationFunction<
  DeleteExplainParameterSetsMutation,
  DeleteExplainParameterSetsMutationVariables
>;

/**
 * __useDeleteExplainParameterSetsMutation__
 *
 * To run a mutation, you first call `useDeleteExplainParameterSetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExplainParameterSetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExplainParameterSetsMutation, { data, loading, error }] = useDeleteExplainParameterSetsMutation({
 *   variables: {
 *      workbookId: // value for 'workbookId'
 *      parameterSetId: // value for 'parameterSetId'
 *   },
 * });
 */
export function useDeleteExplainParameterSetsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteExplainParameterSetsMutation,
    DeleteExplainParameterSetsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteExplainParameterSetsMutation,
    DeleteExplainParameterSetsMutationVariables
  >(DeleteExplainParameterSetsDocument, options);
}
export type DeleteExplainParameterSetsMutationHookResult = ReturnType<
  typeof useDeleteExplainParameterSetsMutation
>;
export type DeleteExplainParameterSetsMutationResult =
  Apollo.MutationResult<DeleteExplainParameterSetsMutation>;
export type DeleteExplainParameterSetsMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteExplainParameterSetsMutation,
    DeleteExplainParameterSetsMutationVariables
  >;
