import React from "react";
import { useParams } from "react-router-dom";
import { useRoutes } from "utils/routes";

import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";
import ExplainWorkbookList from "components/ExplainWorkbookList";
import ServerExplainWorkbookList from "components/ServerExplainWorkbookList";
import PageContent from "components/PageContent";
import { useFeature } from "components/OrganizationFeatures";
import UpgradeRequired from "components/UpgradeRequired";

const QueryTuning = () => {
  const { databaseId, serverId } = useParams();
  const { databaseWorkbooks, serverWorkbooks } = useRoutes();
  const hasQueryTuning = useFeature("queryTuning");

  const featureNav = databaseId ? (
    <PageSecondaryNavigation>
      <PageNavLink to={databaseWorkbooks(databaseId)}>Workbooks</PageNavLink>
    </PageSecondaryNavigation>
  ) : (
    <PageSecondaryNavigation>
      <PageNavLink to={serverWorkbooks(serverId)}>Workbooks</PageNavLink>
    </PageSecondaryNavigation>
  );

  return hasQueryTuning ? (
    <PageContent
      title="Query Tuning"
      pageCategory="query-tuning"
      pageName="index"
      featureNav={featureNav}
    >
      {databaseId ? <ExplainWorkbookList /> : <ServerExplainWorkbookList />}
    </PageContent>
  ) : (
    <UpgradeRequired feature="Query Tuning" />
  );
};

export default QueryTuning;
