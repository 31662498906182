import MonitoringUserSetupInstructions from "../../components/MonitoringUserSetupInstructions";
import VerifyConnection from "../../components/VerifyConnection";

Connect to the database you will be monitoring, again as a user with **cloudsqlsuperuser / alloydbsuperuser** privileges.
Then run the following to create a monitoring user (we've generated a random password for you, but you
can replace it with one of your choosing):

<MonitoringUserSetupInstructions />

Note that it is important you run these as a user with **cloudsqlsuperuser / alloydbsuperuser** privileges in order to pass down the full access to statistics tables.

## Verify connection

Let's make sure we can connect to the database with the new user:

<VerifyConnection host="1.2.3.4" />

Write down the username and password of the monitoring user, we will use it in the last step of this guide.
