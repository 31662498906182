// @ts-nocheck
import * as Types from "../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type QueryDetailIndexAdvisorIssuesQueryVariables = Types.Exact<{
  databaseId: Types.Scalars["ID"]["input"];
  queryId: Types.Scalars["ID"]["input"];
}>;

export type QueryDetailIndexAdvisorIssuesQuery = {
  __typename?: "GraphqlQuery";
  getIssues: Array<{
    __typename?: "Issue";
    id: string;
    checkGroupAndName: string;
    databaseId?: string | null;
    state: string;
    descriptionTemplate: string;
    groupingKey: any;
    detailsJson: any;
    descriptionReferences: Array<{
      __typename?: "IssueDescriptionReference";
      param: string;
      name: string;
    }>;
  }>;
};

export const QueryDetailIndexAdvisorIssuesDocument = gql`
  query QueryDetailIndexAdvisorIssues($databaseId: ID!, $queryId: ID!) {
    getIssues(
      databaseId: $databaseId
      referentId: $queryId
      referentType: "Query"
      checkGroupsAndNames: [
        "schema/index_unused"
        "index_advisor/indexing_engine"
      ]
    ) {
      id
      checkGroupAndName
      databaseId
      state
      descriptionTemplate
      descriptionReferences {
        param
        name
      }
      groupingKey
      detailsJson
    }
  }
`;

/**
 * __useQueryDetailIndexAdvisorIssuesQuery__
 *
 * To run a query within a React component, call `useQueryDetailIndexAdvisorIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryDetailIndexAdvisorIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryDetailIndexAdvisorIssuesQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *      queryId: // value for 'queryId'
 *   },
 * });
 */
export function useQueryDetailIndexAdvisorIssuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryDetailIndexAdvisorIssuesQuery,
    QueryDetailIndexAdvisorIssuesQueryVariables
  > &
    (
      | {
          variables: QueryDetailIndexAdvisorIssuesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryDetailIndexAdvisorIssuesQuery,
    QueryDetailIndexAdvisorIssuesQueryVariables
  >(QueryDetailIndexAdvisorIssuesDocument, options);
}
export function useQueryDetailIndexAdvisorIssuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryDetailIndexAdvisorIssuesQuery,
    QueryDetailIndexAdvisorIssuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryDetailIndexAdvisorIssuesQuery,
    QueryDetailIndexAdvisorIssuesQueryVariables
  >(QueryDetailIndexAdvisorIssuesDocument, options);
}
export function useQueryDetailIndexAdvisorIssuesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        QueryDetailIndexAdvisorIssuesQuery,
        QueryDetailIndexAdvisorIssuesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryDetailIndexAdvisorIssuesQuery,
    QueryDetailIndexAdvisorIssuesQueryVariables
  >(QueryDetailIndexAdvisorIssuesDocument, options);
}
export type QueryDetailIndexAdvisorIssuesQueryHookResult = ReturnType<
  typeof useQueryDetailIndexAdvisorIssuesQuery
>;
export type QueryDetailIndexAdvisorIssuesLazyQueryHookResult = ReturnType<
  typeof useQueryDetailIndexAdvisorIssuesLazyQuery
>;
export type QueryDetailIndexAdvisorIssuesSuspenseQueryHookResult = ReturnType<
  typeof useQueryDetailIndexAdvisorIssuesSuspenseQuery
>;
export type QueryDetailIndexAdvisorIssuesQueryResult = Apollo.QueryResult<
  QueryDetailIndexAdvisorIssuesQuery,
  QueryDetailIndexAdvisorIssuesQueryVariables
>;
