import React from "react";

import Callout from "components/Callout";

const QueryTuningCallout = ({
  showPermissionHint,
}: {
  showPermissionHint?: boolean;
}) => {
  return (
    <Callout className="mb-4">
      Query Tuning is now available in beta, helping you optimize slow queries
      by testing different parameters, query variants and comparing their
      EXPLAIN plans.{" "}
      <a href="https://pganalyze.com/docs/query-tuning">Learn more.</a>
      {showPermissionHint && (
        <div className="mt-2">
          You are not allowed to create new workbooks because you do not have
          the <strong>Tune Queries</strong> permission on this database. To
          resolve this issue, ask an admin to add the permission to your
          account. You can still view existing workbooks.
        </div>
      )}
    </Callout>
  );
};

export default QueryTuningCallout;
