import React, { useState } from "react";
import { StripeElementStyle } from "@stripe/stripe-js";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import {
  useAsyncActionFlash,
  useSetFlash,
  useSetNextFlashLocation,
} from "components/WithFlashes";
import { useRoutes } from "utils/routes";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";

import {
  UpdateCreditCardMutation,
  useUpdateCreditCardMutation,
} from "./gql/Mutation.generated";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import Callout from "components/Callout";

const stripeElementStyle: StripeElementStyle = {
  base: {
    fontFamily: '"Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif',
    "::placeholder": {
      color: "#aeaeae",
    },
  },
};

export const CreditCardForm: React.FunctionComponent<{
  cardHolderName: string;
  setCardHolderName: (val: string) => void;
}> = ({ cardHolderName, setCardHolderName }) => {
  return (
    <>
      <div className="mb-4">
        <label className="control-label">Name on card</label>
        <input
          className="card-holder form-control"
          type="text"
          value={cardHolderName}
          onChange={(e) => setCardHolderName(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="control-label" htmlFor="card-number">
          Card number
        </label>
        <CardNumberElement
          className={styles.stripeFormControl}
          options={{ style: stripeElementStyle }}
        />
      </div>
      <div className="flex gap-6 mb-4">
        <div className="flex-grow">
          <label className="control-label">Expiry date</label>
          <CardExpiryElement
            className={styles.stripeFormControl}
            options={{ style: stripeElementStyle }}
          />
        </div>
        <div className="flex-grow max-w-[8rem]">
          <label className="control-label" htmlFor="card-cvc">
            CVC
          </label>
          <CardCvcElement
            className={styles.stripeFormControl}
            options={{ style: stripeElementStyle }}
          />
        </div>
      </div>
    </>
  );
};

const UpdateCreditCard: React.FunctionComponent<{
  organizationSlug: string;
}> = ({ organizationSlug }) => {
  const navigate = useNavigate();
  const setFlash = useSetFlash();
  const setNextFlashLocation = useSetNextFlashLocation();
  const elements = useElements();
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cardHolderName, setCardHolderName] = useState("");
  const { organizationSubscription } = useRoutes();

  const [
    updateBillingCard,
    { called: mutationCalled, loading: mutationLoading, error: mutationError },
  ] = useUpdateCreditCardMutation();
  useAsyncActionFlash({
    called: mutationCalled,
    loading: mutationLoading,
    error: mutationError?.message,
  });

  const updateBillingCardOnCompleted = (data: UpdateCreditCardMutation) => {
    const successMessage =
      "Updated your billing details, this will take effect with your next charge.";
    // 3D Secure handling, similar to activatePlanOnCompleted in ActivePlan component
    if (!data.updateBillingCard.organizationBilling.paymentRequiresAction) {
      // 3D Secure not required
      setFlash("success", successMessage);
      setNextFlashLocation(organizationSubscription(organizationSlug));
      navigate(organizationSubscription(organizationSlug));
      return;
    }

    // Handle 3D Secure
    setLoading(true);
    stripe
      .confirmCardPayment(
        data.updateBillingCard.organizationBilling.stripeClientSecret,
      )
      .then(({ paymentIntent, error }) => {
        setLoading(false);
        if (error) {
          setError(error.message);
        } else if (paymentIntent.status === "succeeded") {
          setFlash("success", successMessage);
          setNextFlashLocation(organizationSubscription(organizationSlug));
          navigate(organizationSubscription(organizationSlug));
        }
      });
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (loading) {
      return;
    }
    if (!window.stripeTestPaymentMethod && (!stripe || !elements)) {
      return;
    }

    if (window.stripeTestPaymentMethod) {
      updateBillingCard({
        variables: {
          organizationSlug,
          paymentMethodId: window.stripeTestPaymentMethod,
        },
        onCompleted: updateBillingCardOnCompleted,
      });
      return;
    }

    setError(null);
    setLoading(true);

    stripe
      .createPaymentMethod({
        type: "card",
        card: elements.getElement("cardNumber"),
        billing_details: {
          name: cardHolderName,
        },
      })
      .then(({ paymentMethod, error }) => {
        setLoading(false);
        if (error) {
          setError(error.message);
        } else {
          updateBillingCard({
            variables: { organizationSlug, paymentMethodId: paymentMethod.id },
            onCompleted: updateBillingCardOnCompleted,
          });
        }
      });
  };

  return (
    <Panel title="Update Credit Card">
      <PanelSection className="w-[42%] min-w-min">
        <Callout className="mb-2">
          If you have outstanding invoices, we will attempt to charge the most
          recent one using the updated payment method.
        </Callout>
        <form onSubmit={handleSubmit}>
          {error && (
            <p
              className="payment-errors alert-danger"
              style={{ display: "inline-block" }}
            >
              {error}
            </p>
          )}
          <CreditCardForm
            cardHolderName={cardHolderName}
            setCardHolderName={setCardHolderName}
          />
          <input
            type="submit"
            name="commit"
            value={
              loading || mutationLoading
                ? "Updating credit card..."
                : "Update credit card"
            }
            disabled={loading || mutationLoading}
            className="btn btn-success"
          ></input>
        </form>
      </PanelSection>
    </Panel>
  );
};

export default UpdateCreditCard;
