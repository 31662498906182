import React, { useState } from "react";
import Panel from "components/Panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCaretDown } from "@fortawesome/pro-regular-svg-icons";
import {
  faCircle,
  faCircleCheck,
  faCircleX,
  faSpinner,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import Button from "components/Button";
import CopyToClipboard from "components/CopyToClipboard";

import { ExplainWorkbookType, ExplainQueryType } from "../ExplainWorkbook";
import { useCreateExplainResultMutation } from "./gql/Mutation.create.generated";
import { useCreateExplainCollectorRunMutation } from "./gql/Mutation.collector.generated";
import { ExplainQueryQuery, useExplainQueryQuery } from "./gql/Query.generated";
type ExplainResultType =
  ExplainQueryQuery["getExplainQuery"]["explainResults"][number];

import { Link, useNavigate } from "react-router-dom";
import { useRoutes } from "utils/routes";
import Grid from "components/Grid";
import Callout from "components/Callout";
import PageContent from "components/PageContent";
import { QueryTextArea } from "../ReviewQuery";
import PillButtonBar from "components/PillButtonBar";
import { formatMs, formatTimeAgo } from "utils/format";
import { ExpandToggle } from "components/Icons";
import Loading from "components/Loading";
import {
  CreateSteps,
  WorkbookCreationHeaderWithWorkbook,
} from "../ChooseParameters";
import { usePauseAfter } from "utils/hooks";
import {
  CreateVariantSteps,
  VariantCreationHeaderWithVariant,
} from "../RewriteQuery";
import PanelSection from "components/PanelSection";
import Tip from "components/Tip";

type RunMethodType = "manual" | "collector";
type RunState =
  | "pending"
  | "success"
  | "success (no analyze)"
  | "errored"
  | "running";
const NO_PARAM_ID = "no param set";

type RunDataType = {
  setId: string;
  name: string;
  state: RunState;
  submitted: number | null;
  runtime: number | null;
  errorMessage: string | null;
};

const RunExplain = ({
  workbook,
  explainQuery,
}: {
  workbook: ExplainWorkbookType;
  explainQuery: ExplainQueryType;
}) => {
  const baseline = workbook.baselineQuery.id === explainQuery.id;
  const workbookTitle = baseline ? (
    <WorkbookCreationHeaderWithWorkbook workbook={workbook} />
  ) : (
    <VariantCreationHeaderWithVariant variant={explainQuery} />
  );

  return (
    <PageContent
      windowTitle={`EXPLAIN Workbook: ${workbook.name}`}
      featureInfo={workbookTitle}
      pageCategory="explains"
      pageName="workbooks"
    >
      <RunExplainContent workbook={workbook} explainQuery={explainQuery} />
    </PageContent>
  );
};

const RunExplainContent = ({
  workbook,
  explainQuery,
}: {
  workbook: ExplainWorkbookType;
  explainQuery: ExplainQueryType;
}) => {
  const supportedCollector = workbook.server.collectorInfo.supportsQueryTuning;
  const [runMethod, setRunMethod] = useState<RunMethodType>(
    supportedCollector && workbook.hasExplainAnalyzeHelper
      ? "collector"
      : "manual",
  );
  const baseline = explainQuery.id === workbook.baselineQuery.id;
  return (
    <Panel
      title={
        baseline ? (
          <CreateSteps step="step3" workbook={workbook} />
        ) : (
          <CreateVariantSteps step="step2" explainQuery={explainQuery} />
        )
      }
    >
      <PanelSection>
        <PillButtonBar
          opts={[
            { value: "manual", label: "Manual Workflow" },
            { value: "collector", label: "Collector Workflow" },
          ]}
          selected={runMethod}
          onChange={(value) => {
            setRunMethod(value);
          }}
          mode="stretch"
        />
      </PanelSection>
      {runMethod === "manual" ? (
        <RunExplainManual workbook={workbook} explainQuery={explainQuery} />
      ) : (
        <RunExplainCollector
          workbook={workbook}
          explainQueryId={explainQuery.id}
        />
      )}
    </Panel>
  );
};

const RunExplainManual = ({
  workbook,
  explainQuery,
}: {
  workbook: ExplainWorkbookType;
  explainQuery: ExplainQueryType;
}) => {
  const navigate = useNavigate();
  const [explainResult, setExplainResult] = useState("");
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>("");
  const { databaseWorkbookVariant } = useRoutes();

  const noParamSets = workbook.parameterSets.length === 0;

  const [createExplainResult] = useCreateExplainResultMutation();

  const allSubmitted = noParamSets
    ? explainQuery.explainResults.length === 1
    : explainQuery.explainResults.length === workbook.parameterSets.length;
  const resultFromCollector = explainQuery.explainResults.some(
    (result) => result.resultSource === "collector_upload",
  );
  const allExecuted =
    explainQuery.explainResults.length > 0 &&
    explainQuery.explainResults.every((result) => result.status === "synced");
  const [focusedSetId, setFocusedSetId] = useState(
    allSubmitted
      ? ""
      : noParamSets
      ? NO_PARAM_ID
      : workbook.parameterSets[0].id,
  );

  // Set statement timeout to 1 minute
  const plannerSettingsCommand = Object.entries(
    explainQuery.plannerSettings as { [key: string]: boolean },
  ).map(([key, value]) => {
    return `SET ${key} = ${value ? "on" : "off"};`;
  });
  const stmtTimeoutCommand = "SET statement_timeout = 60000;";
  const explainCommand = "EXPLAIN (ANALYZE, VERBOSE, BUFFERS, FORMAT JSON)";
  const explainQueryText = noParamSets
    ? explainQuery.queryText
    : explainQuery.queryTextWithParameters.find(
        (val) => val.parameterSetId === focusedSetId,
      )?.queryWithParameters;
  const queryTextWithSemicolon = `${explainQueryText}${
    explainQueryText?.endsWith(";") ? "" : ";"
  }`;
  const explainWithQueryText = [
    ...plannerSettingsCommand,
    stmtTimeoutCommand,
    explainCommand,
    queryTextWithSemicolon,
  ].join("\n");

  const validateExplainResult = (
    result: string,
  ): { format: "json" | "text" | null } => {
    const resultExample = `[
  {
    "Plan": {
      "Node Type": ...
    }
  }
]`;
    const invalidJSONmessage = (
      <div>
        Looks like a JSON EXPLAIN result is pasted, but the structure is
        invalid. Check the structure to make sure it is a single-element array
        containing the 'Plan' key <Tip content={<pre>{resultExample}</pre>} />.
      </div>
    );
    const hasPlanKey = result.includes(`"Plan": {`);
    try {
      const parsedJson = JSON.parse(result);
      if (parsedJson[0]?.Plan === undefined) {
        setErrorMessage(invalidJSONmessage);
        return { format: null };
      }
    } catch {
      if (hasPlanKey) {
        setErrorMessage(invalidJSONmessage);
        return { format: null };
      }
      setErrorMessage("");
      return { format: "text" };
    }
    setErrorMessage("");
    return { format: "json" };
  };

  const handleSubmitCurrentSet = () => {
    if (explainResult === "") {
      setErrorMessage("Explain result is required");
      return;
    }
    const { format } = validateExplainResult(explainResult);
    if (!format) {
      return;
    }
    const uploadedResultSetIds = explainQuery.explainResults.map(
      (result) => result.parameterSetId,
    );
    if (
      !uploadedResultSetIds.includes(focusedSetId) ||
      window.confirm(
        `Result for this parameter set is already uploaded. Would you like to update the result?`,
      )
    ) {
      createExplainResult({
        variables: {
          explainQueryId: explainQuery.id,
          explainJson: format === "json" ? explainResult : "",
          explainText: format === "text" ? explainResult : "",
          parameterSetId: focusedSetId === NO_PARAM_ID ? null : focusedSetId,
        },
        onCompleted: () => {
          setErrorMessage("");
          setExplainResult("");
          const nextSetId = workbook.parameterSets.find(
            (val) =>
              val.id !== focusedSetId && !uploadedResultSetIds.includes(val.id),
          )?.id;
          setFocusedSetId(nextSetId ?? "");
        },
        onError: (error) => {
          setErrorMessage(error.message);
        },
      });
    }
  };

  const runData = generateRunData(workbook, explainQuery, noParamSets);

  return (
    <>
      <ManualInstructions />
      <div className="p-2.5">
        <Grid
          className="grid-cols-[1fr_200px_320px]"
          data={runData}
          columns={[
            {
              field: "name",
              header: "Run",
              renderer: function NameCell({ fieldData, rowData }) {
                return (
                  <div
                    className="cursor-pointer flex"
                    onClick={() => {
                      setErrorMessage("");
                      focusedSetId === rowData.setId
                        ? setFocusedSetId("")
                        : setFocusedSetId(rowData.setId);
                    }}
                  >
                    <div>
                      {focusedSetId === rowData.setId ? (
                        <FontAwesomeIcon icon={faCircleCaretDown} />
                      ) : (
                        <RunIcon state={rowData.state} />
                      )}
                    </div>
                    <div className="ml-2">
                      <div>{fieldData}</div>
                      <div className="text-[#D1242F]">
                        {rowData.errorMessage}
                      </div>
                    </div>
                  </div>
                );
              },
              disableSort: true,
            },
            {
              field: "submitted",
              header: "Submitted",
              nullValue: "-",
              renderer: function SubmittedCell({ fieldData }) {
                return formatTimeAgo(fieldData);
              },
              disableSort: true,
            },
            {
              field: "runtime",
              header: "Runtime",
              style: "number",
              renderer: function RuntimeCell({ fieldData, rowData }) {
                if (rowData.state === "success (no analyze)") {
                  return "Ran without ANALYZE";
                } else if (fieldData === null) {
                  return "-";
                }
                return formatMs(fieldData);
              },
              disableSort: true,
            },
          ]}
        />
        {focusedSetId !== "" && (
          <div className="grid gap-2 p-2">
            <div>
              <div className="my-1 font-medium">EXPLAIN ANALYZE Command</div>
              <QueryTextArea
                queryText={explainWithQueryText}
                className="max-h-[200px] overflow-y-scroll bg-[#F9FAFB]"
              />
            </div>
            <div className="flex justify-between">
              <CopyToClipboard
                content={explainWithQueryText}
                label={"Copy command"}
                className="py-2 px-1"
              />
            </div>
            <Callout thin className="mb-2">
              In case you run into a query timeout, run the command without
              ANALYZE and paste the output.
            </Callout>
            <div className="flex justify-between">
              <div className="font-medium">EXPLAIN output</div>
              <div className="text-[#606060]">
                JSON or Text format supported
              </div>
            </div>
            <div>
              <textarea
                rows={10}
                className="bg-white rounded border border-[#ddd] box-border w-full leading-5 px-2 py-1.5 disabled:bg-[#eee]"
                onChange={(e) => {
                  setExplainResult(e.target.value);
                  validateExplainResult(e.target.value);
                }}
                value={explainResult}
                placeholder={`Paste EXPLAIN output here...\n[\n  {\n    \"Plan\": {\n      "Node Type": ...`}
              />
            </div>
            {errorMessage !== "" && (
              <div>
                <Callout variant="error" thin>
                  {errorMessage}
                </Callout>
              </div>
            )}
            <div>
              <button
                className="btn btn-success"
                onClick={handleSubmitCurrentSet}
              >
                Continue
              </button>
            </div>
          </div>
        )}
        {allSubmitted && (
          <div className="pt-2">
            <button
              className="btn btn-success"
              onClick={() =>
                navigate(
                  databaseWorkbookVariant(
                    workbook.databaseId,
                    workbook.id,
                    explainQuery.id,
                  ),
                )
              }
              disabled={resultFromCollector && !allExecuted}
            >
              Explore workbook
            </button>
          </div>
        )}
      </div>
    </>
  );
};

const POLL_INTERVAL_MS = 5000; // 5 seconds
const POLL_INTERVAL_SHORT_MS = 1000; // 1 second
const AUTOMATIC_PAUSE_INTERVAL_MS = POLL_INTERVAL_MS * 12 * 10; // 10 minutes

const RunExplainCollector = ({
  workbook,
  explainQueryId,
}: {
  workbook: ExplainWorkbookType;
  explainQueryId: string;
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [paused, setPaused] = usePauseAfter(AUTOMATIC_PAUSE_INTERVAL_MS);

  const { databaseWorkbookVariant } = useRoutes();
  const [createExplainCollectorRun] = useCreateExplainCollectorRunMutation();
  const { data, loading, error, startPolling, stopPolling } =
    useExplainQueryQuery({
      variables: { explainQueryId: explainQueryId },
      pollInterval: POLL_INTERVAL_MS,
    });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const noParamSets = workbook.parameterSets.length === 0;
  const explainQuery = data.getExplainQuery as ExplainQueryType;
  const supportedCollector = workbook.server.collectorInfo.supportsQueryTuning;
  const supportCollectorWorkflow =
    supportedCollector && workbook.hasExplainAnalyzeHelper;

  const allExecuted =
    explainQuery.explainResults.length > 0 &&
    explainQuery.explainResults.every(
      (result) => result.status === "synced" || result.status === "error",
    );
  const allSucceeded =
    allExecuted &&
    explainQuery.explainResults.every((result) => result.status === "synced");
  if (!supportCollectorWorkflow || allExecuted || paused) {
    stopPolling();
  }
  const collectPlanRunnable =
    (supportCollectorWorkflow && explainQuery.explainResults.length === 0) ||
    (allExecuted && !allSucceeded);

  const runData = generateRunData(workbook, explainQuery, noParamSets);
  return (
    <div className="p-2.5">
      {!supportCollectorWorkflow && (
        <Callout variant="warning" className="mb-4">
          {!supportedCollector && (
            <div>
              Unable to run the Collector Workflow: the collector is either not
              running, running a version below 0.64.1, or not connected over
              Websocket. See{" "}
              <a
                target="_blank"
                rel="noopener"
                href="https://pganalyze.com/docs/collector/upgrading"
              >
                upgrade instructions
              </a>
              .
            </div>
          )}
          {!workbook.hasExplainAnalyzeHelper && (
            <div>
              The setup for the Collector Workflow is incomplete: the
              <code>pganalyze.explain_analyze</code> helper function was not
              found in this database. Follow the{" "}
              <a
                target="_blank"
                rel="noopener"
                href="https://pganalyze.com/docs/query-tuning/collector-workflow"
              >
                set up documentation
              </a>{" "}
              to enable the Collector Workflow.
            </div>
          )}
        </Callout>
      )}
      {paused && !allExecuted && (
        <Callout className="mb-2.5" variant="warning" thin>
          Automatic refresh paused.{" "}
          <Button
            bare
            onClick={() => {
              setPaused(false);
              startPolling(POLL_INTERVAL_SHORT_MS);
            }}
          >
            Click here to resume.
          </Button>
        </Callout>
      )}
      <div className="pb-4">
        <QueryTextArea
          queryText={explainQuery.queryTextWithAlias}
          className="max-h-[200px] overflow-y-scroll bg-[#F9FAFB]"
        />
      </div>
      <Grid
        className="grid-cols-[1fr_200px_320px]"
        data={runData}
        columns={[
          {
            field: "name",
            header: "Run",
            renderer: function NameCell({ fieldData, rowData }) {
              return (
                <div className="flex">
                  <div>
                    <RunIcon state={rowData.state} />
                  </div>
                  <div className="ml-2">
                    <div>{fieldData}</div>
                    <div className="text-[#D1242F]">{rowData.errorMessage}</div>
                  </div>
                </div>
              );
            },
          },
          {
            field: "submitted",
            header: "Executed",
            nullValue: "-",
            renderer: function SubmittedCell({ fieldData }) {
              return formatTimeAgo(fieldData);
            },
          },
          {
            field: "runtime",
            header: "Runtime",
            style: "number",
            renderer: function RuntimeCell({ fieldData, rowData }) {
              if (rowData.state === "success (no analyze)") {
                return "Ran without ANALYZE due to timeout";
              } else if (fieldData === null) {
                return "-";
              }
              return formatMs(fieldData);
            },
          },
        ]}
      />
      {errorMessage !== "" && (
        <div className="pt-2">
          <Callout variant="error" thin>
            {errorMessage}
          </Callout>
        </div>
      )}
      <div className="pt-4">
        {allSucceeded ? (
          <Link
            className="btn btn-success"
            to={databaseWorkbookVariant(
              workbook.databaseId,
              workbook.id,
              explainQuery.id,
            )}
          >
            Explore workbook
          </Link>
        ) : (
          <button
            className="btn btn-success"
            onClick={() => {
              createExplainCollectorRun({
                variables: { explainQueryId: explainQuery.id },
                onCompleted: () => {
                  setErrorMessage("");
                  setPaused(false);
                  startPolling(POLL_INTERVAL_SHORT_MS);
                },
                onError: (error) => {
                  setErrorMessage(error.message);
                },
              });
            }}
            disabled={!collectPlanRunnable}
          >
            Collect Plans
          </button>
        )}
      </div>
    </div>
  );
};

const ManualInstructions = () => {
  const [showInstructions, setShowInstructions] = useState(true);

  const [tool, setTool] = useState<"psql" | "file">("psql");
  function handleToggleShowInstruction() {
    setShowInstructions(!showInstructions);
  }

  return (
    <div className="p-2.5">
      <div className="rounded border border-[#E8E8EE] p-4 text-[#606060]">
        <div className="font-medium text-[16px]">
          <Button bare onClick={handleToggleShowInstruction}>
            <ExpandToggle className="w-3 mr-2" expanded={showInstructions} />{" "}
            Instructions
          </Button>
        </div>
        {showInstructions && (
          <div className="pt-2 grid gap-2">
            <PillButtonBar
              opts={[
                { value: "psql", label: "Run EXPLAIN in psql" },
                { value: "file", label: "Run EXPLAIN from the terminal" },
              ]}
              selected={tool}
              onChange={(value) => {
                setTool(value);
              }}
            />
            {tool === "psql" ? (
              <div className="grid gap-2">
                <div>
                  <div>
                    Update psql settings for easier copy/pasting:{" "}
                    <code>\pset tuples_only true \pset format unaligned</code>{" "}
                    <CopyToClipboard
                      content={
                        "\\pset tuples_only true \\pset format unaligned"
                      }
                    />
                    .
                  </div>
                  <div>
                    You can also use <code>\t \a</code>{" "}
                    <CopyToClipboard content={"\\t \\a"} /> instead.
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      If you can use a clipboard, you can pipe the output into
                      pbcopy with <code>\o |pbcopy -b</code>{" "}
                      <CopyToClipboard content={"\\o |pbcopy -b"} />.
                    </div>
                    <div>
                      After running the EXPLAIN command, quit psql with{" "}
                      <code>\q</code>, which will pipe the last output to the
                      clipboard.
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  Save the EXPLAIN query to the <code>explain.sql</code> file
                  and run <code>psql -XqAt -f explain.sql | pbcopy</code>{" "}
                  <CopyToClipboard
                    content={"psql -XqAt -f explain.sql | pbcopy"}
                  />
                  .
                </div>
                <div>You can also pipe the result to the file.</div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const RunIcon = ({ state }: { state: RunState }) => {
  let icon = faCircle;
  let color = "text-[#E8E8EE]";
  let title = "pending";
  if (state === "running") {
    icon = faSpinner;
    color = "text-[#337ab7] animate-spin-slow";
    title = "running";
  } else if (state === "errored") {
    icon = faCircleX;
    color = "text-[#D1242F]";
    title = "errored";
  } else if (state === "success") {
    icon = faCircleCheck;
    color = "text-[#5CB85C]";
    title = "success";
  } else if (state === "success (no analyze)") {
    icon = faTriangleExclamation;
    color = "text-[#CA8A04]";
    title = "success (no analyze)";
  }
  return <FontAwesomeIcon icon={icon} className={color} title={title} />;
};

function getState(result: ExplainResultType): RunState {
  if (!result) {
    return "pending";
  }
  if (result.runtimeMs) {
    return "success";
  }
  if (result.resultSource === "collector_upload") {
    if (result.status === "error") {
      return "errored";
    } else if (result.status === "synced") {
      // synced but with no runtimeMs
      return "success (no analyze)";
    } else {
      return "running";
    }
  }
  return "success (no analyze)";
}

function generateRunData(
  workbook: ExplainWorkbookType,
  explainQuery: ExplainQueryType,
  noParamSets: boolean,
): RunDataType[] {
  const firstResult = explainQuery.explainResults[0];

  return noParamSets
    ? [
        {
          setId: NO_PARAM_ID,
          name: "Baseline Run",
          state: getState(firstResult),
          submitted: submittedTime(firstResult),
          runtime: firstResult ? firstResult.runtimeMs : null,
          errorMessage: firstResult?.errorMessage,
        },
      ]
    : workbook.parameterSets.map((paramSet) => {
        const result = explainQuery.explainResults.find(
          (res) => res.parameterSetId === paramSet.id,
        );
        return {
          setId: paramSet.id,
          name: paramSet.name,
          state: getState(result),
          submitted: submittedTime(result),
          runtime: result ? result.runtimeMs : null,
          errorMessage: result?.errorMessage,
        };
      });
}

function submittedTime(result: ExplainResultType | null): number | null {
  if (
    !result ||
    (result.resultSource === "collector_upload" && !result.status)
  ) {
    return null;
  }
  return result.updatedAt * 1000;
}

export default RunExplain;
