import React from "react";

import { Navigate } from "react-router-dom";

import { useUserPreferences } from "utils/hooks";
import Loading from "components/Loading";

import { useRootRedirectQuery } from "./gql/Query.generated";

function RootRedirect() {
  const [lastVisitedOrg] = useUserPreferences("lastVisitedOrg");
  const { data, loading, error } = useRootRedirectQuery();
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const { organizations } = data.getCurrentUserDetails;

  if (organizations.length === 0) {
    return <Navigate to={`/organizations/new`} replace />;
  }

  const org =
    organizations.find((o) => o.slug === lastVisitedOrg) ?? organizations[0];

  return <Navigate to={`/organizations/${org.slug}`} replace />;
}

export default RootRedirect;
