import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  ExplainWorkbookDetailsDocument,
  useExplainWorkbookDetailsQuery,
} from "../ExplainWorkbook/gql/Query.generated";
import { ExplainWorkbookType, ExplainQueryType } from "../ExplainWorkbook";
import { useQueryForWorkbookLazyQuery } from "../ReviewQuery/gql/Query.generated";
import { useDeleteExplainQueryMutation } from "../ExplainVariantSidebar/gql/Mutation.variant.generated";
import { useCreateExplainQueryMutation } from "./gql/Mutation.generated";

import Loading from "components/Loading";
import Panel from "components/Panel";
import PageContent from "components/PageContent";
import PanelSection from "components/PanelSection";
import Callout from "components/Callout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle1,
  faCircle2,
  faEdit,
  faExclamationCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { useRoutes } from "utils/routes";
import { LocationState, QueryTextArea } from "../ReviewQuery";
import Button from "components/Button";
import VariantNameEditPanel from "../VariantNameEditPanel";
import classNames from "classnames";
import PlannerSettingsPanel, { PlannerSettings } from "../PlannerSettingsPanel";
import { cleanupQuery } from "../ReviewQuery/util";

const RewriteQuery = () => {
  const { workbookId, databaseId } = useParams();

  const { loading, error, data } = useExplainWorkbookDetailsQuery({
    variables: { workbookId, databaseId },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  return <RewriteQueryContent workbook={data.getExplainWorkbookDetails} />;
};

const RewriteQueryContent = ({
  workbook,
}: {
  workbook: ExplainWorkbookType;
}) => {
  const baselineQuery = workbook.baselineQuery;
  const navigate = useNavigate();
  const location = useLocation();
  const { databaseWorkbookVariantRun } = useRoutes();

  const { databaseId } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [queryEdit, setQueryEdit] = useState(false);
  const state = location.state as LocationState;
  const [query, setQuery] = useState(
    state?.queryText ?? baselineQuery.queryTextWithAlias,
  );
  const [name, setName] = useState(state?.name ?? "");
  const [description, setDescription] = useState(state?.description ?? "");
  const [plannerSettings, setPlannerSettings] = useState<PlannerSettings>({});
  const [showPlannerSettings, setShowPlannerSettings] = useState(false);

  // Query for checking if the query is valid
  const [getQuery, { loading, error, data }] = useQueryForWorkbookLazyQuery();
  // Mutation for creating explain query (new variant)
  const [createExplainQuery] = useCreateExplainQueryMutation();

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const handleCheckQuery = () => {
    if (query.trim() === "") {
      setErrorMessage("Query text is required");
      return;
    }
    getQuery({
      variables: {
        databaseId,
        queryText: cleanupQuery(query),
        baselineId: baselineQuery.id,
      },
      onCompleted: (data) => {
        setQueryEdit(false);
        if (data.getQueryForWorkbook.validQuery) {
          setErrorMessage("");
        } else {
          setErrorMessage(data.getQueryForWorkbook.errorMessage);
        }
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const handleRunExplain = () => {
    if (!validQuery) {
      setErrorMessage("Query is not valid");
      return;
    }

    createExplainQuery({
      variables: {
        databaseId: workbook.databaseId,
        workbookId: workbook.id,
        name: name,
        description: description || null,
        baselineExplainQueryId: baselineQuery.id,
        queryText: query,
        plannerSettings: plannerSettings,
      },
      refetchQueries: [
        {
          query: ExplainWorkbookDetailsDocument,
          variables: {
            workbookId: workbook.id,
            databaseId: workbook.databaseId,
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        navigate(
          databaseWorkbookVariantRun(
            workbook.databaseId,
            workbook.id,
            data.createExplainQuery.id,
          ),
        );
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  function handleUpdateQuery(newQuery: string) {
    setQueryEdit(true);
    setQuery(newQuery);
    setErrorMessage("");
  }

  const checkedQuery = data?.getQueryForWorkbook;
  const validQuery = checkedQuery?.validQuery && !queryEdit;
  const workbookTitle = (
    <VariantCreationHeader
      name={name}
      setName={setName}
      description={description}
      setDescription={setDescription}
    />
  );

  const editButton = (
    <button onClick={() => setQueryEdit(true)}>
      <FontAwesomeIcon icon={faEdit} />
    </button>
  );

  return (
    <PageContent
      windowTitle={`EXPLAIN Workbook: ${workbook.name}`}
      title={workbookTitle}
      pageCategory="query-tuning"
      pageName="workbooks"
      layout="sidebar"
    >
      <Panel
        title={<CreateVariantSteps step="step1" />}
        secondaryTitle={validQuery && editButton}
        className="mt-1 flex-grow"
      >
        <PanelSection className="flex-grow flex flex-col items-stretch gap-2">
          {validQuery ? (
            <QueryTextArea
              className="flex-grow"
              queryText={checkedQuery.normalizedQuery.replace(
                /\$[\d]+/g,
                (match) => {
                  const matchedParam = checkedQuery.parameters.find(
                    (param) => `$${param.ref}` === match,
                  );
                  return matchedParam ? `$${matchedParam.name}` : match;
                },
              )}
            />
          ) : (
            <textarea
              className="flex-grow rounded border border-[#E8E8EE] p-2 text-[#606060] w-full font-query text-[13px]"
              rows={10}
              value={query}
              onChange={(e) => handleUpdateQuery(e.target.value)}
            />
          )}
          {errorMessage && (
            <div className="text-[#C22426]">
              <FontAwesomeIcon icon={faExclamationCircle} /> {errorMessage}
            </div>
          )}
          <div>
            {validQuery ? (
              <button className="btn btn-success" onClick={handleRunExplain}>
                Run EXPLAIN...
              </button>
            ) : (
              <button
                className="btn btn-success"
                onClick={handleCheckQuery}
                disabled={query === ""}
              >
                Verify Query
              </button>
            )}
          </div>
        </PanelSection>
      </Panel>
      <div className="h-3">
        {/* margin for above panel; due to layout we can't specify margin there directly */}
      </div>
      <div className="w-[320px]">
        <Callout className="!py-2 mb-4">
          Customize planner behavior by changing the settings. Settings are only
          applied to the current session.
        </Callout>
        <Button
          bare
          onClick={() => setShowPlannerSettings(true)}
          className={"btn btn-primary w-full !px-4 !py-2.5 !font-medium"}
        >
          <FontAwesomeIcon icon={faEdit} className="mr-1" /> Edit Planner
          Settings
        </Button>
        {showPlannerSettings && (
          <PlannerSettingsPanel
            onDismiss={() => setShowPlannerSettings(false)}
            plannerSettings={plannerSettings}
            setPlannerSettings={setPlannerSettings}
          />
        )}
        {Object.keys(plannerSettings).length > 0 ? (
          <div className="grid gap-3 mt-4">
            {Object.entries(plannerSettings).map(([key, value], i) => {
              return (
                <div key={`planner-${i}`} className="flex">
                  <div className="grow">
                    <span className="font-mono">{key}</span>
                  </div>
                  <div className="font-mono">{value}</div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="leading-7 font-medium mt-4">
            No custom planner settings
          </div>
        )}
      </div>
    </PageContent>
  );
};

export const VariantCreationHeaderWithVariant = ({
  variant,
}: {
  variant: ExplainQueryType;
}) => {
  const [name, setName] = useState(variant.name);
  const [description, setDescription] = useState(variant.description || "");

  return (
    <VariantCreationHeader
      name={name}
      description={description}
      setName={setName}
      setDescription={setDescription}
      explainQuery={variant}
    />
  );
};

export const VariantCreationHeader = ({
  name,
  description,
  setName,
  setDescription,
  explainQuery,
}: {
  name: string;
  description: string;
  setName?: (name: string) => void;
  setDescription?: (desc: string) => void;
  explainQuery?: ExplainQueryType;
}) => {
  const { databaseId, workbookId } = useParams();
  const [showVariantNameEditPanel, setShowVariantNameEditPanel] =
    useState(false);
  const navigate = useNavigate();
  const { databaseWorkbookVariants } = useRoutes();

  const [deleteExplainVariant] = useDeleteExplainQueryMutation();

  function handleEditShow() {
    setShowVariantNameEditPanel(true);
  }
  function handleEditDismiss() {
    setShowVariantNameEditPanel(false);
  }
  function handleDeleteVariant() {
    if (explainQuery) {
      deleteExplainVariant({
        variables: { explainQueryId: explainQuery.id },
        refetchQueries: [
          {
            query: ExplainWorkbookDetailsDocument,
            variables: { workbookId: workbookId, databaseId },
          },
        ],
        awaitRefetchQueries: true,
        onCompleted: () => {
          navigate(databaseWorkbookVariants(databaseId, workbookId));
        },
      });
    } else {
      navigate(databaseWorkbookVariants(databaseId, workbookId));
    }
  }

  return (
    <div className="flex flex-row justify-between items-center">
      <div>
        <h2 className="text-[22px] text-[#606060] m-0 py-[9px] font-medium overflow-visible leading-[26px]">
          {name || "Untitled Variant"}
        </h2>
        <div className="text-[14px] leading-5 font-normal flex">
          {description && (
            <span className="mr-2 max-w-[320px] truncate">{description}</span>
          )}
          <Button bare onClick={handleEditShow} className="text-[#337AB7]">
            {description ? "Edit" : "Edit name and description"}
          </Button>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          bare
          onClick={handleDeleteVariant}
          className="text-[14px] text-[#606060] font-normal"
        >
          Cancel workflow
        </Button>
      </div>
      {showVariantNameEditPanel && (
        <VariantNameEditPanel
          onDismiss={handleEditDismiss}
          name={name}
          description={description}
          setName={setName}
          setDescription={setDescription}
          variant={explainQuery}
        />
      )}
    </div>
  );
};

export const CreateVariantSteps = ({
  step,
  explainQuery,
}: {
  step: "step1" | "step2";
  explainQuery?: ExplainQueryType;
}) => {
  const navigate = useNavigate();
  const { databaseWorkbookVariantNew } = useRoutes();
  const { databaseId, workbookId } = useParams();

  const active = "text-[#29426D]";
  const inactive = "text-[#979797]";

  const step1Class = step === "step1" ? active : inactive;
  const step2Class = step === "step2" ? active : inactive;

  const [deleteExplainVariant] = useDeleteExplainQueryMutation();

  const handleBackToRewriteQuery = () => {
    if (
      window.confirm(
        "Going back to the Rewrite query step? All uploaded or executed EXPLAIN results will be cleared.",
      )
    ) {
      deleteExplainVariant({
        variables: {
          explainQueryId: explainQuery.id,
        },
        refetchQueries: [
          {
            query: ExplainWorkbookDetailsDocument,
            variables: {
              workbookId,
              databaseId,
            },
          },
        ],
        awaitRefetchQueries: true,
        onCompleted: () => {
          navigate(databaseWorkbookVariantNew(databaseId, workbookId), {
            state: {
              queryText: explainQuery.queryTextWithAlias,
              name: explainQuery.name,
              description: explainQuery.description,
            },
          });
        },
      });
    }
  };

  return (
    <div className="flex justify-center align-middle text-[14px] gap-4 font-medium">
      <div
        className={classNames(step1Class, step !== "step1" && "cursor-pointer")}
        onClick={step !== "step1" ? handleBackToRewriteQuery : undefined}
      >
        <FontAwesomeIcon icon={faCircle1} /> Rewrite Query and Edit Planner
      </div>
      <div className={step2Class}>
        <FontAwesomeIcon icon={faCircle2} /> Run EXPLAIN
      </div>
    </div>
  );
};

export default RewriteQuery;
