---
title: 'Step 1: Create helper function and test'
install_track_title: 'Log Insights: Setup (Crunchy Bridge)'
backlink_href: /docs/log-insights/setup
backlink_title: 'Log Insights: Setup'
---

import { CollectorStartCommand } from "../../../install/crunchy_bridge/01_deploy_the_collector.mdx";

import { MonitoringUserLogRead } from "../../../components/MonitoringUserSetupInstructions";

import imgLogInsightsScreenshot from '../log_insights_screenshot.png'

export const ImgLogInsightsScreenshot = () => <img src={imgLogInsightsScreenshot} alt='Screenshot of pganalyze Log Insights feature' />

Log output for Crunchy Bridge database servers is collected directly from the database server using SQL functions.

If you are using the application user, an additional function needs to be created to grant access to the logs (**run the following with superuser credentials**):

<MonitoringUserLogRead username="application" />

Next, configure your cluster to enable file logging by adding config parameters using the dashboard with these values:

* **log_destination**: `syslog,stderr`
* **log_line_prefix**: `'%m [%p] %q[user=%u,db=%d,app=%a] '`

You can then verify whether this is working by running the test command:

<CollectorStartCommand apiKey={props.apiKey} cmd="test" />

This should show an output like this:

```
I Running collector test with pganalyze-collector X.XX.X
I [default] Testing statistics collection...
I [default]   Test collection successful for PostgreSQL XX.X
I [default] Submitted full snapshot successfully
I [default] Testing activity snapshots...
I [default] Testing log download...
I [default]   Log test successful

...Test summary

Test successful
```

Note the "Log test successful", which indicates that the helper method works correctly.

<PublicOnly>
  <p>You will start seeing log data in pganalyze Log Insights within a few minutes.</p>
  <p>
    We recommend setting up <a href="/docs/explain/setup/crunchy_bridge/01_auto_explain_check">Automated EXPLAIN</a> as
    a follow-up step, to automatically EXPLAIN slow queries in Postgres.
  </p>
</PublicOnly>
