import React, { useState } from "react";

import ModalContainer from "components/ModalContainer";

import { ExplainWorkbookType } from "../ExplainWorkbook";
import { ExplainWorkbookDetailsDocument } from "../ExplainWorkbook/gql/Query.generated";
import { useUpdateExplainParameterSetsMutation } from "../EditParameterSets/gql/Mutation.update.generated";

import {
  convertParamValue,
  convertToParamSetType,
  ParameterSetsType,
  ParamSetType,
  formatQueryTextWithParamValues,
  validateParamSet,
} from "../ExplainWorkbook/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faMagnifyingGlass,
} from "@fortawesome/pro-solid-svg-icons";
import Button from "components/Button";
import { useParams } from "react-router-dom";
import { QueryTextArea } from "../ReviewQuery";

const EditParameterSetPanel = ({
  onDismiss,
  workbook,
  parameterSetId,
  refetchWorkbook,
}: {
  onDismiss: () => void;
  workbook: ExplainWorkbookType;
  parameterSetId: string;
  refetchWorkbook?: boolean;
}) => {
  const { databaseId } = useParams();
  const baselineQuery = workbook.baselineQuery;
  const [queryText, setQueryText] = useState(
    workbook.baselineQuery.queryTextWithAlias,
  );
  const [errorMessage, setErrorMessage] = useState("");
  const currAliasParamMap = workbook.aliasParamMapList.find(
    (ap) => ap.id === parameterSetId,
  );
  const [paramSet, setParamSet] = useState<ParamSetType>(
    convertToParamSetType(currAliasParamMap.parameters),
  );

  const [updateExplainParameterSets] = useUpdateExplainParameterSetsMutation();

  const handleUpdateParamSet = () => {
    const validateError = validateParamSet(
      paramSet,
      Object.keys(baselineQuery.paramRefAliasMap).length,
    );
    if (validateError) {
      setErrorMessage(validateError);
      return;
    }
    const newSet = Object.entries(paramSet).reduce((accum, [ref, set]) => {
      accum[ref] = {
        value: convertParamValue(set),
        type: set.type,
      };
      return accum;
    }, {} as ParamSetType);
    const parameterSets: ParameterSetsType = {};
    parameterSets[parameterSetId] = newSet;
    const refetchOpts = refetchWorkbook
      ? {
          refetchQueries: [
            {
              query: ExplainWorkbookDetailsDocument,
              variables: {
                workbookId: workbook.id,
                databaseId,
              },
            },
          ],
          awaitRefetchQueries: true,
        }
      : undefined;
    updateExplainParameterSets({
      variables: {
        workbookId: workbook.id,
        parameterSets: parameterSets,
      },
      ...refetchOpts,
      onCompleted: () => {
        setErrorMessage("");
        onDismiss();
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  // TODO: lots of same functions exist in CreateParameterSetPanel, combine them
  const handlePreviewParameters = () => {
    const preview = formatQueryTextWithParamValues(
      workbook.baselineQuery.queryTextWithAlias,
      paramSet,
    );
    setQueryText(preview);
  };

  const handleParamValueChange = (alias: string, value: string) => {
    const prevParam = paramSet[alias];
    const newSet = { ...paramSet };
    if (value === "" && !prevParam?.type && !prevParam?.isNull) {
      // remove from the set when the value becomes an empty string without type or flagged as null
      delete newSet[alias];
      setParamSet(newSet);
      return;
    }
    newSet[alias] = {
      value: value,
      type: prevParam?.type,
      isNull: prevParam?.isNull,
    };
    setParamSet(newSet);
  };
  const handleParamNullChange = (alias: string, isNull: boolean) => {
    const prevParam = paramSet[alias];
    const newSet = { ...paramSet };
    // when null is checked but there is an existing value, empty it
    newSet[alias] = {
      value: isNull ? undefined : prevParam?.value,
      type: prevParam?.type,
      isNull: isNull,
    };
    setParamSet(newSet);
  };
  const inputFields = Object.entries(baselineQuery.paramRefAliasMap).flatMap(
    ([ref, alias]) => {
      const aliasStr = alias as string;
      return [
        <div key={`label-${ref}`}>{aliasStr}</div>,
        <input
          key={`input-${ref}`}
          className="bg-white rounded border border-gray-300 box-content h-5 leading-5 px-2 py-1.5 disabled:bg-[#eee]"
          type="text"
          value={paramSet[aliasStr]?.value ?? ""}
          disabled={paramSet[aliasStr]?.isNull}
          onChange={(e) => handleParamValueChange(aliasStr, e.target.value)}
        />,
        <input
          key={`null-${ref}`}
          type="checkbox"
          checked={paramSet[aliasStr]?.isNull || false}
          onChange={(e) => handleParamNullChange(aliasStr, e.target.checked)}
        />,
        <div key={`type-${ref}`}>
          {paramSet[aliasStr]?.type ?? "Auto-detect (unknown type)"}
        </div>,
      ];
    },
  );

  return (
    <ModalContainer
      title={`Edit Parameter Set${
        refetchWorkbook ? ` (${currAliasParamMap.name})` : ""
      }`}
      layout="centered"
      onClose={onDismiss}
    >
      <div className="grid gap-4">
        <div className="relative">
          <QueryTextArea
            queryText={queryText}
            className="max-h-[100px] overflow-y-scroll py-4 mb-4"
            showCopyToClipboard
          />
        </div>
        <div>
          <div className="grid grid-cols-[min-content_1fr_40px_200px] gap-2 mb-2 items-center font-medium">
            <div>Name</div>
            <div>Value</div>
            <div>NULL</div>
            <div>Parameter Type</div>
            {Object.values(inputFields)}
          </div>
          <Button bare onClick={handlePreviewParameters}>
            <FontAwesomeIcon icon={faMagnifyingGlass} /> Preview Parameters
          </Button>
        </div>
        {errorMessage && (
          <div className="text-[#C22426]">
            <FontAwesomeIcon icon={faExclamationCircle} /> {errorMessage}
          </div>
        )}
        <div>
          <button className="btn btn-success" onClick={handleUpdateParamSet}>
            Update Parameter Set
          </button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default EditParameterSetPanel;
