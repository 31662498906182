// @ts-nocheck
import * as Types from "../../../types/types";

import { gql } from "@apollo/client";
export type WithSubscriptionCheckFragment = {
  __typename?: "Organization";
  planInfo: {
    __typename?: "OrganizationPlanInfo";
    id: string;
    activeSubscription: boolean;
    isTrial: boolean;
  };
};

export const WithSubscriptionCheckFragmentDoc = gql`
  fragment WithSubscriptionCheck on Organization {
    planInfo {
      id
      activeSubscription
      isTrial
    }
  }
`;
