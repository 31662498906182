// @ts-nocheck
import * as Types from "../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ExplainComparisonQueryVariables = Types.Exact<{
  databaseId: Types.Scalars["ID"]["input"];
  planAId: Types.Scalars["ID"]["input"];
  planBId: Types.Scalars["ID"]["input"];
}>;

export type ExplainComparisonQuery = {
  __typename?: "GraphqlQuery";
  planA?: {
    __typename?: "QueryExplain";
    id: string;
    humanId: string;
    fingerprint: string;
    totalCost?: number | null;
    totalBlkReadTime?: number | null;
    totalSharedBlksRead?: number | null;
    seenAt: number;
    annotatedJson?: any | null;
    querySample?: {
      __typename?: "QuerySample";
      id: string;
      runtimeMs: number;
    } | null;
  } | null;
  planB?: {
    __typename?: "QueryExplain";
    id: string;
    humanId: string;
    fingerprint: string;
    totalCost?: number | null;
    totalBlkReadTime?: number | null;
    totalSharedBlksRead?: number | null;
    seenAt: number;
    annotatedJson?: any | null;
    querySample?: {
      __typename?: "QuerySample";
      id: string;
      runtimeMs: number;
    } | null;
  } | null;
};

export const ExplainComparisonDocument = gql`
  query ExplainComparison($databaseId: ID!, $planAId: ID!, $planBId: ID!) {
    planA: getQueryExplain(databaseId: $databaseId, explainId: $planAId) {
      id
      humanId
      fingerprint
      totalCost
      totalBlkReadTime
      totalSharedBlksRead
      querySample {
        id
        runtimeMs
      }
      seenAt
      annotatedJson
    }
    planB: getQueryExplain(databaseId: $databaseId, explainId: $planBId) {
      id
      humanId
      fingerprint
      totalCost
      totalBlkReadTime
      totalSharedBlksRead
      querySample {
        id
        runtimeMs
      }
      seenAt
      annotatedJson
    }
  }
`;

/**
 * __useExplainComparisonQuery__
 *
 * To run a query within a React component, call `useExplainComparisonQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplainComparisonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplainComparisonQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *      planAId: // value for 'planAId'
 *      planBId: // value for 'planBId'
 *   },
 * });
 */
export function useExplainComparisonQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplainComparisonQuery,
    ExplainComparisonQueryVariables
  > &
    (
      | { variables: ExplainComparisonQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplainComparisonQuery,
    ExplainComparisonQueryVariables
  >(ExplainComparisonDocument, options);
}
export function useExplainComparisonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplainComparisonQuery,
    ExplainComparisonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplainComparisonQuery,
    ExplainComparisonQueryVariables
  >(ExplainComparisonDocument, options);
}
export function useExplainComparisonSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExplainComparisonQuery,
        ExplainComparisonQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplainComparisonQuery,
    ExplainComparisonQueryVariables
  >(ExplainComparisonDocument, options);
}
export type ExplainComparisonQueryHookResult = ReturnType<
  typeof useExplainComparisonQuery
>;
export type ExplainComparisonLazyQueryHookResult = ReturnType<
  typeof useExplainComparisonLazyQuery
>;
export type ExplainComparisonSuspenseQueryHookResult = ReturnType<
  typeof useExplainComparisonSuspenseQuery
>;
export type ExplainComparisonQueryResult = Apollo.QueryResult<
  ExplainComparisonQuery,
  ExplainComparisonQueryVariables
>;
