// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpdateExplainWorkbookMutationVariables = Types.Exact<{
  workbookId: Types.Scalars["ID"]["input"];
  name?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  description?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  parameterSetTypes?: Types.InputMaybe<
    Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"]
  >;
  parameterRefAliases?: Types.InputMaybe<
    Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"]
  >;
}>;

export type UpdateExplainWorkbookMutation = {
  __typename?: "Mutation";
  updateExplainWorkbook: {
    __typename?: "UpdateExplainWorkbookPayload";
    explainWorkbookId?: string | null;
  };
};

export const UpdateExplainWorkbookDocument = gql`
  mutation UpdateExplainWorkbook(
    $workbookId: ID!
    $name: String
    $description: String
    $parameterSetTypes: [String!]
    $parameterRefAliases: [String!]
  ) {
    updateExplainWorkbook(
      workbookId: $workbookId
      name: $name
      description: $description
      parameterSetTypes: $parameterSetTypes
      parameterRefAliases: $parameterRefAliases
    ) {
      explainWorkbookId
    }
  }
`;
export type UpdateExplainWorkbookMutationFn = Apollo.MutationFunction<
  UpdateExplainWorkbookMutation,
  UpdateExplainWorkbookMutationVariables
>;

/**
 * __useUpdateExplainWorkbookMutation__
 *
 * To run a mutation, you first call `useUpdateExplainWorkbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExplainWorkbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExplainWorkbookMutation, { data, loading, error }] = useUpdateExplainWorkbookMutation({
 *   variables: {
 *      workbookId: // value for 'workbookId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      parameterSetTypes: // value for 'parameterSetTypes'
 *      parameterRefAliases: // value for 'parameterRefAliases'
 *   },
 * });
 */
export function useUpdateExplainWorkbookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateExplainWorkbookMutation,
    UpdateExplainWorkbookMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateExplainWorkbookMutation,
    UpdateExplainWorkbookMutationVariables
  >(UpdateExplainWorkbookDocument, options);
}
export type UpdateExplainWorkbookMutationHookResult = ReturnType<
  typeof useUpdateExplainWorkbookMutation
>;
export type UpdateExplainWorkbookMutationResult =
  Apollo.MutationResult<UpdateExplainWorkbookMutation>;
export type UpdateExplainWorkbookMutationOptions = Apollo.BaseMutationOptions<
  UpdateExplainWorkbookMutation,
  UpdateExplainWorkbookMutationVariables
>;
