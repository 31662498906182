import React, { useState } from "react";

import ExplainTable, { nodeTypesToStr } from "components/ExplainTable";
import Loading from "components/Loading";
import LogProcessingDisabledPanel from "components/LogProcessingDisabledPanel";
import Panel from "components/Panel";
import QueryExplainsBlankSlate from "components/QueryExplainsBlankSlate";

import { useDateRange } from "components/WithDateRange";
import FilterSearch from "components/FilterSearch";
import { makeFilter } from "utils/filter";
import Grid, { GridColumn, MsCell, NumberCell } from "components/Grid";
import GraphSection from "components/Graph/GraphSection";
import DateRangeGraph from "components/Graph/DateRangeGraph";
import {
  Data,
  Datum,
  defined,
  InteractionPoint,
  SeriesConfig,
} from "components/Graph/util";
import { ScatterSeries } from "components/Graph/Series";
import { Link, useNavigate } from "react-router-dom";
import { useRoutes } from "utils/routes";
import Tip from "components/Tip";
import classNames from "classnames";
import ExplainFingerprint from "components/ExplainFingerprint";
import {
  QueryExplainListQueryHookResult,
  useQueryExplainListQuery,
} from "./gql/Query.generated";
import { QueryExplainType } from "components/QueryDetails";

type QueryPlanType =
  QueryExplainListQueryHookResult["data"]["getQueryPlans"][number];
type QueryPlanStatsType =
  QueryExplainListQueryHookResult["data"]["getQueryPlanStats"][number];
type QueryExplainForGraphType =
  QueryExplainListQueryHookResult["data"]["getQueryExplainsForGraph"][number];

const QueryExplainList: React.FunctionComponent<{
  explains: QueryExplainType[];
  queryId: string;
  databaseId: string;
  blockSize: number;
}> = ({ explains, databaseId, queryId, blockSize }) => {
  const { databaseQueryExplainCompare } = useRoutes();
  const [compareCandidates, setCompareCandidates] = useState<
    [planA?: string, planB?: string]
  >([undefined, undefined]);
  const [range] = useDateRange();
  const { from, to } = range;
  const [searchTerm, setSearchTerm] = useState("");
  const { error, loading, data } = useQueryExplainListQuery({
    variables: {
      queryId: queryId,
      databaseId,
      startTs: from.unix(),
      endTs: to.unix(),
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  let warning;
  if (data.getServerDetails.collectorInfo?.logCollectionDisabled) {
    warning = (
      <LogProcessingDisabledPanel
        disabledReasons={
          data.getServerDetails.collectorInfo?.logCollectionDisabledReason
        }
      />
    );
  }

  if (explains.length === 0) {
    return (
      <>
        <PlanStatisticsPanel
          queryPlans={data.getQueryPlans}
          explains={explains}
          databaseId={databaseId}
          queryId={queryId}
        />
        <PlanGraphsPanel
          planStats={data.getQueryPlanStats}
          explains={data.getQueryExplainsForGraph}
          databaseId={databaseId}
          queryId={queryId}
        />
        {warning || (
          <QueryExplainsBlankSlate serverId={data.getServerDetails.humanId} />
        )}
      </>
    );
  }
  const explainsWithQueryId = explains.map((explain) => {
    return {
      ...explain,
      query: {
        id: queryId,
      },
    };
  });

  const filteredData = explainsWithQueryId.filter(
    makeFilter(searchTerm, "fingerprint"),
  );

  function handleCompareCandidatesChanged(
    candidates: [planA?: string, planB?: string],
  ) {
    setCompareCandidates(candidates);
  }

  let candidateA: string, candidateB: string;
  if (compareCandidates.length > 0) {
    candidateA = compareCandidates[0];
  }
  if (compareCandidates.length > 1) {
    candidateB = candidateA;
    candidateA = compareCandidates[1];
  }
  let compareLink;
  if (candidateA != null && candidateB != null) {
    compareLink = databaseQueryExplainCompare(
      databaseId,
      queryId,
      candidateA,
      candidateB,
    );
  }

  return (
    <>
      {warning}
      <PlanStatisticsPanel
        queryPlans={data.getQueryPlans}
        explains={explains}
        databaseId={databaseId}
        queryId={queryId}
      />
      <PlanGraphsPanel
        planStats={data.getQueryPlanStats}
        explains={data.getQueryExplainsForGraph}
        databaseId={databaseId}
        queryId={queryId}
      />
      <Panel
        title={`Plan Samples (${explains.length})`}
        secondaryTitle={
          <FilterSearch
            initialValue={searchTerm}
            onChange={setSearchTerm}
            placeholder="Search plan fingerprint..."
          />
        }
      >
        <ExplainTable
          databaseId={databaseId}
          explains={filteredData}
          blockSize={blockSize}
          compareCandidates={compareCandidates}
          onCompareCandidatesChanged={handleCompareCandidatesChanged}
        />
      </Panel>
      <div>
        <Link
          to={compareLink ?? ""}
          className={classNames(
            "block btn btn-primary",
            !compareLink && "disabled pointer-events-none",
          )}
        >
          Compare selected plans
        </Link>
      </div>
    </>
  );
};

const PlanStatisticsPanel = ({
  queryPlans,
  explains,
  databaseId,
  queryId,
}: {
  queryPlans: QueryPlanType[];
  explains: QueryExplainType[];
  databaseId: string;
  queryId: string;
}) => {
  const [showWithoutStats, setShowWithoutStats] = useState(false);
  const { databaseQueryExplain } = useRoutes();

  if (queryPlans.length === 0 && explains.length === 0) {
    return null;
  }
  let data = queryPlans;
  const dataFromExplains = queryPlans.length === 0 && explains.length > 0;
  if (dataFromExplains) {
    const uniqueExplains: {
      [key: string]: {
        runtime: number[];
        totalCost: number[];
        planNodeTypes: string[];
        humanId: string;
      };
    } = {};
    explains.forEach((explain) => {
      const uniqueExplain = uniqueExplains[explain.fingerprint] || {
        runtime: [],
        totalCost: [],
        planNodeTypes: null,
        humanId: null,
      };
      uniqueExplain.runtime.push(explain.querySample.runtimeMs);
      uniqueExplain.totalCost.push(explain.totalCost);
      uniqueExplain.planNodeTypes = explain.planNodeTypes;
      uniqueExplain.humanId ||= explain.humanId;
      uniqueExplains[explain.fingerprint] = uniqueExplain;
    });
    data = Object.keys(uniqueExplains).map((key) => {
      const val = uniqueExplains[key];
      // reuse explain's humanId as originalPlanId
      return {
        originalPlanId: val.humanId,
        planFingerprint: key,
        totalCost:
          val.totalCost.reduce((acc, num) => acc + num, 0) /
          val.totalCost.length,
        avgTime:
          val.runtime.reduce((acc, num) => acc + num, 0) / val.runtime.length,
        calls: val.runtime.length,
        planNodeTypes: val.planNodeTypes,
      } as QueryPlanType;
    });
  } else if (!showWithoutStats) {
    data = data.filter((dat) => dat.calls !== null);
  }
  const columns: GridColumn<
    (typeof data)[number],
    keyof (typeof data)[number]
  >[] = [
    {
      field: "planFingerprint",
      header: "Plan",
      renderer: function PlanFingerprintCell({ rowData, fieldData }) {
        const explainUrl = databaseQueryExplain(
          databaseId,
          queryId,
          dataFromExplains
            ? rowData.originalPlanId
            : `planid-${rowData.originalPlanId}`,
        );
        return (
          <Link to={explainUrl}>
            <ExplainFingerprint fingerprint={fieldData} />
          </Link>
        );
      },
      width: "minmax(10%,120px)",
    },
    {
      field: "totalCost",
      header: "Est. Cost",
      style: "number",
      nullValue: "-",
      renderer: NumberCell,
      width: "minmax(10%,120px)",
    },
    {
      field: "avgTime",
      header: "Avg Runtime",
      style: "number",
      nullValue: "-",
      renderer: MsCell,
      width: "minmax(10%,120px)",
    },
    {
      field: "calls",
      header: dataFromExplains ? "Plan Samples" : "Calls / Min",
      style: "number",
      nullValue: "-",
      renderer: NumberCell,
      width: "minmax(10%,120px)",
    },
    {
      field: "planNodeTypes",
      header: "Plan Nodes",
      renderer: ({ fieldData }) => nodeTypesToStr(fieldData),
      width: "1fr",
    },
  ];
  if (!dataFromExplains) {
    columns.splice(4, 0, {
      field: "originalPlanId",
      header: "Original Plan ID",
      style: "number",
      nullValue: "-",
      tip: "The planid field in Amazon Aurora's aurora_stat_plans().",
      width: "160px",
    });
  }
  const secondaryTitle = !dataFromExplains && (
    <>
      <input
        type="checkbox"
        checked={showWithoutStats}
        id="show_without_stats"
        className="!mt-0"
        onChange={(evt) => setShowWithoutStats(evt.target.checked)}
      />
      <label className="text-[14px] font-normal pl-[4px] pr-[12px] mb-0">
        Show All Plans{" "}
        <Tip content="Show plans that were not executed within the selected time range" />
      </label>
    </>
  );

  return (
    <Panel title="Plan Statistics" secondaryTitle={secondaryTitle}>
      <Grid data={data} pageSize={5} columns={columns} />
    </Panel>
  );
};

const PlanGraphsPanel = ({
  planStats,
  explains,
  databaseId,
  queryId,
}: {
  planStats: QueryPlanStatsType[];
  explains: QueryExplainForGraphType[];
  databaseId: string;
  queryId: string;
}) => {
  const { databaseQueryExplain } = useRoutes();
  const navigate = useNavigate();

  if (planStats.length === 0) {
    return null;
  }

  const avgGraphData: Data = {};
  const callsGraphData: Data = {};
  const graphSeries: SeriesConfig[] = [];
  planStats.forEach((val, idx) => {
    const fingerprintShort = val.planFingerprint.substring(0, 7);
    avgGraphData[fingerprintShort] = val.avgTime as unknown as Datum[];
    callsGraphData[fingerprintShort] = val.calls as unknown as Datum[];
    graphSeries.push({
      key: fingerprintShort,
      label: fingerprintShort,
    });
    if (idx === 0 && explains.length > 0) {
      // remove explains that happened when no other chart data is available
      explains = explains.filter((e) =>
        defined(avgGraphData[fingerprintShort].find((c) => c[0] === e.time)),
      );
      avgGraphData["explains"] = explains.map((e) => [
        e.time,
        e.querySample.runtimeMs,
      ]);
      graphSeries.push({
        key: "explains",
        type: ScatterSeries,
        label: `EXPLAIN Plan Samples (${explains.length})`,
        tipLabel: "EXPLAIN",
        color: "violet",
      });
    }
  });

  const onClick = (point: InteractionPoint) => {
    if (!point) {
      return;
    }
    const d = point.nearby.find((d) => d.series === "explains");
    if (!d) {
      return;
    }
    const id = explains[d.index].humanId;
    navigate(databaseQueryExplain(databaseId, queryId, id));
  };

  return (
    <>
      <Panel title="Avg Time">
        <GraphSection>
          <DateRangeGraph
            data={avgGraphData}
            onClick={onClick}
            series={graphSeries}
            axes={{
              left: {
                format: "duration ms",
                tipFormat: (y: number): string => y.toFixed(1) + " ms",
              },
            }}
          />
        </GraphSection>
      </Panel>
      <Panel title="Calls">
        <GraphSection>
          <DateRangeGraph
            data={callsGraphData}
            series={graphSeries}
            axes={{
              left: {
                format: "count",
                tipFormat: (y: number): string => y.toFixed(1) + "/min",
              },
            }}
          />
        </GraphSection>
      </Panel>
    </>
  );
};

export default QueryExplainList;
