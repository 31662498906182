// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
export type ExplainQueryShortFragmentFragment = {
  __typename?: "ExplainQuery";
  id: string;
  createdAt: number;
  updatedAt: number;
  description?: string | null;
  queryFingerprint: string;
  queryText: string;
  name: string;
  queryTextWithAlias: string;
  paramRefAliasMap: any;
  draft: boolean;
  plannerSettings: any;
  user?: { __typename?: "User"; id: string; fullname?: string | null } | null;
  queryTextWithParameters: Array<{
    __typename?: "QueryTextWithParameters";
    id: string;
    parameterSetId: string;
    queryWithParameters: string;
  }>;
};

export const ExplainQueryShortFragmentFragmentDoc = gql`
  fragment ExplainQueryShortFragment on ExplainQuery {
    id
    createdAt
    updatedAt
    description
    queryFingerprint
    queryText
    name
    user {
      id
      fullname
    }
    queryTextWithAlias
    paramRefAliasMap
    queryTextWithParameters {
      id
      parameterSetId
      queryWithParameters
    }
    draft
    plannerSettings
  }
`;
