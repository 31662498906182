import React, { useState } from "react";

import { ValidateFunction } from "ajv";
import { validateIndexSelectionConfig } from "utils/ajv-validators";

import { faBook } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import Button from "components/Button";
import { useFeature } from "components/OrganizationFeatures";
import {
  BALANCED_DEFAULT_SETTINGS,
  READ_OPTIMIZED_DEFAULT_SETTINGS,
  WRITE_OPTIMIZED_DEFAULT_SETTINGS,
} from "../util";
import ModalContainer from "components/ModalContainer";
import Callout from "components/Callout";

export type IndexSelectionCustomConfig = {
  useConsolidation: boolean;
  settingsJson: string;
  preset?: "ignored" | "default";
};

const EditSettingsPanel: React.FunctionComponent<{
  onTry: (config: IndexSelectionCustomConfig) => void;
  onDismiss: () => void;
  initialConfig: IndexSelectionCustomConfig;
  schemaName: string;
  tableName: string;
  storedSettingsName: string;
  defaultSettingsName: string;
  hasOverride: boolean;
}> = ({
  onTry,
  onDismiss,
  initialConfig,
  schemaName,
  tableName,
  storedSettingsName,
  defaultSettingsName,
  hasOverride,
}) => {
  const allowConsolidation = useFeature("indexAdvisorV3Consolidation");
  const [schemaError, setSchemaError] = useState<undefined | string>(undefined);
  const [preset, setPreset] = useState<"ignored" | "default" | undefined>(
    undefined,
  );

  const [settingsJson, setSettingsJson] = useState(initialConfig.settingsJson);
  const [useConsolidation, setUseConsolidation] = useState(
    initialConfig.useConsolidation,
  );

  function handleTryCustomSettings() {
    if (preset) {
      onTry({
        useConsolidation: null,
        settingsJson: null,
        preset: preset,
      });
      return;
    }
    let updatedConfig;
    try {
      updatedConfig = JSON.parse(settingsJson);
    } catch (e: unknown) {
      if (e instanceof SyntaxError) {
        setSchemaError(e.message);
      } else {
        setSchemaError("could not parse JSON");
      }
      return;
    }

    const valid = validateIndexSelectionConfig(updatedConfig);
    if (!valid) {
      const schemaErrorStr = getSchemaErrorStr(
        validateIndexSelectionConfig as unknown as ValidateFunction,
      );
      setSchemaError(schemaErrorStr);
      return;
    }

    setSchemaError(undefined);
    onTry({
      settingsJson,
      useConsolidation,
    });
  }
  return (
    <ModalContainer
      title={`Custom Configuration for table ${schemaName}.${tableName}`}
      onClose={onDismiss}
    >
      <div className="grid grid-rows-2 mb-2">
        {hasOverride && (
          <div>
            <label className="font-normal mb-2">
              <input
                type="radio"
                name="preset"
                value="default"
                className="align-middle !m-0 !mr-1"
                onChange={() => setPreset("default")}
              />{" "}
              Remove override ({storedSettingsName}) and revert to the
              automatically selected configuration (currently:{" "}
              {defaultSettingsName})
            </label>
          </div>
        )}
        <div>
          <label className="font-normal mb-2">
            <input
              type="radio"
              name="preset"
              value="ignored"
              className="align-middle !m-0 !mr-1"
              onChange={() => setPreset("ignored")}
            />{" "}
            Ignore this table in Index Advisor
          </label>
        </div>
        <div>
          <label className="font-normal mb-2">
            <input
              type="radio"
              name="preset"
              value="custom"
              className="align-middle !m-0 !mr-1"
              onChange={() => setPreset(undefined)}
              defaultChecked
            />{" "}
            Use the following custom configuration
          </label>
        </div>
      </div>
      {!preset && (
        <Panel title="Custom Configuration">
          <PanelSection>
            <div className="mb-2 flex justify-between">
              <a
                target="_blank"
                href="https://pganalyze.com/docs/indexing-engine/cp-model"
              >
                <FontAwesomeIcon icon={faBook} /> Learn more about the
                Constraint Programming Model
              </a>
              <div>
                Load existing configuration:{" "}
                <Button
                  onClick={() =>
                    setSettingsJson(
                      JSON.stringify(READ_OPTIMIZED_DEFAULT_SETTINGS, null, 2),
                    )
                  }
                  variant="link"
                  className="hover:underline"
                  bare
                >
                  Read-optimized
                </Button>
                ,{" "}
                <Button
                  onClick={() =>
                    setSettingsJson(
                      JSON.stringify(BALANCED_DEFAULT_SETTINGS, null, 2),
                    )
                  }
                  variant="link"
                  className="hover:underline"
                  bare
                >
                  Balanced
                </Button>
                ,{" "}
                <Button
                  onClick={() =>
                    setSettingsJson(
                      JSON.stringify(WRITE_OPTIMIZED_DEFAULT_SETTINGS, null, 2),
                    )
                  }
                  variant="link"
                  className="hover:underline"
                  bare
                >
                  Write-optimized
                </Button>
              </div>
            </div>
            <pre>
              <textarea
                rows={25}
                className="bg-transparent w-full"
                value={settingsJson}
                onChange={(e) => setSettingsJson(e.target.value)}
              />
            </pre>
            {schemaError && (
              <div className="mt-2 text-[#FF0000]">{schemaError}</div>
            )}
            {allowConsolidation ? (
              <label className="block mb-2">
                <input
                  type="checkbox"
                  checked={useConsolidation}
                  onChange={(e) => setUseConsolidation(e.target.checked)}
                />{" "}
                Allow consolidation/removal of indexes (excluding unique
                indexes/primary keys)
              </label>
            ) : (
              <Callout>
                Custom configurations can also enable index consolidation
                insights.{" "}
                <a href="mailto:support@pganalyze.com?subject=Index%20Consolidation%20early%20access%20request">
                  Contact support
                </a>{" "}
                to explore this experimental feature and give us feedback.{" "}
                <a href="https://pganalyze.com/docs/index-advisor/consolidation">
                  Learn more about the current limitations.
                </a>
              </Callout>
            )}
          </PanelSection>
        </Panel>
      )}
      <button className="btn btn-success" onClick={handleTryCustomSettings}>
        Try configuration
      </button>
    </ModalContainer>
  );
};

function getSchemaErrorStr(validateFn: ValidateFunction): string | undefined {
  const errors = validateFn.errors;
  if (!errors) {
    return undefined;
  }
  // since the schema is small, we can allow the user to focus on the first
  // error (at least for now).
  const err = errors[0];
  const params: any = err.params;
  let msg = err.message;
  if (params.allowedValues) {
    msg += ": " + params.allowedValues.join(", ");
  }
  if ("instancePath" in err && err.instancePath) {
    msg = err.instancePath + " " + msg;
  }

  return msg;
}

export default EditSettingsPanel;
