// @ts-nocheck
import * as Types from "../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ApiKeysQueryVariables = Types.Exact<{
  organizationSlug: Types.Scalars["ID"]["input"];
}>;

export type ApiKeysQuery = {
  __typename?: "GraphqlQuery";
  getApiKeys: Array<{
    __typename?: "ApiKey";
    id: string;
    token: string;
    accessType: string;
    accessScopeType: string;
    accessScopeName: string;
    lastUsedAt?: number | null;
    createdAt: number;
  }>;
  getOrganizationDetails?: {
    __typename?: "Organization";
    id: string;
    slug: string;
    name: string;
    permittedToCreateApiKeys: boolean;
    permittedToCreateRWApiKeys: boolean;
  } | null;
};

export const ApiKeysDocument = gql`
  query ApiKeys($organizationSlug: ID!) {
    getApiKeys(organizationSlug: $organizationSlug) {
      id
      token
      accessType
      accessScopeType
      accessScopeName
      lastUsedAt
      createdAt
    }
    getOrganizationDetails(organizationSlug: $organizationSlug) {
      id
      slug
      name
      permittedToCreateApiKeys
      permittedToCreateRWApiKeys
    }
  }
`;

/**
 * __useApiKeysQuery__
 *
 * To run a query within a React component, call `useApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiKeysQuery({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useApiKeysQuery(
  baseOptions: Apollo.QueryHookOptions<ApiKeysQuery, ApiKeysQueryVariables> &
    ({ variables: ApiKeysQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApiKeysQuery, ApiKeysQueryVariables>(
    ApiKeysDocument,
    options,
  );
}
export function useApiKeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiKeysQuery,
    ApiKeysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApiKeysQuery, ApiKeysQueryVariables>(
    ApiKeysDocument,
    options,
  );
}
export function useApiKeysSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ApiKeysQuery, ApiKeysQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ApiKeysQuery, ApiKeysQueryVariables>(
    ApiKeysDocument,
    options,
  );
}
export type ApiKeysQueryHookResult = ReturnType<typeof useApiKeysQuery>;
export type ApiKeysLazyQueryHookResult = ReturnType<typeof useApiKeysLazyQuery>;
export type ApiKeysSuspenseQueryHookResult = ReturnType<
  typeof useApiKeysSuspenseQuery
>;
export type ApiKeysQueryResult = Apollo.QueryResult<
  ApiKeysQuery,
  ApiKeysQueryVariables
>;
