import React from "react";
import Identicon from "components/Identicon";

type Props =
  | {
      explain: { fingerprint: string };
      fingerprint?: never;
    }
  | {
      fingerprint: string;
      explain?: never;
    };

const ExplainFingerprint: React.FunctionComponent<Props> = (props) => {
  const fingerprint =
    "explain" in props ? props.explain.fingerprint : props.fingerprint;
  return (
    <span title={fingerprint} className="inline-flex items-center gap-1">
      <Identicon identity={fingerprint} />
      {fingerprint.substring(0, 7)}
    </span>
  );
};

export default ExplainFingerprint;
