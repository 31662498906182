import React, { useState } from "react";
import { Link } from "react-router-dom";

import Form from "components/Form";
import FormErrors from "components/FormErrors";
import GoogleSignIn from "components/GoogleSignIn";
import Loading from "components/Loading";
import Page from "components/Page";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import TimezoneSelect from "components/TimezoneSelect";
import TrackedLink from "components/TrackedLink";

import {
  AccountSettingsQuery,
  useAccountSettingsQuery,
} from "./gql/Query.generated";
import { useRoutes } from "utils/routes";

type Props = {
  flashes: Array<{
    name: string;
    msg: string;
  }>;
  googleAuthDomain: string | null | undefined;
  googleAuthorizePath: string;
  googleClientId: string | null | undefined;
  userErrors: Array<string>;
  passwordErrors: Array<string>;
  demoOnly: boolean;
  passwordAuthDisabled: boolean;
};

const AccountSettings: React.FunctionComponent<Props> = ({
  demoOnly,
  googleClientId,
  googleAuthDomain,
  googleAuthorizePath,
  userErrors,
  flashes,
  passwordErrors,
  passwordAuthDisabled,
}) => {
  const { loading, error, data } = useAccountSettingsQuery();
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const legacyContent = (
    <PageContent
      title="User Account Settings"
      pageCategory="user"
      pageName="edit"
    >
      {demoOnly ? (
        <DemoNotice />
      ) : (
        <>
          <UpdateSettings data={data} userErrors={userErrors} />
          <WeeklyReportSettings data={data} userErrors={userErrors} />
          {googleClientId && (
            <GoogleAuth
              {...{
                data,
                googleAuthDomain,
                googleAuthorizePath,
                googleClientId,
              }}
            />
          )}
          <SamlInfo data={data} />
          <ChangePassword {...{ data, passwordErrors, passwordAuthDisabled }} />
        </>
      )}
    </PageContent>
  );

  return (
    <Page legacyContent={{ ["users/edit"]: legacyContent }} flashes={flashes} />
  );
};

const DemoNotice: React.FunctionComponent = () => {
  return (
    <Panel title="Demo Account">
      <PanelSection>
        Thank you for trying out our demo account!
        <br />
        <br />
        <TrackedLink
          href="https://app.pganalyze.com/users/sign_up"
          className="btn btn-success"
          eventCategory="Signup Link"
          eventLabel="Demo Account Settings"
        >
          Sign up for my own account
        </TrackedLink>
      </PanelSection>
    </Panel>
  );
};

const UpdateSettings: React.FunctionComponent<{
  data: AccountSettingsQuery;
  userErrors: Array<string>;
}> = ({ data, userErrors }) => {
  const { users } = useRoutes();
  const { fullname, timezone, email, pendingReconfirmationEmail } =
    data.getCurrentUserDetails;

  return (
    <Panel title="Update Settings">
      <Form action={users()} method="put" id="user_settings_form">
        <FormErrors errors={userErrors} modelName="user" />
        <div className="form-group">
          <label className="control-label" htmlFor="user_fullname">
            Full Name
          </label>
          <input
            className="form-control"
            type="text"
            defaultValue={fullname}
            name="user[fullname]"
            id="user_fullname"
          />
        </div>
        <div className="form-group">
          <label className="control-label" htmlFor="user_timezone">
            Timezone
          </label>
          <TimezoneSelect
            name="user[timezone]"
            id="user_timezone"
            defaultValue={timezone}
          />
        </div>
        <div className="form-group">
          <label className="control-label" htmlFor="user_email">
            Email
          </label>
          <input
            className="form-control"
            type="email"
            defaultValue={email}
            name="user[email]"
            id="user_email"
            required
          />
          {pendingReconfirmationEmail && (
            <div className="mt-1">
              A new email address {pendingReconfirmationEmail} is currently
              waiting for a confirmation. Please check your email and confirm to
              update the email address.
            </div>
          )}
        </div>
        <div className="form-group">
          <input
            type="submit"
            name="commit"
            value="Save Account Settings"
            className="btn btn-primary"
            data-disable-with="Save Account Settings"
          />
        </div>
      </Form>
    </Panel>
  );
};

const WeeklyReportSettings: React.FunctionComponent<{
  data: AccountSettingsQuery;
  userErrors: Array<string>;
}> = ({ data, userErrors }) => {
  const { users } = useRoutes();
  const { receiveWeeklyReports } = data.getCurrentUserDetails;

  return (
    <Panel title="Weekly Report Settings">
      <Form action={users()} method="put" id="weekly_report_settings_form">
        <FormErrors errors={userErrors} modelName="user" />
        <div className="form-group">
          Weekly reports get sent once per week on Monday to your email address,
          for each of your organizations that are receiving statistics. The
          report shows information about week-by-week query activity, new slow
          queries and other summary information.
        </div>
        <div className="form-group">
          <input type="hidden" name="user[receive_weekly_reports]" value="0" />
          <input
            type="checkbox"
            defaultChecked={receiveWeeklyReports}
            name="user[receive_weekly_reports]"
            id="user_receive_weekly_reports"
            value="1"
          />{" "}
          <label
            className="control-label"
            htmlFor="user_receive_weekly_reports"
          >
            Enable Weekly Report
          </label>
        </div>
        <div className="form-group">
          <input
            type="submit"
            name="commit"
            value="Save Settings"
            className="btn btn-primary"
            data-disable-with="Save Settings"
          />
        </div>
      </Form>
    </Panel>
  );
};

const GoogleAuth: React.FunctionComponent<{
  data: AccountSettingsQuery;
  googleAuthDomain: string | null | undefined;
  googleAuthorizePath: string;
  googleClientId: string | null | undefined;
}> = ({ data, googleAuthDomain, googleAuthorizePath, googleClientId }) => {
  const { authProvider } = data.getCurrentUserDetails;
  const [googleAuthErrors, setGoogleAuthErrors] = useState([]);
  const { users } = useRoutes();

  if (authProvider == "saml") {
    return null;
  }

  if (authProvider == "google") {
    return (
      <Panel title="Sign In with Google">
        <PanelSection>
          {googleAuthDomain && (
            <span>
              You have authentication with Google enabled, and are signed in for
              the domain <strong>{googleAuthDomain}</strong>.
            </span>
          )}
          {!googleAuthDomain && (
            <span>You have authentication with Google enabled.</span>
          )}
        </PanelSection>
        <PanelSection>
          <Form action={users()} method="put" insideSection={true}>
            <input
              type="hidden"
              name="disable_provider_authentication"
              value="1"
            />
            <button type="submit" className="btn btn-primary">
              Disable Google Authentication
            </button>
          </Form>
        </PanelSection>
      </Panel>
    );
  }

  return (
    <Panel title="Sign In with Google">
      <PanelSection>
        You don't have authentication through Google enabled right now.
        <FormErrors errors={googleAuthErrors} modelName="user" />
        <GoogleSignIn
          authorizePath={googleAuthorizePath}
          googleClientId={googleClientId || ""}
          errorHandler={(authErrors: string[]) => {
            setGoogleAuthErrors(authErrors);
          }}
        />
      </PanelSection>
    </Panel>
  );
};

const SamlInfo: React.FunctionComponent<{
  data: AccountSettingsQuery;
}> = ({ data }) => {
  const { organization } = useRoutes();
  const { authProvider, authUid, authOrganization } =
    data.getCurrentUserDetails;
  if (authProvider != "saml") {
    return null;
  }

  return (
    <Panel title="Single Sign-On">
      <PanelSection>
        Your user account is linked to an organization through Single Sign-On
        with SAML.
      </PanelSection>
      <PanelSection>
        <strong>Single Sign-On Organization:</strong>{" "}
        <Link to={organization(authOrganization.slug)}>
          {authOrganization.name}
        </Link>
        <br />
        <strong>Identity Provider Username:</strong> {authUid}
      </PanelSection>
    </Panel>
  );
};

const ChangePassword: React.FunctionComponent<{
  data: AccountSettingsQuery;
  passwordErrors: Array<string>;
  passwordAuthDisabled: boolean;
}> = ({ data, passwordErrors, passwordAuthDisabled }) => {
  const [enablePasswordAuth, setEnablePasswordAuth] = useState(false);
  const { users } = useRoutes();

  const { authHasPassword, authProvider } = data.getCurrentUserDetails;

  if (passwordAuthDisabled) {
    return (
      <Panel title="Sign In with Username & Password">
        <PanelSection>
          You are signed in via LDAP.
          <br />
          <br />
          Please change your password elsewhere or ask your administrator for
          help.
        </PanelSection>
      </Panel>
    );
  }

  if (authProvider == "saml") {
    return null;
  }

  if (!authHasPassword) {
    const showForm = enablePasswordAuth || passwordErrors.length > 0;
    return (
      <Panel title="Sign In with Username & Password">
        {!showForm && (
          <PanelSection>
            Password authentication is currently disabled for your user account.
          </PanelSection>
        )}
        {!showForm && (
          <PanelSection>
            <button
              className="btn btn-primary"
              onClick={() => setEnablePasswordAuth(true)}
            >
              Enable Password Authentication
            </button>
          </PanelSection>
        )}
        {showForm && (
          <Form action={users()} method="put" id="user_password_form">
            <FormErrors errors={passwordErrors} modelName="user" />
            <div className="form-group">
              <label className="control-label" htmlFor="user_password">
                New password
              </label>
              <input
                autoComplete="off"
                className="form-control"
                type="password"
                name="user[password]"
                id="user_password"
                required
              />
            </div>
            <div className="form-group">
              <label
                className="control-label"
                htmlFor="user_password_confirmation"
              >
                New password (confirm)
              </label>
              <input
                className="form-control"
                type="password"
                name="user[password_confirmation]"
                id="user_password_confirmation"
                required
              />
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary">
                Set Password
              </button>
            </div>
          </Form>
        )}
      </Panel>
    );
  }

  return (
    <Panel title="Sign In with Username & Password">
      <Form action={users()} method="put" id="user_password_form">
        <FormErrors errors={passwordErrors} modelName="user" />
        <div className="form-group">
          <label className="control-label" htmlFor="user_current_password">
            Current password
          </label>
          <input
            className="form-control"
            type="password"
            name="user[current_password]"
            id="user_current_password"
            required
          />
        </div>
        <div className="form-group">
          <label className="control-label" htmlFor="user_password">
            New password
          </label>
          <input
            autoComplete="off"
            className="form-control"
            type="password"
            name="user[password]"
            id="user_password"
            required
          />
        </div>
        <div className="form-group">
          <label className="control-label" htmlFor="user_password_confirmation">
            New password (confirm)
          </label>
          <input
            className="form-control"
            type="password"
            name="user[password_confirmation]"
            id="user_password_confirmation"
            required
          />
        </div>
        <div className="form-group">
          <button type="submit" className="btn btn-primary">
            Update Password
          </button>
        </div>
      </Form>
      {authProvider && (
        <PanelSection>
          <Form action={users()} method="put">
            <input
              type="hidden"
              name="disable_password_authentication"
              value="1"
            />
            <button type="submit" className="btn btn-primary">
              Disable Password Authentication
            </button>
          </Form>
        </PanelSection>
      )}
    </Panel>
  );
};

export default AccountSettings;
