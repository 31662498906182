// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
export type BillingPlanInfoFragmentFragment = {
  __typename?: "Organization";
  id: string;
  permittedToBilling: boolean;
  planInfo: {
    __typename?: "OrganizationPlanInfo";
    id: string;
    activeSubscription: boolean;
    canceledSubscription: boolean;
    subscriptionPlanId: string;
    subscriptionPlanName: string;
    supportsExtraServers: boolean;
    trialEndsAt?: number | null;
    isTrial: boolean;
    expiredTrial: boolean;
    supportsLitePlan: boolean;
    subscriptionCancelationPending: boolean;
    subscriptionCancelationEffectiveAt?: number | null;
  };
};

export const BillingPlanInfoFragmentFragmentDoc = gql`
  fragment BillingPlanInfoFragment on Organization {
    id
    permittedToBilling
    planInfo {
      id
      activeSubscription
      canceledSubscription
      subscriptionPlanId
      subscriptionPlanName
      supportsExtraServers
      trialEndsAt
      isTrial
      expiredTrial
      supportsLitePlan
      subscriptionCancelationPending
      subscriptionCancelationEffectiveAt
    }
  }
`;
