// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type QuerySamplesForWorkbookQueryVariables = Types.Exact<{
  databaseId: Types.Scalars["ID"]["input"];
  workbookId: Types.Scalars["ID"]["input"];
}>;

export type QuerySamplesForWorkbookQuery = {
  __typename?: "GraphqlQuery";
  getQuerySamples: Array<{
    __typename?: "QuerySample";
    id: string;
    occurredAt: number;
    runtimeMs: number;
    jsonParameters?: any | null;
    explain?: {
      __typename?: "QueryExplain";
      id: string;
      humanId: string;
      fingerprint: string;
    } | null;
  }>;
};

export const QuerySamplesForWorkbookDocument = gql`
  query QuerySamplesForWorkbook($databaseId: ID!, $workbookId: ID!) {
    getQuerySamples(databaseId: $databaseId, workbookId: $workbookId) {
      id
      occurredAt
      runtimeMs
      jsonParameters
      explain {
        id
        humanId
        fingerprint
      }
    }
  }
`;

/**
 * __useQuerySamplesForWorkbookQuery__
 *
 * To run a query within a React component, call `useQuerySamplesForWorkbookQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuerySamplesForWorkbookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySamplesForWorkbookQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *      workbookId: // value for 'workbookId'
 *   },
 * });
 */
export function useQuerySamplesForWorkbookQuery(
  baseOptions: Apollo.QueryHookOptions<
    QuerySamplesForWorkbookQuery,
    QuerySamplesForWorkbookQueryVariables
  > &
    (
      | { variables: QuerySamplesForWorkbookQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QuerySamplesForWorkbookQuery,
    QuerySamplesForWorkbookQueryVariables
  >(QuerySamplesForWorkbookDocument, options);
}
export function useQuerySamplesForWorkbookLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QuerySamplesForWorkbookQuery,
    QuerySamplesForWorkbookQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QuerySamplesForWorkbookQuery,
    QuerySamplesForWorkbookQueryVariables
  >(QuerySamplesForWorkbookDocument, options);
}
export function useQuerySamplesForWorkbookSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        QuerySamplesForWorkbookQuery,
        QuerySamplesForWorkbookQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QuerySamplesForWorkbookQuery,
    QuerySamplesForWorkbookQueryVariables
  >(QuerySamplesForWorkbookDocument, options);
}
export type QuerySamplesForWorkbookQueryHookResult = ReturnType<
  typeof useQuerySamplesForWorkbookQuery
>;
export type QuerySamplesForWorkbookLazyQueryHookResult = ReturnType<
  typeof useQuerySamplesForWorkbookLazyQuery
>;
export type QuerySamplesForWorkbookSuspenseQueryHookResult = ReturnType<
  typeof useQuerySamplesForWorkbookSuspenseQuery
>;
export type QuerySamplesForWorkbookQueryResult = Apollo.QueryResult<
  QuerySamplesForWorkbookQuery,
  QuerySamplesForWorkbookQueryVariables
>;
