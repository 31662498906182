---
title: 'Step 3: Set Up Service Account'
install_track_title: 'Log Insights: Setup (Google Cloud SQL and AlloyDB)'
backlink_href: /docs/log-insights/setup
backlink_title: 'Log Insights: Setup'
---

import GCPConfigureAccess from '../../../components/GCPConfigureAccess'

If you used IAM Authentication to create a monitoring user using a service account,
grant "Pub/Sub Subscriber" permission to that service account and continue to the next step.

Go to "IAM & Admin", click on "Service accounts" and then "Create Service Account".

* Pick a name of your choice, e.g. "pganalyze-collector"
* Grant "Pub/Sub Subscriber" permission to the service account

We now have two options here:

<GCPConfigureAccess />

<Link className="btn btn-success" to="04_configure_collector">
  Continue to Step 4: Configure collector
</Link>
