// @ts-nocheck
import * as Types from "../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type QueryDetailsExplainListQueryVariables = Types.Exact<{
  databaseId: Types.Scalars["ID"]["input"];
  queryId: Types.Scalars["ID"]["input"];
  startTs: Types.Scalars["Int"]["input"];
  endTs: Types.Scalars["Int"]["input"];
}>;

export type QueryDetailsExplainListQuery = {
  __typename?: "GraphqlQuery";
  getQueryExplains: Array<{
    __typename?: "QueryExplain";
    id: string;
    humanId: string;
    fingerprint: string;
    seenAt: number;
    planNodeTypes?: Array<string | null> | null;
    totalCost?: number | null;
    totalBlkReadTime?: number | null;
    totalSharedBlksRead?: number | null;
    format: string;
    querySample?: {
      __typename?: "QuerySample";
      id: string;
      runtimeMs: number;
    } | null;
  }>;
};

export const QueryDetailsExplainListDocument = gql`
  query QueryDetailsExplainList(
    $databaseId: ID!
    $queryId: ID!
    $startTs: Int!
    $endTs: Int!
  ) {
    getQueryExplains(
      databaseId: $databaseId
      queryId: $queryId
      startTs: $startTs
      endTs: $endTs
    ) {
      id
      humanId
      fingerprint
      seenAt
      planNodeTypes
      totalCost
      totalBlkReadTime
      totalSharedBlksRead
      querySample {
        id
        runtimeMs
      }
      format
    }
  }
`;

/**
 * __useQueryDetailsExplainListQuery__
 *
 * To run a query within a React component, call `useQueryDetailsExplainListQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryDetailsExplainListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryDetailsExplainListQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *      queryId: // value for 'queryId'
 *      startTs: // value for 'startTs'
 *      endTs: // value for 'endTs'
 *   },
 * });
 */
export function useQueryDetailsExplainListQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryDetailsExplainListQuery,
    QueryDetailsExplainListQueryVariables
  > &
    (
      | { variables: QueryDetailsExplainListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryDetailsExplainListQuery,
    QueryDetailsExplainListQueryVariables
  >(QueryDetailsExplainListDocument, options);
}
export function useQueryDetailsExplainListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryDetailsExplainListQuery,
    QueryDetailsExplainListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryDetailsExplainListQuery,
    QueryDetailsExplainListQueryVariables
  >(QueryDetailsExplainListDocument, options);
}
export function useQueryDetailsExplainListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        QueryDetailsExplainListQuery,
        QueryDetailsExplainListQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryDetailsExplainListQuery,
    QueryDetailsExplainListQueryVariables
  >(QueryDetailsExplainListDocument, options);
}
export type QueryDetailsExplainListQueryHookResult = ReturnType<
  typeof useQueryDetailsExplainListQuery
>;
export type QueryDetailsExplainListLazyQueryHookResult = ReturnType<
  typeof useQueryDetailsExplainListLazyQuery
>;
export type QueryDetailsExplainListSuspenseQueryHookResult = ReturnType<
  typeof useQueryDetailsExplainListSuspenseQuery
>;
export type QueryDetailsExplainListQueryResult = Apollo.QueryResult<
  QueryDetailsExplainListQuery,
  QueryDetailsExplainListQueryVariables
>;
