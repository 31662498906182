// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import { ExplainQueryFragmentFragmentDoc } from "../../ExplainWorkbook/gql/Fragment.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CreateExplainResultMutationVariables = Types.Exact<{
  explainQueryId: Types.Scalars["ID"]["input"];
  explainText?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  explainJson?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  parameterSetId?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
}>;

export type CreateExplainResultMutation = {
  __typename?: "Mutation";
  createExplainResult: {
    __typename?: "ExplainQuery";
    id: string;
    createdAt: number;
    updatedAt: number;
    description?: string | null;
    queryFingerprint: string;
    queryText: string;
    name: string;
    queryTextWithAlias: string;
    paramRefAliasMap: any;
    draft: boolean;
    plannerSettings: any;
    explainResults: Array<{
      __typename?: "ExplainResult";
      id: string;
      format: string;
      resultSource: string;
      createdAt: number;
      updatedAt: number;
      status?: string | null;
      errorMessage?: string | null;
      totalCost?: number | null;
      totalSharedBlksHit?: number | null;
      totalSharedBlksRead?: number | null;
      totalSharedBlksWritten?: number | null;
      totalSharedBlksDirtied?: number | null;
      totalBlkReadTime?: number | null;
      totalBlkWriteTime?: number | null;
      planNodeTypes?: Array<string | null> | null;
      runtimeMs?: number | null;
      planFingerprint?: string | null;
      parameterSetId?: string | null;
      user?: {
        __typename?: "User";
        id: string;
        fullname?: string | null;
      } | null;
    }>;
    user?: { __typename?: "User"; id: string; fullname?: string | null } | null;
    queryTextWithParameters: Array<{
      __typename?: "QueryTextWithParameters";
      id: string;
      parameterSetId: string;
      queryWithParameters: string;
    }>;
  };
};

export const CreateExplainResultDocument = gql`
  mutation CreateExplainResult(
    $explainQueryId: ID!
    $explainText: String
    $explainJson: String
    $parameterSetId: ID
  ) {
    createExplainResult(
      explainQueryId: $explainQueryId
      explainText: $explainText
      explainJson: $explainJson
      parameterSetId: $parameterSetId
    ) {
      ...ExplainQueryFragment
    }
  }
  ${ExplainQueryFragmentFragmentDoc}
`;
export type CreateExplainResultMutationFn = Apollo.MutationFunction<
  CreateExplainResultMutation,
  CreateExplainResultMutationVariables
>;

/**
 * __useCreateExplainResultMutation__
 *
 * To run a mutation, you first call `useCreateExplainResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExplainResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExplainResultMutation, { data, loading, error }] = useCreateExplainResultMutation({
 *   variables: {
 *      explainQueryId: // value for 'explainQueryId'
 *      explainText: // value for 'explainText'
 *      explainJson: // value for 'explainJson'
 *      parameterSetId: // value for 'parameterSetId'
 *   },
 * });
 */
export function useCreateExplainResultMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExplainResultMutation,
    CreateExplainResultMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExplainResultMutation,
    CreateExplainResultMutationVariables
  >(CreateExplainResultDocument, options);
}
export type CreateExplainResultMutationHookResult = ReturnType<
  typeof useCreateExplainResultMutation
>;
export type CreateExplainResultMutationResult =
  Apollo.MutationResult<CreateExplainResultMutation>;
export type CreateExplainResultMutationOptions = Apollo.BaseMutationOptions<
  CreateExplainResultMutation,
  CreateExplainResultMutationVariables
>;
