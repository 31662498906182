// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CreateExplainParameterSetsMutationVariables = Types.Exact<{
  databaseId: Types.Scalars["ID"]["input"];
  explainQueryId: Types.Scalars["ID"]["input"];
  parameterSet?: Types.InputMaybe<Types.Scalars["JSON"]["input"]>;
  queryText?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  querySampleIds?: Types.InputMaybe<
    Array<Types.Scalars["ID"]["input"]> | Types.Scalars["ID"]["input"]
  >;
  finalize?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
}>;

export type CreateExplainParameterSetsMutation = {
  __typename?: "Mutation";
  createExplainParameterSets?: {
    __typename?: "ExplainWorkbook";
    id: string;
    parameterRefAliases: Array<string>;
    parameterSets: Array<{
      __typename?: "ParameterSet";
      id: string;
      name: string;
      paramValues: any;
    }>;
    aliasParamMapList: Array<{
      __typename?: "AliasParamMap";
      id: string;
      name: string;
      parameters: any;
    }>;
  } | null;
};

export const CreateExplainParameterSetsDocument = gql`
  mutation CreateExplainParameterSets(
    $databaseId: ID!
    $explainQueryId: ID!
    $parameterSet: JSON
    $queryText: String
    $querySampleIds: [ID!]
    $finalize: Boolean
  ) {
    createExplainParameterSets(
      databaseId: $databaseId
      explainQueryId: $explainQueryId
      parameterSet: $parameterSet
      queryText: $queryText
      querySampleIds: $querySampleIds
      finalize: $finalize
    ) {
      id
      parameterSets {
        id
        name
        paramValues
      }
      parameterRefAliases
      aliasParamMapList {
        id
        name
        parameters
      }
    }
  }
`;
export type CreateExplainParameterSetsMutationFn = Apollo.MutationFunction<
  CreateExplainParameterSetsMutation,
  CreateExplainParameterSetsMutationVariables
>;

/**
 * __useCreateExplainParameterSetsMutation__
 *
 * To run a mutation, you first call `useCreateExplainParameterSetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExplainParameterSetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExplainParameterSetsMutation, { data, loading, error }] = useCreateExplainParameterSetsMutation({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *      explainQueryId: // value for 'explainQueryId'
 *      parameterSet: // value for 'parameterSet'
 *      queryText: // value for 'queryText'
 *      querySampleIds: // value for 'querySampleIds'
 *      finalize: // value for 'finalize'
 *   },
 * });
 */
export function useCreateExplainParameterSetsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExplainParameterSetsMutation,
    CreateExplainParameterSetsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExplainParameterSetsMutation,
    CreateExplainParameterSetsMutationVariables
  >(CreateExplainParameterSetsDocument, options);
}
export type CreateExplainParameterSetsMutationHookResult = ReturnType<
  typeof useCreateExplainParameterSetsMutation
>;
export type CreateExplainParameterSetsMutationResult =
  Apollo.MutationResult<CreateExplainParameterSetsMutation>;
export type CreateExplainParameterSetsMutationOptions =
  Apollo.BaseMutationOptions<
    CreateExplainParameterSetsMutation,
    CreateExplainParameterSetsMutationVariables
  >;
