import React, { useEffect, useState } from "react";

import { useAsyncActionFlash } from "components/WithFlashes";
import { useRoutes } from "utils/routes";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";

import { useUpdateBillingDetailsQuery } from "./gql/Query.generated";
import { useUpdateBillingDetailsMutationMutation } from "./gql/Mutation.generated";
import Loading from "components/Loading";
import { CountryDropdown } from "react-country-region-selector";
import DocsSnippet from "components/DocsSnippet";

export const BillingDetailsForm: React.FunctionComponent<{
  billingCompanyName: string;
  setBillingCompanyName: (val: string) => void;
  billingAddressLine1: string;
  setBillingAddressLine1: (val: string) => void;
  billingAddressLine2: string;
  setBillingAddressLine2: (val: string) => void;
  billingAddressZipCode: string;
  setBillingAddressZipCode: (val: string) => void;
  billingAddressCity: string;
  setBillingAddressCity: (val: string) => void;
  billingAddressState: string;
  setBillingAddressState: (val: string) => void;
  billingAddressCountry: string;
  setBillingAddressCountry: (val: string) => void;
  billingVatId: string;
  setBillingVatId: (val: string) => void;
  billingEmail: string;
  setBillingEmail: (val: string) => void;
}> = ({
  billingCompanyName,
  setBillingCompanyName,
  billingAddressLine1,
  setBillingAddressLine1,
  billingAddressLine2,
  setBillingAddressLine2,
  billingAddressZipCode,
  setBillingAddressZipCode,
  billingAddressCity,
  setBillingAddressCity,
  billingAddressState,
  setBillingAddressState,
  billingAddressCountry,
  setBillingAddressCountry,
  billingVatId,
  setBillingVatId,
  billingEmail,
  setBillingEmail,
}) => {
  const [showBillingVatId, setShowBillingVatId] = useState(false);
  useEffect(() => {
    if (billingVatId) {
      setShowBillingVatId(true);
    }
  }, [billingVatId]);

  return (
    <>
      <div className="form-group">
        <label htmlFor="billing-company-name">Company name</label>
        <input
          id="billing-company-name"
          className="form-control"
          type="text"
          value={billingCompanyName}
          onChange={(e) => setBillingCompanyName(e.target.value)}
        />
      </div>
      {!showBillingVatId ? (
        <div className="help-block" style={{ marginTop: -5 }}>
          <a
            id="toggle-vat-id"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setShowBillingVatId(true);
            }}
          >
            Add VAT ID / Tax ID
          </a>
        </div>
      ) : (
        <div className="form-group">
          <label htmlFor="billing-vat-id">VAT ID / Tax ID (optional)</label>
          <input
            id="billing-vat-id"
            className="form-control"
            type="text"
            value={billingVatId}
            onChange={(e) => setBillingVatId(e.target.value)}
          />
        </div>
      )}
      <div className="form-group">
        <label htmlFor="billing-address-line-1">Address</label>
        <input
          id="billing-address-line-1"
          placeholder="Street address, PO box"
          style={{ display: "block", marginBottom: ".2em" }}
          className="form-control"
          type="text"
          value={billingAddressLine1}
          onChange={(e) => setBillingAddressLine1(e.target.value)}
        />
        <input
          placeholder="Apartment, building, floor (optional)"
          className="form-control"
          type="text"
          value={billingAddressLine2}
          onChange={(e) => setBillingAddressLine2(e.target.value)}
        />
      </div>
      <div className="flex gap-6">
        <div className="form-group flex-grow-[3]">
          <label htmlFor="billing-address-city">City</label>
          <input
            id="billing-address-city"
            className="form-control"
            type="text"
            value={billingAddressCity}
            onChange={(e) => setBillingAddressCity(e.target.value)}
          />
        </div>
        <div className="form-group flex-grow-[2]">
          <label htmlFor="billing-address-state">State</label>
          <input
            id="billing-address-state"
            className="form-control"
            type="text"
            value={billingAddressState}
            onChange={(e) => setBillingAddressState(e.target.value)}
          />
        </div>
      </div>
      <div className="flex gap-6">
        <div className="form-group flex-grow-[2]">
          <label htmlFor="billing-address-zip-code">Postcode</label>
          <input
            id="billing-address-zip-code"
            className="form-control"
            type="text"
            value={billingAddressZipCode}
            onChange={(e) => setBillingAddressZipCode(e.target.value)}
          />
        </div>
        <div className="form-group flex-grow-[3]">
          <label htmlFor="billing-address-country">Country</label>
          <CountryDropdown
            id="billing-address-country"
            className="form-control"
            value={billingAddressCountry}
            onChange={(val) => setBillingAddressCountry(val)}
            valueType="short"
            priorityOptions={["US"]}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="billing-email">
          Billing email{" "}
          <DocsSnippet
            title="Billing email"
            content="You will receive one payment notification each billing interval (e.g. monthly) to your billing email address with the invoice attached. Invoices are also downloadable directly in pganalyze. In case of payment failures you will get notifications to this email address. Must be a single email address."
          />
        </label>
        <input
          id="billing-email"
          className="form-control"
          type="email"
          value={billingEmail}
          required={true}
          onChange={(e) => setBillingEmail(e.target.value)}
        />
      </div>
    </>
  );
};

const UpdateBillingDetails: React.FunctionComponent<{
  organizationSlug: string;
}> = ({ organizationSlug }) => {
  const { loading, error, data } = useUpdateBillingDetailsQuery({
    variables: {
      organizationSlug,
    },
  });
  const [billingCompanyName, setBillingCompanyName] = useState("");
  const [billingAddressLine1, setBillingAddressLine1] = useState("");
  const [billingAddressLine2, setBillingAddressLine2] = useState("");
  const [billingAddressZipCode, setBillingAddressZipCode] = useState("");
  const [billingAddressCity, setBillingAddressCity] = useState("");
  const [billingAddressState, setBillingAddressState] = useState("");
  const [billingAddressCountry, setBillingAddressCountry] = useState("");
  const [billingVatId, setBillingVatId] = useState("");
  const [billingEmail, setBillingEmail] = useState("");
  const { organizationSubscription } = useRoutes();

  useEffect(() => {
    if (data) {
      setBillingCompanyName(
        data.getOrganizationBilling.billingCompanyName || "",
      );
      setBillingAddressLine1(
        data.getOrganizationBilling.billingAddressLine1 || "",
      );
      setBillingAddressLine2(
        data.getOrganizationBilling.billingAddressLine2 || "",
      );
      setBillingAddressZipCode(
        data.getOrganizationBilling.billingAddressZipCode || "",
      );
      setBillingAddressCity(
        data.getOrganizationBilling.billingAddressCity || "",
      );
      setBillingAddressState(
        data.getOrganizationBilling.billingAddressState || "",
      );
      setBillingAddressCountry(
        data.getOrganizationBilling.billingAddressCountry || "",
      );
      setBillingVatId(data.getOrganizationBilling.billingVatId || "");
      setBillingEmail(data.getOrganizationBilling.billingEmail || "");
    }
  }, [data]);

  const [
    updateBillingInfo,
    { called: mutationCalled, loading: mutationLoading, error: mutationError },
  ] = useUpdateBillingDetailsMutationMutation();
  useAsyncActionFlash({
    called: mutationCalled,
    loading: mutationLoading,
    error: mutationError?.message,
    success: "Updated your billing details successfully.",
    successRedirect: organizationSubscription(organizationSlug),
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    updateBillingInfo({
      variables: {
        organizationSlug,
        billingCompanyName,
        billingAddressLine1,
        billingAddressLine2,
        billingAddressZipCode,
        billingAddressCity,
        billingAddressState,
        billingAddressCountry,
        billingVatId,
        billingEmail,
      },
    });
  };

  return (
    <Panel title="Update Billing Details">
      <PanelSection className="w-[42%] min-w-min">
        <form onSubmit={handleSubmit}>
          <BillingDetailsForm
            billingCompanyName={billingCompanyName}
            setBillingCompanyName={setBillingCompanyName}
            billingAddressLine1={billingAddressLine1}
            setBillingAddressLine1={setBillingAddressLine1}
            billingAddressLine2={billingAddressLine2}
            setBillingAddressLine2={setBillingAddressLine2}
            billingAddressZipCode={billingAddressZipCode}
            setBillingAddressZipCode={setBillingAddressZipCode}
            billingAddressCity={billingAddressCity}
            setBillingAddressCity={setBillingAddressCity}
            billingAddressState={billingAddressState}
            setBillingAddressState={setBillingAddressState}
            billingAddressCountry={billingAddressCountry}
            setBillingAddressCountry={setBillingAddressCountry}
            billingVatId={billingVatId}
            setBillingVatId={setBillingVatId}
            billingEmail={billingEmail}
            setBillingEmail={setBillingEmail}
          />
          <div className="form-group">
            <input
              type="submit"
              name="commit"
              value={mutationLoading ? "Saving changes..." : "Save changes"}
              disabled={mutationLoading}
              className="btn btn-success"
            ></input>
          </div>
        </form>
      </PanelSection>
    </Panel>
  );
};

export default UpdateBillingDetails;
