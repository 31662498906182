// @ts-nocheck
import * as Types from "../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type QueryExplainListQueryVariables = Types.Exact<{
  databaseId: Types.Scalars["ID"]["input"];
  queryId: Types.Scalars["ID"]["input"];
  startTs: Types.Scalars["Int"]["input"];
  endTs: Types.Scalars["Int"]["input"];
}>;

export type QueryExplainListQuery = {
  __typename?: "GraphqlQuery";
  getQueryPlans: Array<{
    __typename?: "QueryPlan";
    id: string;
    originalPlanId: string;
    explainPlan?: string | null;
    planFingerprint?: string | null;
    totalCost?: number | null;
    planNodeTypes?: Array<string | null> | null;
    avgTime?: number | null;
    calls?: number | null;
  }>;
  getQueryPlanStats: Array<{
    __typename?: "QueryPlanStatsSeries";
    id: string;
    queryPlanId: string;
    planFingerprint?: string | null;
    avgTime?: Array<Array<number | null> | null> | null;
    calls?: Array<Array<number | null> | null> | null;
  }>;
  getQueryExplainsForGraph: Array<{
    __typename?: "QueryExplain";
    id: string;
    humanId: string;
    time: number;
    querySample?: {
      __typename?: "QuerySample";
      id: string;
      runtimeMs: number;
    } | null;
  }>;
  getServerDetails: {
    __typename?: "Server";
    id: string;
    humanId: string;
    collectorInfo?: {
      __typename?: "CollectorInfo";
      logCollectionDisabled: boolean;
      logCollectionDisabledReason?: string | null;
    } | null;
  };
};

export const QueryExplainListDocument = gql`
  query QueryExplainList(
    $databaseId: ID!
    $queryId: ID!
    $startTs: Int!
    $endTs: Int!
  ) {
    getQueryPlans(
      databaseId: $databaseId
      queryId: $queryId
      startTs: $startTs
      endTs: $endTs
    ) {
      id
      originalPlanId
      explainPlan
      planFingerprint
      totalCost
      planNodeTypes
      avgTime
      calls
    }
    getQueryPlanStats(
      databaseId: $databaseId
      queryId: $queryId
      startTs: $startTs
      endTs: $endTs
    ) {
      id
      queryPlanId
      planFingerprint
      avgTime
      calls
    }
    getQueryExplainsForGraph(
      databaseId: $databaseId
      queryId: $queryId
      startTs: $startTs
      endTs: $endTs
    ) {
      id
      humanId
      time
      querySample {
        id
        runtimeMs
      }
    }
    getServerDetails(databaseId: $databaseId) {
      id
      collectorInfo {
        logCollectionDisabled
        logCollectionDisabledReason
      }
      humanId
    }
  }
`;

/**
 * __useQueryExplainListQuery__
 *
 * To run a query within a React component, call `useQueryExplainListQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryExplainListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryExplainListQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *      queryId: // value for 'queryId'
 *      startTs: // value for 'startTs'
 *      endTs: // value for 'endTs'
 *   },
 * });
 */
export function useQueryExplainListQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryExplainListQuery,
    QueryExplainListQueryVariables
  > &
    (
      | { variables: QueryExplainListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryExplainListQuery, QueryExplainListQueryVariables>(
    QueryExplainListDocument,
    options,
  );
}
export function useQueryExplainListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryExplainListQuery,
    QueryExplainListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryExplainListQuery,
    QueryExplainListQueryVariables
  >(QueryExplainListDocument, options);
}
export function useQueryExplainListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        QueryExplainListQuery,
        QueryExplainListQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryExplainListQuery,
    QueryExplainListQueryVariables
  >(QueryExplainListDocument, options);
}
export type QueryExplainListQueryHookResult = ReturnType<
  typeof useQueryExplainListQuery
>;
export type QueryExplainListLazyQueryHookResult = ReturnType<
  typeof useQueryExplainListLazyQuery
>;
export type QueryExplainListSuspenseQueryHookResult = ReturnType<
  typeof useQueryExplainListSuspenseQuery
>;
export type QueryExplainListQueryResult = Apollo.QueryResult<
  QueryExplainListQuery,
  QueryExplainListQueryVariables
>;
