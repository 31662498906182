import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import Panel from "components/Panel";
import { useDateRange } from "components/WithDateRange";
import Loading from "components/Loading";
import QUERY from "./Query.graphql";
import {
  VacuumSimulatorVariables,
  VacuumSimulator as VacuumSimulatorType,
  VacuumSimulator_getVacuumSimulatorInput_tableStats as SchemaTableStatsType,
  VacuumSimulator_getVacuumSimulatorInput as VacuumSimulatorInputType,
  VacuumSimulator_getSchemaTableVacuumInfo as TableVacuumInfoType,
} from "./types/VacuumSimulator";
import {
  DeadRowsGraph,
  InsertsGraph,
  MultiXactAgeGraph,
  XactAgeGraph,
} from "components/VacuumGraph";
import PanelTable from "components/PanelTable";
import { formatBytes, formatNumber, formatPercent } from "utils/format";
import DateRangeGraph from "components/Graph/DateRangeGraph";
import {
  AreaSeries,
  LineSeries,
  ThresholdSeries,
} from "components/Graph/Series";
import { Datum, valueNonZero } from "components/Graph/util";
import { useRouteHashState } from "utils/hooks";
import { NoDataGridContainer } from "components/Grid";
import Button from "components/Button";
import { UndoIcon } from "components/Icons";
import PanelSection from "components/PanelSection";
import Callout from "components/Callout";
import { ConfigSettingDocsSnippet } from "components/DocsSnippet";
import CopyToClipboard from "components/CopyToClipboard";
import {
  VacuumSimulatorHashState,
  vacuumSimulatorHashStateDecoder,
  vacuumSimulatorHashStateEncoder,
} from "./util";
import { AutovacuumCount, simulateVacuum } from "./simulateVacuum";
import VacuumCountGraph from "./VacuumCountGraph";
import RangeInput from "components/RangeInput";
import Tip from "components/Tip";

const INT_MAX = 2147483647;
const FREEZE_MIN = 100000;
const FREEZE_MAX = 2000000000;

const VacuumSimulator: React.FunctionComponent<{
  databaseId: string;
  tableId: string;
}> = ({ databaseId, tableId }) => {
  const [{ from, to }] = useDateRange();
  const { data, loading, error } = useQuery<
    VacuumSimulatorType,
    VacuumSimulatorVariables
  >(QUERY, {
    variables: {
      databaseId,
      tableId,
      startTs: from.unix(),
      endTs: to.unix(),
    },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const { getVacuumSimulatorInput, getSchemaTableVacuumInfo } = data;

  if (getVacuumSimulatorInput.tableStats == null) {
    return (
      <Panel title="VACUUM Simulator">
        <NoDataGridContainer>
          No schema table statistic data available for simulation
        </NoDataGridContainer>
      </Panel>
    );
  }

  return (
    <VacuumSimulatorDisplay
      tableStats={getVacuumSimulatorInput.tableStats}
      simulatorInput={getVacuumSimulatorInput}
      vacuumInfo={getSchemaTableVacuumInfo}
    />
  );
};

const VacuumSimulatorDisplay: React.FunctionComponent<{
  tableStats: SchemaTableStatsType;
  simulatorInput: VacuumSimulatorInputType;
  vacuumInfo: TableVacuumInfoType;
}> = ({ tableStats, simulatorInput, vacuumInfo }) => {
  const {
    autovacuumVacuumThreshold,
    autovacuumVacuumScaleFactor,
    autovacuumFreezeMaxAge,
    autovacuumMultixactFreezeMaxAge,
    autovacuumFreezeMinAge,
    autovacuumMultixactFreezeMinAge,
    vacuumFreezeTableAge,
    vacuumMultixactFreezeTableAge,
    autovacuumVacuumInsertThreshold,
    autovacuumVacuumInsertScaleFactor,
  } = vacuumInfo;
  const [routeHashState, setRouteHashState] = useRouteHashState<
    VacuumSimulatorHashState[]
  >({
    encode: vacuumSimulatorHashStateEncoder,
    decode: vacuumSimulatorHashStateDecoder,
  });
  const [autovacuumThreshold, setAutovacuumThreshold] = useState(
    routeHashState?.find((v) => v.key == "threshold")?.value ??
      autovacuumVacuumThreshold,
  );
  const [scaleFactor, setScaleFactor] = useState(
    routeHashState?.find((v) => v.key == "scale_factor")?.value ??
      autovacuumVacuumScaleFactor,
  );
  const [freezeMaxAge, setFreezeMaxAge] = useState(
    routeHashState?.find((v) => v.key == "freeze_max_age")?.value ??
      autovacuumFreezeMaxAge,
  );
  const [mxidFreezeMaxAge, setMixdFreezeMaxAge] = useState(
    routeHashState?.find((v) => v.key == "mxid_freeze_max_age")?.value ??
      autovacuumMultixactFreezeMaxAge,
  );
  const [insertThreshold, setInsertThreshold] = useState(
    routeHashState?.find((v) => v.key == "insert_threshold")?.value ??
      autovacuumVacuumInsertThreshold,
  );
  const [insertScaleFactor, setInsertScaleFactor] = useState(
    routeHashState?.find((v) => v.key == "insert_scale_factor")?.value ??
      autovacuumVacuumInsertScaleFactor,
  );

  const hasMxid = tableStats?.minmxidAge?.some((val): boolean => {
    return valueNonZero(val[1]);
  });
  const runInsertsVacuum = (autovacuumVacuumInsertThreshold ?? -1) !== -1;
  const [ignoreInitialReusableRows, setIgnoreInitialReusableRows] =
    useState(false);

  const resetToCurrent = () => {
    setAutovacuumThreshold(autovacuumVacuumThreshold);
    setScaleFactor(autovacuumVacuumScaleFactor);
    setFreezeMaxAge(autovacuumFreezeMaxAge);
    setMixdFreezeMaxAge(autovacuumMultixactFreezeMaxAge);
    setInsertThreshold(autovacuumVacuumInsertThreshold);
    setInsertScaleFactor(autovacuumVacuumInsertScaleFactor);
    setRouteHashState([]);
  };

  const nonToastVacuumRuns = vacuumInfo.vacuumRuns.filter((val) => !val.toast);
  const autovacuumCountFromVacuumRuns: AutovacuumCount = {
    total: nonToastVacuumRuns.map((vr) => vr.vacuumStart),
    deadRows: [],
    inserts: [],
    freezing: [],
  };
  const generatePermalink = (): string => {
    const newHash: VacuumSimulatorHashState[] = [];
    if (autovacuumThreshold !== autovacuumVacuumThreshold) {
      newHash.push({ key: "threshold", value: autovacuumThreshold });
    }
    if (scaleFactor !== autovacuumVacuumScaleFactor) {
      newHash.push({ key: "scale_factor", value: scaleFactor });
    }
    if (freezeMaxAge !== autovacuumFreezeMaxAge) {
      newHash.push({ key: "freeze_max_age", value: freezeMaxAge });
    }
    if (mxidFreezeMaxAge !== autovacuumMultixactFreezeMaxAge) {
      newHash.push({ key: "mxid_freeze_max_age", value: mxidFreezeMaxAge });
    }
    if (insertThreshold !== autovacuumVacuumInsertThreshold) {
      newHash.push({ key: "insert_threshold", value: insertThreshold });
    }
    if (insertScaleFactor != autovacuumVacuumInsertScaleFactor) {
      newHash.push({ key: "insert_scale_factor", value: insertScaleFactor });
    }
    setRouteHashState(newHash);
    return window.location.href;
  };
  const panelIcons = (
    <>
      <CopyToClipboard
        className="border p-[3px] rounded inline-flex"
        content={generatePermalink}
        label=""
        title="Copy permalink to clipboard"
        fixedWidth
        variant="link"
      />
      <Button
        bare
        onClick={resetToCurrent}
        variant="link"
        className="ml-1 !border-solid !border !border-[#e5e7eb] !p-[3px] !rounded"
      >
        <UndoIcon fixedWidth title="Reset all simulation data" />
      </Button>
    </>
  );

  // from simulation
  const {
    totalAutovacuumCount,
    deadRowsVacuumed,
    deadRowsThresholdData,
    frozenxidAgeVacuumed,
    freezeAgeThresholdData,
    minmxidAgeVacuumed,
    minmxidAgeThresholdData,
    insertRowsVacuumed,
    insertRowsThresholdData,
    avoidableGrowthRows,
    reusableRows,
  } = simulateVacuum(
    tableStats,
    simulatorInput,
    {
      autovacuumThreshold,
      scaleFactor,
      freezeMaxAge,
      mxidFreezeMaxAge,
      freezeMinAge: autovacuumFreezeMinAge,
      mxidFreezeMinAge: autovacuumMultixactFreezeMinAge,
      vacuumFreezeTableAge,
      vacuumMultixactFreezeTableAge,
      insertThreshold,
      insertScaleFactor,
    },
    ignoreInitialReusableRows,
  );
  return (
    <>
      <Callout
        title="What is VACUUM Simulator"
        className="my-2"
        learnMoreLink="https://pganalyze.com/docs/vacuum-advisor/vacuum-simulator"
      >
        VACUUM Simulator lets you tweak autovacuum settings to learn the
        relation between settings and autovacuum patterns. It also helps you
        finding better autovacuum settings for the table.
      </Callout>
      <Panel
        title="VACUUM triggered by: dead rows (bloat)"
        secondaryTitle={panelIcons}
        expandable
        defaultExpanded
      >
        <>
          <PanelTable horizontal borders>
            <tbody>
              <tr>
                <th>
                  <ConfigSettingDocsSnippet
                    configName="autovacuum_vacuum_threshold"
                    noIcon
                  />
                </th>
                <td>{formatNumber(autovacuumVacuumThreshold)}</td>
                <th>
                  <ConfigSettingDocsSnippet
                    configName="autovacuum_vacuum_scale_factor"
                    noIcon
                  />
                </th>
                <td>{formatPercent(autovacuumVacuumScaleFactor, 1)}</td>
              </tr>
            </tbody>
          </PanelTable>
          <PanelSection>
            <div className="pr-[65px]">
              <DeadRowsGraph
                stats={tableStats}
                autovacuumVacuumThreshold={autovacuumVacuumThreshold}
                autovacuumVacuumScaleFactor={autovacuumVacuumScaleFactor}
              />
              <VacuumCountGraph
                autovacuumCount={autovacuumCountFromVacuumRuns}
                title="Current"
                focus="total"
              />
            </div>
          </PanelSection>
          <PanelSection>
            <div className="grid grid-cols-2">
              <div className="w-4/5">
                <AdjustSection
                  configName="autovacuum_vacuum_threshold"
                  setFunction={setAutovacuumThreshold}
                  value={autovacuumThreshold}
                  defaultValue={autovacuumVacuumThreshold}
                  min={0}
                  max={INT_MAX}
                  step={1000}
                />
              </div>
              <div className="w-4/5">
                <AdjustSection
                  configName="autovacuum_vacuum_scale_factor"
                  setFunction={setScaleFactor}
                  value={scaleFactor}
                  defaultValue={autovacuumVacuumScaleFactor}
                  min={0}
                  max={1.0}
                  step={0.01}
                />
              </div>
            </div>
          </PanelSection>
          <PanelSection>
            <div className="pr-[65px]">
              <DateRangeGraph
                axes={{
                  left: {
                    tipFormat: (y: number): string => formatNumber(y),
                  },
                }}
                series={[
                  {
                    type: AreaSeries,
                    key: "dead",
                    label: "Dead Rows",
                    color: "#efefef",
                  },
                  {
                    type: ThresholdSeries,
                    key: "threshold",
                    label: "Threshold (Rows required to start autovacuum)",
                    tipLabel: "Threshold",
                  },
                ]}
                data={{
                  threshold: deadRowsThresholdData,
                  dead: deadRowsVacuumed,
                }}
              />
              <VacuumCountGraph
                autovacuumCount={totalAutovacuumCount}
                title="Simulation"
                focus="deadRows"
              />
            </div>
            <div className="flex justify-end">
              <input
                type="checkbox"
                checked={ignoreInitialReusableRows}
                id="ignore_initial_reusable_rows"
                className="!mt-0"
                onChange={(evt) =>
                  setIgnoreInitialReusableRows(evt.target.checked)
                }
              />
              <label
                htmlFor="ignore_initial_reusable_rows"
                className="pl-2 mb-0 font-normal"
              >
                Ignore initial reusable rows{" "}
                <Tip
                  content={
                    <>
                      The initial reusable row count is calculated based on the
                      current estimated bloat row count. However, it can
                      sometimes make bloat growth in the simulation harder to
                      understand, especially when the majority of the graph is
                      occupied by the reusable rows. Check this checkbox to make
                      the initial reusable rows count to zero.
                    </>
                  }
                />
              </label>
            </div>
            <DateRangeGraph
              axes={{
                left: {
                  tipFormat: (y: number): string => formatNumber(y),
                },
                right: {
                  format: formatBytes,
                  tipFormat: formatBytes,
                },
              }}
              series={[
                {
                  type: AreaSeries,
                  key: "reusable",
                  label: "Reusable Rows",
                  color: "#addd8e",
                },
                {
                  type: AreaSeries,
                  key: "dead",
                  label: "Dead Rows",
                  color: "#efefef",
                },
                {
                  type: LineSeries,
                  key: "growth",
                  label: simulatorInput.avgRowSize
                    ? "Avoidable Growth"
                    : "Avoidable Growth Rows",
                  yAxis: "right",
                },
              ]}
              data={{
                reusable: reusableRows,
                dead: deadRowsVacuumed,
                growth: simulatorInput.avgRowSize
                  ? avoidableGrowthRows.map((val) => [
                      val[0],
                      val[1] && val[1] * simulatorInput.avgRowSize,
                    ])
                  : avoidableGrowthRows,
              }}
            />
          </PanelSection>
        </>
      </Panel>
      <Panel
        title="VACUUM triggered by: freeze age (freezing)"
        secondaryTitle={panelIcons}
        expandable
        defaultExpanded
      >
        <>
          <PanelTable horizontal borders>
            <tbody>
              <tr>
                <th>
                  <ConfigSettingDocsSnippet
                    configName="autovacuum_freeze_max_age"
                    noIcon
                  />
                </th>
                <td>{formatNumber(autovacuumFreezeMaxAge)}</td>
                <th>
                  <ConfigSettingDocsSnippet
                    configName="autovacuum_multixact_freeze_max_age"
                    noIcon
                  />
                </th>
                <td>{formatNumber(autovacuumMultixactFreezeMaxAge)}</td>
              </tr>
            </tbody>
          </PanelTable>
          <PanelSection>
            <div className="pr-[65px]">
              <XactAgeGraph
                frozenxidAge={tableStats.frozenxidAge as Datum[]}
                autovacuumFreezeMaxAge={autovacuumFreezeMaxAge}
              />
              <MultiXactAgeGraph
                minmxidAge={tableStats.minmxidAge as Datum[]}
                autovacuumMultixactFreezeMaxAge={
                  autovacuumMultixactFreezeMaxAge
                }
              />
              <VacuumCountGraph
                autovacuumCount={autovacuumCountFromVacuumRuns}
                title="Current"
                focus="total"
              />
            </div>
          </PanelSection>
          <PanelSection>
            <div className="grid grid-cols-2">
              <div className="w-4/5">
                <AdjustSection
                  configName="autovacuum_freeze_max_age"
                  setFunction={setFreezeMaxAge}
                  value={freezeMaxAge}
                  defaultValue={autovacuumFreezeMaxAge}
                  min={FREEZE_MIN}
                  max={FREEZE_MAX}
                  step={10000000}
                />
              </div>
              <div className="w-4/5">
                <AdjustSection
                  configName="autovacuum_multixact_freeze_max_age"
                  setFunction={setMixdFreezeMaxAge}
                  value={mxidFreezeMaxAge}
                  defaultValue={autovacuumMultixactFreezeMaxAge}
                  min={FREEZE_MIN}
                  max={FREEZE_MAX}
                  step={10000000}
                  noMxidMessage={!hasMxid && "Multixact ID not used"}
                />
              </div>
            </div>
          </PanelSection>
          <PanelSection>
            <div className="pr-[65px]">
              <DateRangeGraph
                axes={{
                  left: {
                    tipFormat: (y: number): string => formatNumber(y),
                  },
                }}
                series={[
                  {
                    type: AreaSeries,
                    key: "frozenxidAge",
                    label: "Oldest Unfrozen XID Age",
                    color: "#efefef",
                  },
                  {
                    type: ThresholdSeries,
                    key: "frozenxidThreshold",
                    label:
                      "Threshold (Age to trigger anti-wraparound autovacuum)",
                    tipLabel: "Threshold",
                  },
                ]}
                data={{
                  frozenxidThreshold: freezeAgeThresholdData,
                  frozenxidAge: frozenxidAgeVacuumed,
                }}
              />
              {hasMxid && (
                <DateRangeGraph
                  axes={{
                    left: {
                      tipFormat: (y: number): string => formatNumber(y),
                    },
                  }}
                  series={[
                    {
                      type: AreaSeries,
                      key: "minmxidAge",
                      label: "Oldest Unfrozen Multixact ID Age",
                      color: "#efefef",
                    },
                    {
                      type: ThresholdSeries,
                      key: "minmxidThreshold",
                      label:
                        "Threshold (Age to trigger anti-wraparound autovacuum)",
                      tipLabel: "Threshold",
                    },
                  ]}
                  data={{
                    minmxidThreshold: minmxidAgeThresholdData,
                    minmxidAge: minmxidAgeVacuumed,
                  }}
                />
              )}
              <VacuumCountGraph
                autovacuumCount={totalAutovacuumCount}
                title="Simulation"
                focus="freezing"
              />
            </div>
          </PanelSection>
        </>
      </Panel>
      {runInsertsVacuum && (
        <Panel
          title="VACUUM triggered by: inserts (freezing)"
          secondaryTitle={panelIcons}
          expandable
          defaultExpanded
        >
          <>
            <PanelTable horizontal borders>
              <tbody>
                <tr>
                  <th>
                    <ConfigSettingDocsSnippet
                      configName="autovacuum_vacuum_insert_threshold"
                      noIcon
                    />
                  </th>
                  <td>{formatNumber(autovacuumVacuumInsertThreshold)}</td>
                  <th>
                    <ConfigSettingDocsSnippet
                      configName="autovacuum_vacuum_insert_scale_factor"
                      noIcon
                    />
                  </th>
                  <td>{formatPercent(autovacuumVacuumInsertScaleFactor, 1)}</td>
                </tr>
              </tbody>
            </PanelTable>
            <PanelSection>
              <div className="pr-[65px]">
                <InsertsGraph
                  stats={tableStats}
                  autovacuumVacuumInsertThreshold={
                    autovacuumVacuumInsertThreshold
                  }
                  autovacuumVacuumInsertScaleFactor={
                    autovacuumVacuumInsertScaleFactor
                  }
                />
                <VacuumCountGraph
                  autovacuumCount={autovacuumCountFromVacuumRuns}
                  title="Current"
                  focus="total"
                />
              </div>
            </PanelSection>
            <PanelSection>
              <div className="grid grid-cols-2">
                <div className="w-4/5">
                  <AdjustSection
                    configName="autovacuum_vacuum_insert_threshold"
                    setFunction={setInsertThreshold}
                    value={insertThreshold}
                    defaultValue={autovacuumVacuumInsertThreshold}
                    min={0}
                    max={INT_MAX}
                    step={1000}
                  />
                </div>
                <div className="w-4/5">
                  <AdjustSection
                    configName="autovacuum_vacuum_insert_scale_factor"
                    setFunction={setInsertScaleFactor}
                    value={insertScaleFactor}
                    defaultValue={autovacuumVacuumInsertScaleFactor}
                    min={0}
                    max={1.0}
                    step={0.01}
                  />
                </div>
              </div>
            </PanelSection>
            <PanelSection>
              <div className="pr-[65px]">
                <DateRangeGraph
                  axes={{
                    left: {
                      tipFormat: (y: number): string => formatNumber(y),
                    },
                  }}
                  series={[
                    {
                      type: AreaSeries,
                      key: "insertRows",
                      label: "Inserted Rows",
                      color: "#efefef",
                    },
                    {
                      type: ThresholdSeries,
                      key: "threshold",
                      label: "Threshold (Rows required to start autovacuum)",
                    },
                  ]}
                  data={{
                    threshold: insertRowsThresholdData,
                    insertRows: insertRowsVacuumed,
                  }}
                />
                <VacuumCountGraph
                  autovacuumCount={totalAutovacuumCount}
                  title="Simulation"
                  focus="inserts"
                />
              </div>
            </PanelSection>
          </>
        </Panel>
      )}
    </>
  );
};

const AdjustSection: React.FunctionComponent<{
  configName: string;
  setFunction: React.Dispatch<React.SetStateAction<number>>;
  value: number;
  defaultValue: number;
  min: number;
  max: number;
  step: number;
  noMxidMessage?: string;
}> = ({
  configName,
  setFunction,
  value,
  defaultValue,
  min,
  max,
  step,
  noMxidMessage,
}) => {
  const scaleFactor = configName.endsWith("scale_factor");
  return (
    <div>
      <div className="pb-2 font-semibold text-[#666] flex">
        <div className="grow">
          <ConfigSettingDocsSnippet configName={configName} noIcon />
        </div>
        {!noMxidMessage && (
          <Button
            bare
            onClick={() => {
              setFunction(defaultValue);
            }}
            variant="link"
            className="!border-solid !border !border-[#e5e7eb] !p-[3px] !rounded"
          >
            <UndoIcon fixedWidth title="Reset" />
          </Button>
        )}
      </div>
      {noMxidMessage ? (
        noMxidMessage
      ) : (
        <>
          <div className="pb-2">
            <RangeInput
              value={value}
              id={configName}
              min={min}
              max={max}
              step={step}
              onChange={(evt) => {
                const newValue = Number(evt.currentTarget.value);
                if (!isNaN(newValue) && newValue >= min && newValue <= max) {
                  setFunction(newValue);
                }
              }}
            />
          </div>
          <div className="text-right">
            <small>
              MIN: {formatNumber(min)}, MAX: {formatNumber(max)}{" "}
              {scaleFactor && `(${formatPercent(max, 0)})`}
            </small>
          </div>
          <div>
            <input
              type="number"
              className="form-control"
              value={value}
              min={min}
              max={max}
              step={step}
              onChange={(evt) => {
                const newValue = Number(evt.currentTarget.value);
                if (!isNaN(newValue) && newValue >= min && newValue <= max) {
                  setFunction(newValue);
                }
              }}
            />
          </div>
          <div className="text-right">
            <small>
              {scaleFactor ? formatPercent(value, 1) : formatNumber(value)}
            </small>
          </div>
        </>
      )}
    </div>
  );
};

export default VacuumSimulator;
