import React, { useEffect, useState } from "react";

import { marked } from "marked";

import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import Loading from "components/Loading";

import styles from "./style.module.scss";
import moment from "moment";
import { formatDateShort } from "utils/format";
import { useAppConfig } from "components/WithAppConfig";

marked.use({
  renderer: {
    link(href: string, title: string, text: string) {
      const titleAttr = !!title ? `title="${title}"` : "";
      return `<a href="${href}" target="_blank" ${titleAttr}>${text}</a>`;
    },
  },
});

type ChangelogChannel = "enterprise-server" | "cloud";

type ChangelogDeployEntry = {
  pr: number;
  summary: string;
  channels: ChangelogChannel[];
  description: string | null;
};

type ChangelogDeploy = {
  deployed_at: string;
  entries: ChangelogDeployEntry[];
};

type Changelog = {
  deployed: ChangelogDeploy[];
};

const Changelog: React.FunctionComponent = () => {
  const { changelog, error } = useChangelog();

  return (
    <PageContent
      title="What's New"
      pageCategory="changelog"
      pageName="changelog"
    >
      <div className="max-w-[1000px]">
        <Panel title="Changelog">
          <PanelSection>
            {changelog ? (
              <ChangelogContent changelog={changelog} />
            ) : (
              <Loading error={!!error} />
            )}
          </PanelSection>
        </Panel>
      </div>
    </PageContent>
  );
};

function useChangelog() {
  const { changelogUrl } = useAppConfig();
  const [changelog, setChangelog] = useState<Changelog | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    fetch(changelogUrl)
      .then((resp) => resp.json())
      .then((result) => setChangelog(result))
      .catch((err) => setError(err));
  }, [changelogUrl]);

  const cloudChangelog = changelog
    ? {
        ...changelog,
        deployed: changelog.deployed
          .map((deploy) => {
            const deployedDate = formatDateShort(moment(deploy.deployed_at));
            return {
              deployed_at: deployedDate,
              entries: deploy.entries.filter((e) =>
                e.channels.includes("cloud"),
              ),
            };
          })
          .filter(
            (deploy) =>
              deploy.entries.length > 0 &&
              moment(deploy.deployed_at).isAfter(moment("2025-01-01")),
          )
          .reduce<ChangelogDeploy[]>((deploys, deploy) => {
            // collapse multiple deploys on the same day
            const prevDeploy =
              deploys.length === 0 ? undefined : deploys.at(-1);
            if (prevDeploy?.deployed_at === deploy.deployed_at) {
              prevDeploy.entries.push(...deploy.entries);
            } else {
              deploys.push(deploy);
            }

            return deploys;
          }, []),
      }
    : undefined;

  return { changelog: cloudChangelog, error };
}

const ChangelogContent: React.FunctionComponent<{ changelog: Changelog }> = ({
  changelog,
}) => {
  return (
    <div className={styles.changelog}>
      {changelog.deployed
        .slice()
        .reverse()
        .map((deploy) => {
          return <ChangelogDeploy key={deploy.deployed_at} deploy={deploy} />;
        })}
    </div>
  );
};

function ChangelogDeploy({ deploy }: { deploy: ChangelogDeploy }) {
  if (deploy.entries.length === 0) {
    return null;
  }
  const deployId = deploy.deployed_at;
  return (
    <div className="mx-4 border-b last:border-b-0 border-gray-200 mt-7 lg:flex">
      <h4 className="mt-1 font-normal leading-none">
        <a
          id={deployId}
          href={`#${deployId}`}
          className="text-inherit after:content-['_#'] after:invisible hover:after:visible"
        >
          {deploy.deployed_at}
        </a>
      </h4>
      <div className="flex-1 group lg:ml-10">
        {deploy.entries.map((entry, i) => {
          const content = entry.description
            ? marked.parse(entry.description, { async: false })
            : undefined;
          return (
            <div
              className="mb-6 first:border-0 first:pt-0 group-first:border-0 group-first:pt-0 border-t border-gray-200 pt-8 text-lg"
              key={i}
            >
              <span className="inline-block mb-2 font-semibold">
                {entry.summary}
              </span>
              {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Changelog;
