// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpdateBillingDetailsQueryVariables = Types.Exact<{
  organizationSlug: Types.Scalars["ID"]["input"];
}>;

export type UpdateBillingDetailsQuery = {
  __typename?: "GraphqlQuery";
  getOrganizationBilling?: {
    __typename?: "OrganizationBilling";
    id: string;
    billingCompanyName?: string | null;
    billingAddressLine1?: string | null;
    billingAddressLine2?: string | null;
    billingAddressZipCode?: string | null;
    billingAddressCity?: string | null;
    billingAddressState?: string | null;
    billingAddressCountry?: string | null;
    billingVatId?: string | null;
    billingEmail?: string | null;
  } | null;
};

export const UpdateBillingDetailsDocument = gql`
  query UpdateBillingDetails($organizationSlug: ID!) {
    getOrganizationBilling(organizationSlug: $organizationSlug) {
      id
      billingCompanyName
      billingAddressLine1
      billingAddressLine2
      billingAddressZipCode
      billingAddressCity
      billingAddressState
      billingAddressCountry
      billingVatId
      billingEmail
    }
  }
`;

/**
 * __useUpdateBillingDetailsQuery__
 *
 * To run a query within a React component, call `useUpdateBillingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateBillingDetailsQuery({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useUpdateBillingDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpdateBillingDetailsQuery,
    UpdateBillingDetailsQueryVariables
  > &
    (
      | { variables: UpdateBillingDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UpdateBillingDetailsQuery,
    UpdateBillingDetailsQueryVariables
  >(UpdateBillingDetailsDocument, options);
}
export function useUpdateBillingDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpdateBillingDetailsQuery,
    UpdateBillingDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UpdateBillingDetailsQuery,
    UpdateBillingDetailsQueryVariables
  >(UpdateBillingDetailsDocument, options);
}
export function useUpdateBillingDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UpdateBillingDetailsQuery,
        UpdateBillingDetailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UpdateBillingDetailsQuery,
    UpdateBillingDetailsQueryVariables
  >(UpdateBillingDetailsDocument, options);
}
export type UpdateBillingDetailsQueryHookResult = ReturnType<
  typeof useUpdateBillingDetailsQuery
>;
export type UpdateBillingDetailsLazyQueryHookResult = ReturnType<
  typeof useUpdateBillingDetailsLazyQuery
>;
export type UpdateBillingDetailsSuspenseQueryHookResult = ReturnType<
  typeof useUpdateBillingDetailsSuspenseQuery
>;
export type UpdateBillingDetailsQueryResult = Apollo.QueryResult<
  UpdateBillingDetailsQuery,
  UpdateBillingDetailsQueryVariables
>;
