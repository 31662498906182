import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  useExplainWorkbooksQuery,
  ExplainWorkbooksDocument,
} from "./gql/Query.generated";
import { useDeleteExplainWorkbookMutation } from "./ExplainVariantSidebar/gql/Mutation.workbook.generated";

import Loading from "components/Loading";
import Panel from "components/Panel";
import Grid from "components/Grid";
import { formatTimestampShort } from "utils/format";
import moment from "moment";
import { useRoutes } from "utils/routes";
import FilterSearch from "components/FilterSearch";
import { makeFilter } from "utils/filter";
import Popover from "components/Popover";
import classNames from "classnames";
import Badge from "components/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import Button from "components/Button";
import QueryTuningCallout from "components/QueryTuningCallout";

const ExplainWorkbookList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showDraft, setShowDraft] = useState(false);
  const { databaseWorkbookVariants, databaseWorkbooksNew } = useRoutes();

  const { databaseId } = useParams();
  const { loading, error, data } = useExplainWorkbooksQuery({
    variables: { databaseId },
  });
  const [deleteExplainWorkbook] = useDeleteExplainWorkbookMutation();

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const secondaryTitle = (
    <>
      <div className="flex">
        <input
          type="checkbox"
          checked={showDraft}
          id="show_draft"
          onChange={(e) => setShowDraft(e.target.checked)}
        />
        <label htmlFor="show_draft">Show draft workbooks</label>
      </div>
      <FilterSearch initialValue={searchTerm} onChange={setSearchTerm} />
    </>
  );

  const canCreateWorkbooks = data.getDatabaseDetails.permittedToTuneQueries;

  const filteredData = data.getExplainWorkbooks
    .filter((dat) => (showDraft ? true : !dat.draft))
    .filter(makeFilter(searchTerm, "name"));

  return (
    <>
      <QueryTuningCallout showPermissionHint={!canCreateWorkbooks} />
      <div className="flex justify-end mb-2">
        <Popover
          content={
            !canCreateWorkbooks && (
              <div>
                You are not allowed to create new workbooks: you do not have the{" "}
                <strong>Tune Queries</strong> permission on this database.
              </div>
            )
          }
        >
          <Link
            to={databaseWorkbooksNew(databaseId)}
            className={classNames(
              "btn btn-success",
              canCreateWorkbooks ? "" : "pointer-events-none opacity-[.65]",
            )}
          >
            New Workbook
          </Link>
        </Popover>
      </div>
      <Panel title="All Workbooks" secondaryTitle={secondaryTitle}>
        <Grid
          className="grid-cols-[1fr_200px_300px_40px]"
          data={filteredData}
          defaultSortBy="lastActivityAt"
          pageSize={10}
          columns={[
            {
              field: "name",
              header: "Workbook",
              renderer: function NameCell({ rowData, fieldData }) {
                return (
                  <div className="flex flex-col">
                    <div className="flex">
                      <Link
                        to={databaseWorkbookVariants(databaseId, rowData.id)}
                      >
                        {fieldData}
                      </Link>
                      {rowData.draft && <Badge className="ml-2">Draft</Badge>}
                    </div>
                    {rowData.user?.fullname}
                  </div>
                );
              },
            },
            {
              field: "variantsCount",
              header: "Variant",
              style: "number",
            },
            {
              field: "lastActivityAt",
              header: "Last Activity",
              defaultSortOrder: "desc",
              className: "text-right",
              headerClassName: "text-right",
              renderer: function LastActivityCell({ fieldData }) {
                return formatTimestampShort(moment.unix(fieldData));
              },
            },
            {
              field: "id",
              header: "",
              renderer: function DeleteCell({ rowData }) {
                return (
                  <Button
                    bare
                    onClick={() => {
                      if (
                        window.confirm(
                          `Delete a draft workbook "${rowData.name}", created by ${rowData.user?.fullname}?`,
                        )
                      ) {
                        deleteExplainWorkbook({
                          variables: { workbookId: rowData.id },
                          refetchQueries: [
                            {
                              query: ExplainWorkbooksDocument,
                              variables: { databaseId },
                            },
                          ],
                          awaitRefetchQueries: true,
                        });
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      title="Delete"
                      className="text-[#CA1515]"
                    />
                  </Button>
                );
              },
            },
          ]}
        />
      </Panel>
    </>
  );
};

export default ExplainWorkbookList;
