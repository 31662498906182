import React from "react";

import {
  ModalContent,
  ModalLayout,
  ModalOverlay,
  ModalCloseButton,
} from "components/Modal";

const ModalContainer = ({
  title,
  onClose,
  className,
  children,
  layout = "right sidebar",
}: {
  title?: React.ReactNode;
  className?: string;
  onClose: () => void;
  children: React.ReactNode;
  layout?: ModalLayout;
}) => {
  return (
    <ModalOverlay onDismiss={onClose}>
      <ModalContent className={className} layout={layout} title={title}>
        <div className="flex space-between bg-[#f7fafc] text-[#606060] border-b border-solid border-[#e8e8ee] text-lg leading-9 pl-4">
          <span className="w-full inline-flex items-center overflow-hidden text-ellipsis">
            {title}
          </span>
          <ModalCloseButton className="float-right text-lg !p-3" />
        </div>
        <div className="m-5">{children}</div>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ModalContainer;
