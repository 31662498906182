// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type QueryForWorkbookQueryVariables = Types.Exact<{
  databaseId: Types.Scalars["ID"]["input"];
  queryId?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
  queryText?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  baselineId?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
}>;

export type QueryForWorkbookQuery = {
  __typename?: "GraphqlQuery";
  getQueryForWorkbook: {
    __typename?: "QueryForWorkbook";
    id: string;
    validQuery: boolean;
    errorMessage?: string | null;
    normalizedQuery?: string | null;
    parameters?: Array<{
      __typename?: "QueryParameter";
      ref: number;
      name: string;
    }> | null;
  };
};

export const QueryForWorkbookDocument = gql`
  query QueryForWorkbook(
    $databaseId: ID!
    $queryId: ID
    $queryText: String
    $baselineId: ID
  ) {
    getQueryForWorkbook(
      databaseId: $databaseId
      queryId: $queryId
      queryText: $queryText
      baselineId: $baselineId
    ) {
      id
      validQuery
      errorMessage
      normalizedQuery
      parameters {
        ref
        name
      }
    }
  }
`;

/**
 * __useQueryForWorkbookQuery__
 *
 * To run a query within a React component, call `useQueryForWorkbookQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryForWorkbookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryForWorkbookQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *      queryId: // value for 'queryId'
 *      queryText: // value for 'queryText'
 *      baselineId: // value for 'baselineId'
 *   },
 * });
 */
export function useQueryForWorkbookQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryForWorkbookQuery,
    QueryForWorkbookQueryVariables
  > &
    (
      | { variables: QueryForWorkbookQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryForWorkbookQuery, QueryForWorkbookQueryVariables>(
    QueryForWorkbookDocument,
    options,
  );
}
export function useQueryForWorkbookLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryForWorkbookQuery,
    QueryForWorkbookQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryForWorkbookQuery,
    QueryForWorkbookQueryVariables
  >(QueryForWorkbookDocument, options);
}
export function useQueryForWorkbookSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        QueryForWorkbookQuery,
        QueryForWorkbookQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryForWorkbookQuery,
    QueryForWorkbookQueryVariables
  >(QueryForWorkbookDocument, options);
}
export type QueryForWorkbookQueryHookResult = ReturnType<
  typeof useQueryForWorkbookQuery
>;
export type QueryForWorkbookLazyQueryHookResult = ReturnType<
  typeof useQueryForWorkbookLazyQuery
>;
export type QueryForWorkbookSuspenseQueryHookResult = ReturnType<
  typeof useQueryForWorkbookSuspenseQuery
>;
export type QueryForWorkbookQueryResult = Apollo.QueryResult<
  QueryForWorkbookQuery,
  QueryForWorkbookQueryVariables
>;
