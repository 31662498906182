// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CreateExplainWorkbookMutationVariables = Types.Exact<{
  databaseId: Types.Scalars["ID"]["input"];
  name: Types.Scalars["String"]["input"];
  queryText: Types.Scalars["String"]["input"];
  normalizedQuery: Types.Scalars["String"]["input"];
  description?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  newAliases?: Types.InputMaybe<Types.Scalars["JSON"]["input"]>;
}>;

export type CreateExplainWorkbookMutation = {
  __typename?: "Mutation";
  createExplainWorkbook: {
    __typename?: "CreateExplainWorkbookPayload";
    explainWorkbookId?: string | null;
    explainQueryId?: string | null;
  };
};

export const CreateExplainWorkbookDocument = gql`
  mutation CreateExplainWorkbook(
    $databaseId: ID!
    $name: String!
    $queryText: String!
    $normalizedQuery: String!
    $description: String
    $newAliases: JSON
  ) {
    createExplainWorkbook(
      databaseId: $databaseId
      name: $name
      queryText: $queryText
      normalizedQuery: $normalizedQuery
      description: $description
      newAliases: $newAliases
    ) {
      explainWorkbookId
      explainQueryId
    }
  }
`;
export type CreateExplainWorkbookMutationFn = Apollo.MutationFunction<
  CreateExplainWorkbookMutation,
  CreateExplainWorkbookMutationVariables
>;

/**
 * __useCreateExplainWorkbookMutation__
 *
 * To run a mutation, you first call `useCreateExplainWorkbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExplainWorkbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExplainWorkbookMutation, { data, loading, error }] = useCreateExplainWorkbookMutation({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *      name: // value for 'name'
 *      queryText: // value for 'queryText'
 *      normalizedQuery: // value for 'normalizedQuery'
 *      description: // value for 'description'
 *      newAliases: // value for 'newAliases'
 *   },
 * });
 */
export function useCreateExplainWorkbookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExplainWorkbookMutation,
    CreateExplainWorkbookMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExplainWorkbookMutation,
    CreateExplainWorkbookMutationVariables
  >(CreateExplainWorkbookDocument, options);
}
export type CreateExplainWorkbookMutationHookResult = ReturnType<
  typeof useCreateExplainWorkbookMutation
>;
export type CreateExplainWorkbookMutationResult =
  Apollo.MutationResult<CreateExplainWorkbookMutation>;
export type CreateExplainWorkbookMutationOptions = Apollo.BaseMutationOptions<
  CreateExplainWorkbookMutation,
  CreateExplainWorkbookMutationVariables
>;
