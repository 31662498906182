// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import { ExplainQueryShortFragmentFragmentDoc } from "./Fragment.short.generated";
export type ExplainQueryFragmentFragment = {
  __typename?: "ExplainQuery";
  id: string;
  createdAt: number;
  updatedAt: number;
  description?: string | null;
  queryFingerprint: string;
  queryText: string;
  name: string;
  queryTextWithAlias: string;
  paramRefAliasMap: any;
  draft: boolean;
  plannerSettings: any;
  explainResults: Array<{
    __typename?: "ExplainResult";
    id: string;
    format: string;
    resultSource: string;
    createdAt: number;
    updatedAt: number;
    status?: string | null;
    errorMessage?: string | null;
    totalCost?: number | null;
    totalSharedBlksHit?: number | null;
    totalSharedBlksRead?: number | null;
    totalSharedBlksWritten?: number | null;
    totalSharedBlksDirtied?: number | null;
    totalBlkReadTime?: number | null;
    totalBlkWriteTime?: number | null;
    planNodeTypes?: Array<string | null> | null;
    runtimeMs?: number | null;
    planFingerprint?: string | null;
    parameterSetId?: string | null;
    user?: { __typename?: "User"; id: string; fullname?: string | null } | null;
  }>;
  user?: { __typename?: "User"; id: string; fullname?: string | null } | null;
  queryTextWithParameters: Array<{
    __typename?: "QueryTextWithParameters";
    id: string;
    parameterSetId: string;
    queryWithParameters: string;
  }>;
};

export const ExplainQueryFragmentFragmentDoc = gql`
  fragment ExplainQueryFragment on ExplainQuery {
    ...ExplainQueryShortFragment
    explainResults {
      id
      format
      resultSource
      createdAt
      updatedAt
      status
      errorMessage
      totalCost
      totalSharedBlksHit
      totalSharedBlksRead
      totalSharedBlksWritten
      totalSharedBlksDirtied
      totalBlkReadTime
      totalBlkWriteTime
      planNodeTypes
      runtimeMs
      planFingerprint
      parameterSetId
      user {
        id
        fullname
      }
    }
  }
  ${ExplainQueryShortFragmentFragmentDoc}
`;
