import InstallChoice from './components/InstallChoice'

import styles from './style.module.scss'

import imgLogoAiven from './images/logo_aiven.svg'
import imgLogoAws from './images/logo_aws.svg'
import imgLogoAzure from './images/logo_azure.svg'
import imgLogoGcp from './images/logo_google_cloud.svg'
import imgLogoPgsql from './images/logo_postgres.svg'
import imgLogoHeroku from './images/logo_heroku.svg'
import imgLogoCrunchyBridge from './images/logo_crunchy_bridge.svg'

export const InstallChoiceList = ({ docsRoot }) => {
  return (
    <InstallChoice
      docsRoot={docsRoot}
      items={[
        {
          link: "install/amazon_rds/00_overview",
          img: imgLogoAws,
          providerName: "Amazon Web Services",
          text: "RDS and Aurora",
        },
        {
          link: "install/azure_database/01_configure_azure_instance",
          img: imgLogoAzure,
          providerName: "Microsoft Azure",
          text: "Flexible Server",
        },
        {
          link: "install/google_cloud_sql/01_create_monitoring_user",
          img: imgLogoGcp,
          providerName: "Google Cloud",
          text: "Cloud SQL and AlloyDB",
        },
        {
          link: "install/self_managed/00_choose_setup_method",
          img: imgLogoPgsql,
          providerName: "Self-Managed",
          text: "VM, Container, On-Premise",
        },
        {
          link: "install/heroku_postgres/01_deploy_the_collector",
          img: imgLogoHeroku,
          providerName: "Heroku",
          text: "Heroku Postgres",
        },
        {
          link: "install/crunchy_bridge/01_deploy_the_collector",
          img: imgLogoCrunchyBridge,
          providerName: "Crunchy Data",
          text: "Crunchy Bridge",
        },
        {
          link: "install/aiven/01_create_monitoring_user",
          img: imgLogoAiven,
          providerName: "Aiven",
          text: "Managed PostgreSQL",
        },
      ]}
    />
  );
};

<PublicOnly>
  <InstallChoiceList docsRoot={true} />
</PublicOnly>
<InAppOnly>
  <InstallChoiceList docsRoot={false} />
</InAppOnly>
