import type { Node, Plan } from "../types/explain";

export function reduce<T>(
  node: Node,
  callback: (state: T, node: Node, depth: number) => T,
  initialState: T,
): T {
  function visit(state: T, node: Node, depth: number): T {
    let newState = callback(state, node, depth);
    if (node.Plans) {
      newState = node.Plans.reduce((childState, child) => {
        return visit(childState, child, depth + 1);
      }, newState);
    }
    return newState;
  }

  return visit(initialState, node, 0);
}

export function findPlanIndexes(plan: Plan): Set<string> {
  return reduce(
    plan[0].Plan,
    (allIndexes, node) => {
      const index = node["Index Name"];
      if (index) {
        allIndexes.add(index);
      }
      return allIndexes;
    },
    new Set<string>(),
  );
}

export type PreparedPlan = {
  root: Node;
  subPlans: Node[];
};

export function extractSubplans(plan: Plan): PreparedPlan {
  // N.B.: We use structuredClone here because we mutate the plan while walking it
  const root = structuredClone(plan[0].Plan);
  const subPlans = [] as Node[];
  function doExtractSubplans(node: Node): void {
    if (!("Plans" in node)) {
      return;
    }
    const mainChildren = node["Plans"].filter((p) => {
      if (p["Parent Relationship"] == "InitPlan") {
        subPlans.push(p);
        return false;
      }
      return true;
    });

    node["Plans"].forEach((p) => doExtractSubplans(p));

    node["Plans"] = mainChildren;
  }

  doExtractSubplans(root);

  return {
    root,
    subPlans,
  };
}
