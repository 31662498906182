import React, { useState } from "react";
import { useParams } from "react-router-dom";

import moment from "moment-timezone";
import sortBy from "lodash/sortBy";

import Form from "components/Form";
import FormSubmit from "components/FormSubmit";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import SettingsNav from "components/SettingsNav";
import CopyToClipboardIcon from "components/CopyToClipboardIcon";

import styles from "./style.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-light-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";

import { ApiKeysQuery, useApiKeysQuery } from "./gql/Query.generated";
import { useRoutes } from "utils/routes";
import { formatDateShort } from "utils/format";
import Loading from "components/Loading";
import Grid from "components/Grid";
import Button from "components/Button";

type ApiKeyType = Omit<ApiKeysQuery["getApiKeys"][number], "__typename">;

const ApiKeys: React.FunctionComponent = () => {
  const { slug: organizationSlug } = useParams();
  return (
    <PageContent
      title="Settings"
      pageCategory="api-keys"
      pageName="index"
      featureNav={<SettingsNav />}
    >
      <ApiKeysContent organizationSlug={organizationSlug} />
    </PageContent>
  );
};

export const ApiKeysContent: React.FunctionComponent<{
  organizationSlug: string;
}> = ({ organizationSlug }) => {
  const { organizationApi } = useRoutes();
  const { data, loading, error } = useApiKeysQuery({
    variables: { organizationSlug },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const apiKeys = sortBy(
    data.getApiKeys,
    (k: ApiKeyType): number | null | undefined => k.lastUsedAt,
  );
  const { slug, permittedToCreateApiKeys, permittedToCreateRWApiKeys } =
    data.getOrganizationDetails;

  return (
    <Panel title="API Keys">
      <Grid
        className="grid-cols-[320px_1fr_120px_120px_120px_40px]"
        data={apiKeys}
        defaultSortBy="createdAt"
        columns={[
          {
            field: "token",
            header: "Key",
            renderer: function TokenCell({ fieldData }) {
              return <Token token={fieldData} />;
            },
          },
          {
            field: "accessScopeType",
            header: "Scope",
            renderer: function ScopeCell({ rowData }) {
              return (
                <>
                  {rowData.accessScopeType}: {rowData.accessScopeName}
                </>
              );
            },
          },
          { field: "accessType", header: "Access Type" },
          {
            field: "lastUsedAt",
            header: "Last Used At",
            nullValue: "-",
            renderer: function LastUsedAtCell({ fieldData }) {
              return formatDateShort(moment.unix(fieldData));
            },
          },
          {
            field: "createdAt",
            header: "Created At",
            renderer: function CreatedAtCell({ fieldData }) {
              return formatDateShort(moment.unix(fieldData));
            },
          },
          {
            field: "id",
            header: "",
            renderer: function RevokeCell({ rowData }) {
              return (
                <a
                  href={`/organizations/${organizationSlug}/api/${rowData.id}`}
                  data-method="delete"
                  data-confirm={`Revoke the API key of ${rowData.accessScopeType.toLowerCase()} ${
                    rowData.accessScopeName
                  } (${rowData.accessType})?`}
                >
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    title="Revoke"
                    className="text-[#CA1515] cursor-pointer"
                  />
                </a>
              );
            },
          },
        ]}
      />
      {permittedToCreateApiKeys ? (
        <>
          <PanelSection>
            <p>
              Choose the access type of the new API key (the key will be created
              at the organization level):
            </p>
            <Form action={organizationApi(slug)} insideSection>
              <div className="form-group">
                <input type="radio" name="scope" value="read" defaultChecked />{" "}
                <label className="text-[#555]" htmlFor="read_scope">
                  Read API Key
                </label>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="help-block">
                      Can be used to retrieve statistics data and pganalyze
                      settings with the pganalyze GraphQL API
                    </div>
                  </div>
                </div>
                <input
                  type="radio"
                  name="scope"
                  value="collector"
                  id="collector_scope"
                />{" "}
                <label className="text-[#555]" htmlFor="collector_scope">
                  Collector API Key
                </label>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="help-block">
                      Can be used by the collector to send database statistics
                      snapshots to pganalyze
                    </div>
                  </div>
                </div>
                {permittedToCreateRWApiKeys && (
                  <>
                    <input
                      type="radio"
                      name="scope"
                      value="read_write"
                      id="read_write_scope"
                    />{" "}
                    <label className="text-[#555]" htmlFor="read_write_scope">
                      Read-Write API Key
                    </label>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="help-block">
                          Can be used to retrieve statistics data and pganalyze
                          settings, and change pganalyze settings with the
                          pganalyze GraphQL API
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <FormSubmit title="Create new API Key" />
            </Form>
          </PanelSection>
        </>
      ) : (
        <PanelSection>
          <strong>Permission Error:</strong> You are not allowed to view or
          create API keys in this organization.
        </PanelSection>
      )}
    </Panel>
  );
};

const Token: React.FunctionComponent<{ token: string }> = ({ token }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  let tokenText;
  let icon;
  if (show) {
    tokenText = token;
    icon = <CopyToClipboardIcon content={token} />;
  } else {
    // mask with * except beginning of token
    const unmaskedLen = /^pga[cwr]_/.test(token)
      ? 8 // for new-style tokens, we have a fixed prefix, so we can show a bit past that
      : 3; // otherwise just show the first three characters

    if (token.length >= unmaskedLen) {
      tokenText =
        token.substring(0, unmaskedLen) +
        "*".repeat(token.length - unmaskedLen);
    } else {
      tokenText = "*".repeat(token.length);
    }
    icon = (
      <Button bare onClick={handleShow} className={styles.icon}>
        <FontAwesomeIcon icon={faEye} title="Show" fixedWidth />
      </Button>
    );
  }

  return (
    <div className="flex justify-between">
      <code className={styles.token}>{tokenText}</code> {icon}
    </div>
  );
};

export default ApiKeys;
