// @ts-nocheck
import * as Types from "../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type QueryDetailsQueryVariables = Types.Exact<{
  databaseId: Types.Scalars["ID"]["input"];
  queryId: Types.Scalars["ID"]["input"];
}>;

export type QueryDetailsQuery = {
  __typename?: "GraphqlQuery";
  getQueryDetails: {
    __typename?: "Query";
    id: string;
    normalizedQuery: string;
    truncatedQuery: string;
    queryAnalysis: any;
    fingerprint: string;
    recentQuerySamplesCount?: number | null;
    recentQueryExplainsCount?: number | null;
    recentQueryTagsetCount?: number | null;
    recentLogLinesCount?: number | null;
    supportQueryTuning: boolean;
    normalizedQueryScanTokens?: Array<{
      __typename?: "QueryScanToken";
      startPos: number;
      endPos: number;
      token: string;
      keywordKind: string;
      schemaTableId?: string | null;
    }> | null;
    postgresRole?: {
      __typename?: "PostgresRole";
      id: string;
      name: string;
    } | null;
  };
  getServerDetails: {
    __typename?: "Server";
    id: string;
    humanId: string;
    blockSize: number;
    organization: {
      __typename?: "Organization";
      id: string;
      slug: string;
      indexAdvisorTrialEndsAt?: number | null;
    };
  };
  trackIoTimingSetting?: {
    __typename?: "PostgresSetting";
    id: string;
    value?: string | null;
  } | null;
  getQueryTagSummaryForQuery: Array<{
    __typename?: "QueryTagSummary";
    id: string;
    key: string;
    values?: Array<{
      __typename?: "QueryTagSummaryValues";
      value?: string | null;
      count: number;
    }> | null;
  }>;
  getDatabaseDetails: {
    __typename?: "Database";
    id: string;
    permittedToTuneQueries: boolean;
  };
};

export const QueryDetailsDocument = gql`
  query QueryDetails($databaseId: ID!, $queryId: ID!) {
    getQueryDetails(databaseId: $databaseId, queryId: $queryId) {
      id
      normalizedQuery
      truncatedQuery
      queryAnalysis
      normalizedQueryScanTokens {
        startPos
        endPos
        token
        keywordKind
        schemaTableId
      }
      fingerprint
      recentQuerySamplesCount
      recentQueryExplainsCount
      recentQueryTagsetCount
      recentLogLinesCount
      postgresRole {
        id
        name
      }
      supportQueryTuning
    }
    getServerDetails(databaseId: $databaseId) {
      id
      humanId
      organization {
        id
        slug
        indexAdvisorTrialEndsAt
      }
      blockSize
    }
    trackIoTimingSetting: getPostgresSettingDetails(
      databaseId: $databaseId
      postgresSettingName: "track_io_timing"
    ) {
      id
      value: resetValuePrettyNoDelimiter
    }
    getQueryTagSummaryForQuery(databaseId: $databaseId, queryId: $queryId) {
      id
      key
      values {
        value
        count
      }
    }
    getDatabaseDetails(databaseId: $databaseId) {
      id
      permittedToTuneQueries
    }
  }
`;

/**
 * __useQueryDetailsQuery__
 *
 * To run a query within a React component, call `useQueryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryDetailsQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *      queryId: // value for 'queryId'
 *   },
 * });
 */
export function useQueryDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryDetailsQuery,
    QueryDetailsQueryVariables
  > &
    (
      | { variables: QueryDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryDetailsQuery, QueryDetailsQueryVariables>(
    QueryDetailsDocument,
    options,
  );
}
export function useQueryDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryDetailsQuery,
    QueryDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryDetailsQuery, QueryDetailsQueryVariables>(
    QueryDetailsDocument,
    options,
  );
}
export function useQueryDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        QueryDetailsQuery,
        QueryDetailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryDetailsQuery, QueryDetailsQueryVariables>(
    QueryDetailsDocument,
    options,
  );
}
export type QueryDetailsQueryHookResult = ReturnType<
  typeof useQueryDetailsQuery
>;
export type QueryDetailsLazyQueryHookResult = ReturnType<
  typeof useQueryDetailsLazyQuery
>;
export type QueryDetailsSuspenseQueryHookResult = ReturnType<
  typeof useQueryDetailsSuspenseQuery
>;
export type QueryDetailsQueryResult = Apollo.QueryResult<
  QueryDetailsQuery,
  QueryDetailsQueryVariables
>;
