// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type PostgresSettingsForDatabaseQueryVariables = Types.Exact<{
  databaseId: Types.Scalars["ID"]["input"];
  patterns:
    | Array<Types.Scalars["String"]["input"]>
    | Types.Scalars["String"]["input"];
}>;

export type PostgresSettingsForDatabaseQuery = {
  __typename?: "GraphqlQuery";
  getPostgresSettings: Array<{
    __typename?: "PostgresSetting";
    id: string;
    name: string;
    resetValue?: string | null;
    docSnippetHtml?: string | null;
    unit?: string | null;
    shortDesc?: string | null;
    vartype?: string | null;
    minVal?: number | null;
    maxVal?: number | null;
    enumvals?: Array<string> | null;
  }>;
};

export const PostgresSettingsForDatabaseDocument = gql`
  query PostgresSettingsForDatabase($databaseId: ID!, $patterns: [String!]!) {
    getPostgresSettings(databaseId: $databaseId, patterns: $patterns) {
      id
      name
      resetValue
      docSnippetHtml
      unit
      shortDesc
      vartype
      minVal
      maxVal
      enumvals
    }
  }
`;

/**
 * __usePostgresSettingsForDatabaseQuery__
 *
 * To run a query within a React component, call `usePostgresSettingsForDatabaseQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostgresSettingsForDatabaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostgresSettingsForDatabaseQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *      patterns: // value for 'patterns'
 *   },
 * });
 */
export function usePostgresSettingsForDatabaseQuery(
  baseOptions: Apollo.QueryHookOptions<
    PostgresSettingsForDatabaseQuery,
    PostgresSettingsForDatabaseQueryVariables
  > &
    (
      | { variables: PostgresSettingsForDatabaseQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PostgresSettingsForDatabaseQuery,
    PostgresSettingsForDatabaseQueryVariables
  >(PostgresSettingsForDatabaseDocument, options);
}
export function usePostgresSettingsForDatabaseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PostgresSettingsForDatabaseQuery,
    PostgresSettingsForDatabaseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PostgresSettingsForDatabaseQuery,
    PostgresSettingsForDatabaseQueryVariables
  >(PostgresSettingsForDatabaseDocument, options);
}
export function usePostgresSettingsForDatabaseSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PostgresSettingsForDatabaseQuery,
        PostgresSettingsForDatabaseQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PostgresSettingsForDatabaseQuery,
    PostgresSettingsForDatabaseQueryVariables
  >(PostgresSettingsForDatabaseDocument, options);
}
export type PostgresSettingsForDatabaseQueryHookResult = ReturnType<
  typeof usePostgresSettingsForDatabaseQuery
>;
export type PostgresSettingsForDatabaseLazyQueryHookResult = ReturnType<
  typeof usePostgresSettingsForDatabaseLazyQuery
>;
export type PostgresSettingsForDatabaseSuspenseQueryHookResult = ReturnType<
  typeof usePostgresSettingsForDatabaseSuspenseQuery
>;
export type PostgresSettingsForDatabaseQueryResult = Apollo.QueryResult<
  PostgresSettingsForDatabaseQuery,
  PostgresSettingsForDatabaseQueryVariables
>;
