// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpdateExplainQueryMutationVariables = Types.Exact<{
  explainQueryId: Types.Scalars["ID"]["input"];
  name?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  description?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type UpdateExplainQueryMutation = {
  __typename?: "Mutation";
  updateExplainQuery: {
    __typename?: "UpdateExplainQueryPayload";
    explainQueryId?: string | null;
  };
};

export const UpdateExplainQueryDocument = gql`
  mutation UpdateExplainQuery(
    $explainQueryId: ID!
    $name: String
    $description: String
  ) {
    updateExplainQuery(
      explainQueryId: $explainQueryId
      name: $name
      description: $description
    ) {
      explainQueryId
    }
  }
`;
export type UpdateExplainQueryMutationFn = Apollo.MutationFunction<
  UpdateExplainQueryMutation,
  UpdateExplainQueryMutationVariables
>;

/**
 * __useUpdateExplainQueryMutation__
 *
 * To run a mutation, you first call `useUpdateExplainQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExplainQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExplainQueryMutation, { data, loading, error }] = useUpdateExplainQueryMutation({
 *   variables: {
 *      explainQueryId: // value for 'explainQueryId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateExplainQueryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateExplainQueryMutation,
    UpdateExplainQueryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateExplainQueryMutation,
    UpdateExplainQueryMutationVariables
  >(UpdateExplainQueryDocument, options);
}
export type UpdateExplainQueryMutationHookResult = ReturnType<
  typeof useUpdateExplainQueryMutation
>;
export type UpdateExplainQueryMutationResult =
  Apollo.MutationResult<UpdateExplainQueryMutation>;
export type UpdateExplainQueryMutationOptions = Apollo.BaseMutationOptions<
  UpdateExplainQueryMutation,
  UpdateExplainQueryMutationVariables
>;
