// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import { ExplainQueryFragmentFragmentDoc } from "../../ExplainWorkbook/gql/Fragment.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CreateExplainCollectorRunMutationVariables = Types.Exact<{
  explainQueryId: Types.Scalars["ID"]["input"];
}>;

export type CreateExplainCollectorRunMutation = {
  __typename?: "Mutation";
  createExplainCollectorRun: {
    __typename?: "ExplainQuery";
    id: string;
    createdAt: number;
    updatedAt: number;
    description?: string | null;
    queryFingerprint: string;
    queryText: string;
    name: string;
    queryTextWithAlias: string;
    paramRefAliasMap: any;
    draft: boolean;
    plannerSettings: any;
    explainResults: Array<{
      __typename?: "ExplainResult";
      id: string;
      format: string;
      resultSource: string;
      createdAt: number;
      updatedAt: number;
      status?: string | null;
      errorMessage?: string | null;
      totalCost?: number | null;
      totalSharedBlksHit?: number | null;
      totalSharedBlksRead?: number | null;
      totalSharedBlksWritten?: number | null;
      totalSharedBlksDirtied?: number | null;
      totalBlkReadTime?: number | null;
      totalBlkWriteTime?: number | null;
      planNodeTypes?: Array<string | null> | null;
      runtimeMs?: number | null;
      planFingerprint?: string | null;
      parameterSetId?: string | null;
      user?: {
        __typename?: "User";
        id: string;
        fullname?: string | null;
      } | null;
    }>;
    user?: { __typename?: "User"; id: string; fullname?: string | null } | null;
    queryTextWithParameters: Array<{
      __typename?: "QueryTextWithParameters";
      id: string;
      parameterSetId: string;
      queryWithParameters: string;
    }>;
  };
};

export const CreateExplainCollectorRunDocument = gql`
  mutation CreateExplainCollectorRun($explainQueryId: ID!) {
    createExplainCollectorRun(explainQueryId: $explainQueryId) {
      ...ExplainQueryFragment
    }
  }
  ${ExplainQueryFragmentFragmentDoc}
`;
export type CreateExplainCollectorRunMutationFn = Apollo.MutationFunction<
  CreateExplainCollectorRunMutation,
  CreateExplainCollectorRunMutationVariables
>;

/**
 * __useCreateExplainCollectorRunMutation__
 *
 * To run a mutation, you first call `useCreateExplainCollectorRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExplainCollectorRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExplainCollectorRunMutation, { data, loading, error }] = useCreateExplainCollectorRunMutation({
 *   variables: {
 *      explainQueryId: // value for 'explainQueryId'
 *   },
 * });
 */
export function useCreateExplainCollectorRunMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExplainCollectorRunMutation,
    CreateExplainCollectorRunMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExplainCollectorRunMutation,
    CreateExplainCollectorRunMutationVariables
  >(CreateExplainCollectorRunDocument, options);
}
export type CreateExplainCollectorRunMutationHookResult = ReturnType<
  typeof useCreateExplainCollectorRunMutation
>;
export type CreateExplainCollectorRunMutationResult =
  Apollo.MutationResult<CreateExplainCollectorRunMutation>;
export type CreateExplainCollectorRunMutationOptions =
  Apollo.BaseMutationOptions<
    CreateExplainCollectorRunMutation,
    CreateExplainCollectorRunMutationVariables
  >;
