// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import { ExplainQueryFragmentFragmentDoc } from "./Fragment.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ExplainWorkbookDetailsQueryVariables = Types.Exact<{
  workbookId: Types.Scalars["ID"]["input"];
  databaseId: Types.Scalars["ID"]["input"];
}>;

export type ExplainWorkbookDetailsQuery = {
  __typename?: "GraphqlQuery";
  getExplainWorkbookDetails: {
    __typename?: "ExplainWorkbook";
    id: string;
    name: string;
    description?: string | null;
    createdAt: number;
    lastActivityAt: number;
    databaseId: string;
    parameterSetsSelected: boolean;
    parameterRefAliases: Array<string>;
    parameterSetTypes: Array<string | null>;
    hasExplainAnalyzeHelper: boolean;
    server: {
      __typename?: "Server";
      id: string;
      blockSize: number;
      collectorInfo?: {
        __typename?: "CollectorInfo";
        supportsQueryTuning: boolean;
      } | null;
    };
    user?: { __typename?: "User"; id: string; fullname?: string | null } | null;
    parameterSets: Array<{
      __typename?: "ParameterSet";
      id: string;
      name: string;
      paramValues: any;
    }>;
    aliasParamMapList: Array<{
      __typename?: "AliasParamMap";
      id: string;
      name: string;
      source?: string | null;
      parameters: any;
    }>;
    baselineQuery: {
      __typename?: "ExplainQuery";
      id: string;
      createdAt: number;
      updatedAt: number;
      description?: string | null;
      queryFingerprint: string;
      queryText: string;
      name: string;
      queryTextWithAlias: string;
      paramRefAliasMap: any;
      draft: boolean;
      plannerSettings: any;
      query?: {
        __typename?: "Query";
        id: string;
        fingerprint: string;
        postgresRole?: {
          __typename?: "PostgresRole";
          id: string;
          name: string;
        } | null;
      } | null;
      explainResults: Array<{
        __typename?: "ExplainResult";
        id: string;
        format: string;
        resultSource: string;
        createdAt: number;
        updatedAt: number;
        status?: string | null;
        errorMessage?: string | null;
        totalCost?: number | null;
        totalSharedBlksHit?: number | null;
        totalSharedBlksRead?: number | null;
        totalSharedBlksWritten?: number | null;
        totalSharedBlksDirtied?: number | null;
        totalBlkReadTime?: number | null;
        totalBlkWriteTime?: number | null;
        planNodeTypes?: Array<string | null> | null;
        runtimeMs?: number | null;
        planFingerprint?: string | null;
        parameterSetId?: string | null;
        user?: {
          __typename?: "User";
          id: string;
          fullname?: string | null;
        } | null;
      }>;
      user?: {
        __typename?: "User";
        id: string;
        fullname?: string | null;
      } | null;
      queryTextWithParameters: Array<{
        __typename?: "QueryTextWithParameters";
        id: string;
        parameterSetId: string;
        queryWithParameters: string;
      }>;
    };
    explainQueries: Array<{
      __typename?: "ExplainQuery";
      id: string;
      createdAt: number;
      updatedAt: number;
      description?: string | null;
      queryFingerprint: string;
      queryText: string;
      name: string;
      queryTextWithAlias: string;
      paramRefAliasMap: any;
      draft: boolean;
      plannerSettings: any;
      query?: {
        __typename?: "Query";
        id: string;
        fingerprint: string;
        postgresRole?: {
          __typename?: "PostgresRole";
          id: string;
          name: string;
        } | null;
      } | null;
      explainResults: Array<{
        __typename?: "ExplainResult";
        id: string;
        format: string;
        resultSource: string;
        createdAt: number;
        updatedAt: number;
        status?: string | null;
        errorMessage?: string | null;
        totalCost?: number | null;
        totalSharedBlksHit?: number | null;
        totalSharedBlksRead?: number | null;
        totalSharedBlksWritten?: number | null;
        totalSharedBlksDirtied?: number | null;
        totalBlkReadTime?: number | null;
        totalBlkWriteTime?: number | null;
        planNodeTypes?: Array<string | null> | null;
        runtimeMs?: number | null;
        planFingerprint?: string | null;
        parameterSetId?: string | null;
        user?: {
          __typename?: "User";
          id: string;
          fullname?: string | null;
        } | null;
      }>;
      user?: {
        __typename?: "User";
        id: string;
        fullname?: string | null;
      } | null;
      queryTextWithParameters: Array<{
        __typename?: "QueryTextWithParameters";
        id: string;
        parameterSetId: string;
        queryWithParameters: string;
      }>;
    }>;
  };
  getDatabaseDetails: {
    __typename?: "Database";
    id: string;
    permittedToTuneQueries: boolean;
  };
};

export const ExplainWorkbookDetailsDocument = gql`
  query ExplainWorkbookDetails($workbookId: ID!, $databaseId: ID!) {
    getExplainWorkbookDetails(workbookId: $workbookId) {
      id
      name
      description
      createdAt
      lastActivityAt
      databaseId
      server {
        id
        blockSize
        collectorInfo {
          supportsQueryTuning
        }
      }
      user {
        id
        fullname
      }
      parameterSetsSelected
      parameterSets {
        id
        name
        paramValues
      }
      parameterRefAliases
      parameterSetTypes
      aliasParamMapList {
        id
        name
        source
        parameters
      }
      baselineQuery {
        ...ExplainQueryFragment
        query {
          id
          postgresRole {
            id
            name
          }
          fingerprint
        }
      }
      explainQueries {
        ...ExplainQueryFragment
        query {
          id
          postgresRole {
            id
            name
          }
          fingerprint
        }
      }
      hasExplainAnalyzeHelper
    }
    getDatabaseDetails(databaseId: $databaseId) {
      id
      permittedToTuneQueries
    }
  }
  ${ExplainQueryFragmentFragmentDoc}
`;

/**
 * __useExplainWorkbookDetailsQuery__
 *
 * To run a query within a React component, call `useExplainWorkbookDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplainWorkbookDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplainWorkbookDetailsQuery({
 *   variables: {
 *      workbookId: // value for 'workbookId'
 *      databaseId: // value for 'databaseId'
 *   },
 * });
 */
export function useExplainWorkbookDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplainWorkbookDetailsQuery,
    ExplainWorkbookDetailsQueryVariables
  > &
    (
      | { variables: ExplainWorkbookDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplainWorkbookDetailsQuery,
    ExplainWorkbookDetailsQueryVariables
  >(ExplainWorkbookDetailsDocument, options);
}
export function useExplainWorkbookDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplainWorkbookDetailsQuery,
    ExplainWorkbookDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplainWorkbookDetailsQuery,
    ExplainWorkbookDetailsQueryVariables
  >(ExplainWorkbookDetailsDocument, options);
}
export function useExplainWorkbookDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExplainWorkbookDetailsQuery,
        ExplainWorkbookDetailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplainWorkbookDetailsQuery,
    ExplainWorkbookDetailsQueryVariables
  >(ExplainWorkbookDetailsDocument, options);
}
export type ExplainWorkbookDetailsQueryHookResult = ReturnType<
  typeof useExplainWorkbookDetailsQuery
>;
export type ExplainWorkbookDetailsLazyQueryHookResult = ReturnType<
  typeof useExplainWorkbookDetailsLazyQuery
>;
export type ExplainWorkbookDetailsSuspenseQueryHookResult = ReturnType<
  typeof useExplainWorkbookDetailsSuspenseQuery
>;
export type ExplainWorkbookDetailsQueryResult = Apollo.QueryResult<
  ExplainWorkbookDetailsQuery,
  ExplainWorkbookDetailsQueryVariables
>;
