---
title: 'Step 2: Create Monitoring User'
install_track_title: Installation Guide (Amazon RDS and Amazon Aurora)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

import MonitoringUserSetupInstructions from "../../components/MonitoringUserSetupInstructions";

We recommend creating a separate monitoring user on your PostgreSQL database for pganalyze.

As an RDS superuser, connect to the database you will be monitoring and run the following (we've
generated a random password for you, but you can replace it with one of your choosing):

<MonitoringUserSetupInstructions />

Note that it is important you run these as RDS superuser in order to pass down the full access to statistics tables.

Write down the username and password of the monitoring user, we will use it in the last step of this guide.

---

Next we continue by installing the pganalyze collector software to your environment:

<Link className="btn btn-success" to="03_install_collector">
  Proceed to Step 3: Install the Collector
</Link>
