import React from "react";
import { Link } from "react-router-dom";

import {
  ServerSettings_hiddenDatabases as HiddenDatabaseType,
  ServerSettings_getServerDetails as FullServerType,
} from "../types/ServerSettings";

import { HiddenDatabases } from "components/DocsSnippet";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTable from "components/PanelTable";
import SnapshotStatus from "components/SnapshotStatus";
import { useRoutes } from "utils/routes";
import Tip from "components/Tip";
import { formatTimestampShort } from "utils/format";
import moment from "moment";

type Props = {
  server: FullServerType;
  hiddenDatabases: HiddenDatabaseType[];
};

const DebugInfo: React.FunctionComponent<Props> = ({
  server,
  hiddenDatabases,
}) => {
  const {
    humanId,
    lastReceivedSnapshotAt,
    lastTestSnapshotAt,
    lastSnapshotSubmitter,
    duplicateSnapshotReceivedAt,
    displaySystemType,
    systemScope,
    systemId,
    postgresVersion,
    latestStats,
  } = server;
  const { serverSnapshots } = useRoutes();

  return (
    <Panel title="Debug Info">
      <PanelTable horizontal={true} borders={true}>
        <tbody>
          <tr>
            <th>Last Snapshot</th>
            <td>
              <SnapshotStatus
                lastReceivedSnapshotAt={lastReceivedSnapshotAt}
                lastTestSnapshotAt={lastTestSnapshotAt}
                duplicateSnapshotReceivedAt={duplicateSnapshotReceivedAt}
              />
            </td>
            <th>Collector Version</th>
            <td>{lastSnapshotSubmitter}</td>
          </tr>
          <tr>
            <th>System Type</th>
            <td>{displaySystemType}</td>
            <th>System ID</th>
            <td>
              {(systemId && systemScope && `${systemId} (${systemScope})`) ||
                systemId ||
                "Unknown"}
            </td>
          </tr>
          <tr>
            <th>Postgres Version</th>
            <td colSpan={3}>{postgresVersion}</td>
          </tr>
          <tr>
            <th>
              Hidden Databases <HiddenDatabases />
            </th>
            <td colSpan={3}>
              {hiddenDatabases
                .map(
                  (d: HiddenDatabaseType): string =>
                    d.datname || "Database #" + d.id,
                )
                .join(", ")}
            </td>
          </tr>
          {latestStats &&
            (latestStats.pgStatStatementsReset ||
              latestStats.pgStatStatementsDealloc) && (
              <tr>
                <th>
                  pg_stat_statements reset{" "}
                  <Tip content="Time at which all statistics in the pg_stat_statements view were last reset." />
                </th>
                <td>
                  {formatTimestampShort(
                    moment.unix(latestStats?.pgStatStatementsReset),
                  )}
                </td>
                <th>
                  pg_stat_statements dealloc{" "}
                  <Tip content="The number of times pg_stat_statements entries were deallocated last 10 minutes because more distinct statements than pg_stat_statements.max were observed." />
                </th>
                <td>{latestStats.pgStatStatementsDealloc ?? 0}</td>
              </tr>
            )}
        </tbody>
      </PanelTable>
      <PanelSection>
        <Link to={serverSnapshots(humanId)}>
          Show Last 10 Statistics Snapshots
        </Link>
      </PanelSection>
    </Panel>
  );
};

export default DebugInfo;
