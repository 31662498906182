import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./style.module.scss";

type LinkProps = {
  to: string;
  children: React.ReactNode;
  end?: boolean;
};

export const PageNavLink = ({ to, end, children }: LinkProps) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => (isActive ? styles.active : undefined)}
      end={end === undefined || end}
    >
      {children}
    </NavLink>
  );
};

type Props = {
  children: React.ReactNode;
};

const PageSecondaryNavigation = ({ children }: Props) => {
  return <div className={styles.container}>{children}</div>;
};

export default PageSecondaryNavigation;
