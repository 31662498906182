// @ts-nocheck
import * as Types from "../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type RootRedirectQueryVariables = Types.Exact<{ [key: string]: never }>;

export type RootRedirectQuery = {
  __typename?: "GraphqlQuery";
  getCurrentUserDetails: {
    __typename?: "User";
    id: string;
    organizations: Array<{
      __typename?: "Organization";
      id: string;
      slug: string;
    }>;
  };
};

export const RootRedirectDocument = gql`
  query RootRedirect {
    getCurrentUserDetails {
      id
      organizations {
        id
        slug
      }
    }
  }
`;

/**
 * __useRootRedirectQuery__
 *
 * To run a query within a React component, call `useRootRedirectQuery` and pass it any options that fit your needs.
 * When your component renders, `useRootRedirectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRootRedirectQuery({
 *   variables: {
 *   },
 * });
 */
export function useRootRedirectQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RootRedirectQuery,
    RootRedirectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RootRedirectQuery, RootRedirectQueryVariables>(
    RootRedirectDocument,
    options,
  );
}
export function useRootRedirectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RootRedirectQuery,
    RootRedirectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RootRedirectQuery, RootRedirectQueryVariables>(
    RootRedirectDocument,
    options,
  );
}
export function useRootRedirectSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RootRedirectQuery,
        RootRedirectQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RootRedirectQuery, RootRedirectQueryVariables>(
    RootRedirectDocument,
    options,
  );
}
export type RootRedirectQueryHookResult = ReturnType<
  typeof useRootRedirectQuery
>;
export type RootRedirectLazyQueryHookResult = ReturnType<
  typeof useRootRedirectLazyQuery
>;
export type RootRedirectSuspenseQueryHookResult = ReturnType<
  typeof useRootRedirectSuspenseQuery
>;
export type RootRedirectQueryResult = Apollo.QueryResult<
  RootRedirectQuery,
  RootRedirectQueryVariables
>;
