// @ts-nocheck
import * as Types from "../../../types/types";

import { gql } from "@apollo/client";
import { BillingPlanInfoFragmentFragmentDoc } from "../PlanInfo/gql/Fragment.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type BillingQueryVariables = Types.Exact<{
  organizationSlug: Types.Scalars["ID"]["input"];
}>;

export type BillingQuery = {
  __typename?: "GraphqlQuery";
  getOrganizationDetails?: {
    __typename?: "Organization";
    id: string;
    permittedToBilling: boolean;
    serverLimit?: number | null;
    serverCountForBilling: number;
    planInfo: {
      __typename?: "OrganizationPlanInfo";
      id: string;
      activeSubscription: boolean;
      canceledSubscription: boolean;
      subscriptionPlanId: string;
      subscriptionPlanName: string;
      supportsExtraServers: boolean;
      trialEndsAt?: number | null;
      isTrial: boolean;
      expiredTrial: boolean;
      supportsLitePlan: boolean;
      subscriptionCancelationPending: boolean;
      subscriptionCancelationEffectiveAt?: number | null;
    };
  } | null;
};

export const BillingDocument = gql`
  query Billing($organizationSlug: ID!) {
    getOrganizationDetails(organizationSlug: $organizationSlug) {
      id
      permittedToBilling
      serverLimit
      serverCountForBilling
      ...BillingPlanInfoFragment
    }
  }
  ${BillingPlanInfoFragmentFragmentDoc}
`;

/**
 * __useBillingQuery__
 *
 * To run a query within a React component, call `useBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingQuery({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useBillingQuery(
  baseOptions: Apollo.QueryHookOptions<BillingQuery, BillingQueryVariables> &
    ({ variables: BillingQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingQuery, BillingQueryVariables>(
    BillingDocument,
    options,
  );
}
export function useBillingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BillingQuery,
    BillingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BillingQuery, BillingQueryVariables>(
    BillingDocument,
    options,
  );
}
export function useBillingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BillingQuery, BillingQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BillingQuery, BillingQueryVariables>(
    BillingDocument,
    options,
  );
}
export type BillingQueryHookResult = ReturnType<typeof useBillingQuery>;
export type BillingLazyQueryHookResult = ReturnType<typeof useBillingLazyQuery>;
export type BillingSuspenseQueryHookResult = ReturnType<
  typeof useBillingSuspenseQuery
>;
export type BillingQueryResult = Apollo.QueryResult<
  BillingQuery,
  BillingQueryVariables
>;
