import React from "react";

import { useQuery } from "@apollo/client";

import Loading from "components/Loading";
import Panel from "components/Panel";

import { useRoutes } from "utils/routes";
import { Link, useParams } from "react-router-dom";
import Grid from "components/Grid";
import {
  ExplainWorkbooksForServer as ExplainWorkbooksType,
  ExplainWorkbooksForServerVariables,
} from "./types/ExplainWorkbooksForServer";

import QUERY from "./Query.graphql";
import QueryTuningCallout from "components/QueryTuningCallout";

const ServerExplainWorkbookList: React.FunctionComponent = () => {
  const { databaseWorkbooks } = useRoutes();
  const { serverId } = useParams();
  const { loading, error, data } = useQuery<
    ExplainWorkbooksType,
    ExplainWorkbooksForServerVariables
  >(QUERY, {
    variables: { serverId },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const workbookCountByDatabase = data.getExplainWorkbooks.reduce(
    (acc, workbook) => {
      const databaseId = workbook.database.id;
      acc[databaseId] = (acc[databaseId] || 0) + 1;
      return acc;
    },
    {} as Record<string, number>,
  );

  const databases = data.getDatabases
    .filter((database) => !database.hidden)
    .map((database) => {
      return {
        id: database.id,
        datname: database.datname,
        workbookCount: workbookCountByDatabase[database.id],
      };
    });

  return (
    <>
      <QueryTuningCallout />
      <Panel title="Databases">
        <Grid
          className="grid-cols-[1fr_150px]"
          data={databases}
          striped
          columns={[
            {
              field: "datname",
              header: "Name",
              renderer: function DatabaseCell({ rowData, fieldData }) {
                return (
                  <Link to={databaseWorkbooks(rowData.id)}>{fieldData}</Link>
                );
              },
            },
            {
              field: "workbookCount",
              header: "Workbooks",
              style: "number",
              nullValue: 0,
            },
          ]}
        />
      </Panel>
    </>
  );
};

export default ServerExplainWorkbookList;
