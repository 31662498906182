import * as React from "react";

import { createAvatar } from "@dicebear/avatars";
import * as style from "@dicebear/avatars-identicon-sprites";

import classNames from "classnames";

type Props = {
  identity: string;
  className?: string;
};

const Identicon: React.FunctionComponent<Props> = ({ identity, className }) => {
  const svg = createAvatar(style, {
    seed: identity,
    background: "#fff",
    colorLevel: 500,
    colors: [
      "blue",
      "blueGrey",
      "brown",
      "cyan",
      "deepPurple",
      "grey",
      "indigo",
      "lightBlue",
      "lightGreen",
    ],
  });
  return (
    <div
      className={classNames("w-3 h-3", className)}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  );
};

export default Identicon;
