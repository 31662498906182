// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import { BillingOverviewFragmentFragmentDoc } from "../../Overview/gql/Fragment.generated";
import { BillingPlanInfoFragmentFragmentDoc } from "../../PlanInfo/gql/Fragment.generated";
import { WithSubscriptionCheckFragmentDoc } from "../../../WithSubscriptionCheck/gql/Fragment.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ActivatePlanMutationMutationVariables = Types.Exact<{
  organizationSlug: Types.Scalars["ID"]["input"];
  newPlanId: Types.Scalars["String"]["input"];
  paymentMethodId?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  billingCompanyName?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  billingAddressLine1?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  billingAddressLine2?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  billingAddressZipCode?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  billingAddressCity?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  billingAddressState?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  billingAddressCountry?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  billingVatId?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  billingEmail?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  newTotalServerCount: Types.Scalars["Int"]["input"];
}>;

export type ActivatePlanMutationMutation = {
  __typename?: "Mutation";
  activateBillingPlan: {
    __typename?: "ActivateBillingPlanPayload";
    organizationBilling: {
      __typename?: "OrganizationBilling";
      id: string;
      subscriptionActiveInStripe: boolean;
      subscriptionNextChargeAt?: number | null;
      subscriptionNextChargeAmount?: number | null;
      subscriptionNextChargeCurrency?: string | null;
      subscriptionCardType?: string | null;
      subscriptionCardLast4?: string | null;
      subscriptionCardExpMonth?: number | null;
      subscriptionCardExpYear?: number | null;
      subscriptionBilledManually: boolean;
      subscriptionInterval?: Types.SubscriptionInterval | null;
      billingAddressWithName?: string | null;
      billingVatId?: string | null;
      billingEmail?: string | null;
      paymentRequiresAction: boolean;
      stripeClientSecret?: string | null;
      invoices: Array<{
        __typename?: "Invoice";
        id: string;
        reference: string;
        formattedTotal: string;
        invoicePdf?: string | null;
        status: string;
        created: number;
      }>;
      planChoices: Array<{
        __typename?: "OrganizationPlanChoice";
        id: string;
        name: string;
        marketingLines: Array<string>;
        notIncludedMarketingLines: Array<string>;
        formattedMonthlyPrice: string;
        formattedServerOveragePrice?: string | null;
        isCurrent: boolean;
        isDowngrade: boolean;
      }>;
    };
    organization: {
      __typename?: "Organization";
      serverLimit?: number | null;
      serverCountForBilling: number;
      subscriptionExtraServers: number;
      id: string;
      permittedToBilling: boolean;
      planInfo: {
        __typename?: "OrganizationPlanInfo";
        id: string;
        activeSubscription: boolean;
        canceledSubscription: boolean;
        subscriptionPlanId: string;
        subscriptionPlanName: string;
        supportsExtraServers: boolean;
        trialEndsAt?: number | null;
        isTrial: boolean;
        expiredTrial: boolean;
        supportsLitePlan: boolean;
        subscriptionCancelationPending: boolean;
        subscriptionCancelationEffectiveAt?: number | null;
      };
    };
  };
};

export const ActivatePlanMutationDocument = gql`
  mutation ActivatePlanMutation(
    $organizationSlug: ID!
    $newPlanId: String!
    $paymentMethodId: String
    $billingCompanyName: String
    $billingAddressLine1: String
    $billingAddressLine2: String
    $billingAddressZipCode: String
    $billingAddressCity: String
    $billingAddressState: String
    $billingAddressCountry: String
    $billingVatId: String
    $billingEmail: String
    $newTotalServerCount: Int!
  ) {
    activateBillingPlan(
      organizationSlug: $organizationSlug
      newPlanId: $newPlanId
      newTotalServerCount: $newTotalServerCount
      paymentMethodId: $paymentMethodId
      billingCompanyName: $billingCompanyName
      billingAddressLine1: $billingAddressLine1
      billingAddressLine2: $billingAddressLine2
      billingAddressZipCode: $billingAddressZipCode
      billingAddressCity: $billingAddressCity
      billingAddressState: $billingAddressState
      billingAddressCountry: $billingAddressCountry
      billingVatId: $billingVatId
      billingEmail: $billingEmail
    ) {
      organizationBilling {
        ...BillingOverviewFragment
      }
      organization {
        ...BillingPlanInfoFragment
        ...WithSubscriptionCheck
        serverLimit
        serverCountForBilling
        subscriptionExtraServers
      }
    }
  }
  ${BillingOverviewFragmentFragmentDoc}
  ${BillingPlanInfoFragmentFragmentDoc}
  ${WithSubscriptionCheckFragmentDoc}
`;
export type ActivatePlanMutationMutationFn = Apollo.MutationFunction<
  ActivatePlanMutationMutation,
  ActivatePlanMutationMutationVariables
>;

/**
 * __useActivatePlanMutationMutation__
 *
 * To run a mutation, you first call `useActivatePlanMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivatePlanMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activatePlanMutationMutation, { data, loading, error }] = useActivatePlanMutationMutation({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *      newPlanId: // value for 'newPlanId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *      billingCompanyName: // value for 'billingCompanyName'
 *      billingAddressLine1: // value for 'billingAddressLine1'
 *      billingAddressLine2: // value for 'billingAddressLine2'
 *      billingAddressZipCode: // value for 'billingAddressZipCode'
 *      billingAddressCity: // value for 'billingAddressCity'
 *      billingAddressState: // value for 'billingAddressState'
 *      billingAddressCountry: // value for 'billingAddressCountry'
 *      billingVatId: // value for 'billingVatId'
 *      billingEmail: // value for 'billingEmail'
 *      newTotalServerCount: // value for 'newTotalServerCount'
 *   },
 * });
 */
export function useActivatePlanMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivatePlanMutationMutation,
    ActivatePlanMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivatePlanMutationMutation,
    ActivatePlanMutationMutationVariables
  >(ActivatePlanMutationDocument, options);
}
export type ActivatePlanMutationMutationHookResult = ReturnType<
  typeof useActivatePlanMutationMutation
>;
export type ActivatePlanMutationMutationResult =
  Apollo.MutationResult<ActivatePlanMutationMutation>;
export type ActivatePlanMutationMutationOptions = Apollo.BaseMutationOptions<
  ActivatePlanMutationMutation,
  ActivatePlanMutationMutationVariables
>;
