// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ExplainResultQueryVariables = Types.Exact<{
  databaseId: Types.Scalars["ID"]["input"];
  workbookId: Types.Scalars["ID"]["input"];
  explainQueryId: Types.Scalars["ID"]["input"];
  resultId: Types.Scalars["ID"]["input"];
}>;

export type ExplainResultQuery = {
  __typename?: "GraphqlQuery";
  getExplainQueryResult: {
    __typename?: "ExplainResult";
    id: string;
    format: string;
    resultSource: string;
    createdAt: number;
    outputJson?: any | null;
    outputText?: string | null;
    annotatedJson?: any | null;
    totalCost?: number | null;
    totalSharedBlksHit?: number | null;
    totalSharedBlksRead?: number | null;
    totalSharedBlksWritten?: number | null;
    totalSharedBlksDirtied?: number | null;
    totalBlkReadTime?: number | null;
    totalBlkWriteTime?: number | null;
    planNodeTypes?: Array<string | null> | null;
    runtimeMs?: number | null;
    planFingerprint?: string | null;
    parameterSetId?: string | null;
    queryText?: string | null;
    user?: { __typename?: "User"; id: string; fullname?: string | null } | null;
    usedParameterSet?: {
      __typename?: "AliasParamMap";
      id: string;
      name: string;
      parameters: any;
    } | null;
  };
  getExplainWorkbookDetails: {
    __typename?: "ExplainWorkbook";
    id: string;
    name: string;
    description?: string | null;
    createdAt: number;
    lastActivityAt: number;
    databaseId: string;
    user?: { __typename?: "User"; id: string; fullname?: string | null } | null;
    parameterSets: Array<{
      __typename?: "ParameterSet";
      id: string;
      name: string;
    }>;
    baselineQuery: {
      __typename?: "ExplainQuery";
      id: string;
      name: string;
      explainResults: Array<{
        __typename?: "ExplainResult";
        id: string;
        parameterSetId?: string | null;
      }>;
    };
    explainQueries: Array<{
      __typename?: "ExplainQuery";
      id: string;
      name: string;
      explainResults: Array<{
        __typename?: "ExplainResult";
        id: string;
        parameterSetId?: string | null;
      }>;
    }>;
  };
  getServerDetails: {
    __typename?: "Server";
    id: string;
    humanId: string;
    name: string;
    blockSize: number;
  };
};

export const ExplainResultDocument = gql`
  query ExplainResult(
    $databaseId: ID!
    $workbookId: ID!
    $explainQueryId: ID!
    $resultId: ID!
  ) {
    getExplainQueryResult(
      explainQueryId: $explainQueryId
      resultId: $resultId
    ) {
      id
      format
      resultSource
      createdAt
      outputJson
      outputText
      annotatedJson
      totalCost
      totalSharedBlksHit
      totalSharedBlksRead
      totalSharedBlksWritten
      totalSharedBlksDirtied
      totalBlkReadTime
      totalBlkWriteTime
      planNodeTypes
      runtimeMs
      planFingerprint
      parameterSetId
      queryText
      user {
        id
        fullname
      }
      usedParameterSet {
        id
        name
        parameters
      }
    }
    getExplainWorkbookDetails(workbookId: $workbookId) {
      id
      name
      description
      createdAt
      lastActivityAt
      databaseId
      user {
        id
        fullname
      }
      parameterSets {
        id
        name
      }
      baselineQuery {
        id
        name
        explainResults {
          id
          parameterSetId
        }
      }
      explainQueries {
        id
        name
        explainResults {
          id
          parameterSetId
        }
      }
    }
    getServerDetails(databaseId: $databaseId) {
      id
      humanId
      name
      blockSize
    }
  }
`;

/**
 * __useExplainResultQuery__
 *
 * To run a query within a React component, call `useExplainResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplainResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplainResultQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *      workbookId: // value for 'workbookId'
 *      explainQueryId: // value for 'explainQueryId'
 *      resultId: // value for 'resultId'
 *   },
 * });
 */
export function useExplainResultQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplainResultQuery,
    ExplainResultQueryVariables
  > &
    (
      | { variables: ExplainResultQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExplainResultQuery, ExplainResultQueryVariables>(
    ExplainResultDocument,
    options,
  );
}
export function useExplainResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplainResultQuery,
    ExplainResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExplainResultQuery, ExplainResultQueryVariables>(
    ExplainResultDocument,
    options,
  );
}
export function useExplainResultSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExplainResultQuery,
        ExplainResultQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplainResultQuery,
    ExplainResultQueryVariables
  >(ExplainResultDocument, options);
}
export type ExplainResultQueryHookResult = ReturnType<
  typeof useExplainResultQuery
>;
export type ExplainResultLazyQueryHookResult = ReturnType<
  typeof useExplainResultLazyQuery
>;
export type ExplainResultSuspenseQueryHookResult = ReturnType<
  typeof useExplainResultSuspenseQuery
>;
export type ExplainResultQueryResult = Apollo.QueryResult<
  ExplainResultQuery,
  ExplainResultQueryVariables
>;
