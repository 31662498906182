// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import { BillingOverviewFragmentFragmentDoc } from "../../Overview/gql/Fragment.generated";
import { BillingPlanInfoFragmentFragmentDoc } from "../../PlanInfo/gql/Fragment.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CancelPlanMutationMutationVariables = Types.Exact<{
  organizationSlug: Types.Scalars["ID"]["input"];
}>;

export type CancelPlanMutationMutation = {
  __typename?: "Mutation";
  cancelBillingPlan: {
    __typename?: "CancelBillingPlanPayload";
    organizationBilling: {
      __typename?: "OrganizationBilling";
      id: string;
      subscriptionActiveInStripe: boolean;
      subscriptionNextChargeAt?: number | null;
      subscriptionNextChargeAmount?: number | null;
      subscriptionNextChargeCurrency?: string | null;
      subscriptionCardType?: string | null;
      subscriptionCardLast4?: string | null;
      subscriptionCardExpMonth?: number | null;
      subscriptionCardExpYear?: number | null;
      subscriptionBilledManually: boolean;
      subscriptionInterval?: Types.SubscriptionInterval | null;
      billingAddressWithName?: string | null;
      billingVatId?: string | null;
      billingEmail?: string | null;
      paymentRequiresAction: boolean;
      stripeClientSecret?: string | null;
      invoices: Array<{
        __typename?: "Invoice";
        id: string;
        reference: string;
        formattedTotal: string;
        invoicePdf?: string | null;
        status: string;
        created: number;
      }>;
      planChoices: Array<{
        __typename?: "OrganizationPlanChoice";
        id: string;
        name: string;
        marketingLines: Array<string>;
        notIncludedMarketingLines: Array<string>;
        formattedMonthlyPrice: string;
        formattedServerOveragePrice?: string | null;
        isCurrent: boolean;
        isDowngrade: boolean;
      }>;
    };
    organization: {
      __typename?: "Organization";
      id: string;
      permittedToBilling: boolean;
      planInfo: {
        __typename?: "OrganizationPlanInfo";
        id: string;
        activeSubscription: boolean;
        canceledSubscription: boolean;
        subscriptionPlanId: string;
        subscriptionPlanName: string;
        supportsExtraServers: boolean;
        trialEndsAt?: number | null;
        isTrial: boolean;
        expiredTrial: boolean;
        supportsLitePlan: boolean;
        subscriptionCancelationPending: boolean;
        subscriptionCancelationEffectiveAt?: number | null;
      };
    };
  };
};

export const CancelPlanMutationDocument = gql`
  mutation CancelPlanMutation($organizationSlug: ID!) {
    cancelBillingPlan(organizationSlug: $organizationSlug) {
      organizationBilling {
        ...BillingOverviewFragment
      }
      organization {
        ...BillingPlanInfoFragment
      }
    }
  }
  ${BillingOverviewFragmentFragmentDoc}
  ${BillingPlanInfoFragmentFragmentDoc}
`;
export type CancelPlanMutationMutationFn = Apollo.MutationFunction<
  CancelPlanMutationMutation,
  CancelPlanMutationMutationVariables
>;

/**
 * __useCancelPlanMutationMutation__
 *
 * To run a mutation, you first call `useCancelPlanMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPlanMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPlanMutationMutation, { data, loading, error }] = useCancelPlanMutationMutation({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useCancelPlanMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelPlanMutationMutation,
    CancelPlanMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelPlanMutationMutation,
    CancelPlanMutationMutationVariables
  >(CancelPlanMutationDocument, options);
}
export type CancelPlanMutationMutationHookResult = ReturnType<
  typeof useCancelPlanMutationMutation
>;
export type CancelPlanMutationMutationResult =
  Apollo.MutationResult<CancelPlanMutationMutation>;
export type CancelPlanMutationMutationOptions = Apollo.BaseMutationOptions<
  CancelPlanMutationMutation,
  CancelPlanMutationMutationVariables
>;
