import React, { useContext } from "react";

import classNames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { Root, Content, Overlay, Title, Portal } from "@radix-ui/react-dialog";

import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import Button from "components/Button";

export type ModalLayout = "right sidebar" | "centered";

const ModalCloseContext = React.createContext(() => {});

export const ModalContent = ({
  children,
  className,
  layout,
  title,
}: {
  className?: string;
  children: React.ReactNode;
  layout?: ModalLayout;
  title: React.ReactNode;
}) => {
  const sharedClasses =
    "isolate z-[101] absolute top-0 right-0 left-0 bg-white p-0 m-0 shadow-md w-auto";
  const baseClasses =
    layout === "right sidebar"
      ? "h-screen overflow-auto md:ml-[300px]"
      : layout === "centered"
      ? "md:mx-[200px] my-[100px]"
      : undefined;

  const cn = classNames(sharedClasses, baseClasses, className);
  return (
    <Content className={cn}>
      <VisuallyHidden>
        <Title>{title}</Title>
      </VisuallyHidden>
      {children}
    </Content>
  );
};

export const ModalOverlay = ({
  children,
  onDismiss,
}: {
  onDismiss: () => void;
  children: React.ReactNode;
}) => {
  const cn =
    "fixed top-0 right-0 bottom-0 left-0 overflow-scroll isolate z-10 bg-[#000000AF]";

  return (
    <Root open onOpenChange={onDismiss}>
      <Portal>
        <Overlay className={cn} />
        <ModalCloseContext.Provider value={onDismiss}>
          {children}
        </ModalCloseContext.Provider>
      </Portal>
    </Root>
  );
};

export const ModalCloseButton = ({ className }: { className?: string }) => {
  const onDismiss = useContext(ModalCloseContext);
  return (
    <Button bare className={className} onClick={onDismiss}>
      <FontAwesomeIcon icon={faTimes} />
    </Button>
  );
};
