// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import { ExplainQueryFragmentFragmentDoc } from "../../ExplainWorkbook/gql/Fragment.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ExplainQueryQueryVariables = Types.Exact<{
  explainQueryId: Types.Scalars["ID"]["input"];
}>;

export type ExplainQueryQuery = {
  __typename?: "GraphqlQuery";
  getExplainQuery: {
    __typename?: "ExplainQuery";
    id: string;
    createdAt: number;
    updatedAt: number;
    description?: string | null;
    queryFingerprint: string;
    queryText: string;
    name: string;
    queryTextWithAlias: string;
    paramRefAliasMap: any;
    draft: boolean;
    plannerSettings: any;
    explainResults: Array<{
      __typename?: "ExplainResult";
      id: string;
      format: string;
      resultSource: string;
      createdAt: number;
      updatedAt: number;
      status?: string | null;
      errorMessage?: string | null;
      totalCost?: number | null;
      totalSharedBlksHit?: number | null;
      totalSharedBlksRead?: number | null;
      totalSharedBlksWritten?: number | null;
      totalSharedBlksDirtied?: number | null;
      totalBlkReadTime?: number | null;
      totalBlkWriteTime?: number | null;
      planNodeTypes?: Array<string | null> | null;
      runtimeMs?: number | null;
      planFingerprint?: string | null;
      parameterSetId?: string | null;
      user?: {
        __typename?: "User";
        id: string;
        fullname?: string | null;
      } | null;
    }>;
    user?: { __typename?: "User"; id: string; fullname?: string | null } | null;
    queryTextWithParameters: Array<{
      __typename?: "QueryTextWithParameters";
      id: string;
      parameterSetId: string;
      queryWithParameters: string;
    }>;
  };
};

export const ExplainQueryDocument = gql`
  query ExplainQuery($explainQueryId: ID!) {
    getExplainQuery(explainQueryId: $explainQueryId) {
      ...ExplainQueryFragment
    }
  }
  ${ExplainQueryFragmentFragmentDoc}
`;

/**
 * __useExplainQueryQuery__
 *
 * To run a query within a React component, call `useExplainQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplainQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplainQueryQuery({
 *   variables: {
 *      explainQueryId: // value for 'explainQueryId'
 *   },
 * });
 */
export function useExplainQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplainQueryQuery,
    ExplainQueryQueryVariables
  > &
    (
      | { variables: ExplainQueryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExplainQueryQuery, ExplainQueryQueryVariables>(
    ExplainQueryDocument,
    options,
  );
}
export function useExplainQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplainQueryQuery,
    ExplainQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExplainQueryQuery, ExplainQueryQueryVariables>(
    ExplainQueryDocument,
    options,
  );
}
export function useExplainQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExplainQueryQuery,
        ExplainQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExplainQueryQuery, ExplainQueryQueryVariables>(
    ExplainQueryDocument,
    options,
  );
}
export type ExplainQueryQueryHookResult = ReturnType<
  typeof useExplainQueryQuery
>;
export type ExplainQueryLazyQueryHookResult = ReturnType<
  typeof useExplainQueryLazyQuery
>;
export type ExplainQuerySuspenseQueryHookResult = ReturnType<
  typeof useExplainQuerySuspenseQuery
>;
export type ExplainQueryQueryResult = Apollo.QueryResult<
  ExplainQueryQuery,
  ExplainQueryQueryVariables
>;
