import React, { useState } from "react";
import { useParams } from "react-router-dom";

import ModalContainer from "components/ModalContainer";

import {
  AutoDetectOption,
  ValueTypeOptions,
  ValueTypeOptionType,
} from "../ExplainWorkbook/util";

import { ExplainWorkbookType } from "../ExplainWorkbook";
import { ExplainWorkbookDetailsDocument } from "../ExplainWorkbook/gql/Query.generated";
import { useUpdateExplainWorkbookMutation } from "../WorkbookNameEditPanel/gql/Mutation.update.generated";

import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Callout from "components/Callout";
import { validateParamName } from "../ReviewQuery";
import CreatableSelect from "components/CreatableSelect";

const EditParameterSettingsPanel = ({
  onDismiss,
  workbook,
  showExistingParameterWarning,
}: {
  onDismiss: () => void;
  workbook: ExplainWorkbookType;
  showExistingParameterWarning?: boolean;
}) => {
  const { databaseId } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessages, setErrorMessages] = useState(
    workbook.parameterRefAliases.map(() => ""),
  );
  const [paramTypes, setParamTypes] = useState(() =>
    workbook.parameterSetTypes.map((type) =>
      type
        ? ValueTypeOptions.find(({ value }) => value === type) || {
            value: type,
            label: type,
          }
        : AutoDetectOption,
    ),
  );
  const [paramNames, setParamNames] = useState(workbook.parameterRefAliases);

  const [updateExplainWorkbook] = useUpdateExplainWorkbookMutation();

  const handleUpdateWorkbook = () => {
    // validate names
    // boolean filter will remove empty error messages (aka no error)
    const errors = paramNames
      .map((_, idx) => validateParamName(paramNames, idx))
      .filter(Boolean)
      .join(" ");
    if (errors) {
      setErrorMessage(errors);
      return;
    }

    // convert null types to an empty string as a workaround of Rails deep_munge
    const parameterSetTypes = paramTypes.map((type) =>
      type === AutoDetectOption ? "" : type.value,
    );
    updateExplainWorkbook({
      variables: {
        workbookId: workbook.id,
        parameterSetTypes,
        parameterRefAliases: paramNames,
      },
      refetchQueries: [
        {
          query: ExplainWorkbookDetailsDocument,
          variables: { workbookId: workbook.id, databaseId },
        },
      ],
      onCompleted: () => {
        setErrorMessage("");
        onDismiss();
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const handleParamTypeChange = (idx: number, type: ValueTypeOptionType) => {
    if (paramTypes[idx] !== type) {
      const newTypes = [...paramTypes];
      newTypes[idx] = type;
      setParamTypes(newTypes);
    }
  };

  const handleParamNameChange = (idx: number, name: string) => {
    const prevName = paramNames[idx];
    if (prevName !== name) {
      const newMessages = [...errorMessages];
      const newNames = [...paramNames];
      newNames[idx] = name;
      setParamNames(newNames);
      newMessages[idx] = validateParamName(newNames, idx);
      setErrorMessages(newMessages);
    }
  };

  const inputFields = workbook.parameterRefAliases.map((_, idx) => {
    const paramType = paramTypes[idx];
    const paramName = paramNames[idx];
    const error = errorMessages[idx];
    const fields = [
      <input
        key={`name-${idx}`}
        className="form-control !h-[38px]"
        value={paramName}
        onChange={(e) => handleParamNameChange(idx, e.target.value)}
      />,
      <div key={`type-${idx}`} className="flex items-center">
        <div className="grow">
          <CreatableSelect
            value={paramType}
            options={ValueTypeOptions}
            onChange={(newValue) => handleParamTypeChange(idx, newValue)}
          />
        </div>
      </div>,
    ];
    if (error) {
      fields.push(
        <div key={`error-${idx}`} className="col-span-2 text-[#C22426]">
          {error}
        </div>,
      );
    }
    return fields;
  });

  return (
    <ModalContainer
      title="Edit Parameter Settings"
      layout="centered"
      onClose={onDismiss}
    >
      {showExistingParameterWarning && (
        <Callout className="mb-4">
          Editing the type of an existing parameter may cause previously created
          EXPLAIN results to become outdated. To avoid this, consider editing
          types only for newly added parameters.
        </Callout>
      )}
      <div className="grid gap-4 text-[#606060]">
        <div>
          <div className="grid grid-cols-2 gap-2 mb-2 items-center">
            <div className="font-medium">Parameter Name</div>
            <div className="font-medium">Type</div>
            {Object.values(inputFields)}
          </div>
        </div>
        {errorMessage && (
          <div className="text-[#C22426]">
            <FontAwesomeIcon icon={faExclamationCircle} /> {errorMessage}
          </div>
        )}
        <div>
          <button
            className="btn btn-success w-[140px]"
            onClick={handleUpdateWorkbook}
            disabled={!!errorMessage || errorMessages.some(Boolean)}
          >
            Save
          </button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default EditParameterSettingsPanel;
