// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import { BillingOverviewFragmentFragmentDoc } from "../../Overview/gql/Fragment.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpdateBillingDetailsMutationMutationVariables = Types.Exact<{
  organizationSlug: Types.Scalars["ID"]["input"];
  billingCompanyName?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  billingAddressLine1?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  billingAddressLine2?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  billingAddressZipCode?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  billingAddressCity?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  billingAddressState?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  billingAddressCountry?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  billingVatId?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  billingEmail?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type UpdateBillingDetailsMutationMutation = {
  __typename?: "Mutation";
  updateBillingDetails: {
    __typename?: "UpdateBillingDetailsPayload";
    organizationBilling: {
      __typename?: "OrganizationBilling";
      id: string;
      subscriptionActiveInStripe: boolean;
      subscriptionNextChargeAt?: number | null;
      subscriptionNextChargeAmount?: number | null;
      subscriptionNextChargeCurrency?: string | null;
      subscriptionCardType?: string | null;
      subscriptionCardLast4?: string | null;
      subscriptionCardExpMonth?: number | null;
      subscriptionCardExpYear?: number | null;
      subscriptionBilledManually: boolean;
      subscriptionInterval?: Types.SubscriptionInterval | null;
      billingAddressWithName?: string | null;
      billingVatId?: string | null;
      billingEmail?: string | null;
      paymentRequiresAction: boolean;
      stripeClientSecret?: string | null;
      invoices: Array<{
        __typename?: "Invoice";
        id: string;
        reference: string;
        formattedTotal: string;
        invoicePdf?: string | null;
        status: string;
        created: number;
      }>;
      planChoices: Array<{
        __typename?: "OrganizationPlanChoice";
        id: string;
        name: string;
        marketingLines: Array<string>;
        notIncludedMarketingLines: Array<string>;
        formattedMonthlyPrice: string;
        formattedServerOveragePrice?: string | null;
        isCurrent: boolean;
        isDowngrade: boolean;
      }>;
    };
  };
};

export const UpdateBillingDetailsMutationDocument = gql`
  mutation UpdateBillingDetailsMutation(
    $organizationSlug: ID!
    $billingCompanyName: String
    $billingAddressLine1: String
    $billingAddressLine2: String
    $billingAddressZipCode: String
    $billingAddressCity: String
    $billingAddressState: String
    $billingAddressCountry: String
    $billingVatId: String
    $billingEmail: String
  ) {
    updateBillingDetails(
      organizationSlug: $organizationSlug
      billingCompanyName: $billingCompanyName
      billingAddressLine1: $billingAddressLine1
      billingAddressLine2: $billingAddressLine2
      billingAddressZipCode: $billingAddressZipCode
      billingAddressCity: $billingAddressCity
      billingAddressState: $billingAddressState
      billingAddressCountry: $billingAddressCountry
      billingVatId: $billingVatId
      billingEmail: $billingEmail
    ) {
      organizationBilling {
        ...BillingOverviewFragment
      }
    }
  }
  ${BillingOverviewFragmentFragmentDoc}
`;
export type UpdateBillingDetailsMutationMutationFn = Apollo.MutationFunction<
  UpdateBillingDetailsMutationMutation,
  UpdateBillingDetailsMutationMutationVariables
>;

/**
 * __useUpdateBillingDetailsMutationMutation__
 *
 * To run a mutation, you first call `useUpdateBillingDetailsMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingDetailsMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingDetailsMutationMutation, { data, loading, error }] = useUpdateBillingDetailsMutationMutation({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *      billingCompanyName: // value for 'billingCompanyName'
 *      billingAddressLine1: // value for 'billingAddressLine1'
 *      billingAddressLine2: // value for 'billingAddressLine2'
 *      billingAddressZipCode: // value for 'billingAddressZipCode'
 *      billingAddressCity: // value for 'billingAddressCity'
 *      billingAddressState: // value for 'billingAddressState'
 *      billingAddressCountry: // value for 'billingAddressCountry'
 *      billingVatId: // value for 'billingVatId'
 *      billingEmail: // value for 'billingEmail'
 *   },
 * });
 */
export function useUpdateBillingDetailsMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBillingDetailsMutationMutation,
    UpdateBillingDetailsMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBillingDetailsMutationMutation,
    UpdateBillingDetailsMutationMutationVariables
  >(UpdateBillingDetailsMutationDocument, options);
}
export type UpdateBillingDetailsMutationMutationHookResult = ReturnType<
  typeof useUpdateBillingDetailsMutationMutation
>;
export type UpdateBillingDetailsMutationMutationResult =
  Apollo.MutationResult<UpdateBillingDetailsMutationMutation>;
export type UpdateBillingDetailsMutationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateBillingDetailsMutationMutation,
    UpdateBillingDetailsMutationMutationVariables
  >;
