// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import { BillingOverviewFragmentFragmentDoc } from "../../Overview/gql/Fragment.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type PayInvoiceMutationVariables = Types.Exact<{
  organizationSlug: Types.Scalars["ID"]["input"];
  invoiceId: Types.Scalars["String"]["input"];
}>;

export type PayInvoiceMutation = {
  __typename?: "Mutation";
  payInvoice: {
    __typename?: "PayInvoicePayload";
    organizationBilling: {
      __typename?: "OrganizationBilling";
      id: string;
      subscriptionActiveInStripe: boolean;
      subscriptionNextChargeAt?: number | null;
      subscriptionNextChargeAmount?: number | null;
      subscriptionNextChargeCurrency?: string | null;
      subscriptionCardType?: string | null;
      subscriptionCardLast4?: string | null;
      subscriptionCardExpMonth?: number | null;
      subscriptionCardExpYear?: number | null;
      subscriptionBilledManually: boolean;
      subscriptionInterval?: Types.SubscriptionInterval | null;
      billingAddressWithName?: string | null;
      billingVatId?: string | null;
      billingEmail?: string | null;
      paymentRequiresAction: boolean;
      stripeClientSecret?: string | null;
      invoices: Array<{
        __typename?: "Invoice";
        id: string;
        reference: string;
        formattedTotal: string;
        invoicePdf?: string | null;
        status: string;
        created: number;
      }>;
      planChoices: Array<{
        __typename?: "OrganizationPlanChoice";
        id: string;
        name: string;
        marketingLines: Array<string>;
        notIncludedMarketingLines: Array<string>;
        formattedMonthlyPrice: string;
        formattedServerOveragePrice?: string | null;
        isCurrent: boolean;
        isDowngrade: boolean;
      }>;
    };
  };
};

export const PayInvoiceDocument = gql`
  mutation PayInvoice($organizationSlug: ID!, $invoiceId: String!) {
    payInvoice(organizationSlug: $organizationSlug, invoiceId: $invoiceId) {
      organizationBilling {
        ...BillingOverviewFragment
      }
    }
  }
  ${BillingOverviewFragmentFragmentDoc}
`;
export type PayInvoiceMutationFn = Apollo.MutationFunction<
  PayInvoiceMutation,
  PayInvoiceMutationVariables
>;

/**
 * __usePayInvoiceMutation__
 *
 * To run a mutation, you first call `usePayInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payInvoiceMutation, { data, loading, error }] = usePayInvoiceMutation({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function usePayInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayInvoiceMutation,
    PayInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PayInvoiceMutation, PayInvoiceMutationVariables>(
    PayInvoiceDocument,
    options,
  );
}
export type PayInvoiceMutationHookResult = ReturnType<
  typeof usePayInvoiceMutation
>;
export type PayInvoiceMutationResult =
  Apollo.MutationResult<PayInvoiceMutation>;
export type PayInvoiceMutationOptions = Apollo.BaseMutationOptions<
  PayInvoiceMutation,
  PayInvoiceMutationVariables
>;
