// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import { ExplainQueryShortFragmentFragmentDoc } from "../../ExplainWorkbook/gql/Fragment.short.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CreateExplainQueryMutationVariables = Types.Exact<{
  databaseId: Types.Scalars["ID"]["input"];
  workbookId: Types.Scalars["ID"]["input"];
  name?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  description?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  baselineExplainQueryId: Types.Scalars["ID"]["input"];
  queryText: Types.Scalars["String"]["input"];
  plannerSettings: Types.Scalars["JSON"]["input"];
}>;

export type CreateExplainQueryMutation = {
  __typename?: "Mutation";
  createExplainQuery: {
    __typename?: "ExplainQuery";
    id: string;
    createdAt: number;
    updatedAt: number;
    description?: string | null;
    queryFingerprint: string;
    queryText: string;
    name: string;
    queryTextWithAlias: string;
    paramRefAliasMap: any;
    draft: boolean;
    plannerSettings: any;
    user?: { __typename?: "User"; id: string; fullname?: string | null } | null;
    queryTextWithParameters: Array<{
      __typename?: "QueryTextWithParameters";
      id: string;
      parameterSetId: string;
      queryWithParameters: string;
    }>;
  };
};

export const CreateExplainQueryDocument = gql`
  mutation CreateExplainQuery(
    $databaseId: ID!
    $workbookId: ID!
    $name: String
    $description: String
    $baselineExplainQueryId: ID!
    $queryText: String!
    $plannerSettings: JSON!
  ) {
    createExplainQuery(
      databaseId: $databaseId
      workbookId: $workbookId
      name: $name
      description: $description
      baselineExplainQueryId: $baselineExplainQueryId
      queryText: $queryText
      plannerSettings: $plannerSettings
    ) {
      ...ExplainQueryShortFragment
    }
  }
  ${ExplainQueryShortFragmentFragmentDoc}
`;
export type CreateExplainQueryMutationFn = Apollo.MutationFunction<
  CreateExplainQueryMutation,
  CreateExplainQueryMutationVariables
>;

/**
 * __useCreateExplainQueryMutation__
 *
 * To run a mutation, you first call `useCreateExplainQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExplainQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExplainQueryMutation, { data, loading, error }] = useCreateExplainQueryMutation({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *      workbookId: // value for 'workbookId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      baselineExplainQueryId: // value for 'baselineExplainQueryId'
 *      queryText: // value for 'queryText'
 *      plannerSettings: // value for 'plannerSettings'
 *   },
 * });
 */
export function useCreateExplainQueryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExplainQueryMutation,
    CreateExplainQueryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExplainQueryMutation,
    CreateExplainQueryMutationVariables
  >(CreateExplainQueryDocument, options);
}
export type CreateExplainQueryMutationHookResult = ReturnType<
  typeof useCreateExplainQueryMutation
>;
export type CreateExplainQueryMutationResult =
  Apollo.MutationResult<CreateExplainQueryMutation>;
export type CreateExplainQueryMutationOptions = Apollo.BaseMutationOptions<
  CreateExplainQueryMutation,
  CreateExplainQueryMutationVariables
>;
