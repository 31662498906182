// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpdateExplainParameterSetsMutationVariables = Types.Exact<{
  workbookId: Types.Scalars["ID"]["input"];
  parameterSets: Types.Scalars["JSON"]["input"];
}>;

export type UpdateExplainParameterSetsMutation = {
  __typename?: "Mutation";
  updateExplainParameterSets?: {
    __typename?: "ExplainWorkbook";
    id: string;
    parameterRefAliases: Array<string>;
    parameterSets: Array<{
      __typename?: "ParameterSet";
      id: string;
      name: string;
      paramValues: any;
    }>;
    aliasParamMapList: Array<{
      __typename?: "AliasParamMap";
      id: string;
      name: string;
      parameters: any;
    }>;
  } | null;
};

export const UpdateExplainParameterSetsDocument = gql`
  mutation UpdateExplainParameterSets($workbookId: ID!, $parameterSets: JSON!) {
    updateExplainParameterSets(
      workbookId: $workbookId
      parameterSets: $parameterSets
    ) {
      id
      parameterSets {
        id
        name
        paramValues
      }
      parameterRefAliases
      aliasParamMapList {
        id
        name
        parameters
      }
    }
  }
`;
export type UpdateExplainParameterSetsMutationFn = Apollo.MutationFunction<
  UpdateExplainParameterSetsMutation,
  UpdateExplainParameterSetsMutationVariables
>;

/**
 * __useUpdateExplainParameterSetsMutation__
 *
 * To run a mutation, you first call `useUpdateExplainParameterSetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExplainParameterSetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExplainParameterSetsMutation, { data, loading, error }] = useUpdateExplainParameterSetsMutation({
 *   variables: {
 *      workbookId: // value for 'workbookId'
 *      parameterSets: // value for 'parameterSets'
 *   },
 * });
 */
export function useUpdateExplainParameterSetsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateExplainParameterSetsMutation,
    UpdateExplainParameterSetsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateExplainParameterSetsMutation,
    UpdateExplainParameterSetsMutationVariables
  >(UpdateExplainParameterSetsDocument, options);
}
export type UpdateExplainParameterSetsMutationHookResult = ReturnType<
  typeof useUpdateExplainParameterSetsMutation
>;
export type UpdateExplainParameterSetsMutationResult =
  Apollo.MutationResult<UpdateExplainParameterSetsMutation>;
export type UpdateExplainParameterSetsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateExplainParameterSetsMutation,
    UpdateExplainParameterSetsMutationVariables
  >;
