export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
  JSON_legacy: { input: any; output: any; }
};

/** Autogenerated return type of ActivateBillingPlan. */
export type ActivateBillingPlanPayload = {
  __typename?: 'ActivateBillingPlanPayload';
  organization: Organization;
  organizationBilling: OrganizationBilling;
};

export enum ActivityKind {
  /** issue acknowledged by an organization member */
  Acknowledged = 'acknowledged',
  /** issue decreased in severity */
  Downgraded = 'downgraded',
  /** issue increased in severity */
  Escalated = 'escalated',
  /** notified organization according to alerting configuration */
  Notified = 'notified',
  /** attempted a notification according to alerting configuration but hit an error */
  NotifyFailed = 'notify_failed',
  /** issue un-acknowledged by an organization member */
  Reopened = 'reopened',
  /** issue detected as resolved by pganalyze */
  Resolved = 'resolved',
  /** issue first detected by pganalyze */
  Triggered = 'triggered'
}

/** Autogenerated return type of AddServer. */
export type AddServerPayload = {
  __typename?: 'AddServerPayload';
  errors: Array<Scalars['String']['output']>;
  server?: Maybe<Server>;
};

/** An alert policy for a user */
export type AlertPolicy = {
  __typename?: 'AlertPolicy';
  assignedCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  policyName: Scalars['String']['output'];
  settings?: Maybe<Array<Maybe<AlertPolicySetting>>>;
};

/** A setting in an alert policy */
export type AlertPolicySetting = {
  __typename?: 'AlertPolicySetting';
  checkName: Scalars['String']['output'];
  dailySummary: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  immediate: Scalars['Boolean']['output'];
  pagerduty: Scalars['Boolean']['output'];
  weeklySummary: Scalars['Boolean']['output'];
};

export type AliasParamMap = {
  __typename?: 'AliasParamMap';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parameters: Scalars['JSON']['output'];
  source?: Maybe<Scalars['String']['output']>;
};

/** An api key */
export type ApiKey = {
  __typename?: 'ApiKey';
  accessScopeId: Scalars['String']['output'];
  accessScopeName: Scalars['String']['output'];
  accessScopeType: Scalars['String']['output'];
  accessType: Scalars['String']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  lastUsedAt?: Maybe<Scalars['Int']['output']>;
  token: Scalars['String']['output'];
};

/** Public configuration settings for pganalyze itself */
export type AppConfig = {
  __typename?: 'AppConfig';
  changelogUrl: Scalars['String']['output'];
  domainName: Scalars['String']['output'];
  emailIntegrationAvailable: Scalars['Boolean']['output'];
  enterpriseEdition: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  pagerDutyAppId?: Maybe<Scalars['String']['output']>;
  sentryDsn?: Maybe<Scalars['String']['output']>;
  sentryTracesSampleRate: Scalars['Float']['output'];
  slackIntegrationAvailable: Scalars['Boolean']['output'];
  stripePublishableKey?: Maybe<Scalars['String']['output']>;
};

/** A Postgres backend */
export type Backend = {
  __typename?: 'Backend';
  backendStart?: Maybe<Scalars['Int']['output']>;
  backendType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identity: Scalars['ID']['output'];
  lastActivityAt?: Maybe<Scalars['Int']['output']>;
  logLineCount?: Maybe<Scalars['Int']['output']>;
  pid: Scalars['Int']['output'];
  postgresRole?: Maybe<PostgresRole>;
  queryCount?: Maybe<Scalars['Int']['output']>;
  snapshots?: Maybe<Array<Maybe<BackendSnapshot>>>;
  waitEventCount?: Maybe<Scalars['Int']['output']>;
};

/** Details for a particular backend at a point in time */
export type BackendAtTime = {
  __typename?: 'BackendAtTime';
  backend: Backend;
  id: Scalars['ID']['output'];
  snapshot: BackendSnapshot;
};

/** A count for a specific kind of backends */
export type BackendCount = {
  __typename?: 'BackendCount';
  backendType: Scalars['String']['output'];
  collectedAt: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  databaseId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  postgresRoleId?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  waitingForLock: Scalars['Boolean']['output'];
};

/** A result row of backend counts */
export type BackendCountsResult = {
  __typename?: 'BackendCountsResult';
  activeCount: Scalars['Int']['output'];
  database?: Maybe<Database>;
  id: Scalars['ID']['output'];
  idleCount: Scalars['Int']['output'];
  idleInTransactionCount: Scalars['Int']['output'];
  totalClientCount: Scalars['Int']['output'];
  waitingForLockCount: Scalars['Int']['output'];
};

/** Snapshot of a specific backend at a given time */
export type BackendSnapshot = {
  __typename?: 'BackendSnapshot';
  collectedAt: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  queryAge?: Maybe<Scalars['String']['output']>;
  queryId?: Maybe<Scalars['ID']['output']>;
  queryStart?: Maybe<Scalars['Int']['output']>;
  queryTextShort?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  uniqBlockedByPids: Array<Maybe<Scalars['Int']['output']>>;
  uniqBlockingPids: Array<Maybe<Scalars['Int']['output']>>;
  waitEvent: Scalars['String']['output'];
  waitEventType: Scalars['String']['output'];
  waitingForLock: Scalars['Boolean']['output'];
};

/** Summary of bloat at the database or server level */
export type Bloat = {
  __typename?: 'Bloat';
  estimatedAt?: Maybe<Scalars['Int']['output']>;
  estimatedTableBytes: Scalars['Int']['output'];
  idealTableBytes: Scalars['Int']['output'];
  numTablesWithStats: Scalars['Int']['output'];
  numTotalTables: Scalars['Int']['output'];
};

export type BufferCacheStats = {
  __typename?: 'BufferCacheStats';
  databaseId: Scalars['ID']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  usage: Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>;
};

/** Series of buffer cache statistics */
export type BufferCacheUsage = {
  __typename?: 'BufferCacheUsage';
  id: Scalars['ID']['output'];
  sharedBuffers: Scalars['Float']['output'];
  stats: Array<BufferCacheStats>;
};

/** Autogenerated return type of CancelBillingPlan. */
export type CancelBillingPlanPayload = {
  __typename?: 'CancelBillingPlanPayload';
  organization: Organization;
  organizationBilling: OrganizationBilling;
};

/** A check on a particular organization */
export type Check = {
  __typename?: 'Check';
  acknowledgedIssueCount: Scalars['Int']['output'];
  checkName: Scalars['String']['output'];
  database?: Maybe<Database>;
  enabled: Scalars['String']['output'];
  groupName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  notifyPagerduty: Scalars['String']['output'];
  notifySlack: Scalars['String']['output'];
  openIssueMaxSeverity?: Maybe<Scalars['String']['output']>;
  resolvedIssueCount30d: Scalars['Int']['output'];
  triggeredIssueCount: Scalars['Int']['output'];
};

/** The configuration settings for a check-up process on a specific server */
export type CheckConfig = {
  __typename?: 'CheckConfig';
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  server: Server;
  settingsJson: Scalars['JSON_legacy']['output'];
};

/** A group of potential issues and their status */
export type CheckUpIssueGroup = {
  __typename?: 'CheckUpIssueGroup';
  checkName: Scalars['String']['output'];
  groupName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

/** The check-up summary status for a given database */
export type CheckUpStatus = {
  __typename?: 'CheckUpStatus';
  critical: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  issueGroups: Array<Maybe<CheckUpIssueGroup>>;
};

/** Information about the collector reporting for this server */
export type CollectorInfo = {
  __typename?: 'CollectorInfo';
  configFromEnv: Scalars['Boolean']['output'];
  logCollectionDisabled: Scalars['Boolean']['output'];
  logCollectionDisabledReason?: Maybe<Scalars['String']['output']>;
  monitoringUser?: Maybe<Scalars['String']['output']>;
  supportsQueryTuning: Scalars['Boolean']['output'];
};

/** Autogenerated return type of CreateExplainWorkbook. */
export type CreateExplainWorkbookPayload = {
  __typename?: 'CreateExplainWorkbookPayload';
  explainQueryId?: Maybe<Scalars['ID']['output']>;
  explainWorkbookId?: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated return type of CreateSupportTicket. */
export type CreateSupportTicketPayload = {
  __typename?: 'CreateSupportTicketPayload';
  ticketId: Scalars['String']['output'];
};

/** A database */
export type Database = {
  __typename?: 'Database';
  collate?: Maybe<Scalars['String']['output']>;
  connectionLimit?: Maybe<Scalars['Int']['output']>;
  datname?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  encoding?: Maybe<Scalars['String']['output']>;
  hasColumnStats: Scalars['Boolean']['output'];
  hasInitialIndexAdvisorAnalysis: Scalars['Boolean']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  indexWriteOverhead: DatabaseIndexWriteOverhead;
  issueCount: Scalars['Int']['output'];
  legacyDefault: Scalars['Boolean']['output'];
  ownerPostgresRole?: Maybe<PostgresRole>;
  permittedToTuneQueries: Scalars['Boolean']['output'];
  queryCount: Scalars['Int']['output'];
  server: Server;
  tableCount: Scalars['Int']['output'];
  totalBloat: Bloat;
  totalIndexSize: Scalars['Int']['output'];
  totalIndexSize7dAgo: Scalars['Int']['output'];
};


/** A database */
export type DatabaseIssueCountArgs = {
  checkGroupsAndNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Freezing stats for the database */
export type DatabaseFreezingStats = {
  __typename?: 'DatabaseFreezingStats';
  age: Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>;
  datname: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

/** A summary of index write overhead for a database */
export type DatabaseIndexWriteOverhead = {
  __typename?: 'DatabaseIndexWriteOverhead';
  tableWritesPerMinute?: Maybe<Scalars['Float']['output']>;
  tablesWithDefaultExprIndexOverhead: Scalars['Int']['output'];
  tablesWithMissingIndexMetadata: Scalars['Int']['output'];
  tablesWithMissingStats: Scalars['Int']['output'];
  totalWriteOverhead?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

/** A possibly partial list of databases */
export type DatabasePartialList = {
  __typename?: 'DatabasePartialList';
  databases: Array<Database>;
  totalCount: Scalars['Int']['output'];
};

/** Statistics for a database */
export type DatabaseStats = {
  __typename?: 'DatabaseStats';
  dataSize: Scalars['Int']['output'];
  database: Database;
  id: Scalars['ID']['output'];
  indexSize: Scalars['Int']['output'];
  tableViewCount: Scalars['Int']['output'];
  toastSize: Scalars['Int']['output'];
  totalSize: Scalars['Int']['output'];
};

/** A database and its summary statistics */
export type DatabaseSummaryStats = {
  __typename?: 'DatabaseSummaryStats';
  criticalsCount: Scalars['Int']['output'];
  database: Database;
  id: Scalars['ID']['output'];
  queriesSlownessCount: Scalars['Int']['output'];
  unusedIndexesCount: Scalars['Int']['output'];
  warningsCount: Scalars['Int']['output'];
};

/** Autogenerated return type of DeleteExplainQuery. */
export type DeleteExplainQueryPayload = {
  __typename?: 'DeleteExplainQueryPayload';
  explainWorkbookId: Scalars['ID']['output'];
};

/** Autogenerated return type of DeleteExplainResults. */
export type DeleteExplainResultsPayload = {
  __typename?: 'DeleteExplainResultsPayload';
  explainWorkbookId: Scalars['ID']['output'];
};

/** Autogenerated return type of DeleteExplainWorkbook. */
export type DeleteExplainWorkbookPayload = {
  __typename?: 'DeleteExplainWorkbookPayload';
  databaseId: Scalars['ID']['output'];
};

/** Autogenerated return type of DeleteOrganizationMember. */
export type DeleteOrganizationMemberPayload = {
  __typename?: 'DeleteOrganizationMemberPayload';
  organization: Organization;
};

/** Autogenerated return type of DeleteServer. */
export type DeleteServerPayload = {
  __typename?: 'DeleteServerPayload';
  errors: Array<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Lists specific features that are enabled for this database/server */
export type EnabledFeatures = {
  __typename?: 'EnabledFeatures';
  activitySnapshots: Scalars['Boolean']['output'];
  checkUp: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  indexAdvisor: Scalars['Boolean']['output'];
  indexAdvisorV3Consolidation: Scalars['Boolean']['output'];
  indexCheck: Scalars['Boolean']['output'];
  lockMonitoring: Scalars['Boolean']['output'];
  logs: Scalars['Boolean']['output'];
  querySearch: Scalars['Boolean']['output'];
  queryTuning: Scalars['Boolean']['output'];
  replication: Scalars['Boolean']['output'];
  saml: Scalars['Boolean']['output'];
  schemaStats: Scalars['Boolean']['output'];
};

/** An explain query */
export type ExplainQuery = {
  __typename?: 'ExplainQuery';
  baselineExplainQueryId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['Int']['output'];
  database: Database;
  description?: Maybe<Scalars['String']['output']>;
  draft: Scalars['Boolean']['output'];
  explainResults: Array<ExplainResult>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  paramRefAliasMap: Scalars['JSON']['output'];
  plannerSettings: Scalars['JSON']['output'];
  query?: Maybe<Query>;
  queryFingerprint: Scalars['String']['output'];
  queryText: Scalars['String']['output'];
  queryTextWithAlias: Scalars['String']['output'];
  queryTextWithParameters: Array<QueryTextWithParameters>;
  server: Server;
  updatedAt: Scalars['Int']['output'];
  user?: Maybe<User>;
  workbook: ExplainWorkbook;
};

/** A result of an explain query */
export type ExplainResult = {
  __typename?: 'ExplainResult';
  annotatedJson?: Maybe<Scalars['JSON_legacy']['output']>;
  createdAt: Scalars['Int']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  explainQuery: ExplainQuery;
  format: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  outputJson?: Maybe<Scalars['JSON_legacy']['output']>;
  outputText?: Maybe<Scalars['String']['output']>;
  parameterSetId?: Maybe<Scalars['ID']['output']>;
  planFingerprint?: Maybe<Scalars['String']['output']>;
  planNodeTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  queryText?: Maybe<Scalars['String']['output']>;
  resultSource: Scalars['String']['output'];
  runtimeMs?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  totalBlkReadTime?: Maybe<Scalars['Float']['output']>;
  totalBlkWriteTime?: Maybe<Scalars['Float']['output']>;
  totalCost?: Maybe<Scalars['Float']['output']>;
  totalSharedBlksDirtied?: Maybe<Scalars['Int']['output']>;
  totalSharedBlksHit?: Maybe<Scalars['Int']['output']>;
  totalSharedBlksRead?: Maybe<Scalars['Int']['output']>;
  totalSharedBlksWritten?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['Int']['output'];
  usedParameterSet?: Maybe<AliasParamMap>;
  user?: Maybe<User>;
};

/** A workbook of explain */
export type ExplainWorkbook = {
  __typename?: 'ExplainWorkbook';
  aliasParamMapList: Array<AliasParamMap>;
  baselineQuery: ExplainQuery;
  createdAt: Scalars['Int']['output'];
  database: Database;
  databaseId: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  draft: Scalars['Boolean']['output'];
  explainQueries: Array<ExplainQuery>;
  hasExplainAnalyzeHelper: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  lastActivityAt: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parameterRefAliases: Array<Scalars['String']['output']>;
  parameterSetTypes: Array<Maybe<Scalars['String']['output']>>;
  parameterSets: Array<ParameterSet>;
  parameterSetsSelected: Scalars['Boolean']['output'];
  server: Server;
  user?: Maybe<User>;
  variantsCount: Scalars['Int']['output'];
};

/** The query root of this schema */
export type GraphqlQuery = {
  __typename?: 'GraphqlQuery';
  getAlertPolicies: Array<AlertPolicy>;
  getAlertPolicyDetails?: Maybe<AlertPolicy>;
  getApiKeys: Array<ApiKey>;
  getAppConfig: AppConfig;
  getBackendCounts: Array<BackendCount>;
  getBackendCountsByDatabase: Array<BackendCountsResult>;
  getBackendDetails?: Maybe<Backend>;
  getBackends: Array<BackendAtTime>;
  getBufferCacheUsage?: Maybe<BufferCacheUsage>;
  getCheckConfig: CheckConfig;
  getCheckConfigs: Array<CheckConfig>;
  getCheckUpStatus: CheckUpStatus;
  getChecks: Array<Check>;
  getCurrentPostgresVersion?: Maybe<PostgresVersion>;
  getCurrentUserDetails: User;
  getDatabaseDetails: Database;
  getDatabaseStats: Array<DatabaseStats>;
  getDatabases: Array<Database>;
  getExplainQueries: Array<ExplainQuery>;
  getExplainQuery: ExplainQuery;
  getExplainQueryResult: ExplainResult;
  getExplainWorkbookDetails: ExplainWorkbook;
  getExplainWorkbooks: Array<ExplainWorkbook>;
  getExplains: Array<QueryExplain>;
  getIndexAdvisorConfigForDatabase: Array<IndexAdvisorConfigForTable>;
  getIndexAdvisorStatus: IndexAdvisorStatus;
  getIssue: Issue;
  getIssueCounts: Array<IssueSummary>;
  getIssues: Array<Issue>;
  getLatestPostgresServerStats?: Maybe<PostgresServerStats>;
  getLogAnalysisCheckpointStarting?: Maybe<LogAnalysisCheckpointStartingResult>;
  getLogAnalysisTempFileCreated?: Maybe<LogAnalysisTempFileCreatedResult>;
  getLogLineDetails: LogLine;
  getLogLines: Array<LogLine>;
  getLogQuotaUsage: LogQuotaUsage;
  getLogStats: LogStatsResult;
  getOrganizationBilling?: Maybe<OrganizationBilling>;
  getOrganizationDetails?: Maybe<Organization>;
  getOrganizationMembershipCurrentUser: OrganizationMembership;
  getOrganizationMembershipDetails: OrganizationMembership;
  getOrganizationRoleDetails?: Maybe<OrganizationRole>;
  getOrganizationRoles: Array<OrganizationRole>;
  getPostgresRoleDetails?: Maybe<PostgresRole>;
  getPostgresRoles: Array<PostgresRole>;
  getPostgresServerStats: Array<PostgresServerStats>;
  getPostgresSettingDetails?: Maybe<PostgresSetting>;
  getPostgresSettings: Array<PostgresSetting>;
  getQueryDetailStats?: Maybe<QueryStats>;
  getQueryDetailSummaryStats?: Maybe<QueryDetailSummaryStats>;
  getQueryDetails: Query;
  getQueryExplain?: Maybe<QueryExplain>;
  getQueryExplains: Array<QueryExplain>;
  getQueryExplainsForGraph: Array<QueryExplain>;
  getQueryForWorkbook: QueryForWorkbook;
  getQueryIndexCheck: QueryIndexCheck;
  getQueryOverviewStats?: Maybe<QueryOverviewStatsSeries>;
  getQueryOverviewStatsByDatabase?: Maybe<Array<QueryOverviewStatsByDatabaseResult>>;
  getQueryPlan?: Maybe<QueryPlan>;
  getQueryPlanStats: Array<QueryPlanStatsSeries>;
  getQueryPlans: Array<QueryPlan>;
  getQuerySamples: Array<QuerySample>;
  getQueryStats: Array<QuerySummaryStats>;
  getQueryStatsForTable?: Maybe<Array<QuerySummaryStats>>;
  getQueryTagKeys: Array<Scalars['String']['output']>;
  getQueryTagSummaryForQuery: Array<QueryTagSummary>;
  getQueryTagsets: Array<QueryTagset>;
  /** Find all open issues that match on the specified parts of the grouping key */
  getRelatedIssues: Array<Issue>;
  getReplicationFollowerStats?: Maybe<ReplicationFollowerStats>;
  getReplicationFollowers: Array<ReplicationFollower>;
  getSchemaFunctionDetails: SchemaFunction;
  getSchemaFunctions: Array<SchemaFunction>;
  getSchemaIndexDetails: SchemaIndex;
  getSchemaIndexStats?: Maybe<SchemaIndexStatsSeries>;
  getSchemaIndices: Array<SchemaIndex>;
  getSchemaTableAutovacuumSettings: Array<SchemaTableAutovacuumSetting>;
  getSchemaTableBloatInfo?: Maybe<SchemaTableBloatInfo>;
  getSchemaTableColumns: Array<SchemaColumn>;
  getSchemaTableConstraints: Array<SchemaConstraint>;
  getSchemaTableDetails: SchemaTable;
  getSchemaTableEvents: Array<SchemaTableEvent>;
  getSchemaTableExtendedStats: Array<SchemaTableExtendedStats>;
  getSchemaTableIndexAdvisorConfig: IndexAdvisorConfigForTable;
  getSchemaTableIndexSelection: IndexSelection;
  getSchemaTableIndices: Array<SchemaIndex>;
  getSchemaTableListWithVacuumSettings: Array<SchemaTableListWithVacuumSettings>;
  getSchemaTableOptionsForSetting: Array<SchemaTableOption>;
  getSchemaTableScans: Array<SchemaTableScanDetail>;
  getSchemaTableScansForQuery: Array<SchemaTableScanForQuery>;
  getSchemaTableStats?: Maybe<SchemaTableStatsSeries>;
  getSchemaTableVacuumInfo: SchemaTableVacuumInfo;
  getSchemaTables: Array<SchemaTable>;
  getSchemaTablesVacuumSummary: Array<SchemaTableVacuumSummary>;
  getSchemaViewDetails: SchemaView;
  getSchemaViews: Array<SchemaView>;
  getServerDetails: Server;
  getServerFreezingStats?: Maybe<ServerFreezingStats>;
  getServerFreezingStatsByTable?: Maybe<ServerFreezingStatsByTable>;
  getServerXactPerSec: ServerXactPerSec;
  getServerXminHorizon?: Maybe<ServerXminHorizon>;
  getServers: Array<Server>;
  getSnapshotDetails: Snapshot;
  getSnapshots: Array<Snapshot>;
  getSummaryStatsByDatabase: Array<DatabaseSummaryStats>;
  getSummaryStatsByServer: Array<ServerSummaryStats>;
  getSystemDetails: SystemDetails;
  getSystemStats?: Maybe<SystemStatsSeries>;
  getVacuumInsightStatus: Array<VacuumInsightStatus>;
  getVacuumRunDetails?: Maybe<VacuumRunDetails>;
  getVacuumRuns: VacuumRuns;
  getVacuumSimulatorInput: VacuumSimulatorInputType;
  getWaitEventCounts: WaitEventCountsResult;
};


/** The query root of this schema */
export type GraphqlQueryGetAlertPoliciesArgs = {
  organizationSlug: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetAlertPolicyDetailsArgs = {
  alertPolicyId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetApiKeysArgs = {
  accessScopeOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  accessType?: InputMaybe<Scalars['String']['input']>;
  organizationSlug?: InputMaybe<Scalars['ID']['input']>;
  serverId?: InputMaybe<Scalars['ID']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetBackendCountsArgs = {
  databaseId?: InputMaybe<Scalars['ID']['input']>;
  endTs?: InputMaybe<Scalars['Int']['input']>;
  serverId?: InputMaybe<Scalars['ID']['input']>;
  startTs?: InputMaybe<Scalars['Int']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetBackendCountsByDatabaseArgs = {
  serverId: Scalars['ID']['input'];
  timestamp: Scalars['Int']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetBackendDetailsArgs = {
  backendLocator: Scalars['String']['input'];
  databaseId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetBackendsArgs = {
  databaseId: Scalars['ID']['input'];
  timestamp: Scalars['Int']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetBufferCacheUsageArgs = {
  endTs: Scalars['Int']['input'];
  serverId: Scalars['ID']['input'];
  startTs: Scalars['Int']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetCheckConfigArgs = {
  checkGroupAndName: Scalars['String']['input'];
  databaseId?: InputMaybe<Scalars['ID']['input']>;
  serverId?: InputMaybe<Scalars['ID']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetCheckConfigsArgs = {
  checkGroupAndName: Scalars['String']['input'];
  organizationSlug: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetCheckUpStatusArgs = {
  databaseId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetChecksArgs = {
  checkGroupAndName?: InputMaybe<Scalars['String']['input']>;
  databaseId?: InputMaybe<Scalars['ID']['input']>;
  organizationSlug?: InputMaybe<Scalars['ID']['input']>;
  serverId?: InputMaybe<Scalars['ID']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetCurrentPostgresVersionArgs = {
  serverId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetDatabaseDetailsArgs = {
  databaseId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetDatabaseStatsArgs = {
  serverId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetDatabasesArgs = {
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  organizationSlug?: InputMaybe<Scalars['ID']['input']>;
  serverId?: InputMaybe<Scalars['ID']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetExplainQueriesArgs = {
  workbookId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetExplainQueryArgs = {
  explainQueryId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetExplainQueryResultArgs = {
  explainQueryId: Scalars['ID']['input'];
  resultId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetExplainWorkbookDetailsArgs = {
  workbookId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetExplainWorkbooksArgs = {
  databaseId?: InputMaybe<Scalars['ID']['input']>;
  serverId?: InputMaybe<Scalars['ID']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetExplainsArgs = {
  databaseId?: InputMaybe<Scalars['ID']['input']>;
  serverId?: InputMaybe<Scalars['ID']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetIndexAdvisorConfigForDatabaseArgs = {
  databaseId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetIndexAdvisorStatusArgs = {
  databaseId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetIssueArgs = {
  issueId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetIssueCountsArgs = {
  checkGroupsAndNames: Array<Scalars['String']['input']>;
  databaseId?: InputMaybe<Scalars['ID']['input']>;
  serverId?: InputMaybe<Scalars['ID']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetIssuesArgs = {
  checkGroupsAndNames?: InputMaybe<Array<Scalars['String']['input']>>;
  databaseId?: InputMaybe<Scalars['ID']['input']>;
  endTs?: InputMaybe<Scalars['Int']['input']>;
  organizationSlug?: InputMaybe<Scalars['ID']['input']>;
  referentId?: InputMaybe<Scalars['ID']['input']>;
  referentType?: InputMaybe<Scalars['String']['input']>;
  serverId?: InputMaybe<Scalars['ID']['input']>;
  severity?: InputMaybe<Array<Scalars['String']['input']>>;
  startTs?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetLatestPostgresServerStatsArgs = {
  endTs?: InputMaybe<Scalars['Int']['input']>;
  serverId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetLogAnalysisCheckpointStartingArgs = {
  endTs: Scalars['Int']['input'];
  serverId: Scalars['ID']['input'];
  startTs: Scalars['Int']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetLogAnalysisTempFileCreatedArgs = {
  databaseId?: InputMaybe<Scalars['ID']['input']>;
  endTs?: InputMaybe<Scalars['Int']['input']>;
  serverId: Scalars['ID']['input'];
  startTs?: InputMaybe<Scalars['Int']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetLogLineDetailsArgs = {
  occurredAt: Scalars['Float']['input'];
  serverId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetLogLinesArgs = {
  backendPid?: InputMaybe<Scalars['Int']['input']>;
  classification?: InputMaybe<Scalars['String']['input']>;
  databaseId?: InputMaybe<Scalars['ID']['input']>;
  occurredAfter?: InputMaybe<Scalars['Int']['input']>;
  occurredBefore?: InputMaybe<Scalars['Int']['input']>;
  postgresRoleId?: InputMaybe<Scalars['String']['input']>;
  queryFingerprint?: InputMaybe<Scalars['String']['input']>;
  serverId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetLogQuotaUsageArgs = {
  endTs?: InputMaybe<Scalars['Int']['input']>;
  serverId: Scalars['ID']['input'];
  startTs?: InputMaybe<Scalars['Int']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetLogStatsArgs = {
  databaseId?: InputMaybe<Scalars['ID']['input']>;
  endTs: Scalars['Int']['input'];
  serverId: Scalars['ID']['input'];
  startTs: Scalars['Int']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetOrganizationBillingArgs = {
  organizationSlug: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetOrganizationDetailsArgs = {
  organizationSlug: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetOrganizationMembershipCurrentUserArgs = {
  organizationSlug: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetOrganizationMembershipDetailsArgs = {
  membershipId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetOrganizationRoleDetailsArgs = {
  roleId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetOrganizationRolesArgs = {
  organizationSlug: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetPostgresRoleDetailsArgs = {
  postgresRoleId: Scalars['String']['input'];
  serverId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetPostgresRolesArgs = {
  serverId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetPostgresServerStatsArgs = {
  endTs: Scalars['Int']['input'];
  serverId: Scalars['ID']['input'];
  startTs: Scalars['Int']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetPostgresSettingDetailsArgs = {
  databaseId?: InputMaybe<Scalars['ID']['input']>;
  postgresSettingName: Scalars['String']['input'];
  serverId?: InputMaybe<Scalars['ID']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetPostgresSettingsArgs = {
  databaseId?: InputMaybe<Scalars['ID']['input']>;
  patterns?: InputMaybe<Array<Scalars['String']['input']>>;
  serverId?: InputMaybe<Scalars['ID']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetQueryDetailStatsArgs = {
  databaseId: Scalars['ID']['input'];
  endTs: Scalars['Int']['input'];
  queryId: Scalars['ID']['input'];
  startTs: Scalars['Int']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetQueryDetailSummaryStatsArgs = {
  compare?: InputMaybe<Scalars['Boolean']['input']>;
  databaseId: Scalars['ID']['input'];
  endTs: Scalars['Int']['input'];
  queryId: Scalars['ID']['input'];
  startTs: Scalars['Int']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetQueryDetailsArgs = {
  databaseId: Scalars['ID']['input'];
  queryId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetQueryExplainArgs = {
  databaseId: Scalars['ID']['input'];
  explainId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetQueryExplainsArgs = {
  databaseId: Scalars['ID']['input'];
  endTs?: InputMaybe<Scalars['Int']['input']>;
  queryId: Scalars['ID']['input'];
  startTs?: InputMaybe<Scalars['Int']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetQueryExplainsForGraphArgs = {
  databaseId: Scalars['ID']['input'];
  endTs: Scalars['Int']['input'];
  queryId: Scalars['ID']['input'];
  startTs: Scalars['Int']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetQueryForWorkbookArgs = {
  baselineId?: InputMaybe<Scalars['ID']['input']>;
  databaseId: Scalars['ID']['input'];
  queryId?: InputMaybe<Scalars['ID']['input']>;
  queryText?: InputMaybe<Scalars['String']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetQueryIndexCheckArgs = {
  queryId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetQueryOverviewStatsArgs = {
  databaseId: Scalars['ID']['input'];
  endTs: Scalars['Int']['input'];
  startTs: Scalars['Int']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetQueryOverviewStatsByDatabaseArgs = {
  endTs: Scalars['Int']['input'];
  serverId: Scalars['ID']['input'];
  startTs: Scalars['Int']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetQueryPlanArgs = {
  databaseId: Scalars['ID']['input'];
  originalPlanId: Scalars['ID']['input'];
  queryId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetQueryPlanStatsArgs = {
  databaseId: Scalars['ID']['input'];
  endTs?: InputMaybe<Scalars['Int']['input']>;
  queryId: Scalars['ID']['input'];
  startTs?: InputMaybe<Scalars['Int']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetQueryPlansArgs = {
  databaseId: Scalars['ID']['input'];
  endTs?: InputMaybe<Scalars['Int']['input']>;
  queryId: Scalars['ID']['input'];
  startTs?: InputMaybe<Scalars['Int']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetQuerySamplesArgs = {
  databaseId: Scalars['ID']['input'];
  queryId?: InputMaybe<Scalars['ID']['input']>;
  workbookId?: InputMaybe<Scalars['ID']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetQueryStatsArgs = {
  compare?: InputMaybe<Scalars['Boolean']['input']>;
  databaseId: Scalars['ID']['input'];
  endTs?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  queryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  startTs?: InputMaybe<Scalars['Int']['input']>;
  statementTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};


/** The query root of this schema */
export type GraphqlQueryGetQueryStatsForTableArgs = {
  databaseId: Scalars['ID']['input'];
  endTs?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  startTs?: InputMaybe<Scalars['Int']['input']>;
  tableId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetQueryTagKeysArgs = {
  databaseId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetQueryTagSummaryForQueryArgs = {
  databaseId: Scalars['ID']['input'];
  queryId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetQueryTagsetsArgs = {
  databaseId: Scalars['ID']['input'];
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetRelatedIssuesArgs = {
  issueId: Scalars['ID']['input'];
  matchGroupOnly?: InputMaybe<Scalars['Boolean']['input']>;
  relatedBy: Array<Scalars['String']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetReplicationFollowerStatsArgs = {
  endTs: Scalars['Int']['input'];
  replicationFollowerId: Scalars['String']['input'];
  serverId: Scalars['ID']['input'];
  startTs: Scalars['Int']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetReplicationFollowersArgs = {
  serverId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaFunctionDetailsArgs = {
  databaseId: Scalars['ID']['input'];
  schemaFunctionId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaFunctionsArgs = {
  databaseId: Scalars['ID']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaIndexDetailsArgs = {
  databaseId: Scalars['ID']['input'];
  schemaIndexId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaIndexStatsArgs = {
  databaseId: Scalars['ID']['input'];
  endTs: Scalars['Int']['input'];
  schemaIndexId: Scalars['ID']['input'];
  startTs: Scalars['Int']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaIndicesArgs = {
  databaseId: Scalars['ID']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaTableAutovacuumSettingsArgs = {
  databaseId: Scalars['ID']['input'];
  schemaTableId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaTableBloatInfoArgs = {
  databaseId: Scalars['ID']['input'];
  endTs?: InputMaybe<Scalars['Int']['input']>;
  schemaTableId: Scalars['ID']['input'];
  startTs?: InputMaybe<Scalars['Int']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaTableColumnsArgs = {
  databaseId: Scalars['ID']['input'];
  endTs?: InputMaybe<Scalars['Int']['input']>;
  schemaTableId: Scalars['ID']['input'];
  startTs?: InputMaybe<Scalars['Int']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaTableConstraintsArgs = {
  databaseId: Scalars['ID']['input'];
  schemaTableId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaTableDetailsArgs = {
  databaseId: Scalars['ID']['input'];
  schemaTableId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaTableEventsArgs = {
  databaseId: Scalars['ID']['input'];
  endTs?: InputMaybe<Scalars['Int']['input']>;
  schemaTableId: Scalars['ID']['input'];
  startTs?: InputMaybe<Scalars['Int']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaTableExtendedStatsArgs = {
  databaseId: Scalars['ID']['input'];
  schemaTableId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaTableIndexAdvisorConfigArgs = {
  databaseId: Scalars['ID']['input'];
  schemaTableId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaTableIndexSelectionArgs = {
  databaseId?: InputMaybe<Scalars['ID']['input']>;
  options?: InputMaybe<Scalars['String']['input']>;
  preset?: InputMaybe<Scalars['String']['input']>;
  runId?: InputMaybe<Scalars['ID']['input']>;
  schemaTableId?: InputMaybe<Scalars['ID']['input']>;
  useConsolidation?: InputMaybe<Scalars['Boolean']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaTableIndicesArgs = {
  databaseId: Scalars['ID']['input'];
  schemaTableId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaTableListWithVacuumSettingsArgs = {
  databaseId?: InputMaybe<Scalars['ID']['input']>;
  serverId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaTableOptionsForSettingArgs = {
  postgresSettingName: Scalars['String']['input'];
  serverId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaTableScansArgs = {
  databaseId: Scalars['ID']['input'];
  endTs?: InputMaybe<Scalars['Int']['input']>;
  indexId?: InputMaybe<Scalars['ID']['input']>;
  startTs?: InputMaybe<Scalars['Int']['input']>;
  tableId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaTableScansForQueryArgs = {
  databaseId: Scalars['ID']['input'];
  queryId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaTableStatsArgs = {
  databaseId: Scalars['ID']['input'];
  endTs: Scalars['Int']['input'];
  schemaTableId: Scalars['ID']['input'];
  startTs: Scalars['Int']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaTableVacuumInfoArgs = {
  databaseId: Scalars['ID']['input'];
  endTs?: InputMaybe<Scalars['Int']['input']>;
  schemaTableId: Scalars['ID']['input'];
  startTs?: InputMaybe<Scalars['Int']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaTablesArgs = {
  databaseId: Scalars['ID']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
  includePartitions?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  parentTableId?: InputMaybe<Scalars['ID']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaTablesVacuumSummaryArgs = {
  endTs?: InputMaybe<Scalars['Int']['input']>;
  serverId: Scalars['ID']['input'];
  startTs?: InputMaybe<Scalars['Int']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaViewDetailsArgs = {
  databaseId: Scalars['ID']['input'];
  schemaViewId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetSchemaViewsArgs = {
  databaseId: Scalars['ID']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
};


/** The query root of this schema */
export type GraphqlQueryGetServerDetailsArgs = {
  databaseId?: InputMaybe<Scalars['ID']['input']>;
  serverId?: InputMaybe<Scalars['ID']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetServerFreezingStatsArgs = {
  databaseId?: InputMaybe<Scalars['ID']['input']>;
  endTs: Scalars['Int']['input'];
  serverId: Scalars['ID']['input'];
  startTs: Scalars['Int']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetServerFreezingStatsByTableArgs = {
  databaseId?: InputMaybe<Scalars['ID']['input']>;
  serverId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetServerXactPerSecArgs = {
  endTs: Scalars['Int']['input'];
  serverId: Scalars['ID']['input'];
  startTs: Scalars['Int']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetServerXminHorizonArgs = {
  endTs: Scalars['Int']['input'];
  serverId: Scalars['ID']['input'];
  startTs: Scalars['Int']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetServersArgs = {
  organizationSlug?: InputMaybe<Scalars['ID']['input']>;
  systemId?: InputMaybe<Scalars['String']['input']>;
  systemScope?: InputMaybe<Scalars['String']['input']>;
  systemType?: InputMaybe<Scalars['String']['input']>;
  withRecentData?: InputMaybe<Scalars['Boolean']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetSnapshotDetailsArgs = {
  collectedAt: Scalars['Int']['input'];
  kind: Scalars['String']['input'];
  serverId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetSnapshotsArgs = {
  serverId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetSummaryStatsByDatabaseArgs = {
  organizationSlug?: InputMaybe<Scalars['ID']['input']>;
  serverId?: InputMaybe<Scalars['ID']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetSummaryStatsByServerArgs = {
  organizationSlug: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetSystemDetailsArgs = {
  databaseId?: InputMaybe<Scalars['ID']['input']>;
  serverId?: InputMaybe<Scalars['ID']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetSystemStatsArgs = {
  endTs: Scalars['Int']['input'];
  serverId: Scalars['ID']['input'];
  startTs: Scalars['Int']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetVacuumInsightStatusArgs = {
  serverId: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetVacuumRunDetailsArgs = {
  serverId: Scalars['ID']['input'];
  vacuumRunIdentity: Scalars['ID']['input'];
};


/** The query root of this schema */
export type GraphqlQueryGetVacuumRunsArgs = {
  databaseId?: InputMaybe<Scalars['ID']['input']>;
  endTs?: InputMaybe<Scalars['Int']['input']>;
  finished?: InputMaybe<Scalars['Boolean']['input']>;
  minDurationSecs?: InputMaybe<Scalars['Int']['input']>;
  serverId: Scalars['ID']['input'];
  startTs?: InputMaybe<Scalars['Int']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetVacuumSimulatorInputArgs = {
  databaseId: Scalars['ID']['input'];
  endTs?: InputMaybe<Scalars['Int']['input']>;
  schemaTableId: Scalars['ID']['input'];
  startTs?: InputMaybe<Scalars['Int']['input']>;
};


/** The query root of this schema */
export type GraphqlQueryGetWaitEventCountsArgs = {
  databaseId: Scalars['ID']['input'];
  endTs?: InputMaybe<Scalars['Int']['input']>;
  startTs?: InputMaybe<Scalars['Int']['input']>;
};

export type IndexAdvisorConfigForTable = {
  __typename?: 'IndexAdvisorConfigForTable';
  defaultPreset?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastRunAt?: Maybe<Scalars['Int']['output']>;
  parentTableId?: Maybe<Scalars['ID']['output']>;
  permittedToEdit: Scalars['Boolean']['output'];
  queries?: Maybe<Scalars['Int']['output']>;
  scansPerMin?: Maybe<Scalars['Float']['output']>;
  schemaName: Scalars['String']['output'];
  settings?: Maybe<Scalars['JSON']['output']>;
  settingsName: Scalars['String']['output'];
  tableName: Scalars['String']['output'];
  tableSize?: Maybe<Scalars['Int']['output']>;
  writesPerMin?: Maybe<Scalars['Float']['output']>;
};

export type IndexAdvisorStatus = {
  __typename?: 'IndexAdvisorStatus';
  analyzedCount: Scalars['Int']['output'];
  analyzedQueriesPercentage: Scalars['Float']['output'];
  analyzedWithoutErrors: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  lastProcessed?: Maybe<Scalars['Int']['output']>;
  processedTables: Scalars['Int']['output'];
  queriesWithErrors: Array<QueryAnalysisError>;
  tableStats: Array<QueryAnalysisTableStats>;
  totalTables: Scalars['Int']['output'];
};

export type IndexSelection = {
  __typename?: 'IndexSelection';
  data: Scalars['JSON']['output'];
  runAt: Scalars['Int']['output'];
  scans: Array<IndexSelectionScanDetail>;
};

/** Scan included in one or more queries referencing the table */
export type IndexSelectionScanDetail = {
  __typename?: 'IndexSelectionScanDetail';
  avgCallsPerMinute: Scalars['Float']['output'];
  combinedExpression: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  joinExpression?: Maybe<Scalars['String']['output']>;
  parameterizedScanExpected?: Maybe<Scalars['Boolean']['output']>;
  queries: Array<QuerySummaryStats>;
  whereExpression?: Maybe<Scalars['String']['output']>;
};

/** Billing invoice for an organization */
export type Invoice = {
  __typename?: 'Invoice';
  created: Scalars['Int']['output'];
  formattedTotal: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoicePdf?: Maybe<Scalars['String']['output']>;
  reference: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

/** A check-up issue */
export type Issue = {
  __typename?: 'Issue';
  activity: Array<IssueActivity>;
  checkConfig: CheckConfig;
  checkGroupAndName: Scalars['String']['output'];
  createdAt: Scalars['Int']['output'];
  database?: Maybe<Database>;
  databaseId?: Maybe<Scalars['ID']['output']>;
  description: Scalars['String']['output'];
  descriptionReferences: Array<IssueDescriptionReference>;
  descriptionTemplate: Scalars['String']['output'];
  detailsJson: Scalars['JSON_legacy']['output'];
  /** @deprecated Issues may now be associated with multiple objects; see references field. */
  displayName: Scalars['String']['output'];
  groupingKey: Scalars['JSON_legacy']['output'];
  id: Scalars['ID']['output'];
  permittedToModify: Scalars['Boolean']['output'];
  queryText?: Maybe<Scalars['String']['output']>;
  /** @deprecated Issues may now be associated with multiple objects; see references field. */
  reference?: Maybe<IssueReference>;
  references: Array<IssueReference>;
  server: Server;
  severity: Scalars['String']['output'];
  state: Scalars['String']['output'];
  updatedAt: Scalars['Int']['output'];
};

/** A set of notifications about an issue */
export type IssueActivity = {
  __typename?: 'IssueActivity';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  kind: ActivityKind;
  occurredAt: Scalars['Int']['output'];
  severity?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type IssueDescriptionReference = {
  __typename?: 'IssueDescriptionReference';
  name: Scalars['String']['output'];
  param: Scalars['String']['output'];
  path?: Maybe<Scalars['String']['output']>;
};

/** Information about the reference that's attached to an issue */
export type IssueReference = {
  __typename?: 'IssueReference';
  createdAt: Scalars['Int']['output'];
  detailsJson: Scalars['JSON_legacy']['output'];
  kind: Scalars['String']['output'];
  name: Scalars['String']['output'];
  queryText?: Maybe<Scalars['String']['output']>;
  referent?: Maybe<IssueReferenceDetail>;
  referentId: Scalars['String']['output'];
  resolvedAt?: Maybe<Scalars['Int']['output']>;
  /** @deprecated This field is now always null. */
  secondaryUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** Detailed information about object linked by an issue reference */
export type IssueReferenceDetail = Backend | Database | PostgresSetting | Query | ReplicationFollower | SchemaIndex | SchemaTable | SchemaTableScan | Server | VacuumRun;

/** A summary of activity for an issue type */
export type IssueSummary = {
  __typename?: 'IssueSummary';
  checkGroupAndName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  newCount: Scalars['Int']['output'];
  resolvedCount: Scalars['Int']['output'];
};

/** Log Analysis for Checkpoint Starting events */
export type LogAnalysisCheckpointStartingResult = {
  __typename?: 'LogAnalysisCheckpointStartingResult';
  id: Scalars['ID']['output'];
  reasons: Array<LogStats>;
};

/** Log Analysis Bucket for Temp File Created events */
export type LogAnalysisTempFileCreatedBucket = {
  __typename?: 'LogAnalysisTempFileCreatedBucket';
  avgSize: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  query?: Maybe<Query>;
  timebucket: Scalars['Int']['output'];
};

/** Log Analysis for Temp File Created events */
export type LogAnalysisTempFileCreatedResult = {
  __typename?: 'LogAnalysisTempFileCreatedResult';
  buckets: Array<LogAnalysisTempFileCreatedBucket>;
  id: Scalars['ID']['output'];
};

/** A log line */
export type LogLine = {
  __typename?: 'LogLine';
  backendPid?: Maybe<Scalars['Int']['output']>;
  childText?: Maybe<Scalars['String']['output']>;
  databaseId?: Maybe<Scalars['ID']['output']>;
  details?: Maybe<Scalars['JSON_legacy']['output']>;
  logClassification?: Maybe<Scalars['String']['output']>;
  logLevel: Scalars['String']['output'];
  logText?: Maybe<Scalars['String']['output']>;
  occurredAt: Scalars['Float']['output'];
  postgresRoleId?: Maybe<Scalars['String']['output']>;
  postgresRoleName?: Maybe<Scalars['String']['output']>;
  queryId?: Maybe<Scalars['ID']['output']>;
  serverId: Scalars['String']['output'];
};

/** Log quota info for a given server and time period */
export type LogQuotaUsage = {
  __typename?: 'LogQuotaUsage';
  /** Total log lines discarded by pganalyze due to exceeding the quota */
  bytesOverQuota: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** Initial quota granted to this server */
  quotaMaxBytes: Scalars['Int']['output'];
  /** Period of time, in seconds, over which quota refills up to max */
  quotaRefillPeriod: Scalars['Int']['output'];
};

/** Log statistics item */
export type LogStats = {
  __typename?: 'LogStats';
  classification?: Maybe<Scalars['String']['output']>;
  count: Scalars['Int']['output'];
  timebucket: Scalars['Int']['output'];
};

export type LogStatsResult = {
  __typename?: 'LogStatsResult';
  id: Scalars['ID']['output'];
  stats: Array<LogStats>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptOrganizationInvitation: Organization;
  activateBillingPlan: ActivateBillingPlanPayload;
  addServer?: Maybe<AddServerPayload>;
  cancelBillingPlan: CancelBillingPlanPayload;
  configureServerNotifications?: Maybe<Server>;
  createExplainCollectorRun: ExplainQuery;
  createExplainParameterSets?: Maybe<ExplainWorkbook>;
  createExplainQuery: ExplainQuery;
  createExplainResult: ExplainQuery;
  createExplainWorkbook: CreateExplainWorkbookPayload;
  createSupportTicket: CreateSupportTicketPayload;
  deleteExplainParameterSets?: Maybe<ExplainWorkbook>;
  deleteExplainQuery?: Maybe<DeleteExplainQueryPayload>;
  deleteExplainResults?: Maybe<DeleteExplainResultsPayload>;
  deleteExplainWorkbook: DeleteExplainWorkbookPayload;
  deleteOrganizationMember: DeleteOrganizationMemberPayload;
  deleteServer: DeleteServerPayload;
  payInvoice: PayInvoicePayload;
  resetOrganizationIntegration?: Maybe<Organization>;
  testLocalCollector?: Maybe<TestLocalCollectorPayload>;
  testPagerDutyIntegration?: Maybe<TestPagerDutyIntegrationPayload>;
  testSlackIntegration?: Maybe<TestSlackIntegrationPayload>;
  updateBillingCard: UpdateBillingCardPayload;
  updateBillingDetails: UpdateBillingDetailsPayload;
  updateExplainParameterSets?: Maybe<ExplainWorkbook>;
  updateExplainQuery: UpdateExplainQueryPayload;
  updateExplainWorkbook: UpdateExplainWorkbookPayload;
  updateIndexingEngineSelectionOptionOverride?: Maybe<IndexAdvisorConfigForTable>;
  updateIntegrationStatus?: Maybe<Organization>;
  updateIssueState?: Maybe<Issue>;
  updateOrganizationDefaultSamlRole: Organization;
  updateOrganizationSamlIntegration: Organization;
  updateServer: UpdateServerPayload;
};


export type MutationAcceptOrganizationInvitationArgs = {
  inviteToken: Scalars['ID']['input'];
  organizationSlug: Scalars['ID']['input'];
};


export type MutationActivateBillingPlanArgs = {
  billingAddressCity?: InputMaybe<Scalars['String']['input']>;
  billingAddressCountry?: InputMaybe<Scalars['String']['input']>;
  billingAddressLine1?: InputMaybe<Scalars['String']['input']>;
  billingAddressLine2?: InputMaybe<Scalars['String']['input']>;
  billingAddressState?: InputMaybe<Scalars['String']['input']>;
  billingAddressZipCode?: InputMaybe<Scalars['String']['input']>;
  billingCompanyName?: InputMaybe<Scalars['String']['input']>;
  billingEmail?: InputMaybe<Scalars['String']['input']>;
  billingVatId?: InputMaybe<Scalars['String']['input']>;
  newPlanId: Scalars['String']['input'];
  newTotalServerCount: Scalars['Int']['input'];
  organizationSlug: Scalars['ID']['input'];
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddServerArgs = {
  awsAssumeRole?: InputMaybe<Scalars['String']['input']>;
  awsDbClusterId?: InputMaybe<Scalars['String']['input']>;
  awsDbInstanceId?: InputMaybe<Scalars['String']['input']>;
  awsRegion?: InputMaybe<Scalars['String']['input']>;
  azureAdClientId?: InputMaybe<Scalars['String']['input']>;
  azureDbServerName?: InputMaybe<Scalars['String']['input']>;
  azureEventhubName?: InputMaybe<Scalars['String']['input']>;
  azureEventhubNamespace?: InputMaybe<Scalars['String']['input']>;
  azureSubscriptionId?: InputMaybe<Scalars['String']['input']>;
  collectAllDatabases?: InputMaybe<Scalars['Boolean']['input']>;
  dbHost: Scalars['String']['input'];
  dbName: Scalars['String']['input'];
  dbPassword?: InputMaybe<Scalars['String']['input']>;
  dbPort?: InputMaybe<Scalars['Int']['input']>;
  dbSslmode?: InputMaybe<Scalars['String']['input']>;
  dbUseIamAuth?: InputMaybe<Scalars['Boolean']['input']>;
  dbUsername: Scalars['String']['input'];
  enableActivity?: InputMaybe<Scalars['Boolean']['input']>;
  enableLogs?: InputMaybe<Scalars['Boolean']['input']>;
  filterLogSecret?: InputMaybe<Scalars['String']['input']>;
  filterQuerySample?: InputMaybe<Scalars['String']['input']>;
  filterQueryText?: InputMaybe<Scalars['String']['input']>;
  gcpAlloydbClusterId?: InputMaybe<Scalars['String']['input']>;
  gcpAlloydbInstanceId?: InputMaybe<Scalars['String']['input']>;
  gcpCloudsqlInstanceId?: InputMaybe<Scalars['String']['input']>;
  gcpProjectId?: InputMaybe<Scalars['String']['input']>;
  gcpPubsubSubscription?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  systemType?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCancelBillingPlanArgs = {
  organizationSlug: Scalars['ID']['input'];
};


export type MutationConfigureServerNotificationsArgs = {
  notificationConfig: NotificationConfigAttributes;
  serverId: Scalars['ID']['input'];
};


export type MutationCreateExplainCollectorRunArgs = {
  explainQueryId: Scalars['ID']['input'];
};


export type MutationCreateExplainParameterSetsArgs = {
  databaseId: Scalars['ID']['input'];
  explainQueryId: Scalars['ID']['input'];
  finalize?: InputMaybe<Scalars['Boolean']['input']>;
  parameterSet?: InputMaybe<Scalars['JSON']['input']>;
  querySampleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  queryText?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateExplainQueryArgs = {
  baselineExplainQueryId: Scalars['ID']['input'];
  databaseId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  plannerSettings: Scalars['JSON']['input'];
  queryText: Scalars['String']['input'];
  workbookId: Scalars['ID']['input'];
};


export type MutationCreateExplainResultArgs = {
  explainJson?: InputMaybe<Scalars['String']['input']>;
  explainQueryId: Scalars['ID']['input'];
  explainText?: InputMaybe<Scalars['String']['input']>;
  parameterSetId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCreateExplainWorkbookArgs = {
  databaseId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  newAliases?: InputMaybe<Scalars['JSON']['input']>;
  normalizedQuery: Scalars['String']['input'];
  queryText: Scalars['String']['input'];
};


export type MutationCreateSupportTicketArgs = {
  grantAccessToAccount: Scalars['Boolean']['input'];
  referencedOrganizationSlug?: InputMaybe<Scalars['String']['input']>;
  referencedServerId?: InputMaybe<Scalars['String']['input']>;
  referencedUrl?: InputMaybe<Scalars['String']['input']>;
  ticketBody: Scalars['String']['input'];
  ticketCategory: Scalars['String']['input'];
  ticketSubject: Scalars['String']['input'];
};


export type MutationDeleteExplainParameterSetsArgs = {
  parameterSetId: Scalars['ID']['input'];
  workbookId: Scalars['ID']['input'];
};


export type MutationDeleteExplainQueryArgs = {
  explainQueryId: Scalars['ID']['input'];
};


export type MutationDeleteExplainResultsArgs = {
  explainQueryId: Scalars['ID']['input'];
  parameterSetsSelected: Scalars['Boolean']['input'];
};


export type MutationDeleteExplainWorkbookArgs = {
  workbookId: Scalars['ID']['input'];
};


export type MutationDeleteOrganizationMemberArgs = {
  membershipId: Scalars['ID']['input'];
};


export type MutationDeleteServerArgs = {
  serverId: Scalars['ID']['input'];
};


export type MutationPayInvoiceArgs = {
  invoiceId: Scalars['String']['input'];
  organizationSlug: Scalars['ID']['input'];
};


export type MutationResetOrganizationIntegrationArgs = {
  integrationId: Scalars['ID']['input'];
};


export type MutationTestLocalCollectorArgs = {
  serverId: Scalars['ID']['input'];
};


export type MutationTestPagerDutyIntegrationArgs = {
  organizationSlug: Scalars['ID']['input'];
  serviceId: Scalars['ID']['input'];
};


export type MutationTestSlackIntegrationArgs = {
  channelId: Scalars['ID']['input'];
  organizationSlug: Scalars['ID']['input'];
};


export type MutationUpdateBillingCardArgs = {
  organizationSlug: Scalars['ID']['input'];
  paymentMethodId: Scalars['String']['input'];
};


export type MutationUpdateBillingDetailsArgs = {
  billingAddressCity?: InputMaybe<Scalars['String']['input']>;
  billingAddressCountry?: InputMaybe<Scalars['String']['input']>;
  billingAddressLine1?: InputMaybe<Scalars['String']['input']>;
  billingAddressLine2?: InputMaybe<Scalars['String']['input']>;
  billingAddressState?: InputMaybe<Scalars['String']['input']>;
  billingAddressZipCode?: InputMaybe<Scalars['String']['input']>;
  billingCompanyName?: InputMaybe<Scalars['String']['input']>;
  billingEmail?: InputMaybe<Scalars['String']['input']>;
  billingVatId?: InputMaybe<Scalars['String']['input']>;
  organizationSlug: Scalars['ID']['input'];
};


export type MutationUpdateExplainParameterSetsArgs = {
  parameterSets: Scalars['JSON']['input'];
  workbookId: Scalars['ID']['input'];
};


export type MutationUpdateExplainQueryArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  explainQueryId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateExplainWorkbookArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameterRefAliases?: InputMaybe<Array<Scalars['String']['input']>>;
  parameterSetTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  workbookId: Scalars['ID']['input'];
};


export type MutationUpdateIndexingEngineSelectionOptionOverrideArgs = {
  databaseId: Scalars['ID']['input'];
  preset?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['String']['input']>;
  tableId: Scalars['ID']['input'];
  useConsolidation?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateIntegrationStatusArgs = {
  organizationSlug: Scalars['ID']['input'];
  step: Scalars['String']['input'];
};


export type MutationUpdateIssueStateArgs = {
  issueId: Scalars['ID']['input'];
  newState: Scalars['String']['input'];
};


export type MutationUpdateOrganizationDefaultSamlRoleArgs = {
  organizationRoleId: Scalars['ID']['input'];
  organizationSlug: Scalars['ID']['input'];
};


export type MutationUpdateOrganizationSamlIntegrationArgs = {
  config: OrganizationSamlConfig;
  organizationRoleId?: InputMaybe<Scalars['ID']['input']>;
  organizationSlug: Scalars['ID']['input'];
};


export type MutationUpdateServerArgs = {
  awsAssumeRole?: InputMaybe<Scalars['String']['input']>;
  awsDbClusterId?: InputMaybe<Scalars['String']['input']>;
  awsDbInstanceId?: InputMaybe<Scalars['String']['input']>;
  awsRegion?: InputMaybe<Scalars['String']['input']>;
  dbHost?: InputMaybe<Scalars['String']['input']>;
  dbName?: InputMaybe<Scalars['String']['input']>;
  dbPassword?: InputMaybe<Scalars['String']['input']>;
  dbPort?: InputMaybe<Scalars['Int']['input']>;
  dbSslmode?: InputMaybe<Scalars['String']['input']>;
  dbUseIamAuth?: InputMaybe<Scalars['Boolean']['input']>;
  dbUsername?: InputMaybe<Scalars['String']['input']>;
  enableActivity?: InputMaybe<Scalars['Boolean']['input']>;
  enableLogs?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  serverId: Scalars['ID']['input'];
  statementResetFrequency?: InputMaybe<Scalars['Int']['input']>;
  statementTimeoutMs?: InputMaybe<Scalars['Int']['input']>;
  statementTimeoutMsQueryText?: InputMaybe<Scalars['Int']['input']>;
};

/** A configuration for notifications */
export type NotificationConfig = {
  __typename?: 'NotificationConfig';
  id: Scalars['ID']['output'];
  pagerduty?: Maybe<PagerdutyNotificationConfig>;
  slack?: Maybe<SlackNotificationConfig>;
};

/** Attributes for configuring notifications */
export type NotificationConfigAttributes = {
  /** PagerDuty configuration */
  pagerduty?: InputMaybe<PagerDutyNotificationConfigAttributes>;
  /** Slack configuration */
  slack?: InputMaybe<SlackNotificationConfigAttributes>;
};

/** An organization */
export type Organization = {
  __typename?: 'Organization';
  createdAt: Scalars['Int']['output'];
  defaultSamlRole?: Maybe<OrganizationRole>;
  enabledFeatures: EnabledFeatures;
  githubBranch?: Maybe<Scalars['String']['output']>;
  githubRepository?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  indexAdvisorTrialEndsAt?: Maybe<Scalars['Int']['output']>;
  memberships: Array<OrganizationMembership>;
  name: Scalars['String']['output'];
  onboardingProgress: OrganizationOnboardingProgress;
  pagerdutyIntegration?: Maybe<PagerdutyIntegration>;
  pastDue: Scalars['Boolean']['output'];
  permittedToBilling: Scalars['Boolean']['output'];
  permittedToCreateApiKeys: Scalars['Boolean']['output'];
  permittedToCreateRWApiKeys: Scalars['Boolean']['output'];
  permittedToCreateRoles: Scalars['Boolean']['output'];
  permittedToCreateServers: Scalars['Boolean']['output'];
  permittedToEditOrganization: Scalars['Boolean']['output'];
  permittedToInviteMembers: Scalars['Boolean']['output'];
  planInfo: OrganizationPlanInfo;
  primaryWithRecentDataCount: Scalars['Int']['output'];
  replicaBillingMultiplier: Scalars['Float']['output'];
  replicaWithRecentDataCount: Scalars['Int']['output'];
  samlIntegration?: Maybe<SamlIntegration>;
  serverCount: Scalars['Int']['output'];
  serverCountForBilling: Scalars['Int']['output'];
  serverLimit?: Maybe<Scalars['Int']['output']>;
  servers: Array<Server>;
  skipServerOverageWarning: Scalars['Boolean']['output'];
  slackIntegration?: Maybe<SlackIntegration>;
  slug: Scalars['String']['output'];
  subscriptionExtraServers: Scalars['Int']['output'];
  userCount: Scalars['Int']['output'];
  userLimit?: Maybe<Scalars['Int']['output']>;
  visibleServerCount: Scalars['Int']['output'];
};

/** Billing information for an organization */
export type OrganizationBilling = {
  __typename?: 'OrganizationBilling';
  billingAddressCity?: Maybe<Scalars['String']['output']>;
  billingAddressCountry?: Maybe<Scalars['String']['output']>;
  billingAddressLine1?: Maybe<Scalars['String']['output']>;
  billingAddressLine2?: Maybe<Scalars['String']['output']>;
  billingAddressState?: Maybe<Scalars['String']['output']>;
  billingAddressWithName?: Maybe<Scalars['String']['output']>;
  billingAddressZipCode?: Maybe<Scalars['String']['output']>;
  billingCompanyName?: Maybe<Scalars['String']['output']>;
  billingCurrency: Scalars['String']['output'];
  billingEmail?: Maybe<Scalars['String']['output']>;
  billingVatId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoices: Array<Invoice>;
  paymentRequiresAction: Scalars['Boolean']['output'];
  planChoices: Array<OrganizationPlanChoice>;
  stripeClientSecret?: Maybe<Scalars['String']['output']>;
  subscriptionActiveInStripe: Scalars['Boolean']['output'];
  subscriptionBilledManually: Scalars['Boolean']['output'];
  subscriptionCardExpMonth?: Maybe<Scalars['Int']['output']>;
  subscriptionCardExpYear?: Maybe<Scalars['Int']['output']>;
  subscriptionCardLast4?: Maybe<Scalars['String']['output']>;
  subscriptionCardOnFile: Scalars['Boolean']['output'];
  subscriptionCardType?: Maybe<Scalars['String']['output']>;
  subscriptionInterval?: Maybe<SubscriptionInterval>;
  subscriptionNextChargeAmount?: Maybe<Scalars['Int']['output']>;
  subscriptionNextChargeAt?: Maybe<Scalars['Int']['output']>;
  subscriptionNextChargeCurrency?: Maybe<Scalars['String']['output']>;
};

/** A membership of a user in an organization */
export type OrganizationMembership = {
  __typename?: 'OrganizationMembership';
  accepted: Scalars['Boolean']['output'];
  alertPolicy: AlertPolicy;
  createdAt: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inviteEmail?: Maybe<Scalars['String']['output']>;
  invitePath?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<Array<Maybe<OrganizationPermission>>>;
  permittedToEdit: Scalars['Boolean']['output'];
  permittedToRemove: Scalars['Boolean']['output'];
  roles?: Maybe<Array<Maybe<OrganizationRole>>>;
  samlUid?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Int']['output'];
  user?: Maybe<User>;
  userIsSelf: Scalars['Boolean']['output'];
};

/** Information about the initial pganalyze product onboarding */
export type OrganizationOnboardingProgress = {
  __typename?: 'OrganizationOnboardingProgress';
  hasDatabasesIntegrated: Scalars['Boolean']['output'];
  hasRecentData: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  integratedCollector: Scalars['Boolean']['output'];
  integratedEmailAlerts: Scalars['Boolean']['output'];
  integratedExplain: Scalars['Boolean']['output'];
  integratedLogInsights: Scalars['Boolean']['output'];
  lastIntegratedServer?: Maybe<Server>;
};

/** A permission for a membership of a user in an organization */
export type OrganizationPermission = {
  __typename?: 'OrganizationPermission';
  api: Scalars['Boolean']['output'];
  billing: Scalars['Boolean']['output'];
  createdAt: Scalars['Int']['output'];
  database?: Maybe<Database>;
  databaseId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  manage: Scalars['Boolean']['output'];
  modify: Scalars['Boolean']['output'];
  restricted: Scalars['Boolean']['output'];
  role: OrganizationRole;
  roleId: Scalars['String']['output'];
  server?: Maybe<Server>;
  serverId?: Maybe<Scalars['String']['output']>;
  tuneQueries: Scalars['Boolean']['output'];
  updatedAt: Scalars['Int']['output'];
  view: Scalars['Boolean']['output'];
};

/** Possible subscription plan choice for an organization */
export type OrganizationPlanChoice = {
  __typename?: 'OrganizationPlanChoice';
  billingCurrencyMonthlyPrice: Scalars['Int']['output'];
  billingCurrencyServerOveragePrice?: Maybe<Scalars['Int']['output']>;
  formattedMonthlyPrice: Scalars['String']['output'];
  formattedServerOveragePrice?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isCurrent: Scalars['Boolean']['output'];
  isDowngrade: Scalars['Boolean']['output'];
  isHidden: Scalars['Boolean']['output'];
  isSelfServe: Scalars['Boolean']['output'];
  marketingLines: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notIncludedMarketingLines: Array<Scalars['String']['output']>;
  replicaBillingMultiplier: Scalars['Float']['output'];
  serverCountForBilling: Scalars['Int']['output'];
  serversIncluded?: Maybe<Scalars['Int']['output']>;
};

/** Billing plan information that is accessible to all members of the organization */
export type OrganizationPlanInfo = {
  __typename?: 'OrganizationPlanInfo';
  activeSubscription: Scalars['Boolean']['output'];
  canceledSubscription: Scalars['Boolean']['output'];
  expiredTrial: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isTrial: Scalars['Boolean']['output'];
  subscriptionCancelationEffectiveAt?: Maybe<Scalars['Int']['output']>;
  subscriptionCancelationPending: Scalars['Boolean']['output'];
  subscriptionPlanId: Scalars['String']['output'];
  subscriptionPlanName: Scalars['String']['output'];
  supportsExtraServers: Scalars['Boolean']['output'];
  supportsLitePlan: Scalars['Boolean']['output'];
  trialEndsAt?: Maybe<Scalars['Int']['output']>;
};

/** A role in an organization */
export type OrganizationRole = {
  __typename?: 'OrganizationRole';
  assignedMemberships: Array<OrganizationMembership>;
  assignmentCount: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissionCount: Scalars['Int']['output'];
  permissions: Array<OrganizationPermission>;
  permittedToEditRole: Scalars['Boolean']['output'];
  permittedToRemoveRole: Scalars['Boolean']['output'];
  updatedAt: Scalars['Int']['output'];
  validMemberships: Array<OrganizationMembership>;
};

/** Attributes for configuring SAML */
export type OrganizationSamlConfig = {
  idpCert?: InputMaybe<Scalars['String']['input']>;
  idpEntityId?: InputMaybe<Scalars['String']['input']>;
  idpMetadataUrl?: InputMaybe<Scalars['String']['input']>;
  idpSsoServiceUrl?: InputMaybe<Scalars['String']['input']>;
};

/** Attributes for configuring PagerDuty alerts */
export type PagerDutyNotificationConfigAttributes = {
  /** List of checks to enable PagerDuty alerts for */
  checks: Array<Scalars['String']['input']>;
  /** Id of PagerDuty service to send alerts to (or null to disable) */
  serviceId?: InputMaybe<Scalars['String']['input']>;
  /** Name of PagerDuty service to send alerts to */
  serviceName?: InputMaybe<Scalars['String']['input']>;
};

/** An integration with PagerDuty */
export type PagerdutyIntegration = {
  __typename?: 'PagerdutyIntegration';
  authInvalid: Scalars['Boolean']['output'];
  availableServices: Array<PagerdutyService>;
  createdAt: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  integratedBy: User;
  kind: Scalars['String']['output'];
  updatedAt: Scalars['Int']['output'];
};

/** Attributes for configuring PagerDuty alerts */
export type PagerdutyNotificationConfig = {
  __typename?: 'PagerdutyNotificationConfig';
  /** List of checks for which PageDuty alerts are enabled */
  checks: Array<Scalars['String']['output']>;
  /** Id of PageDuty service alerts will be sent to (null if disabled) */
  serviceId?: Maybe<Scalars['String']['output']>;
  /** Name of PageDuty service alerts will be sent to */
  serviceName?: Maybe<Scalars['String']['output']>;
};

/** PagerDuty service */
export type PagerdutyService = {
  __typename?: 'PagerdutyService';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ParameterSet = {
  __typename?: 'ParameterSet';
  createdAt: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  paramValues: Scalars['JSON']['output'];
};

/** Autogenerated return type of PayInvoice. */
export type PayInvoicePayload = {
  __typename?: 'PayInvoicePayload';
  organization: Organization;
  organizationBilling: OrganizationBilling;
};

/** Role in a Postgres server */
export type PostgresRole = {
  __typename?: 'PostgresRole';
  config?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  connectionLimit?: Maybe<Scalars['Int']['output']>;
  createDb?: Maybe<Scalars['Boolean']['output']>;
  createRole?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  inherit?: Maybe<Scalars['Boolean']['output']>;
  login?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  passwordValidUntil?: Maybe<Scalars['Int']['output']>;
  replication?: Maybe<Scalars['Boolean']['output']>;
  superUser?: Maybe<Scalars['Boolean']['output']>;
};

/** Statistics for the current Postgres server */
export type PostgresServerStats = {
  __typename?: 'PostgresServerStats';
  collectedAt: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  pgStatStatementsDealloc?: Maybe<Scalars['Int']['output']>;
  pgStatStatementsReset?: Maybe<Scalars['Int']['output']>;
  postgresServerStatsId: Scalars['ID']['output'];
  primaryWalLsn?: Maybe<Scalars['String']['output']>;
  standbyApplyByteLag?: Maybe<Scalars['Float']['output']>;
  standbyIsStreaming?: Maybe<Scalars['Boolean']['output']>;
  standbyReceiveLsn?: Maybe<Scalars['String']['output']>;
  standbyReplayLsn?: Maybe<Scalars['String']['output']>;
  standbyReplayTimestamp?: Maybe<Scalars['Float']['output']>;
  standbyReplayTimestampAge?: Maybe<Scalars['Int']['output']>;
};

/** Config setting in a Postgres server */
export type PostgresSetting = {
  __typename?: 'PostgresSetting';
  bootValuePretty?: Maybe<Scalars['String']['output']>;
  docSnippetHtml?: Maybe<Scalars['String']['output']>;
  enumvals?: Maybe<Array<Scalars['String']['output']>>;
  group?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  maxVal?: Maybe<Scalars['Float']['output']>;
  minVal?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  resetValue?: Maybe<Scalars['String']['output']>;
  resetValuePretty?: Maybe<Scalars['String']['output']>;
  resetValuePrettyNoDelimiter?: Maybe<Scalars['String']['output']>;
  shortDesc?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  sourceFile?: Maybe<Scalars['String']['output']>;
  sourceLine?: Maybe<Scalars['Int']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  vartype?: Maybe<Scalars['String']['output']>;
};

/** Version of a Postgres server */
export type PostgresVersion = {
  __typename?: 'PostgresVersion';
  humanVersion: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

/** A specific query running in the database */
export type Query = {
  __typename?: 'Query';
  databaseId: Scalars['ID']['output'];
  fingerprint: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  normalizedQuery: Scalars['String']['output'];
  normalizedQueryScanTokens?: Maybe<Array<QueryScanToken>>;
  postgresRole?: Maybe<PostgresRole>;
  queryAnalysis: Scalars['JSON_legacy']['output'];
  recentLogLinesCount?: Maybe<Scalars['Int']['output']>;
  recentQueryExplainsCount?: Maybe<Scalars['Int']['output']>;
  recentQuerySamplesCount?: Maybe<Scalars['Int']['output']>;
  recentQueryTagsetCount?: Maybe<Scalars['Int']['output']>;
  supportQueryTuning: Scalars['Boolean']['output'];
  truncatedQuery: Scalars['String']['output'];
};

export type QueryAnalysisError = {
  __typename?: 'QueryAnalysisError';
  error: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  normalizedQuery?: Maybe<Scalars['String']['output']>;
  pctOfTotal: Scalars['Float']['output'];
  postgresRole?: Maybe<PostgresRole>;
  tableNames?: Maybe<Array<Scalars['String']['output']>>;
  truncatedQuery?: Maybe<Scalars['String']['output']>;
};

export type QueryAnalysisTableStats = {
  __typename?: 'QueryAnalysisTableStats';
  failed?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  insightsCount?: Maybe<Scalars['Int']['output']>;
  lastRun?: Maybe<Scalars['Int']['output']>;
  scansCount?: Maybe<Scalars['Int']['output']>;
  tableName: Scalars['String']['output'];
};

/** Summary statistics for a specific query */
export type QueryDetailSummaryStats = {
  __typename?: 'QueryDetailSummaryStats';
  avgTime: Scalars['Float']['output'];
  avgTimeDelta: Scalars['Float']['output'];
  callsPerMinute: Scalars['Float']['output'];
  callsPerMinuteDelta: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
};

/** A specific explain plan of a query */
export type QueryExplain = {
  __typename?: 'QueryExplain';
  annotatedJson?: Maybe<Scalars['JSON_legacy']['output']>;
  database: Database;
  error?: Maybe<Scalars['String']['output']>;
  fingerprint: Scalars['String']['output'];
  format: Scalars['String']['output'];
  humanId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  outputJson?: Maybe<Scalars['JSON_legacy']['output']>;
  outputText?: Maybe<Scalars['String']['output']>;
  planNodeTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  postgresRole: PostgresRole;
  query: Query;
  querySample?: Maybe<QuerySample>;
  seenAt: Scalars['Int']['output'];
  source: Scalars['String']['output'];
  time: Scalars['Int']['output'];
  totalBlkReadTime?: Maybe<Scalars['Float']['output']>;
  totalBlkWriteTime?: Maybe<Scalars['Float']['output']>;
  totalCost?: Maybe<Scalars['Float']['output']>;
  totalSharedBlksDirtied?: Maybe<Scalars['Int']['output']>;
  totalSharedBlksHit?: Maybe<Scalars['Int']['output']>;
  totalSharedBlksRead?: Maybe<Scalars['Int']['output']>;
  totalSharedBlksWritten?: Maybe<Scalars['Int']['output']>;
};

export type QueryForWorkbook = {
  __typename?: 'QueryForWorkbook';
  errorMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  normalizedQuery?: Maybe<Scalars['String']['output']>;
  parameters?: Maybe<Array<QueryParameter>>;
  validQuery: Scalars['Boolean']['output'];
};

/** Index check for a specific query */
export type QueryIndexCheck = {
  __typename?: 'QueryIndexCheck';
  failedToParse: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  tables: Array<Maybe<QueryIndexCheckTable>>;
  timedOut: Scalars['Boolean']['output'];
};

/** Indexed column of an index check */
export type QueryIndexCheckIndexedColumn = {
  __typename?: 'QueryIndexCheckIndexedColumn';
  column: SchemaColumn;
  index: SchemaIndex;
};

/** Table and index information as part of an index check */
export type QueryIndexCheckTable = {
  __typename?: 'QueryIndexCheckTable';
  columnsIndexed: Array<Maybe<QueryIndexCheckIndexedColumn>>;
  columnsMissingIndex: Array<Maybe<SchemaColumn>>;
  table: SchemaTable;
};

export type QueryOverviewStatsByDatabaseResult = {
  __typename?: 'QueryOverviewStatsByDatabaseResult';
  avgTime?: Maybe<Scalars['Float']['output']>;
  database?: Maybe<Database>;
  id: Scalars['ID']['output'];
  pctOfTotal?: Maybe<Scalars['Float']['output']>;
  pctOfTotalIo?: Maybe<Scalars['Float']['output']>;
  queriesPerSecond?: Maybe<Scalars['Float']['output']>;
  queriesPerSecondSeries?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
};

/** Series of query overview statistics */
export type QueryOverviewStatsSeries = {
  __typename?: 'QueryOverviewStatsSeries';
  avgIotime?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  avgTime?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  id: Scalars['ID']['output'];
  pct90th?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  pct95th?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  pct98th?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  pct99th?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
};

export type QueryParameter = {
  __typename?: 'QueryParameter';
  name: Scalars['String']['output'];
  ref: Scalars['Int']['output'];
};

/** A plan of a query */
export type QueryPlan = {
  __typename?: 'QueryPlan';
  annotatedJson?: Maybe<Scalars['JSON_legacy']['output']>;
  avgTime?: Maybe<Scalars['Float']['output']>;
  calls?: Maybe<Scalars['Float']['output']>;
  explainPlan?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  originalPlanId: Scalars['ID']['output'];
  planCapturedTime?: Maybe<Scalars['Int']['output']>;
  planFingerprint?: Maybe<Scalars['String']['output']>;
  planNodeTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  totalCost?: Maybe<Scalars['Float']['output']>;
};

/** Series of query plan statistics */
export type QueryPlanStatsSeries = {
  __typename?: 'QueryPlanStatsSeries';
  avgTime?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  calls?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  id: Scalars['ID']['output'];
  planFingerprint?: Maybe<Scalars['String']['output']>;
  queryPlanId: Scalars['ID']['output'];
};

/** A specific sample of a query */
export type QuerySample = {
  __typename?: 'QuerySample';
  explain?: Maybe<QueryExplain>;
  id: Scalars['ID']['output'];
  jsonParameters?: Maybe<Scalars['JSON']['output']>;
  occurredAt: Scalars['Int']['output'];
  parameters?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  queryTagset?: Maybe<QueryTagset>;
  queryText: Scalars['String']['output'];
  runtimeMs: Scalars['Float']['output'];
};

/** Scan tokens that make up the query text */
export type QueryScanToken = {
  __typename?: 'QueryScanToken';
  endPos: Scalars['Int']['output'];
  keywordKind: Scalars['String']['output'];
  schemaTableId?: Maybe<Scalars['ID']['output']>;
  startPos: Scalars['Int']['output'];
  token: Scalars['String']['output'];
};

/** Statistics for a specific query */
export type QueryStats = {
  __typename?: 'QueryStats';
  avgBlksHit?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  avgBlksRead?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  avgIoTime?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  avgTime?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  bytesLoaded?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  calls?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  cpuTimePct?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  id: Scalars['ID']['output'];
  ioTimePct?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  noData?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
};

/** Summed up statistics for a specific query */
export type QuerySummaryStats = {
  __typename?: 'QuerySummaryStats';
  avgBytesLoaded: Scalars['Float']['output'];
  avgIoTime: Scalars['Float']['output'];
  avgTime: Scalars['Float']['output'];
  avgTimeDelta?: Maybe<Scalars['Float']['output']>;
  bufferHitRatio: Scalars['Float']['output'];
  callsPerMinute: Scalars['Float']['output'];
  callsPerMinuteDelta?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  ioPercent?: Maybe<Scalars['Float']['output']>;
  normalizedQuery: Scalars['String']['output'];
  pctOfTotal: Scalars['Float']['output'];
  pctOfTotalIo?: Maybe<Scalars['Float']['output']>;
  postgresRole?: Maybe<PostgresRole>;
  queryComment?: Maybe<Scalars['String']['output']>;
  queryId: Scalars['String']['output'];
  queryUrl: Scalars['String']['output'];
  statementType: Array<Maybe<Scalars['String']['output']>>;
  tableNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  totalCalls: Scalars['Int']['output'];
  totalCallsDelta?: Maybe<Scalars['Int']['output']>;
  truncatedQuery: Scalars['String']['output'];
};

/** Summary statistics for a particular kind of query tag */
export type QueryTagSummary = {
  __typename?: 'QueryTagSummary';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  values?: Maybe<Array<QueryTagSummaryValues>>;
};

/** Query tag values for a particular key in summary statistics */
export type QueryTagSummaryValues = {
  __typename?: 'QueryTagSummaryValues';
  count: Scalars['Int']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

/** Recorded information on a particular query tagset */
export type QueryTagset = {
  __typename?: 'QueryTagset';
  commentData: Scalars['JSON_legacy']['output'];
  commentText: Scalars['String']['output'];
  databaseId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  query?: Maybe<Query>;
  querySample?: Maybe<QuerySample>;
  seenAt: Scalars['Int']['output'];
};

export type QueryTextWithParameters = {
  __typename?: 'QueryTextWithParameters';
  id: Scalars['ID']['output'];
  parameterSetId: Scalars['ID']['output'];
  queryWithParameters: Scalars['String']['output'];
};

/** A replication follower of the current server */
export type ReplicationFollower = {
  __typename?: 'ReplicationFollower';
  clientAddr: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastStats?: Maybe<ReplicationFollowerLastStats>;
  syncState?: Maybe<Scalars['String']['output']>;
};

/** The latest statistics for a replication follower */
export type ReplicationFollowerLastStats = {
  __typename?: 'ReplicationFollowerLastStats';
  collectedAt: Scalars['Int']['output'];
  flushLsn?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  localByteLag?: Maybe<Scalars['Float']['output']>;
  remoteByteLag?: Maybe<Scalars['Float']['output']>;
  replayLsn?: Maybe<Scalars['String']['output']>;
  sentLsn?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  writeLsn?: Maybe<Scalars['String']['output']>;
};

/** Statistics for a replication follower */
export type ReplicationFollowerStats = {
  __typename?: 'ReplicationFollowerStats';
  id: Scalars['ID']['output'];
  localByteLag?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  remoteByteLag?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
};

/** An integration with a SAML identity provider */
export type SamlIntegration = {
  __typename?: 'SamlIntegration';
  authInvalid: Scalars['Boolean']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  idpEntityId: Scalars['String']['output'];
  integratedBy: User;
  kind: Scalars['String']['output'];
  updatedAt: Scalars['Int']['output'];
};

/** Column of a table in a schema */
export type SchemaColumn = {
  __typename?: 'SchemaColumn';
  dataType: Scalars['String']['output'];
  defaultValue?: Maybe<Scalars['String']['output']>;
  hotUpdateCapable: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  indexed: Scalars['Boolean']['output'];
  lastStats?: Maybe<SchemaColumnStats>;
  lastStatsInherited?: Maybe<SchemaColumnStats>;
  name: Scalars['String']['output'];
  notNull: Scalars['Boolean']['output'];
  tableId: Scalars['ID']['output'];
  updatesPerMinute?: Maybe<Scalars['Float']['output']>;
};

/** Statistics for a particular table column */
export type SchemaColumnStats = {
  __typename?: 'SchemaColumnStats';
  avgWidth: Scalars['Int']['output'];
  correlation: Scalars['Float']['output'];
  nDistinct: Scalars['Float']['output'];
  nullFrac: Scalars['Float']['output'];
};

/** Constraint of a table in a schema */
export type SchemaConstraint = {
  __typename?: 'SchemaConstraint';
  constraintDef: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Function in a schema */
export type SchemaFunction = {
  __typename?: 'SchemaFunction';
  args: Scalars['String']['output'];
  config?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  functionName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  issues?: Maybe<Array<Maybe<Issue>>>;
  language: Scalars['String']['output'];
  result: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
  securityDefiner: Scalars['Boolean']['output'];
  source: Scalars['String']['output'];
};

/** Index in a schema */
export type SchemaIndex = {
  __typename?: 'SchemaIndex';
  constraintDef?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Int']['output'];
  definitionShort: Scalars['String']['output'];
  fillfactor?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  indexDef: Scalars['String']['output'];
  indexDefShort: Scalars['String']['output'];
  issues?: Maybe<Array<Maybe<Issue>>>;
  lastUsedAt?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<SchemaIndexMetadata>;
  name: Scalars['String']['output'];
  scansPerMin?: Maybe<Scalars['Float']['output']>;
  schemaName: Scalars['String']['output'];
  similarIndices?: Maybe<Array<Maybe<SchemaIndex>>>;
  sizeBytes?: Maybe<Scalars['Float']['output']>;
  tableId: Scalars['ID']['output'];
  tableName: Scalars['String']['output'];
  valid?: Maybe<Scalars['Boolean']['output']>;
  writeOverhead?: Maybe<Scalars['Float']['output']>;
};

/** Derived metadata about an index */
export type SchemaIndexMetadata = {
  __typename?: 'SchemaIndexMetadata';
  exprIdxUsedDefaultEst: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Int']['output'];
  writeOverhead: Scalars['Float']['output'];
};

/** Series of schema index statistics */
export type SchemaIndexStatsSeries = {
  __typename?: 'SchemaIndexStatsSeries';
  blocksFromCache?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  blocksFromDisk?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  cachedSize?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>;
  id: Scalars['ID']['output'];
  indexScans?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  indexSize?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
};

/** Table in a schema */
export type SchemaTable = {
  __typename?: 'SchemaTable';
  bloatStats?: Maybe<SchemaTableInlineBloatStats>;
  databaseId: Scalars['ID']['output'];
  estimatedRowSize: Scalars['Int']['output'];
  fillfactor?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  issues: Array<Issue>;
  lastInfo?: Maybe<SchemaTableInfo>;
  lastStats?: Maybe<SchemaTableInlineStats>;
  /** @deprecated Use lastStats.liveTuples instead */
  liveTuples?: Maybe<Scalars['Float']['output']>;
  parentTable?: Maybe<SchemaTable>;
  parentTableId?: Maybe<Scalars['ID']['output']>;
  partitionBoundary?: Maybe<Scalars['String']['output']>;
  partitionedBy?: Maybe<Scalars['String']['output']>;
  schemaName: Scalars['String']['output'];
  /** @deprecated Use lastStats.sizeBytes instead */
  sizeBytes?: Maybe<Scalars['Float']['output']>;
  stats7dAgo?: Maybe<SchemaTableInlineStats>;
  tableName: Scalars['String']['output'];
};

/** Autovacuum config setting of a table */
export type SchemaTableAutovacuumSetting = {
  __typename?: 'SchemaTableAutovacuumSetting';
  name: Scalars['String']['output'];
  serverValue?: Maybe<Scalars['String']['output']>;
  tableOverrideAvailable: Scalars['Boolean']['output'];
  tableValue?: Maybe<Scalars['String']['output']>;
  toastOption: Scalars['Boolean']['output'];
};

/** Bloat statistics for a particular table */
export type SchemaTableBloatInfo = {
  __typename?: 'SchemaTableBloatInfo';
  estimateAccountsForToast: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  stats: Array<SchemaTableBloatStats>;
};

/** Bloat statistics timeseries for a particular table */
export type SchemaTableBloatStats = {
  __typename?: 'SchemaTableBloatStats';
  estimatedBloatBytes?: Maybe<Scalars['Int']['output']>;
  estimatedDataBytes?: Maybe<Scalars['Int']['output']>;
  estimatedPctBloat?: Maybe<Scalars['Float']['output']>;
  estimatedRows?: Maybe<Scalars['Int']['output']>;
  estimatedTableBytes?: Maybe<Scalars['Int']['output']>;
  ts: Scalars['Int']['output'];
};

/** VACUUM/ANALYZE event of a table in a schema */
export type SchemaTableEvent = {
  __typename?: 'SchemaTableEvent';
  eventType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  occurredAt: Scalars['Int']['output'];
};

/** Extended stats of a table in a schema */
export type SchemaTableExtendedStats = {
  __typename?: 'SchemaTableExtendedStats';
  columns?: Maybe<Scalars['String']['output']>;
  dependencies?: Maybe<Scalars['String']['output']>;
  expressions?: Maybe<Scalars['String']['output']>;
  hasStatisticsData: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  inherited?: Maybe<Scalars['Boolean']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  nDistinct?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  schemaName: Scalars['String']['output'];
};

/** Stats updated by ANALYZE and VACUUM */
export type SchemaTableInfo = {
  __typename?: 'SchemaTableInfo';
  lastAnalyzeAt?: Maybe<Scalars['Int']['output']>;
  lastAutoanalyzeAt?: Maybe<Scalars['Int']['output']>;
};

/** Bloat statistics for a table at a given point in time */
export type SchemaTableInlineBloatStats = {
  __typename?: 'SchemaTableInlineBloatStats';
  estimateAccountsForToast: Scalars['Boolean']['output'];
  estimatedBloatBytes?: Maybe<Scalars['Int']['output']>;
  estimatedPctBloat?: Maybe<Scalars['Float']['output']>;
  estimatedTableBytes?: Maybe<Scalars['Int']['output']>;
};

/** Statistics for a table at a given point in time */
export type SchemaTableInlineStats = {
  __typename?: 'SchemaTableInlineStats';
  dataSizeBytes?: Maybe<Scalars['Float']['output']>;
  deadTupleRatio?: Maybe<Scalars['Float']['output']>;
  deadTuples?: Maybe<Scalars['Float']['output']>;
  indexSizeBytes?: Maybe<Scalars['Float']['output']>;
  liveTuples?: Maybe<Scalars['Float']['output']>;
  snapshotAt?: Maybe<Scalars['Int']['output']>;
};

/** List of tables with their vacuum settings */
export type SchemaTableListWithVacuumSettings = {
  __typename?: 'SchemaTableListWithVacuumSettings';
  databaseId: Scalars['ID']['output'];
  databaseName: Scalars['String']['output'];
  estimatedTableBytes?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  idealTableBytes?: Maybe<Scalars['Int']['output']>;
  lastAutovacuumAt?: Maybe<Scalars['Int']['output']>;
  lastVacuumAt?: Maybe<Scalars['Int']['output']>;
  schemaName: Scalars['String']['output'];
  serverAutovacuumVacuumScaleFactor?: Maybe<Scalars['Float']['output']>;
  serverAutovacuumVacuumThreshold?: Maybe<Scalars['Int']['output']>;
  sizeBytes?: Maybe<Scalars['Int']['output']>;
  tableAutovacuumVacuumScaleFactor?: Maybe<Scalars['Float']['output']>;
  tableAutovacuumVacuumThreshold?: Maybe<Scalars['Int']['output']>;
  tableId: Scalars['ID']['output'];
  tableName: Scalars['String']['output'];
};

/** Customized options / configuration settings for a table */
export type SchemaTableOption = {
  __typename?: 'SchemaTableOption';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  table: SchemaTable;
  value: Scalars['String']['output'];
};

/** Table scan data in a schema */
export type SchemaTableScan = {
  __typename?: 'SchemaTableScan';
  id: Scalars['ID']['output'];
};

/** Scan included in one or more queries referencing the table */
export type SchemaTableScanDetail = {
  __typename?: 'SchemaTableScanDetail';
  avgCallsPerMinute: Scalars['Float']['output'];
  combinedExpression: Scalars['String']['output'];
  genericPlanRows?: Maybe<Scalars['Int']['output']>;
  genericPlanScanExplain?: Maybe<Scalars['String']['output']>;
  genericPlanScanMethod?: Maybe<Scalars['String']['output']>;
  genericPlanScanType?: Maybe<Scalars['String']['output']>;
  genericPlanStartupCost?: Maybe<Scalars['Int']['output']>;
  genericPlanTotalCost?: Maybe<Scalars['Int']['output']>;
  genericPlanWidth?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  joinExpression?: Maybe<Scalars['String']['output']>;
  parameterizedScanExpected: Scalars['Boolean']['output'];
  queries: Array<QuerySummaryStats>;
  whereExpression?: Maybe<Scalars['String']['output']>;
};

/** Scan included in one or more queries referencing the table */
export type SchemaTableScanForQuery = {
  __typename?: 'SchemaTableScanForQuery';
  combinedExpression: Scalars['String']['output'];
  genericPlanRows: Scalars['Int']['output'];
  genericPlanScanExplain: Scalars['String']['output'];
  genericPlanScanMethod: Scalars['String']['output'];
  genericPlanScanType: Scalars['String']['output'];
  genericPlanStartupCost: Scalars['Int']['output'];
  genericPlanTotalCost: Scalars['Int']['output'];
  genericPlanWidth: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  joinExpression?: Maybe<Scalars['String']['output']>;
  parameterizedScanExpected: Scalars['Boolean']['output'];
  suggestedIndex?: Maybe<Scalars['String']['output']>;
  table: SchemaTable;
  whereExpression?: Maybe<Scalars['String']['output']>;
};

/** Series of schema table statistics */
export type SchemaTableStatsSeries = {
  __typename?: 'SchemaTableStatsSeries';
  cachedDataSize?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>;
  cachedIndexSize?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>;
  cachedToastSize?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>;
  deadTuples?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  deletes?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  frozenxidAge?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>;
  hotUpdates?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  id: Scalars['ID']['output'];
  indexSize?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  inserts?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  insertsSinceVacuum?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  liveTuples?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  minmxidAge?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>;
  tableSize?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  toastSize?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  totalSize?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  updates?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
};

/** Vacuum information for a particular table */
export type SchemaTableVacuumInfo = {
  __typename?: 'SchemaTableVacuumInfo';
  autovacuumAnalyzeScaleFactor: Scalars['Float']['output'];
  autovacuumAnalyzeScaleFactorDefault: Scalars['Float']['output'];
  autovacuumAnalyzeThreshold: Scalars['Int']['output'];
  autovacuumAnalyzeThresholdDefault: Scalars['Int']['output'];
  autovacuumEnabled: Scalars['Boolean']['output'];
  autovacuumEnabledDefault: Scalars['Boolean']['output'];
  autovacuumFreezeMaxAge: Scalars['Int']['output'];
  autovacuumFreezeMaxAgeDefault: Scalars['Int']['output'];
  autovacuumFreezeMinAge: Scalars['Int']['output'];
  autovacuumFreezeMinAgeDefault: Scalars['Int']['output'];
  autovacuumMultixactFreezeMaxAge: Scalars['Int']['output'];
  autovacuumMultixactFreezeMaxAgeDefault: Scalars['Int']['output'];
  autovacuumMultixactFreezeMinAge: Scalars['Int']['output'];
  autovacuumMultixactFreezeMinAgeDefault: Scalars['Int']['output'];
  autovacuumVacuumCostDelay: Scalars['Int']['output'];
  autovacuumVacuumCostDelayDefault: Scalars['Int']['output'];
  autovacuumVacuumCostLimit: Scalars['Int']['output'];
  autovacuumVacuumCostLimitDefault: Scalars['Int']['output'];
  autovacuumVacuumInsertScaleFactor?: Maybe<Scalars['Float']['output']>;
  autovacuumVacuumInsertScaleFactorDefault?: Maybe<Scalars['Float']['output']>;
  autovacuumVacuumInsertThreshold?: Maybe<Scalars['Int']['output']>;
  autovacuumVacuumInsertThresholdDefault?: Maybe<Scalars['Int']['output']>;
  autovacuumVacuumScaleFactor: Scalars['Float']['output'];
  autovacuumVacuumScaleFactorDefault: Scalars['Float']['output'];
  autovacuumVacuumThreshold: Scalars['Int']['output'];
  autovacuumVacuumThresholdDefault: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  vacuumFreezeTableAge: Scalars['Int']['output'];
  vacuumFreezeTableAgeDefault: Scalars['Int']['output'];
  vacuumMultixactFreezeTableAge: Scalars['Int']['output'];
  vacuumMultixactFreezeTableAgeDefault: Scalars['Int']['output'];
  vacuumRuns: Array<VacuumRunDetails>;
};

/** Vacuum summary for a particular table */
export type SchemaTableVacuumSummary = {
  __typename?: 'SchemaTableVacuumSummary';
  databaseId: Scalars['ID']['output'];
  databaseName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  schemaName: Scalars['String']['output'];
  schemaTableId: Scalars['ID']['output'];
  tableName: Scalars['String']['output'];
  vacuumCount: Scalars['Int']['output'];
  vacuumMaxDurationSecs?: Maybe<Scalars['Float']['output']>;
  vacuumMaxIndexPhases: Scalars['Int']['output'];
};

/** View in a schema */
export type SchemaView = {
  __typename?: 'SchemaView';
  fillfactor?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  issues?: Maybe<Array<Maybe<Issue>>>;
  lastStats?: Maybe<SchemaViewLastStats>;
  materializedView: Scalars['Boolean']['output'];
  schemaName: Scalars['String']['output'];
  sizeBytes?: Maybe<Scalars['Float']['output']>;
  viewDefinition: Scalars['String']['output'];
  viewName: Scalars['String']['output'];
};

/** Latest statistics for a materialized view */
export type SchemaViewLastStats = {
  __typename?: 'SchemaViewLastStats';
  dataSizeBytes?: Maybe<Scalars['Float']['output']>;
  deadTuples?: Maybe<Scalars['Float']['output']>;
  indexSizeBytes?: Maybe<Scalars['Float']['output']>;
  liveTuples?: Maybe<Scalars['Float']['output']>;
};

/** A server */
export type Server = {
  __typename?: 'Server';
  autovacuumAvgWorkerCount: Scalars['Float']['output'];
  awsAssumeRole?: Maybe<Scalars['String']['output']>;
  awsDbClusterId?: Maybe<Scalars['String']['output']>;
  awsDbInstanceId?: Maybe<Scalars['String']['output']>;
  awsRegion?: Maybe<Scalars['String']['output']>;
  azureAdClientId?: Maybe<Scalars['String']['output']>;
  azureDbServerName?: Maybe<Scalars['String']['output']>;
  azureEventhubName?: Maybe<Scalars['String']['output']>;
  azureEventhubNamespace?: Maybe<Scalars['String']['output']>;
  azureSubscriptionId?: Maybe<Scalars['String']['output']>;
  blockSize: Scalars['Int']['output'];
  collectorInfo?: Maybe<CollectorInfo>;
  configFromEnv?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['Int']['output'];
  currentTime?: Maybe<Scalars['Int']['output']>;
  databases?: Maybe<Array<Maybe<Database>>>;
  databasesWithoutColumnStats: DatabasePartialList;
  dbHost?: Maybe<Scalars['String']['output']>;
  dbName?: Maybe<Scalars['String']['output']>;
  dbPort?: Maybe<Scalars['Int']['output']>;
  dbSslmode?: Maybe<Scalars['String']['output']>;
  dbUseIamAuth?: Maybe<Scalars['Boolean']['output']>;
  dbUsername?: Maybe<Scalars['String']['output']>;
  displaySystemType?: Maybe<Scalars['String']['output']>;
  duplicateSnapshotReceivedAt?: Maybe<Scalars['Int']['output']>;
  enableActivity?: Maybe<Scalars['Boolean']['output']>;
  enableLogs?: Maybe<Scalars['Boolean']['output']>;
  filterLogSecret?: Maybe<Scalars['String']['output']>;
  filterQuerySample?: Maybe<Scalars['String']['output']>;
  filterQueryText?: Maybe<Scalars['String']['output']>;
  gcpAlloydbClusterId?: Maybe<Scalars['String']['output']>;
  gcpAlloydbInstanceId?: Maybe<Scalars['String']['output']>;
  gcpCloudsqlInstanceId?: Maybe<Scalars['String']['output']>;
  gcpProjectId?: Maybe<Scalars['String']['output']>;
  gcpPubsubSubscription?: Maybe<Scalars['String']['output']>;
  hasCriticalIssues: Scalars['Boolean']['output'];
  hasPgssEnabled?: Maybe<Scalars['Boolean']['output']>;
  hasRecentSnapshot: Scalars['Boolean']['output'];
  humanId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  integratedExplain: Scalars['Boolean']['output'];
  integratedLogInsights: Scalars['Boolean']['output'];
  lastActivitySnapshotAt?: Maybe<Scalars['Int']['output']>;
  lastReceivedSnapshotAt?: Maybe<Scalars['Int']['output']>;
  lastSnapshot?: Maybe<Snapshot>;
  lastSnapshotAt?: Maybe<Scalars['Int']['output']>;
  lastSnapshotSubmitter?: Maybe<Scalars['String']['output']>;
  lastTestSnapshotAt?: Maybe<Scalars['Int']['output']>;
  latestStats?: Maybe<PostgresServerStats>;
  localCollectorTestOutput?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notificationConfig?: Maybe<NotificationConfig>;
  oldestXminAssignedAgo?: Maybe<Scalars['Int']['output']>;
  organization: Organization;
  permittedToEdit: Scalars['Boolean']['output'];
  permittedToRemove: Scalars['Boolean']['output'];
  postgresMajorVersion?: Maybe<Scalars['String']['output']>;
  postgresVersion?: Maybe<Scalars['String']['output']>;
  retentionInDays: Scalars['Int']['output'];
  schemaProcessingDisabled: Scalars['Boolean']['output'];
  statementResetFrequency?: Maybe<Scalars['Int']['output']>;
  statementTimeoutMs?: Maybe<Scalars['Int']['output']>;
  statementTimeoutMsQueryText?: Maybe<Scalars['Int']['output']>;
  systemId?: Maybe<Scalars['String']['output']>;
  systemScope?: Maybe<Scalars['String']['output']>;
  systemType?: Maybe<Scalars['String']['output']>;
  tags: Array<ServerTag>;
  totalBloat: Bloat;
  txidUtilizationSummary?: Maybe<Scalars['Float']['output']>;
  usesLocalCollector: Scalars['Boolean']['output'];
  vacuumCount: VacuumCount;
  xactPerSecSummary?: Maybe<Scalars['Float']['output']>;
};


/** A server */
export type ServerDatabasesWithoutColumnStatsArgs = {
  focusDatabaseId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

/** Freezing stats for the current server */
export type ServerFreezingStats = {
  __typename?: 'ServerFreezingStats';
  autovacuumFreezeMaxAge?: Maybe<Scalars['Int']['output']>;
  autovacuumMultixactFreezeMaxAge?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  mxidDatabases: Array<DatabaseFreezingStats>;
  vacuumFailsafeAge?: Maybe<Scalars['Int']['output']>;
  vacuumMultixactFailsafeAge?: Maybe<Scalars['Int']['output']>;
  xidDatabases: Array<DatabaseFreezingStats>;
};

/** Freezing stats data of the current server by table */
export type ServerFreezingStatsByTable = {
  __typename?: 'ServerFreezingStatsByTable';
  autovacuumFreezeMaxAge?: Maybe<Scalars['Int']['output']>;
  autovacuumMultixactFreezeMaxAge?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  lastCollectedAt: Scalars['Int']['output'];
  tableStats: Array<TableFreezingStats>;
};

/** A server and its summary statistics */
export type ServerSummaryStats = {
  __typename?: 'ServerSummaryStats';
  criticalsCount: Scalars['Int']['output'];
  databaseCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  queriesSlownessCount: Scalars['Int']['output'];
  server: Server;
  unusedIndexesCount: Scalars['Int']['output'];
  warningsCount: Scalars['Int']['output'];
};

/** A server tag */
export type ServerTag = {
  __typename?: 'ServerTag';
  key: Scalars['String']['output'];
  source: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Assigned transaction IDs per second data for the current server */
export type ServerXactPerSec = {
  __typename?: 'ServerXactPerSec';
  autovacuumFreezeMaxAge?: Maybe<Scalars['Int']['output']>;
  autovacuumMultixactFreezeMaxAge?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  multixactPerSec: Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>;
  vacuumFreezeMinAge?: Maybe<Scalars['Int']['output']>;
  vacuumMultixactFreezeMinAge?: Maybe<Scalars['Int']['output']>;
  xactPerSec: Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>;
};

/** Xmin Horizon age data for the current server */
export type ServerXminHorizon = {
  __typename?: 'ServerXminHorizon';
  backend?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>;
  id: Scalars['String']['output'];
  preparedXact?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>;
  replicationSlot?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>;
  replicationSlotCatalog?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>;
  standby?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>;
};

/** Slack channel */
export type SlackChannel = {
  __typename?: 'SlackChannel';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SlackChannelList = {
  __typename?: 'SlackChannelList';
  channels: Array<SlackChannel>;
  error?: Maybe<Scalars['String']['output']>;
};

/** An integration with Slack */
export type SlackIntegration = {
  __typename?: 'SlackIntegration';
  authInvalid: Scalars['Boolean']['output'];
  availableChannels: SlackChannelList;
  createdAt: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  integratedBy: User;
  kind: Scalars['String']['output'];
  updatedAt: Scalars['Int']['output'];
};

/** Attributes for configuring Slack notifications */
export type SlackNotificationConfig = {
  __typename?: 'SlackNotificationConfig';
  /** Id of Slack channel notifications will be sent to (null if disabled) */
  channelId?: Maybe<Scalars['String']['output']>;
  /** Name of Slack channel notifications will be sent to */
  channelName?: Maybe<Scalars['String']['output']>;
  /** List of checks for which Slack notifications are enabled */
  checks: Array<Scalars['String']['output']>;
};

/** Attributes for configuring Slack notifications */
export type SlackNotificationConfigAttributes = {
  /** Id of Slack channel to send notifications to (or null to disable) */
  channelId?: InputMaybe<Scalars['String']['input']>;
  /** Name of Slack channel to send notifications to */
  channelName?: InputMaybe<Scalars['String']['input']>;
  /** List of checks to enable Slack notifications for */
  checks: Array<Scalars['String']['input']>;
};

/** A snapshot */
export type Snapshot = {
  __typename?: 'Snapshot';
  collectedAt: Scalars['Int']['output'];
  collectorVersion: Scalars['Int']['output'];
  collectorVersionStr: Scalars['String']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  hasError: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  submitter: Scalars['String']['output'];
  systemJson?: Maybe<Scalars['JSON_legacy']['output']>;
};

export enum SortDirection {
  /** Ascending sort */
  Asc = 'ASC',
  /** Descending sort */
  Desc = 'DESC'
}

export enum SubscriptionInterval {
  /** Subscription is paid every month */
  Month = 'MONTH',
  /** Subscription is paid every year */
  Year = 'YEAR'
}

/** Details about the server system */
export type SystemDetails = {
  __typename?: 'SystemDetails';
  id: Scalars['ID']['output'];
  info?: Maybe<SystemInfo>;
  stats?: Maybe<SystemStats>;
};

/** Information about a server system */
export type SystemInfo = {
  __typename?: 'SystemInfo';
  amazonRdsEnhanced?: Maybe<Scalars['Boolean']['output']>;
  crunchyBridgeClusterId?: Maybe<Scalars['String']['output']>;
  data?: Maybe<SystemInfoData>;
  systemType?: Maybe<Scalars['String']['output']>;
};

/** Detailed data about the information on a server system */
export type SystemInfoData = {
  __typename?: 'SystemInfoData';
  architecture?: Maybe<Scalars['String']['output']>;
  autoMinorVersionUpgrade?: Maybe<Scalars['Boolean']['output']>;
  availabilityZone?: Maybe<Scalars['String']['output']>;
  backupRetentionPeriod?: Maybe<Scalars['Int']['output']>;
  citusVersion?: Maybe<Scalars['String']['output']>;
  clusterName?: Maybe<Scalars['String']['output']>;
  coordinatorServerEdition?: Maybe<Scalars['String']['output']>;
  coordinatorStorageMb?: Maybe<Scalars['Int']['output']>;
  coordinatorVcores?: Maybe<Scalars['Int']['output']>;
  cpuUnits?: Maybe<Scalars['Int']['output']>;
  highAvailabilityEnabled?: Maybe<Scalars['Boolean']['output']>;
  highAvailabilityMode?: Maybe<Scalars['String']['output']>;
  highAvailabilityState?: Maybe<Scalars['String']['output']>;
  instanceClass?: Maybe<Scalars['String']['output']>;
  instanceId?: Maybe<Scalars['String']['output']>;
  isAuroraPostgres?: Maybe<Scalars['Boolean']['output']>;
  kernelVersion?: Maybe<Scalars['String']['output']>;
  latestRestorableTime?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  memoryGb?: Maybe<Scalars['Float']['output']>;
  multiAz?: Maybe<Scalars['Boolean']['output']>;
  nodeCount?: Maybe<Scalars['Int']['output']>;
  nodeServerEdition?: Maybe<Scalars['String']['output']>;
  nodeStorageMb?: Maybe<Scalars['Int']['output']>;
  nodeVcores?: Maybe<Scalars['Int']['output']>;
  operatingSystem?: Maybe<Scalars['String']['output']>;
  parameterApplyStatus?: Maybe<Scalars['String']['output']>;
  planId?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<Scalars['String']['output']>;
  platformVersion?: Maybe<Scalars['String']['output']>;
  preferredBackupWindow?: Maybe<Scalars['String']['output']>;
  preferredMaintenanceWindow?: Maybe<Scalars['String']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  publiclyAccessible?: Maybe<Scalars['Boolean']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  regionId?: Maybe<Scalars['String']['output']>;
  replicationRole?: Maybe<Scalars['String']['output']>;
  resourceGroup?: Maybe<Scalars['String']['output']>;
  resourceName?: Maybe<Scalars['String']['output']>;
  resourceType?: Maybe<Scalars['String']['output']>;
  secondaryAvailabilityZone?: Maybe<Scalars['String']['output']>;
  skuName?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  storageGb?: Maybe<Scalars['Int']['output']>;
  storageProvisionedIops?: Maybe<Scalars['String']['output']>;
  storageType?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  virtualizationSystem?: Maybe<Scalars['String']['output']>;
};

/** Statistics about the system at a specific point in time */
export type SystemStats = {
  __typename?: 'SystemStats';
  collectedAt: Scalars['Int']['output'];
  cpuHardwareCacheSize?: Maybe<Scalars['Int']['output']>;
  cpuHardwareCoresPerSocket?: Maybe<Scalars['Int']['output']>;
  cpuHardwareModel?: Maybe<Scalars['String']['output']>;
  cpuHardwareSockets?: Maybe<Scalars['Int']['output']>;
  cpuHardwareSpeedMhz?: Maybe<Scalars['Float']['output']>;
  memoryApplicationBytes?: Maybe<Scalars['Float']['output']>;
  memoryFreeBytes?: Maybe<Scalars['Float']['output']>;
  memoryTotalBytes?: Maybe<Scalars['Float']['output']>;
  schedulerLoadavg1min?: Maybe<Scalars['Float']['output']>;
  schedulerLoadavg5min?: Maybe<Scalars['Float']['output']>;
  schedulerLoadavg15min?: Maybe<Scalars['Float']['output']>;
  storageBytesAvailable?: Maybe<Scalars['Float']['output']>;
  storageBytesTotal?: Maybe<Scalars['Float']['output']>;
  storageMountpoint?: Maybe<Scalars['String']['output']>;
};

/** Series of system statistics */
export type SystemStatsSeries = {
  __typename?: 'SystemStatsSeries';
  cpuIdle?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  cpuIowait?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  cpuIrq?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  cpuSteal?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  cpuSystem?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  cpuUser?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  cpuUtilization?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  id: Scalars['ID']['output'];
  loadAvg1min?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  loadAvg5min?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  loadAvg15min?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  memoryBuffers?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  memoryFree?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  memoryPageCache?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  memoryProcess?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  memorySwap?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  memoryTotal?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  networkReceive?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  networkTransmit?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  numCores?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  storageIopsRead?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  storageIopsWrite?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  storageLatencyRead?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  storageLatencyWrite?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  storageQueueDepth?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  storageSpaceFree?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  storageSpaceTotal?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  storageSpaceUsed?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  storageThroughputRead?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  storageThroughputWrite?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  storageUtilization?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  storageUtilizationPct90?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  storageUtilizationPct100?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
};

/** Freezing stats data of the table */
export type TableFreezingStats = {
  __typename?: 'TableFreezingStats';
  databaseId: Scalars['Int']['output'];
  datname: Scalars['String']['output'];
  frozenXid?: Maybe<Scalars['Int']['output']>;
  frozenxidAge?: Maybe<Scalars['Int']['output']>;
  frozenxidAssignedAt?: Maybe<Scalars['Int']['output']>;
  lastAutovacuumAt?: Maybe<Scalars['Int']['output']>;
  lastVacuumAt?: Maybe<Scalars['Int']['output']>;
  minMxid?: Maybe<Scalars['Int']['output']>;
  minmxidAge?: Maybe<Scalars['Int']['output']>;
  minmxidAssignedAt?: Maybe<Scalars['Int']['output']>;
  schemaName: Scalars['String']['output'];
  tableId: Scalars['Int']['output'];
  tableName: Scalars['String']['output'];
};

/** Autogenerated return type of TestLocalCollector. */
export type TestLocalCollectorPayload = {
  __typename?: 'TestLocalCollectorPayload';
  okay: Scalars['Boolean']['output'];
};

/** Autogenerated return type of TestPagerDutyIntegration. */
export type TestPagerDutyIntegrationPayload = {
  __typename?: 'TestPagerDutyIntegrationPayload';
  okay: Scalars['Boolean']['output'];
};

/** Autogenerated return type of TestSlackIntegration. */
export type TestSlackIntegrationPayload = {
  __typename?: 'TestSlackIntegrationPayload';
  okay: Scalars['Boolean']['output'];
};

/** Autogenerated return type of UpdateBillingCard. */
export type UpdateBillingCardPayload = {
  __typename?: 'UpdateBillingCardPayload';
  organizationBilling: OrganizationBilling;
};

/** Autogenerated return type of UpdateBillingDetails. */
export type UpdateBillingDetailsPayload = {
  __typename?: 'UpdateBillingDetailsPayload';
  organizationBilling: OrganizationBilling;
};

/** Autogenerated return type of UpdateExplainQuery. */
export type UpdateExplainQueryPayload = {
  __typename?: 'UpdateExplainQueryPayload';
  explainQueryId?: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated return type of UpdateExplainWorkbook. */
export type UpdateExplainWorkbookPayload = {
  __typename?: 'UpdateExplainWorkbookPayload';
  explainWorkbookId?: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated return type of UpdateServer. */
export type UpdateServerPayload = {
  __typename?: 'UpdateServerPayload';
  errors: Array<Scalars['String']['output']>;
  server: Server;
};

/** A user */
export type User = {
  __typename?: 'User';
  authHasPassword?: Maybe<Scalars['Boolean']['output']>;
  authOrganization?: Maybe<Organization>;
  authProvider?: Maybe<Scalars['String']['output']>;
  authUid?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  demo: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  fullname?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  organizations: Array<Organization>;
  pendingReconfirmationEmail?: Maybe<Scalars['String']['output']>;
  permittedToCreateOrganization: Scalars['Boolean']['output'];
  receiveWeeklyReports: Scalars['Boolean']['output'];
  timezone?: Maybe<Scalars['String']['output']>;
};

/** Vacuum count info at the server level */
export type VacuumCount = {
  __typename?: 'VacuumCount';
  antiWraparoundAutovacuumCount: Scalars['Int']['output'];
  autovacuumCount: Scalars['Int']['output'];
  manualVacuumRunsCount: Scalars['Int']['output'];
  skippedCount: Scalars['Int']['output'];
  totalVacuumRunsCount: Scalars['Int']['output'];
};

/** Latest status of each VACUUM insight type that has run on the server */
export type VacuumInsightStatus = {
  __typename?: 'VacuumInsightStatus';
  checkGroupAndName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastRunAt: Scalars['Int']['output'];
};

/** A Postgres VACUUM run */
export type VacuumRun = {
  __typename?: 'VacuumRun';
  autovacuum: Scalars['Boolean']['output'];
  backendIdentity: Scalars['ID']['output'];
  backendPid?: Maybe<Scalars['Int']['output']>;
  databaseId: Scalars['ID']['output'];
  databaseName: Scalars['String']['output'];
  details?: Maybe<VacuumRunDetails>;
  id: Scalars['ID']['output'];
  identity: Scalars['ID']['output'];
  postgresRole?: Maybe<PostgresRole>;
  postgresRoleId?: Maybe<Scalars['String']['output']>;
  schemaTable?: Maybe<SchemaTable>;
  schemaTableId?: Maybe<Scalars['ID']['output']>;
  toast?: Maybe<Scalars['Boolean']['output']>;
  vacuumEnd?: Maybe<Scalars['Int']['output']>;
  vacuumStart: Scalars['Int']['output'];
};

/** Details for a particular vacuum run */
export type VacuumRunDetails = {
  __typename?: 'VacuumRunDetails';
  autovacuum: Scalars['Boolean']['output'];
  backendIdentity: Scalars['ID']['output'];
  backendPid?: Maybe<Scalars['Int']['output']>;
  blockSize: Scalars['Int']['output'];
  costDelayMs: Scalars['Int']['output'];
  costLimit?: Maybe<Scalars['Int']['output']>;
  costPageDirty: Scalars['Int']['output'];
  costPageMiss: Scalars['Int']['output'];
  database: Database;
  databaseId: Scalars['ID']['output'];
  deadTuplesEnd?: Maybe<Scalars['Int']['output']>;
  deadTuplesStart?: Maybe<Scalars['Int']['output']>;
  details?: Maybe<VacuumRunLogDetails>;
  expectFreezing?: Maybe<Scalars['Boolean']['output']>;
  heapBlksTotal: Scalars['Float']['output'];
  heapBlksTotalBytes: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  identity: Scalars['ID']['output'];
  maxDeadTuples: Scalars['Float']['output'];
  phases?: Maybe<Array<VacuumRunPhase>>;
  postgresRole?: Maybe<PostgresRole>;
  postgresRoleId?: Maybe<Scalars['String']['output']>;
  relfrozenxidEnd?: Maybe<Scalars['Int']['output']>;
  relfrozenxidStart?: Maybe<Scalars['Int']['output']>;
  schemaTable?: Maybe<SchemaTable>;
  schemaTableId?: Maybe<Scalars['ID']['output']>;
  /** Array of arrays each containing a data point of vacuum run statistics (collected_at, phase, heap_blks_scanned, heap_blks_vacuumed, index_vacuum_count, num_dead_tuples) */
  stats?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>;
  toast?: Maybe<Scalars['Boolean']['output']>;
  vacuumEnd?: Maybe<Scalars['Int']['output']>;
  vacuumStart: Scalars['Int']['output'];
};

/** Details from the log event for a particular Postgres vacuum run */
export type VacuumRunLogDetails = {
  __typename?: 'VacuumRunLogDetails';
  aggressive?: Maybe<Scalars['Boolean']['output']>;
  elapsedSecs?: Maybe<Scalars['Float']['output']>;
  frozenskippedPages?: Maybe<Scalars['Float']['output']>;
  newDeadTuples?: Maybe<Scalars['Float']['output']>;
  newRelTuples?: Maybe<Scalars['Float']['output']>;
  numIndexScans?: Maybe<Scalars['Int']['output']>;
  oldestXmin?: Maybe<Scalars['Int']['output']>;
  pagesRemoved?: Maybe<Scalars['Float']['output']>;
  pinskippedPages?: Maybe<Scalars['Float']['output']>;
  readRateMb?: Maybe<Scalars['Float']['output']>;
  relPages?: Maybe<Scalars['Float']['output']>;
  rusageKernel?: Maybe<Scalars['Float']['output']>;
  rusageUser?: Maybe<Scalars['Float']['output']>;
  tuplesDeleted?: Maybe<Scalars['Float']['output']>;
  vacuumPageDirty?: Maybe<Scalars['Float']['output']>;
  vacuumPageHit?: Maybe<Scalars['Float']['output']>;
  vacuumPageMiss?: Maybe<Scalars['Float']['output']>;
  writeRateMb?: Maybe<Scalars['Float']['output']>;
};

/** Phase of a particular vacuum run */
export type VacuumRunPhase = {
  __typename?: 'VacuumRunPhase';
  endDate: Scalars['Int']['output'];
  indexVacuumCount: Scalars['Int']['output'];
  phase: Scalars['Int']['output'];
  startDate: Scalars['Int']['output'];
};

/** Result for a get vacuum runs query */
export type VacuumRuns = {
  __typename?: 'VacuumRuns';
  id: Scalars['ID']['output'];
  lastCollectedAt?: Maybe<Scalars['Int']['output']>;
  vacuumRuns: Array<VacuumRun>;
};

/** VACUUM Simulator input data for a particular table */
export type VacuumSimulatorInputType = {
  __typename?: 'VacuumSimulatorInputType';
  avgRowSize?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  initialBloatRows: Scalars['Int']['output'];
  multixactPerSec: Scalars['Float']['output'];
  tableStats?: Maybe<SchemaTableStatsSeries>;
  xactPerSec: Scalars['Float']['output'];
};

/** A count for a specific kind of wait event */
export type WaitEventCount = {
  __typename?: 'WaitEventCount';
  collectedAt: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  waitEvent: Scalars['Int']['output'];
  waitEventType: Scalars['Int']['output'];
};

export type WaitEventCountsResult = {
  __typename?: 'WaitEventCountsResult';
  counts: Array<WaitEventCount>;
  id: Scalars['ID']['output'];
};
