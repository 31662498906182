import Panel from "components/Panel";
import React, { useState } from "react";

import { useExplainWorkbookDetailsQuery } from "../ExplainWorkbook/gql/Query.generated";
import { ExplainWorkbookType, ExplainQueryType } from "../ExplainWorkbook";

import Grid, { NumberCell } from "components/Grid";
import ExpandableSQL from "components/ExpandableSQL";
import { Link, useParams } from "react-router-dom";
import { useRoutes } from "utils/routes";
import Loading from "components/Loading";
import PageContent from "components/PageContent";
import { ExplainWorkbookFeatureNav, RuntimeMsCell } from "../ExplainWorkbook";
import EditParameterSets, { missingValueAliases } from "../EditParameterSets";
import RunExplain from "../RunExplain";
import ExplainVariantSidebar from "../ExplainVariantSidebar";
import ExplainCompareLinkBar from "../ExplainCompareLinkBar";
import { mru } from "utils/array";
import ChooseParameters from "../ChooseParameters";
import ExplainFingerprint from "components/ExplainFingerprint";

// This is not really tab, but for which page to show
type ExplainVariantTabType = "run";

const ExplainVariant = ({ tab }: { tab?: ExplainVariantTabType }) => {
  const { databaseId, workbookId, variantId } = useParams();
  const { databaseWorkbookVariantResult, databaseWorkbookVariantRun } =
    useRoutes();
  const [compareCandidates, setCompareCandidates] = useState<string[]>([]);
  function handleToggleCompareCandidate(
    e: React.ChangeEvent<HTMLInputElement>,
  ) {
    const { checked } = e.currentTarget;
    const candidateId = e.currentTarget.dataset.compareId;
    const newCandidates = checked
      ? mru(candidateId, compareCandidates, 2)
      : compareCandidates.filter((id) => id != candidateId);

    setCompareCandidates(newCandidates);
  }

  const { loading, error, data } = useExplainWorkbookDetailsQuery({
    variables: { workbookId, databaseId },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const workbook = data.getExplainWorkbookDetails;

  // During the initial workbook creation flow:
  // If the selection is not done yet, show the selection page
  if (!workbook.parameterSetsSelected) {
    return <ChooseParameters workbook={workbook} />;
  }

  const explainQuery =
    workbook.baselineQuery.id === variantId
      ? workbook.baselineQuery
      : workbook.explainQueries.find((val) => val.id === variantId);
  const noParamSets = workbook.parameterSets.length === 0;

  const featureNav = (
    <ExplainWorkbookFeatureNav
      workbookId={workbookId}
      databaseId={databaseId}
    />
  );

  // When there is zero result uploaded, or with "run" path, show Run EXPLAIN
  if (explainQuery?.explainResults.length === 0 || tab === "run") {
    // Check if this variant's aliases contain missing value aliases
    // (can't run EXPLAIN until missing value aliases are all set)
    const missingAliases = Array.from(
      new Set(
        workbook.parameterSets.flatMap((ps) =>
          missingValueAliases(workbook, ps.id).map((v) => `$${v}`),
        ),
      ),
    );
    if (
      missingAliases.some((alias) =>
        Object.values(explainQuery.paramRefAliasMap).includes(alias),
      )
    ) {
      // Missing aliases so it can't show Run EXPLAIN yet, show edit params page
      return (
        <EditParameterSets workbook={workbook} explainQuery={explainQuery} />
      );
    } else if (explainQuery) {
      return <RunExplain workbook={workbook} explainQuery={explainQuery} />;
    }
  }

  let fastestRuntime = Infinity;
  const variantData = workbook.parameterSets
    .map((ps) => {
      const result = explainQuery?.explainResults.find(
        (er) => er.parameterSetId === ps.id,
      );
      fastestRuntime = Math.min(fastestRuntime, result?.runtimeMs ?? Infinity);
      return {
        resultId: result?.id,
        setId: ps.id,
        paramSetName: ps.name,
        planFingerprint: result?.planFingerprint,
        totalCost: result?.totalCost,
        runtimeMs: result?.runtimeMs,
        totalBlkReadTime: result?.totalBlkReadTime,
        errorMessage: result?.errorMessage,
        resultSource: result?.resultSource,
        resultStatus: result?.status,
      };
    })
    .sort((a, b) => Number(a.setId) - Number(b.setId));

  if (noParamSets) {
    const result = explainQuery?.explainResults[0];
    variantData.push({
      resultId: result?.id,
      setId: null,
      paramSetName: null,
      planFingerprint: result?.planFingerprint,
      totalCost: result?.totalCost,
      runtimeMs: result?.runtimeMs,
      totalBlkReadTime: result?.totalBlkReadTime,
      errorMessage: result?.errorMessage,
      resultSource: result?.resultSource,
      resultStatus: result?.status,
    });
  }

  const plannerSettings =
    explainQuery &&
    Object.entries(explainQuery.plannerSettings as { [key: string]: boolean });

  return (
    <PageContent
      windowTitle={`EXPLAIN Workbook: ${workbook.name}`}
      title={workbook.name}
      pageCategory="explains"
      pageName="workbooks"
      layout="leftSidebar"
      featureNav={featureNav}
    >
      {explainQuery ? (
        <>
          <ExplainQueryPanel workbook={workbook} explainQuery={explainQuery} />
          {plannerSettings.length > 0 && (
            <Panel title="Planner Settings">
              <div className="grid grid-cols-2 p-2 gap-y-2 gap-x-4">
                {plannerSettings.map(([key, value], i) => {
                  return (
                    <div key={`planner-${i}`} className="flex">
                      <div className="grow">
                        <span className="font-mono">{key}</span>
                      </div>
                      <div className="font-mono">{value}</div>
                    </div>
                  );
                })}
              </div>
            </Panel>
          )}
          <Panel title="Query Plans">
            <Grid
              className="grid-cols-[34px_16%_1fr_repeat(3,18%)]"
              data={variantData}
              columns={[
                {
                  header: "",
                  disableSort: true,
                  field: "resultId",
                  renderer: function CompareSelectCell({ fieldData }) {
                    const checked = compareCandidates.includes(fieldData);
                    return (
                      <input
                        type="checkbox"
                        data-compare-id={fieldData}
                        className="cursor-pointer !mt-0.5"
                        checked={checked}
                        onChange={handleToggleCompareCandidate}
                      />
                    );
                  },
                },
                {
                  field: "planFingerprint",
                  header: "Plan",
                  renderer: function PlanFingerprintCell({
                    fieldData,
                    rowData,
                  }) {
                    if (rowData.resultId == null || fieldData == null) {
                      return (
                        <Link
                          to={databaseWorkbookVariantRun(
                            databaseId,
                            workbook.id,
                            explainQuery.id,
                          )}
                        >
                          Run EXPLAIN
                        </Link>
                      );
                    }
                    return (
                      <Link
                        to={databaseWorkbookVariantResult(
                          databaseId,
                          workbook.id,
                          explainQuery.id,
                          rowData.resultId,
                        )}
                      >
                        <ExplainFingerprint fingerprint={fieldData} />
                      </Link>
                    );
                  },
                },
                {
                  field: "setId",
                  header: "Variant",
                  renderer: () => explainQuery.name,
                },
                {
                  field: "paramSetName",
                  header: "Parameter Set",
                },
                {
                  field: "totalCost",
                  header: "Est. Cost",
                  renderer: NumberCell,
                  style: "number",
                  nullValue: "-",
                },
                {
                  field: "runtimeMs",
                  header: "Runtime",
                  renderer: ({ fieldData, rowData }) => {
                    return (
                      <RuntimeMsCell
                        fieldData={fieldData}
                        rowData={rowData}
                        fastestRuntime={fastestRuntime}
                      />
                    );
                  },
                  style: "number",
                },
              ]}
            />
          </Panel>
          <ExplainCompareLinkBar
            databaseId={databaseId}
            workbookId={workbook.id}
            compareCandidates={compareCandidates}
          />
        </>
      ) : (
        <Loading error />
      )}
      {/* sidebar */}
      <ExplainVariantSidebar
        workbook={workbook}
        explainQuery={explainQuery}
        permittedToTuneQueries={data.getDatabaseDetails.permittedToTuneQueries}
      />
    </PageContent>
  );
};

export const ExplainQueryPanel = ({
  workbook,
  explainQuery,
}: {
  workbook: ExplainWorkbookType;
  explainQuery: ExplainQueryType;
}) => {
  const [selectedParameterSetId, setSelectedParameterSetId] = useState("");
  const handleParameterSetSelected = (selected: string) => {
    setSelectedParameterSetId(selected);
  };
  const query = selectedParameterSetId
    ? explainQuery.queryTextWithParameters.find(
        (val) => val.parameterSetId === selectedParameterSetId,
      ).queryWithParameters
    : explainQuery.queryTextWithAlias;

  return (
    <div className="rounded-md bg-[#f7fafc] border border-[#E8E8EE] p-4 mb-4 grid gap-2 text-[#606060]">
      <div className="flex">
        <div className="flex-grow text-[18px] leading-6">
          {explainQuery.name}
        </div>
        <div>
          <select
            value={selectedParameterSetId}
            onChange={(e) => handleParameterSetSelected(e.target.value)}
            className="bg-inherit"
          >
            <option value="">With parameter names</option>
            {workbook.parameterSets.map((set) => {
              return (
                <option key={set.id} value={set.id}>
                  With {set.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div>
        <ExpandableSQL sql={query} />
      </div>
    </div>
  );
};

export default ExplainVariant;
