// @ts-nocheck
import * as Types from "../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type WithAppConfigQueryVariables = Types.Exact<{ [key: string]: never }>;

export type WithAppConfigQuery = {
  __typename?: "GraphqlQuery";
  getAppConfig: {
    __typename?: "AppConfig";
    id: string;
    pagerDutyAppId?: string | null;
    slackIntegrationAvailable: boolean;
    enterpriseEdition: boolean;
    emailIntegrationAvailable: boolean;
    sentryDsn?: string | null;
    sentryTracesSampleRate: number;
    stripePublishableKey?: string | null;
    domainName: string;
    changelogUrl: string;
  };
};

export const WithAppConfigDocument = gql`
  query WithAppConfig {
    getAppConfig {
      id
      pagerDutyAppId
      slackIntegrationAvailable
      enterpriseEdition
      emailIntegrationAvailable
      sentryDsn
      sentryTracesSampleRate
      stripePublishableKey
      domainName
      changelogUrl
    }
  }
`;

/**
 * __useWithAppConfigQuery__
 *
 * To run a query within a React component, call `useWithAppConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useWithAppConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWithAppConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useWithAppConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WithAppConfigQuery,
    WithAppConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WithAppConfigQuery, WithAppConfigQueryVariables>(
    WithAppConfigDocument,
    options,
  );
}
export function useWithAppConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WithAppConfigQuery,
    WithAppConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WithAppConfigQuery, WithAppConfigQueryVariables>(
    WithAppConfigDocument,
    options,
  );
}
export function useWithAppConfigSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WithAppConfigQuery,
        WithAppConfigQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WithAppConfigQuery,
    WithAppConfigQueryVariables
  >(WithAppConfigDocument, options);
}
export type WithAppConfigQueryHookResult = ReturnType<
  typeof useWithAppConfigQuery
>;
export type WithAppConfigLazyQueryHookResult = ReturnType<
  typeof useWithAppConfigLazyQuery
>;
export type WithAppConfigSuspenseQueryHookResult = ReturnType<
  typeof useWithAppConfigSuspenseQuery
>;
export type WithAppConfigQueryResult = Apollo.QueryResult<
  WithAppConfigQuery,
  WithAppConfigQueryVariables
>;
