import React from "react";

import { Link } from "react-router-dom";
import classNames from "classnames";

import { useRoutes } from "utils/routes";

const ExplainCompareLinkBar = ({
  databaseId,
  workbookId,
  compareCandidates,
}: {
  databaseId: string;
  workbookId: string;
  compareCandidates: string[];
}) => {
  const { databaseWorkbookCompare } = useRoutes();
  let candidateA: string, candidateB: string;
  if (compareCandidates.length > 0) {
    candidateA = compareCandidates[0];
  }
  if (compareCandidates.length > 1) {
    candidateB = candidateA;
    candidateA = compareCandidates[1];
  }
  let compareLink;
  if (candidateA != null && candidateB != null) {
    compareLink = databaseWorkbookCompare(
      databaseId,
      workbookId,
      candidateA,
      candidateB,
    );
  }
  return (
    <>
      <div className="min-h-[96px]" />
      <div className="fixed bottom-0 bg-white py-4 border-t flex items-center gap-2 w-full -ml-5 pl-5">
        <Link
          to={compareLink ?? ""}
          className={classNames(
            "block btn btn-primary",
            !compareLink && "disabled pointer-events-none",
          )}
        >
          Compare selected plans
        </Link>
        <div>
          {candidateA
            ? candidateB
              ? ""
              : "Select another plan"
            : "No plans selected"}
        </div>
      </div>
    </>
  );
};

export default ExplainCompareLinkBar;
