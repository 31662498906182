// @ts-nocheck
import * as Types from "../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type AccountSettingsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type AccountSettingsQuery = {
  __typename?: "GraphqlQuery";
  getCurrentUserDetails: {
    __typename?: "User";
    id: string;
    email: string;
    timezone?: string | null;
    fullname?: string | null;
    authProvider?: string | null;
    authUid?: string | null;
    authHasPassword?: boolean | null;
    receiveWeeklyReports: boolean;
    pendingReconfirmationEmail?: string | null;
    authOrganization?: {
      __typename?: "Organization";
      id: string;
      name: string;
      slug: string;
    } | null;
  };
};

export const AccountSettingsDocument = gql`
  query AccountSettings {
    getCurrentUserDetails {
      id
      email
      timezone
      fullname
      authProvider
      authUid
      authHasPassword
      authOrganization {
        id
        name
        slug
      }
      receiveWeeklyReports
      pendingReconfirmationEmail
    }
  }
`;

/**
 * __useAccountSettingsQuery__
 *
 * To run a query within a React component, call `useAccountSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountSettingsQuery,
    AccountSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountSettingsQuery, AccountSettingsQueryVariables>(
    AccountSettingsDocument,
    options,
  );
}
export function useAccountSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountSettingsQuery,
    AccountSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountSettingsQuery,
    AccountSettingsQueryVariables
  >(AccountSettingsDocument, options);
}
export function useAccountSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AccountSettingsQuery,
        AccountSettingsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AccountSettingsQuery,
    AccountSettingsQueryVariables
  >(AccountSettingsDocument, options);
}
export type AccountSettingsQueryHookResult = ReturnType<
  typeof useAccountSettingsQuery
>;
export type AccountSettingsLazyQueryHookResult = ReturnType<
  typeof useAccountSettingsLazyQuery
>;
export type AccountSettingsSuspenseQueryHookResult = ReturnType<
  typeof useAccountSettingsSuspenseQuery
>;
export type AccountSettingsQueryResult = Apollo.QueryResult<
  AccountSettingsQuery,
  AccountSettingsQueryVariables
>;
