import React, { useState } from "react";
import ModalContainer from "components/ModalContainer";

import Select from "components/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { faEdit, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import Button from "components/Button";
import { useParams } from "react-router-dom";
import styles from "../../PostgresSettingDetails/style.module.scss";

import { usePostgresSettingsForDatabaseQuery } from "./gql/Query.generated";

import Loading from "components/Loading";
import {
  PlannerSettingNames,
  PlannerSettingType,
  PlannerSettingVartypeType,
  validatePlannerSettingValue,
} from "./util";
import Tip from "components/Tip";
import Popover from "components/Popover";

export type PlannerSettings = {
  [key: string]: string;
};

export type CurrentPlannerSettings = {
  [key: string]: PlannerSettingType;
};

const PlannerSettingsPanel = ({
  onDismiss,
  plannerSettings,
  setPlannerSettings,
}: {
  onDismiss: () => void;
  plannerSettings: PlannerSettings;
  setPlannerSettings: (settings: PlannerSettings) => void;
}) => {
  const { databaseId } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedSetting, setSelectedSetting] = useState({
    name: "",
    value: "",
    description: "",
  });
  const { loading, error, data } = usePostgresSettingsForDatabaseQuery({
    variables: { databaseId, patterns: PlannerSettingNames },
  });

  if (error) {
    return <Loading error={!!error} />;
  }

  const currentSettings: CurrentPlannerSettings = {};
  if (data) {
    data.getPostgresSettings.forEach((setting) => {
      currentSettings[setting.name] = {
        value: setting.resetValue,
        docSnippetHtml: setting.docSnippetHtml,
        unit: setting.unit,
        shortDesc: setting.shortDesc,
        vartype: setting.vartype as unknown as PlannerSettingVartypeType,
        minVal: setting.minVal,
        maxVal: setting.maxVal,
        enumvals: setting.enumvals,
      };
    });
  }
  const currentNames = PlannerSettingNames.filter((n) =>
    Object.keys(currentSettings).includes(n),
  );

  const handleUpdatePlannerSettings = () => {
    const { valid, error } = validatePlannerSettingValue(
      selectedSetting.value,
      currentSettings[selectedSetting.name],
    );
    if (!valid) {
      setErrorMessage(error);
      return;
    }
    const newSettings = { ...plannerSettings };
    newSettings[selectedSetting.name] = selectedSetting.value;
    setPlannerSettings(newSettings);
    setSelectedSetting({ name: "", value: "", description: "" });
  };
  const handleRemovePlannerSetting = (name: string) => {
    const newSettings = { ...plannerSettings };
    delete newSettings[name];
    setPlannerSettings(newSettings);
  };

  const handleSettingSelected = (name: string) => {
    if (name) {
      setSelectedSetting({
        name: name,
        value: plannerSettings[name] || currentSettings[name].value,
        description: currentSettings[name].docSnippetHtml,
      });
    } else {
      setSelectedSetting({ name: "", value: "", description: "" });
    }
    setErrorMessage("");
  };

  return (
    <ModalContainer
      title="Edit Planner Settings"
      layout="centered"
      onClose={onDismiss}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="grid gap-2 mb-2">
            <div className="leading-7 font-medium">Custom planner settings</div>
            <div className="grid gap-2">
              {Object.keys(plannerSettings).length > 0 ? (
                Object.entries(plannerSettings).map(([key, value], i) => {
                  return (
                    <div key={`planner-${i}`} className="flex">
                      <div className="grow">
                        <span className="font-mono">{key}</span>{" "}
                        <Tip content={currentSettings[key].shortDesc} />
                      </div>
                      <div className="font-mono">
                        {value} (was {currentSettings[key].value})
                      </div>
                      <Button
                        bare
                        onClick={() => handleSettingSelected(key)}
                        className="ml-2"
                        variant="link"
                      >
                        <FontAwesomeIcon icon={faEdit} title="Edit" />
                      </Button>
                      <Button
                        bare
                        onClick={() => handleRemovePlannerSetting(key)}
                        className="ml-2"
                        variant="danger"
                      >
                        <FontAwesomeIcon icon={faTrashAlt} title="Remove" />
                      </Button>
                    </div>
                  );
                })
              ) : (
                <div>No custom planner settings</div>
              )}
            </div>
          </div>
          <hr />
          <div className="grid gap-2">
            <div className="leading-7 font-medium">
              Select a planner setting to edit
            </div>
            <div className="flex form-group items-center justify-between !mb-0">
              <div className="flex items-center">
                <Select
                  placeholder="Search settings..."
                  items={currentNames}
                  value={selectedSetting.name}
                  onChange={handleSettingSelected}
                  itemToString={(item) => item}
                  block
                />
                {selectedSetting.name && (
                  <>
                    <div className="w-[220px] ml-3">
                      {["bool", "enum"].includes(
                        currentSettings[selectedSetting.name].vartype,
                      ) ? (
                        <select
                          className="form-control"
                          defaultValue={selectedSetting.value}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            if (newValue != selectedSetting.value) {
                              setSelectedSetting({
                                ...selectedSetting,
                                value: newValue,
                              });
                            }
                            setErrorMessage("");
                          }}
                        >
                          {(
                            currentSettings[selectedSetting.name].enumvals ?? [
                              "on",
                              "off",
                            ]
                          ).map((value) => (
                            <option
                              key={`${selectedSetting.name}-${value}`}
                              value={value}
                            >
                              {value}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          className="form-control"
                          type="text"
                          onChange={(e) => {
                            const newValue = e.target.value;
                            if (newValue != selectedSetting.value) {
                              setSelectedSetting({
                                ...selectedSetting,
                                value: newValue,
                              });
                            }
                            setErrorMessage("");
                          }}
                          value={selectedSetting.value}
                        />
                      )}
                    </div>
                    {currentSettings[selectedSetting.name].unit && (
                      <div className="ml-2">
                        (unit: {currentSettings[selectedSetting.name].unit})
                      </div>
                    )}
                  </>
                )}
              </div>
              {selectedSetting.name && (
                <div className="ml-2">
                  <Popover
                    content={
                      selectedSetting.value ===
                        currentSettings[selectedSetting.name].value &&
                      "Change the setting value to add customized setting"
                    }
                  >
                    <button
                      className="btn btn-success"
                      onClick={handleUpdatePlannerSettings}
                      disabled={
                        selectedSetting.value ===
                        currentSettings[selectedSetting.name].value
                      }
                    >
                      Add customized setting
                    </button>
                  </Popover>
                </div>
              )}
            </div>
            {errorMessage && (
              <div className="text-[#C22426]">
                <FontAwesomeIcon icon={faExclamationCircle} /> {errorMessage}
              </div>
            )}
            {selectedSetting.name && (
              <div className="grid gap-2">
                <div className="rounded border border-[#E8E8EE] p-2">
                  {
                    // eslint-disable-next-line react/no-danger
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedSetting.description,
                      }}
                      className={styles.snippet}
                    />
                  }
                </div>
              </div>
            )}
            {Object.keys(plannerSettings).length > 0 && (
              <div>
                <hr />
                <button
                  className="btn btn-success w-[140px]"
                  onClick={onDismiss}
                >
                  Submit Settings
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </ModalContainer>
  );
};

export default PlannerSettingsPanel;
