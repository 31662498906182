// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type PlanComparisonQueryVariables = Types.Exact<{
  organizationSlug: Types.Scalars["ID"]["input"];
}>;

export type PlanComparisonQuery = {
  __typename?: "GraphqlQuery";
  getOrganizationBilling?: {
    __typename?: "OrganizationBilling";
    id: string;
    planChoices: Array<{
      __typename?: "OrganizationPlanChoice";
      id: string;
      name: string;
      formattedMonthlyPrice: string;
      formattedServerOveragePrice?: string | null;
      replicaBillingMultiplier: number;
      isCurrent: boolean;
      isDowngrade: boolean;
      isHidden: boolean;
      isSelfServe: boolean;
      serversIncluded?: number | null;
    }>;
  } | null;
};

export const PlanComparisonDocument = gql`
  query PlanComparison($organizationSlug: ID!) {
    getOrganizationBilling(organizationSlug: $organizationSlug) {
      id
      planChoices {
        id
        name
        formattedMonthlyPrice
        formattedServerOveragePrice
        replicaBillingMultiplier
        isCurrent
        isDowngrade
        isHidden
        isSelfServe
        serversIncluded
      }
    }
  }
`;

/**
 * __usePlanComparisonQuery__
 *
 * To run a query within a React component, call `usePlanComparisonQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanComparisonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanComparisonQuery({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function usePlanComparisonQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlanComparisonQuery,
    PlanComparisonQueryVariables
  > &
    (
      | { variables: PlanComparisonQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlanComparisonQuery, PlanComparisonQueryVariables>(
    PlanComparisonDocument,
    options,
  );
}
export function usePlanComparisonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlanComparisonQuery,
    PlanComparisonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlanComparisonQuery, PlanComparisonQueryVariables>(
    PlanComparisonDocument,
    options,
  );
}
export function usePlanComparisonSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PlanComparisonQuery,
        PlanComparisonQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PlanComparisonQuery,
    PlanComparisonQueryVariables
  >(PlanComparisonDocument, options);
}
export type PlanComparisonQueryHookResult = ReturnType<
  typeof usePlanComparisonQuery
>;
export type PlanComparisonLazyQueryHookResult = ReturnType<
  typeof usePlanComparisonLazyQuery
>;
export type PlanComparisonSuspenseQueryHookResult = ReturnType<
  typeof usePlanComparisonSuspenseQuery
>;
export type PlanComparisonQueryResult = Apollo.QueryResult<
  PlanComparisonQuery,
  PlanComparisonQueryVariables
>;
