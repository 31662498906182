// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
export type BillingOverviewFragmentFragment = {
  __typename?: "OrganizationBilling";
  id: string;
  subscriptionActiveInStripe: boolean;
  subscriptionNextChargeAt?: number | null;
  subscriptionNextChargeAmount?: number | null;
  subscriptionNextChargeCurrency?: string | null;
  subscriptionCardType?: string | null;
  subscriptionCardLast4?: string | null;
  subscriptionCardExpMonth?: number | null;
  subscriptionCardExpYear?: number | null;
  subscriptionBilledManually: boolean;
  subscriptionInterval?: Types.SubscriptionInterval | null;
  billingAddressWithName?: string | null;
  billingVatId?: string | null;
  billingEmail?: string | null;
  paymentRequiresAction: boolean;
  stripeClientSecret?: string | null;
  invoices: Array<{
    __typename?: "Invoice";
    id: string;
    reference: string;
    formattedTotal: string;
    invoicePdf?: string | null;
    status: string;
    created: number;
  }>;
  planChoices: Array<{
    __typename?: "OrganizationPlanChoice";
    id: string;
    name: string;
    marketingLines: Array<string>;
    notIncludedMarketingLines: Array<string>;
    formattedMonthlyPrice: string;
    formattedServerOveragePrice?: string | null;
    isCurrent: boolean;
    isDowngrade: boolean;
  }>;
};

export const BillingOverviewFragmentFragmentDoc = gql`
  fragment BillingOverviewFragment on OrganizationBilling {
    id
    subscriptionActiveInStripe
    subscriptionNextChargeAt
    subscriptionNextChargeAmount
    subscriptionNextChargeCurrency
    subscriptionCardType
    subscriptionCardLast4
    subscriptionCardExpMonth
    subscriptionCardExpYear
    subscriptionBilledManually
    subscriptionInterval
    billingAddressWithName
    billingVatId
    billingEmail
    invoices {
      id
      reference
      formattedTotal
      invoicePdf
      status
      created
    }
    planChoices {
      id
      name
      marketingLines
      notIncludedMarketingLines
      formattedMonthlyPrice
      formattedServerOveragePrice
      isCurrent
      isDowngrade
    }
    paymentRequiresAction
    stripeClientSecret
  }
`;
