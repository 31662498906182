// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type DeleteExplainQueryMutationVariables = Types.Exact<{
  explainQueryId: Types.Scalars["ID"]["input"];
}>;

export type DeleteExplainQueryMutation = {
  __typename?: "Mutation";
  deleteExplainQuery?: {
    __typename?: "DeleteExplainQueryPayload";
    explainWorkbookId: string;
  } | null;
};

export const DeleteExplainQueryDocument = gql`
  mutation DeleteExplainQuery($explainQueryId: ID!) {
    deleteExplainQuery(explainQueryId: $explainQueryId) {
      explainWorkbookId
    }
  }
`;
export type DeleteExplainQueryMutationFn = Apollo.MutationFunction<
  DeleteExplainQueryMutation,
  DeleteExplainQueryMutationVariables
>;

/**
 * __useDeleteExplainQueryMutation__
 *
 * To run a mutation, you first call `useDeleteExplainQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExplainQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExplainQueryMutation, { data, loading, error }] = useDeleteExplainQueryMutation({
 *   variables: {
 *      explainQueryId: // value for 'explainQueryId'
 *   },
 * });
 */
export function useDeleteExplainQueryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteExplainQueryMutation,
    DeleteExplainQueryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteExplainQueryMutation,
    DeleteExplainQueryMutationVariables
  >(DeleteExplainQueryDocument, options);
}
export type DeleteExplainQueryMutationHookResult = ReturnType<
  typeof useDeleteExplainQueryMutation
>;
export type DeleteExplainQueryMutationResult =
  Apollo.MutationResult<DeleteExplainQueryMutation>;
export type DeleteExplainQueryMutationOptions = Apollo.BaseMutationOptions<
  DeleteExplainQueryMutation,
  DeleteExplainQueryMutationVariables
>;
