// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ActivatePlanQueryVariables = Types.Exact<{
  organizationSlug: Types.Scalars["ID"]["input"];
}>;

export type ActivatePlanQuery = {
  __typename?: "GraphqlQuery";
  getOrganizationBilling?: {
    __typename?: "OrganizationBilling";
    id: string;
    billingAddressWithName?: string | null;
    billingCompanyName?: string | null;
    billingAddressLine1?: string | null;
    billingAddressLine2?: string | null;
    billingAddressZipCode?: string | null;
    billingAddressCity?: string | null;
    billingAddressState?: string | null;
    billingAddressCountry?: string | null;
    billingVatId?: string | null;
    billingEmail?: string | null;
    billingCurrency: string;
    subscriptionCardType?: string | null;
    subscriptionCardLast4?: string | null;
    subscriptionCardExpMonth?: number | null;
    subscriptionCardExpYear?: number | null;
    planChoices: Array<{
      __typename?: "OrganizationPlanChoice";
      id: string;
      name: string;
      serversIncluded?: number | null;
      serverCountForBilling: number;
      replicaBillingMultiplier: number;
      billingCurrencyServerOveragePrice?: number | null;
      billingCurrencyMonthlyPrice: number;
      formattedServerOveragePrice?: string | null;
      formattedMonthlyPrice: string;
      isCurrent: boolean;
      isDowngrade: boolean;
      isSelfServe: boolean;
      marketingLines: Array<string>;
    }>;
  } | null;
  getOrganizationDetails?: {
    __typename?: "Organization";
    id: string;
    serverLimit?: number | null;
    subscriptionExtraServers: number;
    replicaWithRecentDataCount: number;
    planInfo: {
      __typename?: "OrganizationPlanInfo";
      id: string;
      isTrial: boolean;
      expiredTrial: boolean;
      subscriptionPlanId: string;
    };
  } | null;
};

export const ActivatePlanDocument = gql`
  query ActivatePlan($organizationSlug: ID!) {
    getOrganizationBilling(organizationSlug: $organizationSlug) {
      id
      billingAddressWithName
      billingCompanyName
      billingAddressLine1
      billingAddressLine2
      billingAddressZipCode
      billingAddressCity
      billingAddressState
      billingAddressCountry
      billingVatId
      billingEmail
      billingCurrency
      subscriptionCardType
      subscriptionCardLast4
      subscriptionCardExpMonth
      subscriptionCardExpYear
      planChoices {
        id
        name
        serversIncluded
        serverCountForBilling
        replicaBillingMultiplier
        billingCurrencyServerOveragePrice
        billingCurrencyMonthlyPrice
        formattedServerOveragePrice
        formattedMonthlyPrice
        isCurrent
        isDowngrade
        isSelfServe
        marketingLines
      }
    }
    getOrganizationDetails(organizationSlug: $organizationSlug) {
      id
      serverLimit
      subscriptionExtraServers
      replicaWithRecentDataCount
      planInfo {
        id
        isTrial
        expiredTrial
        subscriptionPlanId
      }
    }
  }
`;

/**
 * __useActivatePlanQuery__
 *
 * To run a query within a React component, call `useActivatePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivatePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivatePlanQuery({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useActivatePlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActivatePlanQuery,
    ActivatePlanQueryVariables
  > &
    (
      | { variables: ActivatePlanQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActivatePlanQuery, ActivatePlanQueryVariables>(
    ActivatePlanDocument,
    options,
  );
}
export function useActivatePlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivatePlanQuery,
    ActivatePlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActivatePlanQuery, ActivatePlanQueryVariables>(
    ActivatePlanDocument,
    options,
  );
}
export function useActivatePlanSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ActivatePlanQuery,
        ActivatePlanQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ActivatePlanQuery, ActivatePlanQueryVariables>(
    ActivatePlanDocument,
    options,
  );
}
export type ActivatePlanQueryHookResult = ReturnType<
  typeof useActivatePlanQuery
>;
export type ActivatePlanLazyQueryHookResult = ReturnType<
  typeof useActivatePlanLazyQuery
>;
export type ActivatePlanSuspenseQueryHookResult = ReturnType<
  typeof useActivatePlanSuspenseQuery
>;
export type ActivatePlanQueryResult = Apollo.QueryResult<
  ActivatePlanQuery,
  ActivatePlanQueryVariables
>;
