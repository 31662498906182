// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type DeleteExplainResultsMutationVariables = Types.Exact<{
  explainQueryId: Types.Scalars["ID"]["input"];
  parameterSetsSelected: Types.Scalars["Boolean"]["input"];
}>;

export type DeleteExplainResultsMutation = {
  __typename?: "Mutation";
  deleteExplainResults?: {
    __typename?: "DeleteExplainResultsPayload";
    explainWorkbookId: string;
  } | null;
};

export const DeleteExplainResultsDocument = gql`
  mutation DeleteExplainResults(
    $explainQueryId: ID!
    $parameterSetsSelected: Boolean!
  ) {
    deleteExplainResults(
      explainQueryId: $explainQueryId
      parameterSetsSelected: $parameterSetsSelected
    ) {
      explainWorkbookId
    }
  }
`;
export type DeleteExplainResultsMutationFn = Apollo.MutationFunction<
  DeleteExplainResultsMutation,
  DeleteExplainResultsMutationVariables
>;

/**
 * __useDeleteExplainResultsMutation__
 *
 * To run a mutation, you first call `useDeleteExplainResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExplainResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExplainResultsMutation, { data, loading, error }] = useDeleteExplainResultsMutation({
 *   variables: {
 *      explainQueryId: // value for 'explainQueryId'
 *      parameterSetsSelected: // value for 'parameterSetsSelected'
 *   },
 * });
 */
export function useDeleteExplainResultsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteExplainResultsMutation,
    DeleteExplainResultsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteExplainResultsMutation,
    DeleteExplainResultsMutationVariables
  >(DeleteExplainResultsDocument, options);
}
export type DeleteExplainResultsMutationHookResult = ReturnType<
  typeof useDeleteExplainResultsMutation
>;
export type DeleteExplainResultsMutationResult =
  Apollo.MutationResult<DeleteExplainResultsMutation>;
export type DeleteExplainResultsMutationOptions = Apollo.BaseMutationOptions<
  DeleteExplainResultsMutation,
  DeleteExplainResultsMutationVariables
>;
