import React, { useContext, useEffect, useMemo } from "react";

import classNames from "classnames";

import PageAnalytics from "components/PageAnalytics";
import PageHeaderNav from "components/PageHeaderNav";
import PageFlash from "components/PageFlash";
import { useSetSkipLegacyStyles } from "components/WithLegacyStyles";

import styles from "./style.module.scss";

type Props = {
  title?: React.ReactNode;
  secondaryTitle?: React.ReactNode;
  pageControls?: React.ReactNode;
  featureInfo?: React.ReactNode;
  featureNav?: React.ReactNode;
  layout?: Layout;
  windowTitle?: string | null | undefined;
  pageCategory?: string | null | undefined;
  pageName?: string | null | undefined;
  pageProps?: unknown | null | undefined;
  pageTab?: string;
  skipLegacyStyles?: boolean;
  children: React.ReactNode;
};

type PageInfo = {
  pageCategory: string;
  pageName: string;
};

const PageContext = React.createContext<PageInfo>({
  pageCategory: "unset",
  pageName: "unset",
});

export const useCurrentPage = (): PageInfo => {
  return useContext(PageContext);
};

type ContentLayout = (props: { children: React.ReactNode }) => React.ReactNode;

const DefaultLayout: ContentLayout = ({ children }) => {
  return (
    <div className="flex-grow mt-[15px] px-5 pb-20 flex flex-col items-stretch">
      {children}
    </div>
  );
};

const SidebarLayout: ContentLayout = ({ children }) => {
  let arr = React.Children.toArray(children);
  if (arr.length === 1) {
    const child = arr[0] as React.ReactElement;
    if (child.type === React.Fragment) {
      arr = React.Children.toArray(child.props.children);
    }
  }
  const main = arr.slice(0, -1);
  const sidebar = arr.at(-1);
  return (
    <div className="flex-grow h-min flex items-stretch overflow-y-auto">
      <div className="mt-[15px] px-5 overflow-y-auto flex-grow flex flex-col items-stretch">
        {main}
      </div>
      <div className="pt-[15px] px-4 overflow-y-auto min-w-max max-w-fit border-l">
        {sidebar}
      </div>
    </div>
  );
};

const LeftSidebarLayout: ContentLayout = ({ children }) => {
  let arr = React.Children.toArray(children);
  if (arr.length === 1) {
    const child = arr[0] as React.ReactElement;
    if (child.type === React.Fragment) {
      arr = React.Children.toArray(child.props.children);
    }
  }
  const main = arr.slice(0, -1);
  const sidebar = arr.at(-1);
  return (
    <div className="flex-grow h-min flex items-stretch overflow-y-auto">
      <div className="pt-[15px] px-4 overflow-y-auto min-w-max max-w-fit border-r">
        {sidebar}
      </div>
      <div className="mt-[15px] px-5 overflow-y-auto flex-grow flex flex-col items-stretch">
        {main}
      </div>
    </div>
  );
};

const Layouts = {
  default: DefaultLayout,
  sidebar: SidebarLayout,
  leftSidebar: LeftSidebarLayout,
  bare: React.Fragment,
} as const;

type Layout = keyof typeof Layouts;

const PageContent: React.FunctionComponent<Props> = ({
  title,
  windowTitle,
  secondaryTitle,
  pageControls,
  featureInfo,
  featureNav,
  pageCategory,
  pageName,
  pageTab,
  pageProps,
  layout,
  skipLegacyStyles,
  children,
}) => {
  const setSkipLegacyStyles = useSetSkipLegacyStyles();
  useEffect(() => {
    setSkipLegacyStyles(skipLegacyStyles);
  }, [skipLegacyStyles, setSkipLegacyStyles]);

  useEffect(() => {
    if (!windowTitle && !title) {
      return;
    }

    const newTitle = windowTitle || title;

    window.document.title = newTitle + " · pganalyze";
  }, [windowTitle, title]);

  const pageInfo = useMemo(
    () => ({
      pageCategory,
      pageName,
    }),
    [pageCategory, pageName],
  );
  const LayoutComponent = Layouts[layout ?? "default"];

  return (
    <PageContext.Provider value={pageInfo}>
      <PageAnalytics
        pageCategory={pageCategory}
        pageName={pageName}
        pageTab={pageTab}
        pageProps={pageProps}
      />
      <div className="h-full flex flex-col">
        <PageHeaderNav className="flex-none" pageControls={pageControls} />
        <div className="relative overflow-auto flex-grow flex flex-col isolate">
          <PageFlash />
          <div className={classNames("flex-grow-0", styles.header)}>
            {title && <h2 className={styles.title}>{title}</h2>}
            {secondaryTitle && (
              <small className={styles.secondaryTitle}>{secondaryTitle}</small>
            )}
            {featureInfo}
            {featureNav}
          </div>
          <LayoutComponent>{children}</LayoutComponent>
        </div>
      </div>
    </PageContext.Provider>
  );
};

export default PageContent;
