import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { ExplainWorkbookType } from "../ExplainWorkbook";
import { useCreateExplainParameterSetsMutation } from "../ChooseParameters/gql/Mutation.create.generated";

import {
  convertParamValue,
  ParamSetType,
  ParamSetValueType,
  validateParamSet,
} from "../ExplainWorkbook/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";

// We don't want to allow editing the actual parameter types in this form, since
// they must be the same for all parameter sets
type NewParamSetType = {
  [alias: string]: Omit<ParamSetValueType, "type">;
};

const CreateParameterSetPanel = ({
  workbook,
  addParamMethod,
}: {
  workbook: ExplainWorkbookType;
  addParamMethod: "query" | "manual";
}) => {
  const baselineQuery = workbook.baselineQuery;
  const { databaseId } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [customParamsQuery, setCustomParamsQuery] = useState("");

  const [newParamSet, setNewParamSet] = useState<NewParamSetType>(() =>
    initializeNewParamSet(workbook),
  );
  const paramSet = toParamSet(newParamSet, workbook);

  const [createExplainParameterSets] = useCreateExplainParameterSetsMutation();

  const handleAddParamsFromQuery = () => {
    if (customParamsQuery == "") {
      setErrorMessage("Query is required");
      return;
    }
    createExplainParameterSets({
      variables: {
        databaseId,
        explainQueryId: baselineQuery.id,
        queryText: customParamsQuery,
      },
      onCompleted: () => {
        setErrorMessage("");
        setCustomParamsQuery("");
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const handleAddParamsManual = () => {
    const validateError = validateParamSet(
      paramSet,
      Object.keys(baselineQuery.paramRefAliasMap).length,
    );
    if (validateError) {
      setErrorMessage(validateError);
      return;
    }
    const newSet = Object.entries(paramSet).reduce((accum, [ref, set]) => {
      accum[ref] = {
        value: convertParamValue(set),
        type: set.type,
      };
      return accum;
    }, {} as ParamSetType);
    createExplainParameterSets({
      variables: {
        databaseId,
        explainQueryId: baselineQuery.id,
        parameterSet: newSet,
      },
      onCompleted: () => {
        setErrorMessage("");
        setNewParamSet(initializeNewParamSet(workbook));
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const handleParamValueChange = (alias: string, value: string) => {
    const prevParam = newParamSet[alias];
    const newSet = { ...newParamSet };

    newSet[alias] = {
      value: value,
      isNull: prevParam?.isNull,
    };
    setNewParamSet(newSet);
  };
  const handleParamNullChange = (alias: string, isNull: boolean) => {
    const prevParam = newParamSet[alias];
    const newSet = { ...newParamSet };
    // when null is checked but there is an existing value, empty it
    newSet[alias] = {
      value: isNull ? undefined : prevParam?.value,
      isNull: isNull,
    };
    setNewParamSet(newSet);
  };

  const inputFields = Object.entries(baselineQuery.paramRefAliasMap).flatMap(
    ([ref, alias]) => {
      const aliasStr = alias as string;
      return [
        <div key={`label-${ref}`}>{aliasStr}</div>,
        <input
          key={`input-${ref}`}
          className="bg-white rounded border border-gray-300 box-content h-5 leading-5 px-2 py-1.5 disabled:bg-[#eee]"
          type="text"
          value={paramSet[aliasStr]?.value ?? ""}
          disabled={paramSet[aliasStr]?.isNull}
          onChange={(e) => handleParamValueChange(aliasStr, e.target.value)}
        />,
        <input
          key={`null-${ref}`}
          type="checkbox"
          checked={paramSet[aliasStr]?.isNull || false}
          onChange={(e) => handleParamNullChange(aliasStr, e.target.checked)}
        />,
        <div key={`type-${ref}`}>
          {paramSet[aliasStr]?.type ?? "Auto-detect"}
        </div>,
      ];
    },
  );

  return (
    <div className="grid gap-4">
      {addParamMethod === "query" ? (
        <div>
          <textarea
            className="bg-white rounded border border-gray-300 box-border w-full leading-5 px-2 py-1.5 disabled:bg-[#eee]"
            placeholder="Paste query with parameter values..."
            value={customParamsQuery}
            rows={10}
            onChange={(e) => setCustomParamsQuery(e.target.value)}
          />
        </div>
      ) : (
        <div>
          <div className="grid grid-cols-[min-content_1fr_40px_200px] gap-2 mb-4 items-center font-medium">
            <div>Name</div>
            <div>Value</div>
            <div>NULL</div>
            <div>Type</div>
            {Object.values(inputFields)}
          </div>
        </div>
      )}
      {errorMessage && (
        <div className="text-[#C22426]">
          <FontAwesomeIcon icon={faExclamationCircle} /> {errorMessage}
        </div>
      )}
      <div>
        <button
          className="btn btn-primary"
          onClick={
            addParamMethod === "query"
              ? handleAddParamsFromQuery
              : handleAddParamsManual
          }
        >
          Create Custom Parameter Set
        </button>
      </div>
    </div>
  );
};

function toParamSet(
  newSet: NewParamSetType,
  workbook: ExplainWorkbookType,
): ParamSetType {
  return Object.fromEntries(
    Object.entries(newSet).map(([alias, param]) => {
      const aliasName = alias.substring(1); // strip the leading '$'
      const typeIdx = workbook.parameterRefAliases.findIndex(
        (alias) => alias === aliasName,
      );
      const type = workbook.parameterSetTypes[typeIdx];
      return [
        alias,
        {
          ...param,
          type,
        },
      ];
    }),
  );
}

function initializeNewParamSet(workbook: ExplainWorkbookType): NewParamSetType {
  return workbook.parameterRefAliases.reduce((ps, alias) => {
    ps[`$${alias}`] = {
      value: "",
      isNull: false,
    };
    return ps;
  }, {} as NewParamSetType);
}

export default CreateParameterSetPanel;
