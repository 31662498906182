import React from "react";

import classNames from "classnames";

import styles from "./style.module.scss";

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  bare?: boolean;
  variant?: "link" | "danger";
};

const Button: React.FunctionComponent<Props> = ({
  className,
  bare,
  variant,
  children,
  ...rest
}) => {
  const baseStyle = !bare && styles.outlineBtn;
  const linkColorStyle =
    variant === "link"
      ? "!text-[#337ab7] hover:text-[#23527c]"
      : variant === "danger"
      ? "!text-[#d43f3a] hover:text-[#ac2925]"
      : undefined;
  return (
    <button
      className={classNames(baseStyle, linkColorStyle, className)}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
