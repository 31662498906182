// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type DeleteExplainWorkbookMutationVariables = Types.Exact<{
  workbookId: Types.Scalars["ID"]["input"];
}>;

export type DeleteExplainWorkbookMutation = {
  __typename?: "Mutation";
  deleteExplainWorkbook: {
    __typename?: "DeleteExplainWorkbookPayload";
    databaseId: string;
  };
};

export const DeleteExplainWorkbookDocument = gql`
  mutation DeleteExplainWorkbook($workbookId: ID!) {
    deleteExplainWorkbook(workbookId: $workbookId) {
      databaseId
    }
  }
`;
export type DeleteExplainWorkbookMutationFn = Apollo.MutationFunction<
  DeleteExplainWorkbookMutation,
  DeleteExplainWorkbookMutationVariables
>;

/**
 * __useDeleteExplainWorkbookMutation__
 *
 * To run a mutation, you first call `useDeleteExplainWorkbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExplainWorkbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExplainWorkbookMutation, { data, loading, error }] = useDeleteExplainWorkbookMutation({
 *   variables: {
 *      workbookId: // value for 'workbookId'
 *   },
 * });
 */
export function useDeleteExplainWorkbookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteExplainWorkbookMutation,
    DeleteExplainWorkbookMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteExplainWorkbookMutation,
    DeleteExplainWorkbookMutationVariables
  >(DeleteExplainWorkbookDocument, options);
}
export type DeleteExplainWorkbookMutationHookResult = ReturnType<
  typeof useDeleteExplainWorkbookMutation
>;
export type DeleteExplainWorkbookMutationResult =
  Apollo.MutationResult<DeleteExplainWorkbookMutation>;
export type DeleteExplainWorkbookMutationOptions = Apollo.BaseMutationOptions<
  DeleteExplainWorkbookMutation,
  DeleteExplainWorkbookMutationVariables
>;
