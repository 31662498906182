// @ts-nocheck
import * as Types from "../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ExplainWorkbooksQueryVariables = Types.Exact<{
  databaseId: Types.Scalars["ID"]["input"];
}>;

export type ExplainWorkbooksQuery = {
  __typename?: "GraphqlQuery";
  getExplainWorkbooks: Array<{
    __typename?: "ExplainWorkbook";
    id: string;
    name: string;
    description?: string | null;
    createdAt: number;
    lastActivityAt: number;
    variantsCount: number;
    draft: boolean;
    user?: { __typename?: "User"; id: string; fullname?: string | null } | null;
  }>;
  getDatabaseDetails: {
    __typename?: "Database";
    id: string;
    permittedToTuneQueries: boolean;
  };
};

export const ExplainWorkbooksDocument = gql`
  query ExplainWorkbooks($databaseId: ID!) {
    getExplainWorkbooks(databaseId: $databaseId) {
      id
      name
      description
      createdAt
      lastActivityAt
      variantsCount
      draft
      user {
        id
        fullname
      }
    }
    getDatabaseDetails(databaseId: $databaseId) {
      id
      permittedToTuneQueries
    }
  }
`;

/**
 * __useExplainWorkbooksQuery__
 *
 * To run a query within a React component, call `useExplainWorkbooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplainWorkbooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplainWorkbooksQuery({
 *   variables: {
 *      databaseId: // value for 'databaseId'
 *   },
 * });
 */
export function useExplainWorkbooksQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplainWorkbooksQuery,
    ExplainWorkbooksQueryVariables
  > &
    (
      | { variables: ExplainWorkbooksQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExplainWorkbooksQuery, ExplainWorkbooksQueryVariables>(
    ExplainWorkbooksDocument,
    options,
  );
}
export function useExplainWorkbooksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplainWorkbooksQuery,
    ExplainWorkbooksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplainWorkbooksQuery,
    ExplainWorkbooksQueryVariables
  >(ExplainWorkbooksDocument, options);
}
export function useExplainWorkbooksSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExplainWorkbooksQuery,
        ExplainWorkbooksQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplainWorkbooksQuery,
    ExplainWorkbooksQueryVariables
  >(ExplainWorkbooksDocument, options);
}
export type ExplainWorkbooksQueryHookResult = ReturnType<
  typeof useExplainWorkbooksQuery
>;
export type ExplainWorkbooksLazyQueryHookResult = ReturnType<
  typeof useExplainWorkbooksLazyQuery
>;
export type ExplainWorkbooksSuspenseQueryHookResult = ReturnType<
  typeof useExplainWorkbooksSuspenseQuery
>;
export type ExplainWorkbooksQueryResult = Apollo.QueryResult<
  ExplainWorkbooksQuery,
  ExplainWorkbooksQueryVariables
>;
