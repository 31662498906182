import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import ExplainPanel from "components/ExplainPanel";
import type { ExplainPlanType, QuerySampleType } from "components/Explain/util";
import { useExplainResultQuery } from "./gql/Query.generated";

import Loading from "components/Loading";
import PageContent from "components/PageContent";
import { ExplainWorkbookFeatureNav } from "../ExplainWorkbook";
import { useRoutes } from "utils/routes";

const ExplainResult = () => {
  const { databaseId, workbookId, variantId, resultId } = useParams();
  const navigate = useNavigate();
  const { databaseWorkbookVariantResult } = useRoutes();

  const { loading, error, data } = useExplainResultQuery({
    variables: {
      databaseId,
      workbookId,
      explainQueryId: variantId,
      resultId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const blockSize = data.getServerDetails.blockSize;
  const explainResult = data.getExplainQueryResult;
  const workbook = data.getExplainWorkbookDetails;
  const explainQueries = [
    data.getExplainWorkbookDetails.baselineQuery,
    ...data.getExplainWorkbookDetails.explainQueries,
  ];
  const explainQuery = explainQueries.find((val) => val.id === variantId);
  const paramSet = explainResult.usedParameterSet;
  const handleVariantChange = (variantId: string) => {
    if (variantId !== explainQuery.id) {
      const newVariant = explainQueries.find(
        (variant) => variant.id === variantId,
      );
      // If a new variant doesn't have current param set's result yet, show the first result instead
      const newResultId =
        newVariant.explainResults.find(
          (result) => paramSet && result.parameterSetId === paramSet.id,
        )?.id || newVariant.explainResults[0].id;
      return navigate(
        databaseWorkbookVariantResult(
          databaseId,
          workbookId,
          newVariant.id,
          newResultId,
        ),
      );
    }
  };
  const handleParameterSetChange = (setId: string) => {
    if (setId !== paramSet.id) {
      const newResultId = explainQuery.explainResults.find(
        (result) => result.parameterSetId === setId,
      ).id;
      return navigate(
        databaseWorkbookVariantResult(
          databaseId,
          workbookId,
          variantId,
          newResultId,
        ),
      );
    }
  };

  const explainPlan: ExplainPlanType = {
    id: explainResult.id,
    humanId: explainResult.id,
    seenAt: explainResult.createdAt,
    querySample: {
      id: explainResult.id,
      occurredAt: explainResult.createdAt,
      runtimeMs: explainResult.runtimeMs,
      queryText: explainResult.queryText,
    } as QuerySampleType,
    query: { id: explainResult.id },
    format: explainResult.format,
    fingerprint: explainResult.planFingerprint,
    outputText: explainResult.outputText,
    annotatedJson: explainResult.annotatedJson,
    totalCost: explainResult.totalCost,
    totalSharedBlksRead: explainResult.totalSharedBlksRead,
    totalBlkReadTime: explainResult.totalBlkReadTime,
  };
  const title = (
    <div className="text-[#606060]">
      <h2 className="m-0 py-[9px] font-medium leading-[26px] text-[22px]">
        {workbook.name}
      </h2>
      <div className="flex">
        <div className="mr-4">
          Variant:{" "}
          <select
            value={explainQuery.id}
            onChange={(e) => handleVariantChange(e.target.value)}
            className="bg-inherit"
          >
            {explainQueries.map((variant) => {
              if (variant.explainResults.length === 0) {
                return null;
              }
              return (
                <option key={variant.id} value={variant.id}>
                  {variant.name}
                </option>
              );
            })}
          </select>
        </div>
        {paramSet && (
          <div>
            Parameter Set:{" "}
            <select
              value={paramSet.id}
              onChange={(e) => handleParameterSetChange(e.target.value)}
              className="bg-inherit"
            >
              {workbook.parameterSets.map((set) => {
                return (
                  <option key={set.id} value={set.id}>
                    {set.name}
                  </option>
                );
              })}
            </select>
          </div>
        )}
      </div>
    </div>
  );
  return (
    <PageContent
      windowTitle={`EXPLAIN Workbook: ${workbook.name}`}
      featureInfo={title}
      pageCategory="explains"
      pageName="workbooks"
      layout="bare"
      featureNav={
        <ExplainWorkbookFeatureNav
          workbookId={workbookId}
          databaseId={databaseId}
        />
      }
    >
      <ExplainPanel
        explain={explainPlan}
        databaseId={databaseId}
        blockSize={blockSize}
        hideComparison
      />
    </PageContent>
  );
};

export default ExplainResult;
