import React, { useState } from "react";

import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/pro-light-svg-icons";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTable from "components/PanelTable";
import ContactSupportLink from "components/ContactSupportLink";

import { formatDateShort } from "utils/format";

import { BillingOverviewQuery } from "../Overview/gql/Query.generated";
export type InvoiceType =
  BillingOverviewQuery["getOrganizationBilling"]["invoices"][number];
import { usePayInvoiceMutation } from "./gql/Mutation.generated";
import Button from "components/Button";
import { useStripe } from "@stripe/react-stripe-js";
import Tip from "components/Tip";
import { useAsyncActionFlash, useSetFlash } from "components/WithFlashes";
import ModalContainer from "components/ModalContainer";

const InvoiceList: React.FunctionComponent<{
  organizationSlug: string;
  organizationCreatedAt: number;
  invoices: InvoiceType[];
  refetchOrganizationBilling: () => void;
}> = ({
  organizationSlug,
  organizationCreatedAt,
  invoices,
  refetchOrganizationBilling,
}) => {
  const stripe = useStripe();
  const setFlash = useSetFlash();
  const [showPaymentInProgress, setShowPaymentInProgress] = useState(false);

  const [payInvoice, { called, loading }] = usePayInvoiceMutation();
  useAsyncActionFlash({
    called: called,
    loading: loading,
  });

  const handlePayInvoice = (invoiceId: string) => {
    setShowPaymentInProgress(true);
    payInvoice({
      variables: { organizationSlug, invoiceId },
      onCompleted: (data) => {
        const successMessage =
          "Payment authentication successful. Your invoice has been paid.";
        // 3D Secure handling, similar to activatePlanOnCompleted in ActivePlan component
        if (!data.payInvoice.organizationBilling.paymentRequiresAction) {
          // 3D Secure not required
          setFlash("success", successMessage);
        } else {
          // Handle 3D Secure
          stripe
            .confirmCardPayment(
              data.payInvoice.organizationBilling.stripeClientSecret,
            )
            .then(({ paymentIntent, error }) => {
              if (error) {
                setFlash(
                  "error",
                  "Payment authentication failed. Please update to a different payment method or contact support.",
                );
              } else if (paymentIntent.status === "succeeded") {
                setFlash("success", successMessage);
              }
            });
        }
        refetchOrganizationBilling();
        setShowPaymentInProgress(false);
      },
      onError: (data) => {
        if (data.message === "Invoice is already paid") {
          // Since Stripe tends to have lag of changing the invoice status from Open to Paid,
          // customers may see "Pay now" link for already-paid invoices.
          // When it happens, the backend returns "Invoice is already paid" error - in the UI,
          // treat them as a success but don't show it as "payment successful" to avoid the confusion.
          setFlash(
            "success",
            "This invoice is already paid. Refreshing the invoice list.",
          );
        } else {
          setFlash("error", data.message);
        }
        refetchOrganizationBilling();
        setShowPaymentInProgress(false);
      },
    });
  };
  return (
    <Panel id="invoices" title="Invoices">
      {invoices.length > 0 ? (
        <PanelTable borders>
          <thead>
            <tr>
              <th>Date</th>
              <th>Reference</th>
              <th>Amount</th>
              <th>Status</th>
              <th></th>
              <th className="w-1/2">Invoice PDF</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
              <tr key={invoice.id}>
                <td>
                  {invoice.invoicePdf ? (
                    <a href={invoice.invoicePdf} title="Download invoice PDF">
                      {formatDateShort(moment.unix(invoice.created))}
                    </a>
                  ) : (
                    formatDateShort(moment.unix(invoice.created))
                  )}
                </td>
                <td>{invoice.reference}</td>
                <td>{invoice.formattedTotal}</td>
                <td>{invoice.status}</td>
                <td>
                  {invoice.status === "Open" && (
                    <>
                      <Button
                        bare
                        onClick={() => handlePayInvoice(invoice.id)}
                        variant="link"
                      >
                        Pay now
                      </Button>{" "}
                      <Tip
                        content={
                          <>
                            By clicking "Pay now", you authorize us to charge
                            this invoice using your current payment method.
                          </>
                        }
                      />
                    </>
                  )}
                </td>
                <td>
                  {invoice.invoicePdf ? (
                    <a href={invoice.invoicePdf} title="Download invoice PDF">
                      <FontAwesomeIcon
                        className="pb-[1px] h-[15px]"
                        icon={faFilePdf}
                      />
                    </a>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </PanelTable>
      ) : (
        <PanelSection>
          <em>(none yet)</em>
        </PanelSection>
      )}
      {moment
        .unix(organizationCreatedAt)
        .isBefore(moment(new Date(2022, 1, 1))) ? (
        <PanelSection>
          For retrieving invoices before January 1st, 2022,{" "}
          <ContactSupportLink simpleStyle={true}>
            please contact support
          </ContactSupportLink>
          .
        </PanelSection>
      ) : null}
      {showPaymentInProgress && (
        <ModalContainer
          title="Processing payment..."
          layout="centered"
          onClose={() => setShowPaymentInProgress(false)}
        >
          Your payment is being processed. Please wait for confirmation.
        </ModalContainer>
      )}
    </Panel>
  );
};

export default InvoiceList;
