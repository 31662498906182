// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ExplainComparisonWorkbookQueryVariables = Types.Exact<{
  workbookId: Types.Scalars["ID"]["input"];
}>;

export type ExplainComparisonWorkbookQuery = {
  __typename?: "GraphqlQuery";
  getExplainWorkbookDetails: {
    __typename?: "ExplainWorkbook";
    id: string;
    parameterSets: Array<{
      __typename?: "ParameterSet";
      id: string;
      name: string;
    }>;
    baselineQuery: {
      __typename?: "ExplainQuery";
      id: string;
      name: string;
      explainResults: Array<{
        __typename?: "ExplainResult";
        id: string;
        createdAt: number;
        totalCost?: number | null;
        totalSharedBlksRead?: number | null;
        totalBlkReadTime?: number | null;
        runtimeMs?: number | null;
        planFingerprint?: string | null;
        parameterSetId?: string | null;
        annotatedJson?: any | null;
      }>;
    };
    explainQueries: Array<{
      __typename?: "ExplainQuery";
      id: string;
      name: string;
      explainResults: Array<{
        __typename?: "ExplainResult";
        id: string;
        createdAt: number;
        totalCost?: number | null;
        totalSharedBlksRead?: number | null;
        totalBlkReadTime?: number | null;
        runtimeMs?: number | null;
        planFingerprint?: string | null;
        parameterSetId?: string | null;
        annotatedJson?: any | null;
      }>;
    }>;
  };
};

export const ExplainComparisonWorkbookDocument = gql`
  query ExplainComparisonWorkbook($workbookId: ID!) {
    getExplainWorkbookDetails(workbookId: $workbookId) {
      id
      parameterSets {
        id
        name
      }
      baselineQuery {
        id
        name
        explainResults {
          id
          createdAt
          totalCost
          totalSharedBlksRead
          totalBlkReadTime
          runtimeMs
          planFingerprint
          parameterSetId
          annotatedJson
        }
      }
      explainQueries {
        id
        name
        explainResults {
          id
          createdAt
          totalCost
          totalSharedBlksRead
          totalBlkReadTime
          runtimeMs
          planFingerprint
          parameterSetId
          annotatedJson
        }
      }
    }
  }
`;

/**
 * __useExplainComparisonWorkbookQuery__
 *
 * To run a query within a React component, call `useExplainComparisonWorkbookQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplainComparisonWorkbookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplainComparisonWorkbookQuery({
 *   variables: {
 *      workbookId: // value for 'workbookId'
 *   },
 * });
 */
export function useExplainComparisonWorkbookQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplainComparisonWorkbookQuery,
    ExplainComparisonWorkbookQueryVariables
  > &
    (
      | { variables: ExplainComparisonWorkbookQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplainComparisonWorkbookQuery,
    ExplainComparisonWorkbookQueryVariables
  >(ExplainComparisonWorkbookDocument, options);
}
export function useExplainComparisonWorkbookLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplainComparisonWorkbookQuery,
    ExplainComparisonWorkbookQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplainComparisonWorkbookQuery,
    ExplainComparisonWorkbookQueryVariables
  >(ExplainComparisonWorkbookDocument, options);
}
export function useExplainComparisonWorkbookSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ExplainComparisonWorkbookQuery,
        ExplainComparisonWorkbookQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplainComparisonWorkbookQuery,
    ExplainComparisonWorkbookQueryVariables
  >(ExplainComparisonWorkbookDocument, options);
}
export type ExplainComparisonWorkbookQueryHookResult = ReturnType<
  typeof useExplainComparisonWorkbookQuery
>;
export type ExplainComparisonWorkbookLazyQueryHookResult = ReturnType<
  typeof useExplainComparisonWorkbookLazyQuery
>;
export type ExplainComparisonWorkbookSuspenseQueryHookResult = ReturnType<
  typeof useExplainComparisonWorkbookSuspenseQuery
>;
export type ExplainComparisonWorkbookQueryResult = Apollo.QueryResult<
  ExplainComparisonWorkbookQuery,
  ExplainComparisonWorkbookQueryVariables
>;
