// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import { BillingOverviewFragmentFragmentDoc } from "./Fragment.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type BillingOverviewQueryVariables = Types.Exact<{
  organizationSlug: Types.Scalars["ID"]["input"];
}>;

export type BillingOverviewQuery = {
  __typename?: "GraphqlQuery";
  getOrganizationBilling?: {
    __typename?: "OrganizationBilling";
    id: string;
    subscriptionActiveInStripe: boolean;
    subscriptionNextChargeAt?: number | null;
    subscriptionNextChargeAmount?: number | null;
    subscriptionNextChargeCurrency?: string | null;
    subscriptionCardType?: string | null;
    subscriptionCardLast4?: string | null;
    subscriptionCardExpMonth?: number | null;
    subscriptionCardExpYear?: number | null;
    subscriptionBilledManually: boolean;
    subscriptionInterval?: Types.SubscriptionInterval | null;
    billingAddressWithName?: string | null;
    billingVatId?: string | null;
    billingEmail?: string | null;
    paymentRequiresAction: boolean;
    stripeClientSecret?: string | null;
    invoices: Array<{
      __typename?: "Invoice";
      id: string;
      reference: string;
      formattedTotal: string;
      invoicePdf?: string | null;
      status: string;
      created: number;
    }>;
    planChoices: Array<{
      __typename?: "OrganizationPlanChoice";
      id: string;
      name: string;
      marketingLines: Array<string>;
      notIncludedMarketingLines: Array<string>;
      formattedMonthlyPrice: string;
      formattedServerOveragePrice?: string | null;
      isCurrent: boolean;
      isDowngrade: boolean;
    }>;
  } | null;
  getOrganizationDetails?: {
    __typename?: "Organization";
    id: string;
    createdAt: number;
    serverLimit?: number | null;
    serverCountForBilling: number;
  } | null;
};

export const BillingOverviewDocument = gql`
  query BillingOverview($organizationSlug: ID!) {
    getOrganizationBilling(organizationSlug: $organizationSlug) {
      ...BillingOverviewFragment
    }
    getOrganizationDetails(organizationSlug: $organizationSlug) {
      id
      createdAt
      serverLimit
      serverCountForBilling
    }
  }
  ${BillingOverviewFragmentFragmentDoc}
`;

/**
 * __useBillingOverviewQuery__
 *
 * To run a query within a React component, call `useBillingOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingOverviewQuery({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useBillingOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    BillingOverviewQuery,
    BillingOverviewQueryVariables
  > &
    (
      | { variables: BillingOverviewQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingOverviewQuery, BillingOverviewQueryVariables>(
    BillingOverviewDocument,
    options,
  );
}
export function useBillingOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BillingOverviewQuery,
    BillingOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BillingOverviewQuery,
    BillingOverviewQueryVariables
  >(BillingOverviewDocument, options);
}
export function useBillingOverviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BillingOverviewQuery,
        BillingOverviewQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BillingOverviewQuery,
    BillingOverviewQueryVariables
  >(BillingOverviewDocument, options);
}
export type BillingOverviewQueryHookResult = ReturnType<
  typeof useBillingOverviewQuery
>;
export type BillingOverviewLazyQueryHookResult = ReturnType<
  typeof useBillingOverviewLazyQuery
>;
export type BillingOverviewSuspenseQueryHookResult = ReturnType<
  typeof useBillingOverviewSuspenseQuery
>;
export type BillingOverviewQueryResult = Apollo.QueryResult<
  BillingOverviewQuery,
  BillingOverviewQueryVariables
>;
