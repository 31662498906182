// @ts-nocheck
import * as Types from "../../../../types/types";

import { gql } from "@apollo/client";
import { BillingOverviewFragmentFragmentDoc } from "../../Overview/gql/Fragment.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpdateCreditCardMutationVariables = Types.Exact<{
  organizationSlug: Types.Scalars["ID"]["input"];
  paymentMethodId: Types.Scalars["String"]["input"];
}>;

export type UpdateCreditCardMutation = {
  __typename?: "Mutation";
  updateBillingCard: {
    __typename?: "UpdateBillingCardPayload";
    organizationBilling: {
      __typename?: "OrganizationBilling";
      id: string;
      subscriptionActiveInStripe: boolean;
      subscriptionNextChargeAt?: number | null;
      subscriptionNextChargeAmount?: number | null;
      subscriptionNextChargeCurrency?: string | null;
      subscriptionCardType?: string | null;
      subscriptionCardLast4?: string | null;
      subscriptionCardExpMonth?: number | null;
      subscriptionCardExpYear?: number | null;
      subscriptionBilledManually: boolean;
      subscriptionInterval?: Types.SubscriptionInterval | null;
      billingAddressWithName?: string | null;
      billingVatId?: string | null;
      billingEmail?: string | null;
      paymentRequiresAction: boolean;
      stripeClientSecret?: string | null;
      invoices: Array<{
        __typename?: "Invoice";
        id: string;
        reference: string;
        formattedTotal: string;
        invoicePdf?: string | null;
        status: string;
        created: number;
      }>;
      planChoices: Array<{
        __typename?: "OrganizationPlanChoice";
        id: string;
        name: string;
        marketingLines: Array<string>;
        notIncludedMarketingLines: Array<string>;
        formattedMonthlyPrice: string;
        formattedServerOveragePrice?: string | null;
        isCurrent: boolean;
        isDowngrade: boolean;
      }>;
    };
  };
};

export const UpdateCreditCardDocument = gql`
  mutation UpdateCreditCard($organizationSlug: ID!, $paymentMethodId: String!) {
    updateBillingCard(
      organizationSlug: $organizationSlug
      paymentMethodId: $paymentMethodId
    ) {
      organizationBilling {
        ...BillingOverviewFragment
      }
    }
  }
  ${BillingOverviewFragmentFragmentDoc}
`;
export type UpdateCreditCardMutationFn = Apollo.MutationFunction<
  UpdateCreditCardMutation,
  UpdateCreditCardMutationVariables
>;

/**
 * __useUpdateCreditCardMutation__
 *
 * To run a mutation, you first call `useUpdateCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditCardMutation, { data, loading, error }] = useUpdateCreditCardMutation({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useUpdateCreditCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCreditCardMutation,
    UpdateCreditCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCreditCardMutation,
    UpdateCreditCardMutationVariables
  >(UpdateCreditCardDocument, options);
}
export type UpdateCreditCardMutationHookResult = ReturnType<
  typeof useUpdateCreditCardMutation
>;
export type UpdateCreditCardMutationResult =
  Apollo.MutationResult<UpdateCreditCardMutation>;
export type UpdateCreditCardMutationOptions = Apollo.BaseMutationOptions<
  UpdateCreditCardMutation,
  UpdateCreditCardMutationVariables
>;
