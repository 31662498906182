import React from "react";
import ReactSelectCreatable from "react-select/creatable";

type Props<T> = {
  value: T | null;
  options: T[];
  onChange: (selected: T | null) => void;
  placeholder?: React.ReactNode;
  isClearable?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean;
};

function CreatableSelect<T>({
  value,
  options,
  onChange,
  placeholder,
  isClearable,
  isMulti,
  isDisabled,
}: Props<T>): React.ReactElement {
  return (
    <ReactSelectCreatable
      value={value}
      options={options}
      onChange={onChange}
      isClearable={isClearable}
      isMulti={isMulti}
      placeholder={placeholder}
      isDisabled={isDisabled}
    />
  );
}

export default CreatableSelect;
