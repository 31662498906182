import bytes from "bytes";
import moment from "moment-timezone";
import capitalize from "lodash/capitalize";

import humanizeDuration from "humanize-duration";
import { Currency, getCurrencySymbol } from "./currency";
import { epochFromFullTransactionId, xidFromFullTransactionId } from "./txid";

export function formatDateFromUnixTs(value: number): string {
  return moment(new Date(value * 1000)).format("MMM DD");
}

export function formatDateShort(momentTs: moment.Moment): string {
  return momentTs.format("YYYY-MM-DD");
}

export function formatDateMonthDay(momentTs: moment.Moment): string {
  return momentTs.format("MMMM D");
}

export function formatDateMonthDayYear(momentTs: moment.Moment): string {
  return momentTs.format("MMMM D, YYYY");
}

export function formatTimestampLong(momentTs: moment.Moment): string {
  return momentTs.format("MMM DD hh:mm:ssa zz");
}

export function formatTimestampShort(momentTs: moment.Moment): string {
  return momentTs.format("YYYY-MM-DD hh:mm:ssa zz");
}

export function formatTimestampShorter(momentTs: moment.Moment): string {
  return momentTs.format("YYYY-MM-DD hh:mma");
}

export function formatTimestampShortest(momentTs: moment.Moment): string {
  return momentTs.format("MMM DD hh:mma");
}

export function formatTimeShort(momentTs: moment.Moment): string {
  return momentTs.format("hh:mma zz");
}

export function formatBytes(
  value: number,
  opts?: { ifNull?: string; decimalPlaces?: number },
): string {
  if (value == null) {
    return opts?.ifNull ?? "";
  }
  const decimalPlaces = opts?.decimalPlaces ?? 1;
  return bytes.format(value, { unitSeparator: " ", decimalPlaces });
}

export function formatBytesTrend(value: number, prevValue: number): string {
  if (prevValue === 0) {
    // technically a +infinity percent change, but it's not worth the UX overhead to explain this correctly
    return "n/a";
  }
  const delta = value - prevValue;
  const pctDelta = (value - prevValue) / prevValue;
  if (delta === 0) {
    return "(no change)";
  }
  const sign = delta > 0 ? "+" : "";
  const pctChange = sign + formatPercent(pctDelta);
  const absChange = sign + formatBytes(delta);
  return `${absChange} (${pctChange})`;
}

export function formatBytesTrendDetail(
  value: number,
  prevValue: number,
  ts: number,
  prevTs: number,
): string {
  if (prevValue === 0) {
    // as above, not quite true, but better than alternatives
    return "n/a";
  }
  const delta = value - prevValue;
  if (delta === 0) {
    return `(no change from ${formatDateFromUnixTs(
      prevTs,
    )} to ${formatDateFromUnixTs(ts)})`;
  } else {
    return `from ${formatBytes(prevValue)} at ${formatDateFromUnixTs(
      prevTs,
    )} to ${formatBytes(value)} at ${formatDateFromUnixTs(ts)}`;
  }
}

export function formatCountShorthand(
  value: number,
  decimalPoints?: number,
): string {
  if (decimalPoints === undefined || decimalPoints === null) {
    decimalPoints = 0;
  }

  const suffixes = ["", "k", "M", "B", "T"];
  let result = value;
  let idx = 0;
  while (result >= 1000 && idx < suffixes.length - 1) {
    result /= 1000;
    idx++;
  }
  return String(result.toFixed(decimalPoints)) + suffixes[idx];
}

export function formatPercent(value: number, decimalPoints?: number): string {
  if (decimalPoints === undefined || decimalPoints === null) {
    decimalPoints = 2;
  }

  return (value * 100).toFixed(decimalPoints) + "%";
}

export function formatMs(
  value: number,
  decimalPoints?: number,
  spacing?: boolean,
): string {
  if (decimalPoints === undefined || decimalPoints === null) {
    decimalPoints = 2;
  }
  if (spacing === undefined || spacing === null) {
    spacing = false;
  }

  return (
    addThousandsSeparator(value.toFixed(decimalPoints)) +
    (spacing ? " " : "") +
    "ms"
  );
}

export function formatMsAdaptive(value: number, spacing?: boolean): string {
  let decimalPoints;
  if (value === 0) {
    decimalPoints = 2;
  } else if (value < 1) {
    decimalPoints = 3;
  } else if (value < 1000) {
    decimalPoints = 2;
  } else {
    decimalPoints = 0;
  }

  return formatMs(value, decimalPoints, spacing);
}

export type IntervalUnit = "ms" | "s" | "m" | "h";

export function formatInterval(
  value: number,
  unit: IntervalUnit,
  precision: number,
): string {
  return addThousandsSeparator(value.toFixed(precision)) + " " + unit;
}

/**
 * Picks a reasonable unit for representing the given interval and ~10× smaller
 * intervals. Return the value according to the selected unit and the unit
 * itself.
 *
 * This is useful for determining axis labels, because there will generally be a
 * small number of sibling ticks at around the same magnitude (log axes will
 * make this a bit more complicated).
 */
export function scaleInterval(ms: number): [value: number, unit: IntervalUnit] {
  const unitsAndScales: [unit: IntervalUnit, scale: number][] = [
    ["s", 1000],
    ["m", 60],
    ["h", 60],
  ];

  let unitIdx = 0;
  let scaledValue = ms;
  let unit: IntervalUnit = "ms";

  while (scaledValue > 10_000 && unitIdx < unitsAndScales.length) {
    const [nextUnit, nextScale] = unitsAndScales[unitIdx];
    scaledValue /= nextScale;
    unit = nextUnit;
    unitIdx++;
  }

  return [scaledValue, unit];
}

export function scaleIntervalTo(ms: number, unit: IntervalUnit): number {
  switch (unit) {
    case "ms":
      return ms;
    case "s":
      return ms / 1000;
    case "m":
      return ms / 1000 / 60;
    case "h":
      return ms / 1000 / 60 / 60;
  }
}

export function formatMsTrend(value: number, prevValue: number): string {
  if (prevValue === 0) {
    // technically a +infinity percent change, but it's not worth the UX overhead to explain this correctly
    return "n/a";
  }
  const delta = value - prevValue;
  const pctDelta = (value - prevValue) / prevValue;
  if (delta === 0) {
    return "(no change)";
  }
  const sign = delta > 0 ? "+" : "";
  const pctChange = sign + formatPercent(pctDelta);
  const absChange = sign + formatMs(delta);
  return `${absChange} (${pctChange})`;
}

export function formatMsTrendDetailRange(
  value: number,
  prevValue: number,
  startTs: number,
  endTs: number,
  prevStartTs: number,
  prevEndTs: number,
): string {
  if (prevValue === 0) {
    // as above, not quite true, but better than alternatives
    return "n/a";
  }
  const delta = value - prevValue;
  if (delta === 0) {
    return `(no change from ${formatDateFromUnixTs(
      prevStartTs,
    )}-${formatDateFromUnixTs(prevEndTs)} to ${formatDateFromUnixTs(
      startTs,
    )}-${formatDateFromUnixTs(endTs)})`;
  } else {
    return `from ${formatMs(prevValue)} during ${formatDateFromUnixTs(
      prevStartTs,
    )}-${formatDateFromUnixTs(prevEndTs)} to ${formatMs(
      value,
    )} during ${formatDateFromUnixTs(startTs)}-${formatDateFromUnixTs(endTs)}`;
  }
}

// See https://stackoverflow.com/a/2901298
const addThousandsSeparator = (numericStr: string): string => {
  var parts = numericStr.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export function formatNumber(value: number, decimal = 0): string {
  if (typeof value !== "number") {
    return value;
  }

  return addThousandsSeparator(value.toFixed(decimal));
}

export function formatNumberWithThreshold(value: number, decimal = 0): string {
  if (typeof value !== "number") {
    return value;
  }
  const roundedValue = parseFloat(value.toFixed(decimal));
  if (roundedValue === 0 && value > 0) {
    return `<0.${"1".padStart(decimal, "0")}`;
  }

  return addThousandsSeparator(value.toFixed(decimal));
}

// Like formatNumber, but assumes we only have limited significant
// digits (without resorting to scientific notation, which is less
// readable in some contexts). It rounds the number as necessary to
// avoid implying excess precision, and formats accordingly. Assumes
// positive input.
export function formatApproximateNumber(value: number): string {
  if (value < 10) {
    return formatNumber(value, value < 1 ? 2 : 1);
  }
  return formatNumber(Number(value.toPrecision(2)));
}

export function formatNumberTrend(
  value: number,
  prevValue: number,
  decimal?: number,
): string {
  if (prevValue === 0) {
    // technically a +infinity percent change, but it's not worth the UX overhead to explain this correctly
    return "n/a";
  }
  const delta = value - prevValue;
  const pctDelta = (value - prevValue) / prevValue;
  if (delta === 0) {
    return "(no change)";
  }
  const sign = delta > 0 ? "+" : "";
  const pctChange = sign + formatPercent(pctDelta);
  const absChange = sign + formatNumber(delta, decimal);
  return `${absChange} (${pctChange})`;
}

export function formatNumberTrendDetailRange(
  value: number,
  prevValue: number,
  startTs: number,
  endTs: number,
  prevStartTs: number,
  prevEndTs: number,
  decimal?: number,
): string {
  if (prevValue === 0) {
    // as above, not quite true, but better than alternatives
    return "n/a";
  }
  const delta = value - prevValue;
  if (delta === 0) {
    return `(no change from ${formatDateFromUnixTs(
      prevStartTs,
    )}-${formatDateFromUnixTs(prevEndTs)} to ${formatDateFromUnixTs(
      startTs,
    )}-${formatDateFromUnixTs(endTs)})`;
  } else {
    return `from ${formatNumber(
      prevValue,
      decimal,
    )} during ${formatDateFromUnixTs(prevStartTs)}-${formatDateFromUnixTs(
      prevEndTs,
    )} to ${formatNumber(value, decimal)} during ${formatDateFromUnixTs(
      startTs,
    )}-${formatDateFromUnixTs(endTs)}`;
  }
}

// formatTimeAgo returns an oriented duration like `10 minutes ago`.
// Takes moment object, the format is not capitalized (e.g. `a minute ago`).
export function formatTimeAgo(value: moment.Moment | null | undefined): string {
  if (value === undefined || value === null) {
    return "-";
  }
  return moment.duration(-moment().diff(value)).humanize(true);
}

// formatDuration returns a duration (prefix/suffix-less).
// Takes the number of seconds since the Unix Epoch.
export function formatDuration(
  value: number | null | undefined,
  withCapitalize?: boolean,
): string {
  if (value === undefined || value === null) {
    return "-";
  }
  if (withCapitalize === undefined || withCapitalize === null) {
    withCapitalize = true;
  }

  if (withCapitalize) {
    return capitalize(moment.duration(value * 1000).humanize());
  } else {
    return moment.duration(value * 1000).humanize();
  }
}

export function formatDurationPrecise(value: number): string {
  if (value === undefined || value === null) {
    return "-";
  }

  return humanizeDuration(value, {
    largest: 2,
    round: true,
    delimiter: " ",
  });
}

export const formatEstimatedCount: (
  n: number | null,
  threshold: number,
) => string = (n, threshold) =>
  n === null ? "?" : n <= threshold ? `${n}` : `${threshold}+`;

export function formatCost(amt: number, currency: Currency): string {
  const currencySymbol = getCurrencySymbol(currency) ?? currency;
  return currencySymbol + formatNumber(amt);
}

// format a possibly schema-qualified SQL object name
export function formatSqlObjectName(
  schemaName: string | null,
  name: string,
): string {
  if (!schemaName) {
    return quoteSqlIdentifier(name);
  }

  return quoteSqlIdentifier(schemaName) + "." + quoteSqlIdentifier(name);
}

// quote identifier if needed
function quoteSqlIdentifier(identifier: string): string {
  /*
   * From quote_identifier in Postgres src/backend/utils/adt/ruleutils.c:
   *
   * Can avoid quoting if ident starts with a lowercase letter or underscore
   * and contains only lowercase letters, digits, and underscores, *and* is
   * not any SQL keyword.  Otherwise, supply quotes.
   */

  // TODO: match SQL keywords
  if (/^[a-z_][a-z0-9_]*$/.test(identifier)) {
    return identifier;
  }

  return `"${identifier.replace('"', '""')}"`;
}

// also copied to https://github.com/pganalyze/pganalyze-docs/blob/main/components/CheckDocumentation/vacuum/XminHorizon.tsx
export function formatFullTransactionID(value: number): string {
  const epoch = epochFromFullTransactionId(value);
  const xid = xidFromFullTransactionId(value) as number;

  return `${epoch}:${xid}`;
}

export function formatList(items: string[], max = Infinity): string {
  if (max < 1) {
    // ignore bogus values of max
    return "";
  }
  if (items.length === 1) {
    return items[0];
  }
  const shortlist = items.slice(0, max);
  const isTruncated = max < items.length;
  const tail = isTruncated
    ? ` and ${items.length - max} more`
    : ` and ${shortlist.at(-1)}`;

  const headCount = isTruncated ? shortlist.length : shortlist.length - 1;
  const head = shortlist.slice(0, headCount).join(", ");

  return head + (headCount > 1 ? "," : "") + tail;
}

export function formatPartialList(shortlist: string[], total: number): string {
  if (total <= shortlist.length) {
    return formatList(shortlist);
  }

  const addComma = shortlist.length > 1;
  const notInShortlistCount = total - shortlist.length;

  return (
    shortlist.join(", ") +
    (addComma ? "," : "") +
    ` and ${notInShortlistCount} more`
  );
}

/**
 * Returns a truncated string with a specified length. Do not truncate a string
 * if the specified length is equal or longer than the string length.
 * When a string is truncated, add … at the end.
 */
export function truncate(str: string, len: number): string {
  if (str == null || len == null) {
    return str;
  }
  if (str.length <= len) {
    return str;
  }
  return str.slice(0, len) + "…";
}
